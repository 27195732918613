import styled from 'styled-components'

import colors from '../../../common/themes/Colors'

import { Typo20PointSevenLightBlackBarlowSemiBoldItalicCapital } from '../../../common/styleGuide/Typos'

import { leaderBoardConstants } from '../../constants/leaderBoardConstants'

interface TbdCardContainerProps {
   isPlayerOne: boolean
}

export const TbdCardContainer = styled.div<TbdCardContainerProps>`
   width: 265px;
   height: 80px;
   display: flex;
   transform: skew(-${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   border: 3.1px solid ${colors.pencilGrey};
   margin-left: ${props => (props.isPlayerOne ? '64px' : '32px')};
   background-color: ${colors.lightGreyTwo};
   justify-content: center;
   position: relative;
   margin-bottom: 0.7px;
`

export const IconWrapper = styled.div`
   margin-bottom: -5px;
`

export const TbdTextComponent = styled(
   Typo20PointSevenLightBlackBarlowSemiBoldItalicCapital
)``

export const TextComponentAndIconWrapper = styled.div`
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 18px;
`
