import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
   MaintenanceContainer,
   MaintenanceTextHeader,
   MaintenanceTextDescription,
   MainDiv
} from './styledComponents'

const MaintenanceComponent = (): ReactElement => {
   const { t } = useTranslation()
   return (
      <MainDiv>
         <MaintenanceContainer>
            <MaintenanceTextHeader>
               {t('underMaintenanceText')}
            </MaintenanceTextHeader>
            <MaintenanceTextDescription>
               {t('maintenanceDescription')}
            </MaintenanceTextDescription>
         </MaintenanceContainer>
      </MainDiv>
   )
}

export default MaintenanceComponent
