import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line

import { Typo36CharcoalGreyBoldItalic } from '../../../common/styleGuide/Typos'
import Heading from '../../../common/components/Heading'

import {
   groundRadius,
   SpecialChallengeType
} from '../../constants/MatchConfigConstants'
import SpecialChallengeMatch from '../../stores/models/SpecialChallengeMatch'
import { MatchModel } from '../../stores/types'

import SurvivalMatchStatus from '../SurvivalMatchStatus'
import MileStoneList from '../MileStoneList'
import {
   Container,
   WheelContainer,
   StyledStatsList,
   WagonWheelWrapper,
   WagonWheelNoteText,
   StyledWagonWheel
} from './styledComponents'

type Props = WithTranslation & {
   matchModel: MatchModel
}

@observer
class OverView extends Component<Props> {
   getMatchDetails = () => {
      const matchModel = this.props.matchModel as SpecialChallengeMatch
      switch (matchModel.specialChallengeDetails?.nameEnum) {
         case SpecialChallengeType.STEAK_CHALLENGE:
         case SpecialChallengeType.WAGON_WHEEL_CHALLENGE:
            return <MileStoneList mileStones={matchModel.challengeMilestones} />
         case SpecialChallengeType.SURVIVAL_CHALLENGE:
            return (
               <SurvivalMatchStatus
                  matchModel={matchModel as SpecialChallengeMatch}
               />
            )
         default:
            return <div />
      }
   }

   render(): ReactNode {
      const { matchModel, t } = this.props
      const { getSelfUserMatchDetails, getUserSelfUserControls } = matchModel
      const { gameStats } = getSelfUserMatchDetails()
      const { getMatchDetails } = this
      return (
         <>
            <StyledStatsList listItems={gameStats.getGameStats()} />
            <Container data-testid='ground'>
               <WheelContainer>
                  <Heading title={t('titles.wagonwheel')} />
                  <WagonWheelNoteText>
                     {t('wagonWheelNoteText')}
                  </WagonWheelNoteText>
                  <WagonWheelWrapper>
                     <StyledWagonWheel
                        radius={groundRadius}
                        userControls={getUserSelfUserControls()}
                        Typo={Typo36CharcoalGreyBoldItalic}
                     />
                  </WagonWheelWrapper>
               </WheelContainer>
               {getMatchDetails()}
            </Container>
         </>
      )
   }
}

export default withTranslation()(OverView)
