import React from 'react'

interface Props {
   width: number
   height: number
}

const OversIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 27 28'
   >
      <defs>
         <filter id='8d2z2ydqla'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
         <path id='sofwgnxpyb' d='M0 .231h17.38v21.564H0z'></path>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#8d2z2ydqla)'
         transform='translate(0 -6)'
      >
         <g transform='translate(0 6.236)'>
            <mask id='4e0ukp1e3c' fill='#fff'>
               <use xlinkHref='#sofwgnxpyb'></use>
            </mask>
            <path
               fill='#FFF'
               d='M14.134.231C6.328.231 0 6.56 0 14.365c0 2.726.774 5.271 2.111 7.43L17.381.61a14.208 14.208 0 00-3.248-.38'
               mask='url(#4e0ukp1e3c)'
               opacity='0.25'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M22.612 9.564L6.272 32.235c.453.234.92.445 1.4.629l15.98-22.17a14.246 14.246 0 00-1.04-1.13M19.19 7.403L3.21 29.57c.27.327.554.641.851.943L20.355 7.91a14.035 14.035 0 00-1.165-.507'
         ></path>
         <path
            fill='#FFF'
            d='M9.482 33.42a14.15 14.15 0 003.25.38c7.805 0 14.134-6.327 14.134-14.134 0-2.727-.775-5.273-2.113-7.432L9.482 33.42z'
            opacity='0.25'
         ></path>
      </g>
   </svg>
)

OversIcon.defaultProps = {
   width: 27,
   height: 28
}
export default OversIcon
