import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import QuickPlayMatch from '../../../stores/models/QuickPlayMatch'

import { TitleAndValue } from '../../MatchesCommon/MatchesCommon'
import MatchCardBowlingLevel from '../../BowllingLevel'
import BatSheildOnOrOff from '../../BatSheild'

import MatchesBaseHeader from '../BaseHeader'
import { ItemWrapper } from '../BaseHeader/styledComponents'

interface QuickPlayMatchHeaderProps {
   model: QuickPlayMatch
}

const QuickPlayMatchHeader = (
   props: QuickPlayMatchHeaderProps
): ReactElement => {
   const { t } = useTranslation()
   const { model } = props
   const { basicMatchDetails } = model
   const { gameStats } = model.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <MatchesBaseHeader model={model}>
         <ItemWrapper>
            <MatchCardBowlingLevel
               bowlingLevel={basicMatchDetails.getBowlingLevel()}
            />
         </ItemWrapper>
         <ItemWrapper>
            <BatSheildOnOrOff isBatSheildOn={basicMatchDetails.batShield} />
         </ItemWrapper>
         <ItemWrapper>
            <TitleAndValue
               title={t('matches.over', { count: overs })}
               value={overs.toString()}
            />
         </ItemWrapper>
      </MatchesBaseHeader>
   )
}
export default QuickPlayMatchHeader
