import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import PathtoFinalsArrowIcon from '../../../../common/icons/PathToFinalsArrowIcon'

import KnockoutMatchComponent from '../KnockoutMatchCardComponent'

import {
   KnockoutMatchContainer,
   DependencyMatchOneWrapper,
   DependencyMatchTwoWrapper,
   ResultMatchWrapper,
   DependencyMatchesWrapper,
   ArrowIconWrapper,
   FirstArrowTextWrapper,
   SecondArrowTextWrapper,
   ArrowText
} from './styledComponent'

interface PlayerProps {
   playerName: string
   bgColor: string
}

interface DependencyMatchProps {
   playerOne: PlayerProps
   playerTwo: PlayerProps
}

interface KnockoutMatchDetailsProps {
   playerOne: PlayerProps
   playerTwo: PlayerProps
   dependencyMatchOne: DependencyMatchProps
   dependencyMatchTwo: DependencyMatchProps
}

interface KnockOutMatchHierarchyWithFirstDependencyMatchProps {
   matchDetails: KnockoutMatchDetailsProps
}

const KnockOutMatchHierarchyWithFirstDependencyMatch = (
   props: KnockOutMatchHierarchyWithFirstDependencyMatchProps
): ReactElement => {
   const { playerOne, playerTwo, dependencyMatchOne } = props.matchDetails

   const { t } = useTranslation()

   return (
      <KnockoutMatchContainer>
         <DependencyMatchesWrapper>
            <DependencyMatchOneWrapper>
               <KnockoutMatchComponent
                  playerOne={dependencyMatchOne.playerOne}
                  playerTwo={dependencyMatchOne.playerTwo}
               />
            </DependencyMatchOneWrapper>
            <DependencyMatchTwoWrapper></DependencyMatchTwoWrapper>
         </DependencyMatchesWrapper>
         <ArrowIconWrapper>
            <FirstArrowTextWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </FirstArrowTextWrapper>
            <PathtoFinalsArrowIcon width={135} height={62} />
            <SecondArrowTextWrapper>
               <ArrowText>
                  <ArrowText>{t('tournaments:winnerText')}</ArrowText>
               </ArrowText>
            </SecondArrowTextWrapper>
         </ArrowIconWrapper>
         <ResultMatchWrapper>
            <KnockoutMatchComponent
               playerOne={playerOne}
               playerTwo={playerTwo}
            />
         </ResultMatchWrapper>
      </KnockoutMatchContainer>
   )
}

export default KnockOutMatchHierarchyWithFirstDependencyMatch
