import styled from '@emotion/styled'

import CorrectIcon from '../../../common/icons/CorrectIcon'
import WrongIcon from '../../../common/icons/WrongIcon'
import Colors from '../../../common/themes/Colors'
import ImageComponent from '../../../common/components/BaseImageComponent'
import {
   Typo16BlackBarlowBold,
   Typo24WhiteBarlowBoldItalic,
   Typo30LightYellowBarlowBoldItalic
} from '../../../common/styleGuide/Typos'

import { CommonBottomBarBg } from '../ConfigItem'

export const MainContainer = styled.div`
   background-image: ${Colors.linerGradients.resourceItemGradient};
   display: flex;
   flex-direction: row;
   transform: skew(-20deg);
   align-items: center;
   justify-content: flex-start;
   overflow: hidden;
   height: 73px;
   box-sizing: border-box;
`
export const ValueTitleContainer = styled.div`
   display: flex;
   flex-direction: column;
   height: 100%;
`

export const RewardsContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`
export const TitleText = styled(CommonBottomBarBg)`
   min-width: 106px; //need to overwrite min width
`

export const ConfettiImage = styled(ImageComponent)`
   position: absolute;

   height: 49px;
   object-fit: contain;
`
export const ValueContainer = styled.div`
   display: flex;
   flex-direction: row;
   min-width: 106px;
   object-fit: contain;
   background-color: ${Colors.marineBlue};
   position: relative;
   height: 49px;
`
export const MileStoneIconValueContainer = styled.div``

export const TitleContainer = styled.div`
   transform: skew(20deg);
`
export const MileStoneItemText = styled(Typo16BlackBarlowBold)`
   object-fit: contain;
   text-align: center;
   font-style: italic;
`

export const MileStoneItemValue = styled(Typo30LightYellowBarlowBoldItalic)`
   padding-left: 20px;
   padding-top: 7px;
   padding-bottom: 7px;
   padding-right: 10px;
   transform: skew(20deg);
   font-family: barlow;
`

export const TargetContainer = styled.div`
   padding-top: 16px;
   transform: skew(20deg);
   padding-bottom: 6px;
   margin-right: 5px;
`

export const WrongIconWrapper = styled(WrongIcon)``
export const RightIconWrapper = styled(CorrectIcon)``
export const RewardIconValueWrapper = styled.div`
   display: flex;
   flex-direction: row;

   overflow: hidden;
   min-width: 82px;
`

export const RewardIconContainer = styled.div`
   box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
   padding-left: 12px;
`
export const RewardIcon = styled(ImageComponent)`
   width: 23px;
   height: 26px;
   transform: skew(20deg);
`
export const RewardValue = styled(Typo24WhiteBarlowBoldItalic)`
   line-height: normal;
   letter-spacing: normal;
   text-align: center;
   color: ${Colors.white};
   font-size: 26px;
   padding-left: 4px;
   transform: skew(20deg);
   padding-bottom: 3px;
`

export const RewardTitle = styled(Typo16BlackBarlowBold)``
