import React, { ReactElement, Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line
import { observer } from 'mobx-react'

import LoadingWrapper from '../../../common/LoadingWrapper'
import Loader from '../../../common/components/Loader'

import { LoaderWrapper } from '../../../matches/routes/MatchesRoute/styledComponents'
import { Leaderboard } from '../../stores/LeaderboardsStore'
import { LeaderboardEntry } from '../../stores/types'

import DotsItem from '../DotsItem'
import LeaderboardHeader from '../LeaderboardHeader'
import LeaderboardItemCard from '../LeaderboardItemCard'
import { IBC_RATING_ENUM } from '../../../common/constants/ResourceConstants'
import {
   LeaderboardCard,
   LeaderboardEmptyCard,
   LeaderboardEmptyText,
   LeaderboardMainDiv,
   LeaderboardRatingNote
} from './styledComponents'

type LeaderboardProps = WithTranslation & {
   leaderboard: Leaderboard
   title: string
   rankingParameter: string
   loggedInUserID: string
}

@observer
class LeaderboardTable extends Component<LeaderboardProps> {
   componentDidMount() {
      const { leaderboard, rankingParameter } = this.props
      if (rankingParameter === IBC_RATING_ENUM) {
         leaderboard.getRatingLeaderboardAPI()
      } else {
         leaderboard.getLeaderboardAPI(rankingParameter)
      }
   }

   renderLeaderboardTable = (
      index: number,
      data: LeaderboardEntry,
      lbEntries: Array<LeaderboardEntry>,
      loggedInUserID: string
   ): Array<ReactElement> => {
      const childs: Array<ReactElement> = []
      if (index !== 0 && data.rank - lbEntries[index - 1].rank > 1) {
         childs.push(<DotsItem key={`dots-${index}`} />)
      }
      childs.push(
         <LeaderboardItemCard
            key={index}
            profilePic={data.profilePic}
            countryPic={data.countryPic}
            rank={data.rank}
            name={data.name}
            score={data.score}
            isSelf={data.userID === loggedInUserID}
         />
      )
      return childs
   }

   handleLeaderboardAPIRetry = (): void => {
      this.props.leaderboard.getLeaderboardAPI(this.props.rankingParameter)
   }

   handleRatingRetry = (): void => {
      this.props.leaderboard.getRatingLeaderboardAPI()
   }

   getStatusAndRetryFunctions = () => {
      const { leaderboard, rankingParameter } = this.props
      const {
         getLeaderboardAPIStatus,
         getRatingLeaderboardAPIStatus,
         getLeaderboardAPIError,
         getRatingLeaderboardAPIError
      } = leaderboard
      if (rankingParameter === IBC_RATING_ENUM) {
         return {
            loadingStatus: getRatingLeaderboardAPIStatus,
            apiError: getRatingLeaderboardAPIError,
            onRetry: this.handleRatingRetry
         }
      }

      return {
         loadingStatus: getLeaderboardAPIStatus,
         apiError: getLeaderboardAPIError,
         onRetry: this.handleLeaderboardAPIRetry
      }
   }

   renderLoader = (): ReactElement => (
      <LoaderWrapper>
         <Loader height={60} width={60} />
      </LoaderWrapper>
   )

   renderAddiontalComponents = (rankingParameter: string): ReactElement => {
      if (rankingParameter === IBC_RATING_ENUM) {
         return (
            <LeaderboardRatingNote>
               {this.props.t('leaderboards.iBCRatingNoteText')}
            </LeaderboardRatingNote>
         )
      }
      return <></>
   }

   renderEmptyCase = (rankingParameter: string): ReactElement => {
      const { t } = this.props
      let emptyText = t('leaderboards.noDataToDisplay')
      if (rankingParameter === IBC_RATING_ENUM) {
         emptyText = t('leaderboards.iBCRatingEmptyCaseText')
      }
      return (
         <LeaderboardEmptyCard>
            <LeaderboardEmptyText>{emptyText}</LeaderboardEmptyText>
         </LeaderboardEmptyCard>
      )
   }

   render(): JSX.Element {
      const {
         leaderboard,
         title,
         loggedInUserID,
         rankingParameter
      } = this.props
      const { rankingParamentResourcePic, lbEntries } = leaderboard
      const {
         loadingStatus,
         apiError,
         onRetry
      } = this.getStatusAndRetryFunctions()

      return (
         <LoadingWrapper
            apiStatus={loadingStatus}
            apiError={apiError}
            onRetry={onRetry}
            renderLoadingView={this.renderLoader}
         >
            {lbEntries.length > 0 ? (
               <LeaderboardMainDiv>
                  <LeaderboardCard>
                     <LeaderboardHeader
                        picURL={rankingParamentResourcePic}
                        resourceDisplayName={title}
                     />
                     {lbEntries.map((data, index) =>
                        this.renderLeaderboardTable(
                           index,
                           data,
                           lbEntries,
                           loggedInUserID
                        )
                     )}
                  </LeaderboardCard>
               </LeaderboardMainDiv>
            ) : (
               this.renderEmptyCase(rankingParameter)
            )}
         </LoadingWrapper>
      )
   }
}
export default withTranslation()(LeaderboardTable)
