import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { TournamnetDetailsTypes } from '../../stores/types'
import TournamentLogoWithNameAndDateComponent from '../TournamentLogoWithNameAndDateComponent'

import {
   TournamentsDetailsHeaderContainer,
   Container,
   InfoIconWrapper,
   InfoText,
   RoundDetailsText
} from './styledComponent'

interface TournamentDetailsHeaderComponentProps {
   tournamentDetails: TournamnetDetailsTypes
   showRoundDetails(): void
}

const getDateFormat = (start_datetime: string): string => {
   const dateString = start_datetime?.substring(0, 10)
   const givenDateFormat = new Date(
      +dateString?.substring(0, 4),
      +dateString?.substring(6, 7) - 1,
      +dateString?.substring(8, 10)
   ).toString()
   return (
      givenDateFormat?.substring(8, 10) +
      givenDateFormat?.substring(3, 8) +
      givenDateFormat?.substring(11, 15)
   )
}

const TournamentDetailsHeaderComponent = (
   props: TournamentDetailsHeaderComponentProps
): ReactElement => {
   const { tournamentDetails, showRoundDetails } = props
   const {
      end_datetime,
      tournament_name,
      tournament_pic_url,
      start_datetime
   } = tournamentDetails

   const date = `${getDateFormat(start_datetime)} - ${getDateFormat(
      end_datetime
   )}`

   const { t } = useTranslation()
   return (
      <TournamentsDetailsHeaderContainer>
         <TournamentLogoWithNameAndDateComponent
            tournamentDetails={{
               name: tournament_name,
               logo: tournament_pic_url,
               date: date
            }}
         />
         <Container onClick={showRoundDetails}>
            <InfoIconWrapper>
               <InfoText>{t('tournaments:i')}</InfoText>
            </InfoIconWrapper>
            <RoundDetailsText>{t('tournaments:roundDetails')}</RoundDetailsText>
         </Container>
      </TournamentsDetailsHeaderContainer>
   )
}

export default TournamentDetailsHeaderComponent
