import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'

import TournamentsRoute from '../../tournaments/routes/TournamentsRoute'
import TournamentsDetailsRoute from '../../tournaments/routes/TournamentsDetailsRoute'
import MatcheDetailsRoute from '../../matches/routes/MatchDetailsRoute'
import Leaderboard from '../../leaderboard/routes/leaderboard'

import { getIdToken } from '../utils/StorageUtils'

import {
   MY_TEAM,
   MY_PROFILE,
   FRIENDS,
   TOURNAMENTS,
   CHALLENGES,
   LEADERBOARDS,
   WALL_OF_FAME,
   SETTINGS,
   HOME_SCREEN_PATH,
   MATCH_DETAILS_PATH,
   LOGIN_SCREEN_PATH
} from '../constants/NavigationConstants'
import { ProtectedRoute } from '../utils/RouteUtils'
import LoadingWrapper from '../LoadingWrapper'
import AuthStore from '../stores/AuthStore'

import CricketConstantsStore from '../stores/CricketConstantsStore'
import MyProfile from './Profile'
import { RouteContainer } from './styledComponents'
import ComingSoon from './ComingSoon'

type RoutesProps = {
   cricketConstantsStore: CricketConstantsStore
   authStore: AuthStore
}

@observer
class Routes extends Component<RoutesProps> {
   componentDidMount() {
      const { isLoggedIn } = this.props.authStore
      this.getCricketConstants(isLoggedIn)
   }

   componentWillUnmount() {
      this.isloginReaction()
   }

   isloginReaction = reaction(
      () => this.props.authStore.isLoggedIn,
      (isLoggedIn: boolean) => {
         this.getCricketConstants(isLoggedIn)
      }
   )

   getCricketConstants = (isLoggedIn: boolean) => {
      isLoggedIn && this.props.cricketConstantsStore.getCricketGameDetails()
   }

   render() {
      const {
         getCricketGameDetailsAPIError,
         getCricketGameDetailsAPIStatus,
         getCricketGameDetails
      } = this.props.cricketConstantsStore

      const getIDtoken = getIdToken()
      return (
         <>
            {getIDtoken ? (
               <LoadingWrapper
                  apiStatus={getCricketGameDetailsAPIStatus}
                  apiError={getCricketGameDetailsAPIError as Error}
                  onRetry={getCricketGameDetails}
               >
                  <RouteContainer>
                     <Switch>
                        <ProtectedRoute
                           exact
                           path={HOME_SCREEN_PATH}
                           component={ComingSoon}
                        ></ProtectedRoute>
                        <ProtectedRoute
                           exact
                           path={MY_PROFILE}
                           component={MyProfile}
                        />
                        <ProtectedRoute
                           path={`${MATCH_DETAILS_PATH}/:matchId`}
                           component={MatcheDetailsRoute}
                        />
                        <ProtectedRoute
                           exact
                           path={MY_TEAM}
                           component={ComingSoon}
                        />
                        <ProtectedRoute
                           exact
                           path={FRIENDS}
                           component={ComingSoon}
                        />
                        <ProtectedRoute
                           exact
                           path={CHALLENGES}
                           component={ComingSoon}
                        />
                        <ProtectedRoute
                           path={`${TOURNAMENTS}/:tournamentId`}
                           component={TournamentsDetailsRoute}
                        />
                        <ProtectedRoute
                           exact
                           path={TOURNAMENTS}
                           component={TournamentsRoute}
                        />
                        <ProtectedRoute
                           exact
                           path={LEADERBOARDS}
                           component={Leaderboard}
                        />
                        <ProtectedRoute
                           exact
                           path={WALL_OF_FAME}
                           component={ComingSoon}
                        />
                        <ProtectedRoute
                           exact
                           path={SETTINGS}
                           component={ComingSoon}
                        />
                     </Switch>
                  </RouteContainer>
               </LoadingWrapper>
            ) : (
               <Redirect
                  to={{
                     pathname: LOGIN_SCREEN_PATH
                  }}
               />
            )}
         </>
      )
   }
}

export default Routes
