import { action } from 'mobx'

import UserControl from '../../../../common/stores/models/UserControls'
import {
   MatchConfigType,
   PitchMarkerOnOff
} from '../../../constants/MatchConfigConstants'
import { getBowlerLevelText } from '../../../Utils/MatchUtils'

import { BasicMatchDetailsType, BowlingLevelDetails } from '../../types'

import GameDetails from '../GameDetails'
import LevelDetails from '../LevelDetails'
import BaseGameModeDetails from '../BaseGameModeDetails'
import SuperRunMultiplier from '../SuperRunMultiplier'
class BasicMatchDetails extends GameDetails {
   superRunMultiplier!: SuperRunMultiplier
   hashKey: string
   matchId: string
   currentInnings: number | null
   levelDetails: LevelDetails
   matchPrepared: boolean
   gameModeDetails: BaseGameModeDetails
   weatherCondition: string
   matchOvers: number
   matchWickets: number
   batShield: boolean
   pitchMarker: boolean
   bowlingLevelDetails?: BowlingLevelDetails
   userControls: UserControl
   constructor() {
      super()
      this.hashKey = ''
      this.matchId = ''
      this.currentInnings = null
      this.levelDetails = new LevelDetails()
      this.matchPrepared = false
      this.gameModeDetails = new BaseGameModeDetails()
      this.weatherCondition = ''
      this.matchOvers = -1
      this.matchWickets = -1
      this.batShield = false
      this.pitchMarker = false
      this.userControls = new UserControl([])
   }

   @action.bound
   setBasicMatchDetails(matchDetails: BasicMatchDetailsType): void {
      const { super_run_multiplier: superRunMultipliers } = matchDetails
      const {
         stadium_details,
         pitch_type,
         match_overs,
         bowling_mode
      } = matchDetails
      this.hashKey = matchDetails.hash_key
      this.matchId = matchDetails.match_id
      this.currentInnings = matchDetails.current_innings
      this.weatherCondition = matchDetails.weather_condition
      this.matchPrepared = matchDetails.match_prepared
      this.pitchMarker =
         matchDetails.pitch_marker === PitchMarkerOnOff.ON.toString()
      this.matchOvers = matchDetails.match_overs
      this.matchWickets = matchDetails.match_wickets
      this.bowlingLevelDetails = matchDetails.bowling_level_details
      this.batShield = matchDetails.bat_shield
      this.superRunMultiplier = new SuperRunMultiplier()
      this.superRunMultiplier.setSuperRunMultiplierAPI(superRunMultipliers)
      this.levelDetails.setTournamentLevelDetailsFromAPI(
         matchDetails.level_details
      )
      this.gameModeDetails.setBaseGameModeDetailsFromAPI(
         matchDetails.game_mode_details
      )

      const gameDetails = {
         stadium_details: stadium_details,
         pitch_type: pitch_type,
         overs: match_overs,
         bowling_mode: bowling_mode
      }

      this.setGameDetailsFromAPI(gameDetails)
      this.userControls = new UserControl(matchDetails?.user_controls || [])
   }

   getMatachConfiguration = () => {
      const configItems = [
         {
            configType: MatchConfigType.WEATHER,
            value: this.weatherCondition
         },
         {
            configType: MatchConfigType.OVERS,
            value: this.matchOvers.toString()
         },
         {
            configType: MatchConfigType.WICKETS,
            value: this.matchWickets.toString()
         }
      ]
      return configItems
   }

   getBowlingLevel = () => {
      const bowilingLevelText = getBowlerLevelText(
         this.bowlingLevelDetails?.level as number
      )
      return {
         displayName: bowilingLevelText,
         level: bowilingLevelText
      }
   }

   getUserControls(userControls) {
      if (userControls) {
         return userControls.map(
            controlDetails => new UserControl(controlDetails)
         )
      }
      return []
   }

   getGameModeConfig = () => {
      const { superRunMultiplier, gameModeDetails } = this
      return {
         runs: superRunMultiplier.runs,
         superRuns: superRunMultiplier.superRuns,
         gameMode: gameModeDetails.name,
         gameModeDisplayName: gameModeDetails.displayName
      }
   }
}

export default BasicMatchDetails
