import React from 'react'

import { bowlerLevelPics } from '../../../common/externalImageUrls/ImageUrl'
import { Typo24WhiteBarlowBoldItalic } from '../../../common/styleGuide/Typos'

import BowlingLevelBg from '../../backgrounds/BowlingLevel'

import {
   BowlerPic,
   BowlingLevelBgWrapper,
   BowlingLevelTextWrapper,
   BowlingLevelContainer
} from './styledComponents'

interface BowlingLevelProps {
   levelText?: string
   level: string
}

const BowlingLevel = (props: BowlingLevelProps) => {
   const { levelText, level } = props
   return (
      <BowlingLevelContainer>
         <BowlerPic src={bowlerLevelPics[level]}></BowlerPic>
         <BowlingLevelBgWrapper>
            <BowlingLevelBg level={level} />
            <BowlingLevelTextWrapper>
               <Typo24WhiteBarlowBoldItalic>
                  {levelText}
               </Typo24WhiteBarlowBoldItalic>
            </BowlingLevelTextWrapper>
         </BowlingLevelBgWrapper>
      </BowlingLevelContainer>
   )
}
export default BowlingLevel
