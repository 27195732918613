import styled from 'styled-components'

export const ContentWrapper = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
`
export const IconAndTextWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
`
export const BowlingLevelBgWrapper = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   margin-bottom: 5px;
`

export const BowlingLevelTextWrapper = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   align-items: center;
   justify-content: center;
   display: flex;
`
