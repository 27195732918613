import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { NameText } from '../../../../../common/components/Profile/UserNameAndPic/styledComponents'
import { Typo16CoolGreyBarlowBoldItalic } from '../../../../../common/styleGuide/Typos'

import MatchUserDetails from '../../../../stores/models/MatchUserDetails'

import {
   ProfileWithScoreCardContainer,
   ProfilePic,
   NameAndScoreCardContainer
} from './styledComponents'

interface ProfileWithScoreCardProps {
   userMatchDetails: MatchUserDetails
   isSelfUser: boolean
}

const ProfileWithScoreCard = (
   props: ProfileWithScoreCardProps
): ReactElement => {
   const { t } = useTranslation()
   const {
      userMatchDetails: { picUrl, name, gameStats },
      isSelfUser
   } = props
   return (
      <ProfileWithScoreCardContainer>
         <ProfilePic src={picUrl ? picUrl : ''} />
         <NameAndScoreCardContainer>
            <NameText>{isSelfUser ? t('you') : name}</NameText>
            <Typo16CoolGreyBarlowBoldItalic>
               {`${gameStats.score}-${gameStats.wickets}`}
            </Typo16CoolGreyBarlowBoldItalic>
         </NameAndScoreCardContainer>
      </ProfileWithScoreCardContainer>
   )
}

ProfileWithScoreCard.defaultProps = {
   isSelfUser: false
}

export default ProfileWithScoreCard
