import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import RoundOneLeaderBoardHeading from '../RoundOneLeaderboardHeading'
import LeaderBoardItem from '../LeaderBoardItem'
import RankAndPrizeItem from '../RankAndPrizesItem'
import PopupLeaderboardModel from '../../../stores/models/PopupLeaderBoardModel'

import { LeaderboardWrapper, ItemsWrapper } from './styledComponents'

interface OverallLeaderBoardProps {
   popupLeaderBoardModels: Array<PopupLeaderboardModel>
}

const OverallLeaderBoard = (props: OverallLeaderBoardProps): ReactElement => {
   const { popupLeaderBoardModels } = props
   const { t } = useTranslation()
   return (
      <LeaderboardWrapper>
         <RoundOneLeaderBoardHeading
            RoundHeadingName={t('tournaments:roundOneLeaderBoardHeading')}
         />
         <RankAndPrizeItem />
         <ItemsWrapper>
            {popupLeaderBoardModels.map((reward, index) => {
               const {
                  maxRank,
                  minRank,
                  resources,
                  totalLbEntriesCount,
                  lbContributionCount
               } = reward
               return (
                  maxRank !== -1 && (
                     <LeaderBoardItem
                        key={index}
                        maxRank={maxRank}
                        minRank={minRank}
                        resources={resources}
                        totalLbsCount={totalLbEntriesCount}
                        lbContributionCount={lbContributionCount}
                     />
                  )
               )
            })}
         </ItemsWrapper>
      </LeaderboardWrapper>
   )
}

export default OverallLeaderBoard
