import { inject, observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'

import { goToUserProfilePage } from '../../utils/NavigationUtils'
import CloseIcon from '../../icons/CloseIcon'
import LogoutIcon from '../../icons/LogoutIcon'
import { Typo32LightBlueGreyBarlowBoldItalicCapital } from '../../styleGuide/Typos'
import {
   LogoutPopUpBody,
   LogoutPopUpContainer,
   LogoutPopUpHeader,
   ConfirmationText,
   YesLogoutButton,
   ButtonText,
   StayInButton,
   ButtonsWrapper,
   CloseIconWrapper,
   LogoutButton,
   LogoutText
} from './styledComponents'

const LogoutPopUp = inject('authStore')(
   observer(props => {
      const {
         authStore: { logoutAPI, logoutAPIStatus }
      } = props
      const { t } = useTranslation()
      const history = useHistory()
      const [modalIsOpen, setIsOpen] = React.useState(false)
      function openModal() {
         setIsOpen(true)
      }
      function closeModal() {
         setIsOpen(false)
      }
      function onSuccessLogout() {
         closeModal()
         goToUserProfilePage(history)
      }
      function logout() {
         logoutAPI(onSuccessLogout)
      }

      return (
         <>
            <LogoutButton onClick={openModal}>
               <LogoutIcon />
               <LogoutText>{t('logOut.logout')}</LogoutText>
            </LogoutButton>
            <Modal
               isOpen={modalIsOpen}
               onRequestClose={closeModal}
               className='ModalContent'
               overlayClassName='Overlay'
               contentLabel='Logoout Model'
            >
               <LogoutPopUpContainer>
                  <LogoutPopUpHeader>
                     <Typo32LightBlueGreyBarlowBoldItalicCapital>
                        {t('logOut.logout')}
                     </Typo32LightBlueGreyBarlowBoldItalicCapital>
                     <CloseIconWrapper onClick={closeModal}>
                        <CloseIcon />
                     </CloseIconWrapper>
                  </LogoutPopUpHeader>
                  <LogoutPopUpBody>
                     <ConfirmationText>
                        {t('logOut.areYouSureWantToLogoutOfCompanionApp')}
                     </ConfirmationText>
                     <ButtonsWrapper>
                        <StayInButton onClick={closeModal}>
                           <ButtonText>{t('logOut.noStayIn')}</ButtonText>
                        </StayInButton>
                        <YesLogoutButton
                           text={t('logOut.yesLogout')}
                           onClick={logout}
                           textTypo={ButtonText}
                           apiStatus={logoutAPIStatus}
                        />
                     </ButtonsWrapper>
                  </LogoutPopUpBody>
               </LogoutPopUpContainer>
            </Modal>
         </>
      )
   })
)

export default LogoutPopUp
