import React, { ReactElement } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useTranslation } from 'react-i18next'

import { Typo18BattleShipGreyBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'

import MatchConfig from '../MatchConfig'
import OversList from '../OversList'
import OverView from '../OverView'

interface TabProps {
   title: string
}

const TabComponent = (props: TabProps): ReactElement => (
   <Typo18BattleShipGreyBarlowSemiBoldCapital>
      {props.title}
   </Typo18BattleShipGreyBarlowSemiBoldCapital>
)

interface MatchDetailsTabsProps {
   matchModel: any
}

const MatchDetailsTabs = (props: MatchDetailsTabsProps): ReactElement => {
   const { matchModel } = props
   const { t } = useTranslation()
   return (
      <Tabs>
         <TabList>
            <Tab>
               <TabComponent title={t('tabs.overview')} />
            </Tab>
            {/* <Tab>
                  <TabComponent title={t('tabs.scoreCard')} />
               </Tab>
               <Tab>
                  <TabComponent title={t('tabs.video')} />
               </Tab> */}
            <Tab>
               <TabComponent title={t('tabs.ballByBall')} />
            </Tab>
            <Tab>
               <TabComponent title={t('tabs.matchConfiguration')} />
            </Tab>
         </TabList>
         <TabPanel>
            <OverView matchModel={matchModel} />
         </TabPanel>
         {/* <TabPanel></TabPanel>
            <TabPanel></TabPanel> */}
         <TabPanel>
            <OversList baseMatch={matchModel} />
         </TabPanel>
         <TabPanel>
            <MatchConfig model={matchModel} />
         </TabPanel>
      </Tabs>
   )
}
export default MatchDetailsTabs
