import React from 'react'

import { confettiImageUrl } from '../../../common/externalImageUrls/ImageUrl'

import ChallengeMileStone from '../../stores/models/SpecialChallengeMatch/ChallengeMileStone'

import {
   MileStoneItemValue,
   ValueContainer,
   MileStoneItemText,
   ValueTitleContainer,
   RewardsContainer,
   MainContainer,
   RewardIconValueWrapper,
   RewardIcon,
   RewardValue,
   ConfettiImage,
   TargetContainer,
   TitleContainer,
   TitleText,
   RewardIconContainer,
   RightIconWrapper,
   WrongIconWrapper
} from './styledComponents'
import './styles.css'

type Props = {
   mileStoneItem: ChallengeMileStone
}

const renderConfettiOrNot = (targetStatus: boolean): React.ReactNode => {
   if (targetStatus) return <ConfettiImage src={confettiImageUrl} />

   return null
}
const renderIconBasedOnTargetStatus = (
   targetStatus: boolean
): React.ReactNode => {
   if (targetStatus) {
      return (
         <TargetContainer>
            <RightIconWrapper />
         </TargetContainer>
      )
   } else if (targetStatus) {
      return (
         <TargetContainer>
            <WrongIconWrapper />
         </TargetContainer>
      )
   }
}

const renderRewards = (mileStoneItem: ChallengeMileStone): React.ReactNode =>
   mileStoneItem.rewards.map((reward, index) => (
      <RewardIconValueWrapper
         key={index}
         className={index % 2 === 0 ? 'firstReward' : 'secondReward'}
      >
         <RewardIconContainer>
            <RewardIcon src={reward.pic} />
         </RewardIconContainer>
         <RewardValue>{reward.value}</RewardValue>
      </RewardIconValueWrapper>
   ))

function MileStoneItem(props: Props) {
   const { mileStoneItem } = props
   const { name, currValue, minValue, isTargetAchieved } = mileStoneItem
   return (
      <MainContainer>
         <ValueTitleContainer>
            <ValueContainer>
               {renderConfettiOrNot(isTargetAchieved)}

               <MileStoneItemValue>{`${currValue}/${minValue}`}</MileStoneItemValue>
               {renderIconBasedOnTargetStatus(isTargetAchieved)}
            </ValueContainer>
            <TitleContainer>
               <TitleText innerDivClassName='bottomBarBg'>
                  <MileStoneItemText>{name}</MileStoneItemText>
               </TitleText>
            </TitleContainer>
         </ValueTitleContainer>
         <RewardsContainer>{renderRewards(mileStoneItem)}</RewardsContainer>
      </MainContainer>
   )
}

export default MileStoneItem
