import styled, { css } from 'styled-components'
import tw from 'twin.macro'

import colors from '../../../common/themes/Colors'
import { tablet } from '../../../common/utils/MixinUtils'

export const SkewNegative20Angle = css`
   transform: skew(-20deg);
`

export const SkewPositive20Angle = css`
   transform: skew(20deg);
`
export const ScoreCardContainer = styled.div`
   display: inline-block;
   ${SkewNegative20Angle}
`

export const MatchCurrentStatusContainer = styled(ScoreCardContainer)`
   ${tw`flex items-center `}
   background-color:${colors.darkTwo};
   margin-top:48px;
   padding: 12px 60px 11px 50px;
   max-width:60%;
   height:6.2%;
   display:flex;
   ${tablet} {
      width:80%;
      height:6.2%;
   }
`

export const MatchCurrentStatusTargetDiv = styled(MatchCurrentStatusContainer)`
   padding: 15px 60px 15px 50px;
`

export const MatchStatusCotainer = styled.div`
   ${tw`flex flex-col`};
   margin-left: 7px;
   width: 48%;
`
export const RemainingMatchStatusContainer = styled.div`
   ${tw`flex justify-between`};
   margin-top: 48px;
`
export const MatchStatContainer = styled.div`
   ${tw`flex-col flex`};
`
export const MatchCurrentStatsWrapper = styled.div`
   ${tw`flex items-center justify-between w-full`}
   ${SkewPositive20Angle}
`
