import React from 'react'

interface RoundTwoDottedLineIconProps {
   width: number
   height: number
}

const RoundTwoDottedLineIcon = (props: RoundTwoDottedLineIconProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 4 201'
      {...props}
   >
      <path
         d='M2.085 2.126v197.657'
         stroke='#BABABA'
         strokeWidth={2.446}
         fill='none'
         fillRule='evenodd'
         strokeDasharray='0 7.828'
         strokeLinecap='round'
         strokeLinejoin='round'
      />
   </svg>
)

RoundTwoDottedLineIcon.defaultProps = {
   width: 4,
   height: 201
}

export default RoundTwoDottedLineIcon
