import styled from 'styled-components'
import ReactInfiniteScrollComponent from 'react-infinite-scroll-component'
import Colors from '../../../common/themes/Colors'
import { tablet, mobile } from '../../../common/utils/MixinUtils'

export const CardsWrapper = styled(ReactInfiniteScrollComponent)`
   display: flex;
   justify-content: space-around;
   ${tablet} {
      justify-content: space-around;
   }
   ${mobile} {
      justify-content: space-around;
   }
   text-align: center;
   align-items: flex-start;
   flex-wrap: wrap;
`
export const LoaderWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin-bottom: 1%;
   margin-top: 1%;
`
export const TitleWrapperDiv = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
   border-bottom: 1px solid ${Colors.charcoalGrey};
   padding-bottom: 8px;
   margin-left: 12px;
   padding-top: 40px;
   margin-bottom: 4px;
`
