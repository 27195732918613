import { observable } from 'mobx'

type RewardType = {
   icon: string
   value: number
}

class Reward {
   @observable icon: string
   @observable value: number

   constructor(reward: RewardType) {
      this.icon = reward.icon
      this.value = reward.value
   }
}

export default Reward
