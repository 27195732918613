import styled from 'styled-components'
import { Typo14PointOneWhiteBarlowBoldItalicCapital } from '../../../../common/styleGuide/Typos'
import colors from '../../../../common/themes/Colors'

export const Container = styled.div`
   height: 560px;
   width: 1238px;
   display: flex;
`

export const FirstKnockoutMatchWrapper = styled.div`
   margin-left: 10px;
   margin-top: 25px;
   margin-bottom: 52.3px;
`

export const SecondKncokoutMatchWrapper = styled.div`
   margin-left: 10px;
   margin-top: 25px;
`

export const ThirdMatchHierarchyWrapper = styled.div`
   margin-left: 10px;
   margin-top: 25px;
`
export const DottedIconWrapper = styled.div`
   margin-top: -37px;
   margin-left: 65px;
`

export const SecondDottedLineWrapper = styled.div`
   margin-top: -5px;
   margin-left: 250px;
`

export const HorizontalDottedLineWrapper = styled.div`
   margin-top: -5px;
   margin-left: 250px;
   transform: rotate(90deg);
   position: absolute;
   left: 255px;
   top: 185px;
`

export const HorizontalLineDiv = styled.div`
   border: 2px dotted ${colors.coolGrey};
   width: 200px;
   height: 0px;
   transform: rotate(90deg);
`

export const DottedLinesWrapper = styled.div`
   display: flex;
   margin-bottom: -78px;
`

export const LeftPartContainer = styled.div``

export const RightPartContainer = styled.div``

export const SemiFinalMatchWrapper = styled.div`
   margin-top: 150px;
   margin-left: 230px;
`
export const SemiFinalRoundOneArrwoIconWrapper = styled.div`
   position: absolute;
   top: 82px;
   margin-left: 8px;
`

export const SemiFinalRoundTwoArrwoIconWrapper = styled.div`
   position: absolute;
   top: 285px;
   margin-left: 5px;
`
export const ArrowText = styled(Typo14PointOneWhiteBarlowBoldItalicCapital)``

export const WinnerTextOneWrapper = styled.div`
   position: absolute;
   top: 0px;
   margin-top: 63px;
   margin-left: 45px;
`

export const WinnerTextTwoWrapper = styled.div`
   position: absolute;
   top: 0px;
   margin-top: 195px;
   margin-left: 45px;
`

export const WinnerTextThreeWrapper = styled.div`
   position: absolute;
   top: 0px;
   margin-top: 265px;
   margin-left: 45px;
`

export const WinnerTextFourWrapper = styled.div`
   position: absolute;
   top: 0px;
   margin-top: 398px;
   margin-left: 45px;
`
