import styled from 'styled-components'

import Colors from '../../../common/themes/Colors'

export const BallItemBg = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 48px;
   height: 48px;
   margin-right: 8px;
`
export const OverItemWrapper = styled.div`
   display: flex;
   justify-content: space-around;
   align-items: flex-start;
   flex-direction: column;
   background: ${Colors.darkBlueGray};
   padding: 1.5%;
   margin-right: 2%;
   margin-top: 1%;
   margin-bottom: 1%;
`

export const BallItemsWrapper = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   margin-top: 16px;
`
