import React from 'react'

interface Props {
   width: number
   height: number
}

const HighScoreIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 30 30'
   >
      <defs>
         <filter id='4vl4ykp8za'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#4vl4ykp8za)'
         transform='translate(-7 -5)'
      >
         <g fill='#FFF'>
            <path
               d='M24.734 29.665H19.24l.936-1.998.366-.844c.467.242.95.461 1.446.657.498-.191.982-.407 1.45-.645l.36.832.937 1.998z'
               opacity='0.25'
            ></path>
            <path d='M28.231 29.665H15.742c-.687 0-1.249.562-1.249 1.25v4.058h14.924v-4.059c.063-.687-.5-1.249-1.186-1.249M31.728 5H12.245a.76.76 0 00-.749.75c0 .436.312.811.75.811h19.482a.76.76 0 00.75-.75c0-.436-.313-.811-.75-.811'></path>
            <path
               d='M31.728 6.561v6.744c0 6.244-3.872 11.927-9.741 14.175-5.87-2.31-9.742-7.93-9.742-14.237V6.56h19.483z'
               opacity='0.25'
            ></path>
            <path d='M21.987 9.371l1.311 3.996h4.184l-3.372 2.498 1.249 3.997-3.372-2.498-3.372 2.498 1.248-3.997-3.372-2.498h4.184zM14.23 20.764a12.586 12.586 0 01-1.86-1.84c-1.748-2.122-2.872-4.932-2.872-7.93v-.062h2.747V8.434H7v2.56c0 6.246 3.871 11.616 9.304 13.864.718.308 1.467.552 2.239.734a15.349 15.349 0 01-4.313-4.827zm17.498-12.33v2.498h2.685v.063h.063c0 2.997-1.062 5.744-2.873 7.93a13.2 13.2 0 01-1.802 1.767 15.22 15.22 0 01-4.319 4.886 13.56 13.56 0 002.187-.72c5.433-2.249 9.304-7.619 9.304-13.863v-2.56h-5.245z'></path>
         </g>
      </g>
   </svg>
)

HighScoreIcon.defaultProps = {
   width: 30,
   height: 30
}
export default HighScoreIcon
