import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { knockoutMatchConstants } from '../../constants/knockOutMatchCardConstants'

import {
   PlayerDetailsContainer,
   PlayerNameText,
   BottomContainer,
   ScoreCardText,
   TopPart,
   YetToPlayText,
   PlayingTextContainer,
   PlayingText
} from './styledComponent'

interface PlayerDetails {
   playerName: string
   matchStatus: string
   score: string
   isPlayerOne: boolean
}

const PlayerDetailsComponent = (props: PlayerDetails): ReactElement => {
   const { playerName, matchStatus, score, isPlayerOne } = props
   const { playing, yetToPlay } = knockoutMatchConstants
   const { t } = useTranslation()
   return (
      <PlayerDetailsContainer>
         <TopPart isPlayerOne={isPlayerOne}>
            <PlayerNameText>{playerName}</PlayerNameText>
         </TopPart>
         {matchStatus === yetToPlay ? (
            <BottomContainer isPlayerOne={isPlayerOne}>
               <YetToPlayText isPlayerOne={isPlayerOne}>
                  {t('tournaments:knockOutMatchPlayerCard.yetToPlay')}
               </YetToPlayText>
            </BottomContainer>
         ) : matchStatus === playing ? (
            <BottomContainer isPlayerOne={isPlayerOne}>
               <ScoreCardText isPlayerOne>{score}</ScoreCardText>
               <PlayingTextContainer>
                  <PlayingText>
                     {t('tournaments:knockOutMatchPlayerCard.playing')}
                  </PlayingText>
               </PlayingTextContainer>
            </BottomContainer>
         ) : (
            <BottomContainer isPlayerOne={isPlayerOne}>
               <ScoreCardText isPlayerOne>{score}</ScoreCardText>
            </BottomContainer>
         )}
      </PlayerDetailsContainer>
   )
}

export default PlayerDetailsComponent
