import styled from 'styled-components'
import colors from '../../../common/themes/Colors'
import {
   Typo24LightBlueGrayBarlowBoldItalicCapital,
   Typo16LightBlueGrayBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

export const TableContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 16px;
   margin-left: 29px;
`

export const LeaderboardRoundContainer = styled.div`
   background-color: ${colors.oceanBlue};
   display: flex;
   justify-content: space-between;
   padding: 16px 48px 16px 32px;
   max-width: 840px;
`

export const LeaderboardText = styled(
   Typo24LightBlueGrayBarlowBoldItalicCapital
)``

export const Top100Text = styled(Typo16LightBlueGrayBarlowBoldItalicCapital)`
   margin-left: 8px;
   margin-top: 5px;
`

export const TextWrapper = styled.div`
   display: flex;
   align-items: center;
`
