import styled from 'styled-components'

export const ContentWrapper = styled.div`
   display: flex;
   align-items: flex-start;
   justify-content: center;
   flex-direction: column;
   width: 80px;
`
export const IconAndTextWrapper = styled.div`
   display: flex;
   align-items: flex-end;
   justify-content: flex-start;
   margin-bottom: 4px;
`
export const IconWrapper = styled.div`
   margin-right: 8px;
   display: flex;
   align-items: flex-end;
`
