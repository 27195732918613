import { StatsDisplayNames } from '../../../../common/constants/StatsConstants'
import HighScoreIcon from '../../../../common/icons/HighScoreIcon'
import { getStats } from '../../../../common/utils/ProfileUtils'
import {
   TotalPrizeType,
   UserGameStatsType,
   UserMileStoneType
} from '../../types'

class UserGameStats {
   score: number
   wickets: number
   overs: number
   fours: number
   innings_no: number
   past_scores: Array<number>
   sixes: number
   target: number
   average: number
   strikeRate: number
   outs: number
   highScoreWithOneBat: number
   highestScore: number
   dotBalls: number
   totalPrize: TotalPrizeType | undefined
   achievedMileStonesInfo: Array<UserMileStoneType> = []
   runs: number
   constructor(gameStatsBolb: string) {
      const gameStats: UserGameStatsType = JSON.parse(gameStatsBolb)
      this.score = gameStats.current_state.score
      this.wickets = gameStats.current_state.wickets
      this.overs = gameStats.current_state.overs
      this.fours = gameStats.fours
      this.innings_no = gameStats.innings_no
      this.past_scores = gameStats.past_scores
      this.sixes = gameStats.sixes
      this.target = gameStats.target
      this.average = gameStats.average
      this.strikeRate = gameStats.strike_rate
      this.outs = gameStats.outs
      this.highScoreWithOneBat = gameStats.high_score_with_one_bat
      this.dotBalls = gameStats.dot_balls
      this.totalPrize = gameStats.total_prize
      this.achievedMileStonesInfo = gameStats.user_milestones_details || []
      this.runs = gameStats.runs
      this.highestScore = gameStats.highest_score
   }

   getGameStats = () => {
      const {
         fours,
         sixes,
         dotBalls,
         overs,
         average,
         highestScore,
         highScoreWithOneBat,
         strikeRate,
         outs,
         runs,
         wickets
      } = this
      const baseStats = getStats({
         fours,
         sixes,
         dot_balls: dotBalls,
         overs,
         average,
         highest_score: highestScore,
         highest_score_with_one_bat: highScoreWithOneBat,
         strike_rate: strikeRate,
         outs,
         runs: runs,
         wickets
      })

      //Note: As we have reset hightest scores in some bugs we need are not displaying this to them.
      if (this.highScoreWithOneBat !== -1) {
         baseStats.splice(3, 0, {
            name: StatsDisplayNames.HIGHEST_SCORE_WITH_1_WICKET,
            value: this.highScoreWithOneBat,
            displayName: 'stats.highestScoreWith1Wicket',
            Icon: HighScoreIcon
         })
      }
      return baseStats
   }
}

export default UserGameStats
