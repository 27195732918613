import { action } from 'mobx'

import { SuperRunMultiplierType } from '../../types'

class SuperRunMultiplier {
   superRuns: number
   runs: number

   constructor() {
      this.superRuns = 0
      this.runs = 0
   }

   @action.bound
   setSuperRunMultiplierAPI(superRunMultiplier: SuperRunMultiplierType) {
      this.superRuns = superRunMultiplier.super_runs
      this.runs = superRunMultiplier.runs
   }
}

export default SuperRunMultiplier
