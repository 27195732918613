import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { History } from 'history'
import { withRouter } from 'react-router-dom'

import AuthStore from '../../stores/AuthStore'
import { goToUserProfilePage } from '../../utils/NavigationUtils'
import { userLoginState } from '../../constants/AuthConstants'
import SteamSignPage from '../../components/SteamSignPage'

interface LoginPageProps {
   authStore: AuthStore
   history: History
}

@inject('authStore')
@observer
class LoginPage extends Component<LoginPageProps> {
   componentDidMount() {
      const { authStore, history } = this.props
      if (authStore.isLoggedIn) {
         goToUserProfilePage(history, authStore.authenticatedUsername)
      }
   }

   componentWillUnmount() {
      const { authStore } = this.props
      authStore.setLoginCurrentState(userLoginState.login)
   }

   render() {
      const { authStore } = this.props
      return <SteamSignPage authStore={authStore} />
   }
}
export default withRouter(LoginPage)
