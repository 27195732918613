import React, { Component, ReactElement } from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line

import AuthStore from '../../../common/stores/AuthStore'

import { Typo18BattleShipGreyBarlowSemiBold } from '../../../common/styleGuide/Typos'

import {
   ResourceEnums,
   IBC_RATING_ENUM
} from '../../../common/constants/ResourceConstants'
import SectionTitle from '../../../common/components/SectionTitle'

import LeaderboardsStore from '../../stores/LeaderboardsStore'
import LeaderboardTabContent from '../../components/LeaderboardTabContent'
import { LeaderboardResourceConstants } from '../../constants/resourceConstants'

import {
   LeaderboardContainer,
   LeaderboardTabsContainer
} from './styledComponents'

import './index.css'

interface TabProps {
   title: string
}

const TabComponent = (props: TabProps): ReactElement => (
   <Typo18BattleShipGreyBarlowSemiBold>
      {props.title}
   </Typo18BattleShipGreyBarlowSemiBold>
)

type LeaderboardProps = WithTranslation & {
   leaderboardStore: LeaderboardsStore
   authStore: AuthStore
}

const leaderboardTabs = [
   {
      label: LeaderboardResourceConstants.SUPERRUNS,
      value: ResourceEnums.SUPER_RUN
   },
   {
      label: LeaderboardResourceConstants.IBCRATING,
      value: IBC_RATING_ENUM
   },
   {
      label: LeaderboardResourceConstants.FANBASE,
      value: ResourceEnums.FAN_BASE
   }
]
@inject('leaderboardStore')
@inject('authStore')
@observer
class Leaderboard extends Component<LeaderboardProps> {
   render(): JSX.Element {
      const { t, leaderboardStore, authStore } = this.props

      return (
         <LeaderboardContainer>
            <SectionTitle
               title={t('leaderboards.leaderboardsText')}
               width={266}
            />
            <LeaderboardTabsContainer>
               <Tabs className={'leaderboardTable'}>
                  <TabList>
                     {leaderboardTabs.map((tabItem, index) => (
                        <Tab key={index}>
                           <TabComponent title={tabItem.label} />
                        </Tab>
                     ))}
                  </TabList>
                  {leaderboardTabs.map((tabItemData, key) => {
                     const { label, value } = tabItemData
                     return (
                        <TabPanel key={key}>
                           <LeaderboardTabContent
                              title={label}
                              rankingParameter={value}
                              leaderboard={
                                 leaderboardStore.resourceWiseLBEntries[value]
                              }
                              loggedInUserID={authStore.getloggedInUserID()}
                           />
                        </TabPanel>
                     )
                  })}
               </Tabs>
            </LeaderboardTabsContainer>
         </LeaderboardContainer>
      )
   }
}

export default withTranslation()(Leaderboard)
