import styled from '@emotion/styled'
import tw from 'twin.macro'

import colors from '../../../../../common/themes/Colors'

export const VersusContainer = styled.div`
   ${tw`flex justify-center items-center self-center mx-4`}
   width: 35px;
   height: 21px;
   border-radius: 13px;
   background-color: ${colors.prussianBlue};
`

export const MatchContestantsContainer = styled.div`
   ${tw`flex justify-start  mx-4`};
   flex-grow: 1;
`
