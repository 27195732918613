import styled from 'styled-components'
import {
   Typo14WhiteBarlowBoldItalic,
   Typo16CoolGreyBarlowMediumItalicCapital,
   Typo18PaleGreyBarlowSemiBoldItalic
} from '../../styleGuide/Typos'
import colors from '../../themes/Colors'
import { StyledButton } from '../Button/styledComponents'
import ButtonWithLoader from '../ButtonWithLoader'

export const BaseButton = styled(StyledButton)`
   padding: 16px 32px;
   border-radius: 0px;
`

export const ButtonsWrapper = styled.div`
   display: flex;
`

export const StayInButton = styled(BaseButton)`
   background-color: ${colors.oceanBlue};
   margin-right: 24px;
`

export const YesLogoutButton = styled(ButtonWithLoader)`
   min-width: 152px;
   height: 49px;
   background-color: ${colors.lightRedBrown};
`

export const LogoutPopUpBody = styled.div`
   flex-grow: 1;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
`

export const LogoutPopUpHeader = styled.div`
   margin: 0 0 24px;
   padding: 24px 32px;
   background-color: ${colors.darkTwo};
`

export const LogoutPopUpContainer = styled.div`
   width: 438px;
   height: 237px;
   padding: 0 0 32px;
   display: flex;
   flex-direction: column;
   background-color: ${colors.charcoalGrey};
`

export const CloseIconWrapper = styled.span`
   font-size: 30px;
   margin: 7px 0 7px 233px;
   color: ${colors.coolGrey};
   cursor: pointer;
`

export const ConfirmationText = styled(Typo18PaleGreyBarlowSemiBoldItalic)``

export const LogoutText = styled(Typo16CoolGreyBarlowMediumItalicCapital)`
   margin-left: 16px;
`

export const LogoutButton = styled(StyledButton)`
   background-color: transparent;
   border: none;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   cursor: pointer;
`

export const ButtonText = styled(Typo14WhiteBarlowBoldItalic)`
   letter-spacing: 0.44px;
`
