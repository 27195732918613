import React from 'react'

interface Props {
   width: number
   height: number
   fillColor: string
}

const LeaderboardIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 26 23'
   >
      <defs>
         <filter id='LeaderboardIcon-active'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.007843 0 0 0 0 0.552941 0 0 0 0 0.847059 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
         <filter id='LeaderboardIcon-inactive'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter={`url(#LeaderboardIcon-${props.fillColor})`}
         transform='translate(-30 -18)'
      >
         <g fill='#FFF' transform='translate(30 18)'>
            <rect
               width='25.043'
               height='1.286'
               y='21.6'
               fillRule='nonzero'
               opacity='0.25'
               rx='0.447'
            ></rect>
            <path d='M9.016 10.08h6.511V21.6H9.016z'></path>
            <path
               d='M1.002 14.4h6.511v7.2H1.002zm16.028-.96h6.511v8.16H17.03z'
               opacity='0.25'
            ></path>
            <path
               fillRule='nonzero'
               d='M12.039 7.166l-.913.484v1.779h1.789V7.633z'
               opacity='0.25'
            ></path>
            <path
               fillRule='nonzero'
               d='M9.463 4.359a.766.766 0 01.25.733l-.349 1.654a.428.428 0 00.184.446.51.51 0 00.532.034l1.556-.827a.858.858 0 01.8 0l1.557.827a.5.5 0 00.532-.034c.15-.1.223-.276.184-.446l-.35-1.654a.769.769 0 01.251-.733l1.315-1.14a.417.417 0 00.13-.463.469.469 0 00-.407-.317l-1.771-.193a.844.844 0 01-.653-.45l-.747-1.53A.47.47 0 0012.039 0a.477.477 0 00-.443.266l-.747 1.53a.834.834 0 01-.648.45l-1.776.193a.469.469 0 00-.407.317.42.42 0 00.135.463l1.31 1.14z'
            ></path>
         </g>
      </g>
   </svg>
)

LeaderboardIcon.defaultProps = {
   width: 26,
   height: 23,
   fillColor: 'inactive'
}
export default LeaderboardIcon
