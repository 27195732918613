import styled from 'styled-components'

export const StyledButton = styled.button`
   border: none;
   cursor: pointer;
   padding: 0px;
   border-radius: 5px;
   ::-moz-focus-inner {
      border: 0;
   }
   outline: none;
   -webkit-tap-highlight-color: transparent;
`
