import styled from 'styled-components'

import Colors from '../../../../common/themes/Colors'
import { Typo14BattleShipGreyBarlowSemiBoldItalic } from '../../../../common/styleGuide/Typos'

export const HeaderWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   text-align: center;
   align-items: center;
   background: ${Colors.darkTwo};
   min-height: 40%;
   width: 92%;
   padding-left: 4%;
   padding-right: 4%;
`
export const ContentWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   text-align: center;
   align-items: flex-end;
   height: 100%;
   width: 94%;
   padding-left: 3%;
   padding-right: 3%;
   margin-bottom: 3%;
`
export const CardWrapper = styled.div`
   width: 516px;
   height: 209px;
   background: ${Colors.charcoalGrey};
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-direction: column;
   padding-bottom: 0.5%;
   margin: 12px;
   cursor: ${props => (props.expired ? 'default' : 'pointer')};
`
export const TitleWrapper = styled.div`
   width: 60%;
`

export const DateTextWrapper = styled.div`
   display: flex;
   flex-direction: row;
`
export const MatchExpiredText = styled(
   Typo14BattleShipGreyBarlowSemiBoldItalic
)`
   margin-left: 10px;
`
