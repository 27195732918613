import styled from '@emotion/styled'
import {
   Typo18SilverBarlowBoldItalic,
   Typo36SliverBarlowExtraBoldItalic
} from '../../../common/styleGuide/Typos'
import { mobile, tablet } from '../../../common/utils/MixinUtils'
import { EMPTY_MATCHES_IMAGE_PATH } from '../../../common/constants/PublicImageConstants'

export const NoMatchesPlayedContainer = styled.div`
   display: flex;
   min-height: 75vh;
   background-image: url(${EMPTY_MATCHES_IMAGE_PATH});
   background-size: cover;
   background-position: center;
   justify-content: center;
   padding-top: 5%;
   ${tablet} {
      min-height: 65vh;
   }
   ${mobile} {
      min-height: 70vh;
   }
`

export const MessageContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`

export const NoMatchesTitleText = styled(Typo36SliverBarlowExtraBoldItalic)``
export const NoMatchesMsgText = styled(Typo18SilverBarlowBoldItalic)`
   margin: 1% 30% 0;
`
