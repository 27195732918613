import { PopupLeaderboardModelProps } from '../../types'

class PopupLeaderBoardModel {
   minRank: number
   maxRank: number
   totalLbEntriesCount: number
   lbContributionCount: number
   resources: Array<any>
   rewardMode: string
   constructor(leaderboardObject: PopupLeaderboardModelProps) {
      const {
         min_rank,
         max_rank,
         resources,
         reward_mode,
         lb_contribution_count,
         total_lb_entries_count
      } = leaderboardObject

      this.maxRank = max_rank
      this.minRank = min_rank
      this.rewardMode = reward_mode
      this.resources = resources
      this.lbContributionCount = lb_contribution_count
      this.totalLbEntriesCount = total_lb_entries_count
   }
}

export default PopupLeaderBoardModel
