import React from 'react'

interface Props {
   width: number
   height: number
}

const RunsIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 31 36'
   >
      <defs>
         <path id='umn69r03yb' d='M.054.164h30.349v34.159H.054z'></path>
         <path id='33zag1cwid' d='M0 .163h7.988V19.62H0z'></path>
         <filter id='fhmnu7pfea'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd' filter='url(#fhmnu7pfea)'>
         <path
            fill='#FFF'
            d='M9.488 28.908s-.734-1.779.607-2.118c0 0-.861.34-.607 2.118'
         ></path>
         <path
            fill='#A3A7B3'
            d='M12.997 26.54s1.05 1.43 2.493.893c0 0-.729 2.43-1.372 2.567 0 0-1.081 2.602-2.682 1.166l-1.948-2.258s-.824-1.86 1.285-2.656l1.258 1.611.966-1.324z'
         ></path>
         <g transform='translate(0 .051)'>
            <mask id='0ca6an7omc' fill='#fff'>
               <use xlinkHref='#umn69r03yb'></use>
            </mask>
            <path
               fill='#FFF'
               d='M12.353 26.838l-.322.974-.624-.847-.634-.764-.702-.846s-.241-1.03-1.598-.868l1.093-.994 1.545-1.327 1.963 2.321s-.58.444-.72 2.351m14.722-1.849c-1.326-2.823-6.38-7.369-6.38-7.369l-2.145-1.525c-.61-.656-1.495-1.259-2.315-1.73.391-.546.613-1.038.734-1.462.23-.617.356-1.28.056-1.663 0 0-1.373-.743-3.351.003 0 0 .046.018.122.051-.162.018-.298.035-.402.049-.272-1.413-1.064-3.19-3.333-3.036 0 0-3.082-.121-3.423 2.651-.077.332-.086.564-.086.564-.086-.903-1.131-4.827-1.131-4.827C4.332.178 1.509.172 1.498.172-.11.06.06 1.274.06 1.274c.706.592 5.307 17.53 5.307 17.53l.904.78-.904-.778.085.564c-1.214 1.214-.085 1.948-.085 1.948l-.536.368c-1.326 1.129.212 1.129.212 1.129-.747 1.185.72.96.72.96-.735.592.366.818.366.818-.113.367.988.057.988.057-.007.012-.016.022-.024.033a.714.714 0 00-.216.154c-.017-.001-2.224-.176-1.482.8 0 0-.964.935.132 1.436l.009.005-.02.015c-.148.09-.22.191-.244.293-.098.21-.073.496.485.762.002 0 .005.003.005.003-.002.005-.184.393.085.66a.501.501 0 00.253.155c.1.037.218.066.368.082 0 0 2.596-.568 2.983-.238a.235.235 0 01.037.047l1.948 2.259c2.287 2.2 3.953-1.695 3.953-1.695s.65-2.203.96-2.04c.31.163.339 1.023.339 1.023-.112.904.536 1.187.536 1.187l-.056.592c-.99.141-.761 1.628-.761 1.628-.485.37-.844 1.328-1.105 2.512h13.51c.3-.622.862-1.57.862-1.57 2.542-3.812-2.37-7.567-2.37-7.567l-.227-.197z'
               mask='url(#0ca6an7omc)'
               opacity='0.25'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M7.737 14.679s.715 4.78 3.593 4.38c0 0 3.26.386 1.856-5.961 0 0-1.762 1.101-5.449 1.581'
         ></path>
         <g transform='translate(0 .051)'>
            <mask id='5ilhj90vue' fill='#fff'>
               <use xlinkHref='#33zag1cwid'></use>
            </mask>
            <path
               fill='#FFF'
               d='M7.988 18.673l-.883-4.538-.713-3.543L5.36 5.787S3.972-.803.642.284c0 0-.863.153-.588 1.027 0 0 4.608 14.614 5.314 17.57.04.196.07.36.084.482 0 0 .792-.146.813.257 0 0 1.005-1.456 1.723-.947'
               mask='url(#5ilhj90vue)'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M7.927 20.547s-.954.214-.88.992l.295 3.1s.592-.178 1.002-.118l-.417-3.974zm4.103 7.316l-1.257-1.61s-2.017.782-1.294 2.633c.009.022.208.253.345.412.238.272.612.733 1.612 1.868 0 0-.768-2.415.595-3.303'
         ></path>
      </g>
   </svg>
)

RunsIcon.defaultProps = {
   width: 31,
   height: 36
}
export default RunsIcon
