import React from 'react'

interface Props {
   width: number
   height: number
   className?: string
}

const CorrectIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 23 23'
      className={props.className}
   >
      <defs>
         <filter
            id='filter-correcticon-1'
            width='155.3%'
            height='155.3%'
            x='-27.6%'
            y='-27.6%'
            filterUnits='objectBoundingBox'
         >
            <feOffset
               dy='1'
               in='SourceAlpha'
               result='shadowOffsetOuter1'
            ></feOffset>
            <feGaussianBlur
               in='shadowOffsetOuter1'
               result='shadowBlurOuter1'
               stdDeviation='1'
            ></feGaussianBlur>
            <feColorMatrix
               in='shadowBlurOuter1'
               result='shadowMatrixOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            ></feColorMatrix>
            <feMerge>
               <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
               <feMergeNode in='SourceGraphic'></feMergeNode>
            </feMerge>
         </filter>
         <linearGradient
            id='linearGradient-2'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#B4EC51'></stop>
            <stop offset='100%' stopColor='#429321'></stop>
         </linearGradient>
      </defs>
      <g
         id='Page-1'
         fill='none'
         fillRule='evenodd'
         stroke='none'
         strokeWidth='1'
      >
         <g
            id='match_centre_overview'
            fillRule='nonzero'
            transform='translate(-1120 -499)'
         >
            <g id='Group-31' transform='translate(1052 491)'>
               <g id='Group-32' transform='translate(10)'>
                  <g id='icons/correct' transform='translate(58 8)'>
                     <g
                        id='icons/ic_selected_tick'
                        filter='url(#filter-correcticon-1)'
                     >
                        <path
                           id='Shape'
                           fill='url(#linearGradient-2)'
                           d='M11.5 1.278a9.583 9.583 0 00-9.583 9.583 9.583 9.583 0 009.583 9.583 9.583 9.583 0 000-19.167z'
                        ></path>
                        <path
                           id='Shape'
                           fill='#FFF'
                           d='M14.5152567 6.38888889L9.72784925 12.2939919 7.71568551 10.3076892 6.38888889 11.7693928 9.88327485 15.2183331 15.9888109 7.68768274z'
                        ></path>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

CorrectIcon.defaultProps = {
   width: 23,
   height: 23
}

export default CorrectIcon
