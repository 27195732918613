import styled from 'styled-components'

import { customDevice, minDeviceWidth } from '../../utils/MixinUtils'
import {
   Type16LightBlueGreyTwoBarlowMediumItalicText,
   Type30LightGreyBarlowBoldText
} from '../../styleGuide/Typos'

export const MatchesIconContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 136px;
   ${customDevice(1080, 1400)} {
      margin: 24px 15px 24px 15px;
   }

   ${minDeviceWidth(1400)} {
      margin: 24px 18px 24px 18px;
   }

   ${customDevice(1622, 1668)} {
      max-width: 96px;
   }
`

export const MatchesTextContainer = styled(Type30LightGreyBarlowBoldText)`
   padding-left: 8px;
`

export const MatchIconText = styled.div`
   display: inline-block;
   display: flex;
   flex-direction: row;
   align-items: center;
`

export const DescriptionText = styled(
   Type16LightBlueGreyTwoBarlowMediumItalicText
)`
   padding-top: 8px;
   text-align: center;
   white-space: pre-wrap;
   word-break: break-all;
   word-wrap: break-word;
`
