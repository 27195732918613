import React from 'react'

import {
   LogoAndNameAndDateWrapper,
   TournamentLogo,
   TournamentTitleText,
   DateTextComponent
} from './styledComponent'

interface TournamenDetails {
   logo: string
   name: string
   date: string
}

interface TournamentDetailsProps {
   tournamentDetails: TournamenDetails
}

const TournamentLogoWithNameWithNameAndDateComponent = (
   props: TournamentDetailsProps
) => {
   const { logo, name, date } = props.tournamentDetails
   return (
      <LogoAndNameAndDateWrapper>
         <TournamentLogo src={logo} />
         <TournamentTitleText>{name}</TournamentTitleText>
         <DateTextComponent>{date}</DateTextComponent>
      </LogoAndNameAndDateWrapper>
   )
}

export default TournamentLogoWithNameWithNameAndDateComponent
