export const LOGIN_SCREEN_PATH = '/'

export const STEAM_RETURN_PATH = '/steam/return'

export const HOME_SCREEN_PATH = '/home'

export const NOT_FOUND_PAGE_PATH = '*'

export const NOT_FOUND_PAGE = '404_NOT_FOUND'

export const MY_TEAM = '/team'

export const MY_PROFILE = '/profile'

export const FRIENDS = '/friends'

export const TOURNAMENTS = '/tournaments'

export const CHALLENGES = '/challenges'

export const LEADERBOARDS = '/leaderboards'

export const WALL_OF_FAME = '/wall_of_fame'

export const SETTINGS = '/settings'

export const MATCH_DETAILS_PATH = '/profile/matchdetails'
