import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import WinnerArrowMarkIcon from '../../../common/icons/WinnerArrowMarkIcon'
import { KnockoutMatchType } from '../../stores/types'

import KnockoutMatchCard from '../KnockOutMatchCard'

import {
   Container,
   DependencyMatchesWrapper,
   ArrowMarkIconWrapper,
   ThirdRoundMatchWrapper,
   FirstWinnerTextComponent,
   SecondWinnerTextComponent
} from './styledComponent'

interface RoundeThreeProps {
   knockOutMatchModel: KnockoutMatchType
}

const KnockOutMatchHierarchyComponent = (
   props: RoundeThreeProps
): ReactElement => {
   const { knockOutMatchModel } = props
   const { childMatches, parentMatch } = knockOutMatchModel
   const { t } = useTranslation()
   return (
      <Container>
         <DependencyMatchesWrapper>
            {childMatches.map((childMatch, index) => (
               <KnockoutMatchCard match={childMatch} key={index} />
            ))}
         </DependencyMatchesWrapper>
         <ArrowMarkIconWrapper>
            <WinnerArrowMarkIcon width={272} height={236} />
            <FirstWinnerTextComponent>
               {t('tournaments:winnerText')}
            </FirstWinnerTextComponent>
            <SecondWinnerTextComponent>
               {t('tournaments:winnerText')}
            </SecondWinnerTextComponent>
         </ArrowMarkIconWrapper>
         <ThirdRoundMatchWrapper>
            <KnockoutMatchCard match={parentMatch} />
         </ThirdRoundMatchWrapper>
      </Container>
   )
}

export default KnockOutMatchHierarchyComponent
