import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line
import MatchesBaseHeader from '../BaseHeader'

import ResourceItemCmp from '../../../../common/components/Resources/ResourceItem'
import TournamentMatch from '../../../stores/models/TournamentMatch'
import { RoundType } from '../../../constants/MatchConfigConstants'

import { TitleAndValue } from '../../MatchesCommon/MatchesCommon'
import WinOrLost from '../../WinAndLose'
import { ItemWrapper } from '../BaseHeader/styledComponents'

interface TournamentMatchHeaderProps {
   model: TournamentMatch
}

const TournamentMatchHeader = (
   props: TournamentMatchHeaderProps
): ReactElement => {
   const { t } = useTranslation()
   const { model } = props
   const { gameStats } = model.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <MatchesBaseHeader model={model}>
         <ItemWrapper>
            <TitleAndValue
               title={t('tournaments.round')}
               value={model.roundDisplayName}
            />
         </ItemWrapper>
         <ItemWrapper>
            <TitleAndValue
               title={t('matches.over', {
                  count: overs
               })}
               value={overs.toString()}
            />
         </ItemWrapper>
         {model.roundType === RoundType.KNOCK_OUT.toString() ? (
            <ItemWrapper>
               <WinOrLost isWon={model.isWon} />
            </ItemWrapper>
         ) : null}
         <ItemWrapper>
            <ResourceItemCmp resource={model.resource} />
         </ItemWrapper>
      </MatchesBaseHeader>
   )
}
export default TournamentMatchHeader
