import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line

import { Typo14CharcoalGreyBoldItalic } from '../../../../common/styleGuide/Typos'
import { userProfileGroundRadius } from '../../../../matches/constants/MatchConfigConstants'
import ProfileStore from '../../../stores/PlayerProfileStore'
import SectionTitle from '../../SectionTitle'
import UserNameAndPic from '../UserNameAndPic'
import StatsList from '../../StatsList'
import ResourceItemWrapper from '../../Resources/ResourceItemWrapper'
import WagonWheel from '../../../../matches/components/WagonWheel'
import {
   ProfileContainer,
   ProfileResourceContainer,
   ResourcesContainer,
   ProfileStatsContainer,
   GroundWrapper,
   ProfileResourceItemWrapper
} from './styledComponents'

type UserProfileDetailsProps = WithTranslation & {
   profileStore: ProfileStore
}

@observer
class UserProfileDetails extends React.Component<UserProfileDetailsProps> {
   renderUserStatsAndGround = () => {
      const { matchesCount, stats } = this.props.profileStore
      if (matchesCount) {
         return (
            <>
               <ProfileStatsContainer>
                  <StatsList listItems={stats}></StatsList>
               </ProfileStatsContainer>
            </>
         )
      }
      return <div />
   }
   render() {
      const {
         profileDetails,
         resources,
         ath,
         userControls
      } = this.props.profileStore
      const { t } = this.props
      const { renderUserStatsAndGround } = this
      return (
         <ProfileContainer>
            <SectionTitle title={t('titles.myProfile')} width={193} />
            <ProfileResourceContainer>
               <UserNameAndPic
                  name={profileDetails.name}
                  url={profileDetails.profileUrl}
               />
               <ResourcesContainer>
                  {resources.map(item => (
                     <ProfileResourceItemWrapper key={item.name}>
                        <ResourceItemWrapper resource={item} fanBaseATH={ath} />
                     </ProfileResourceItemWrapper>
                  ))}
               </ResourcesContainer>
               <GroundWrapper>
                  <WagonWheel
                     radius={userProfileGroundRadius}
                     userControls={userControls}
                     Typo={Typo14CharcoalGreyBoldItalic}
                     displayStringFormat={`{0}%`}
                  />
               </GroundWrapper>
            </ProfileResourceContainer>
            {renderUserStatsAndGround()}
         </ProfileContainer>
      )
   }
}

export default withTranslation()(UserProfileDetails)
