import styled from 'styled-components'

import {
   Typo14PaleGreyThreeBarlowBoldItalicText,
   Typo18PaleGreyBarlowSemiBoldItalicCapital
} from '../../../common/styleGuide/Typos'
import colors from '../../../common/themes/Colors'

export const ExpiresText = styled(Typo14PaleGreyThreeBarlowBoldItalicText)`
   margin-bottom: 4px;
`

export const TimerAndIconWrapper = styled.div`
   display: flex;
   align-items: center;
`
export const TimerContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   background-color: ${colors.oceanBlue};
   min-height: 50px;
   justify-content: center;
`

export const IconWrapper = styled.div`
   margin-right: 8px;
   padding-bottom: 5px;
`
export const RoundCompletedText = styled(
   Typo18PaleGreyBarlowSemiBoldItalicCapital
)``
