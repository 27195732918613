import React from 'react'
interface Props {
   width: number
   height: number
}

const CorrectIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 24 24'
   >
      <defs>
         <linearGradient
            id='correct-linearGradient-1'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#3B961D'></stop>
            <stop offset='100%' stopColor='#429321'></stop>
         </linearGradient>
      </defs>
      <g
         id='Page-1'
         fill='none'
         fillRule='evenodd'
         stroke='none'
         strokeWidth='1'
      >
         <g
            id='match_centre_configuration'
            fillRule='nonzero'
            transform='translate(-1421 -459)'
         >
            <g id='Group-13' transform='translate(1185 338)'>
               <g id='Group-3' transform='translate(43 99)'>
                  <g id='ic_active_tick' transform='translate(193 22)'>
                     <path
                        id='Shape'
                        fill='url(#correct-linearGradient-1)'
                        d='M11.977 0C5.362 0 0 5.362 0 11.977c0 6.614 5.362 11.976 11.977 11.976 6.614 0 11.976-5.362 11.976-11.976C23.953 5.362 18.591 0 11.977 0z'
                     ></path>
                     <path
                        id='Shape'
                        fill='#FFF'
                        d='M16.141848 6.44902924L10.1471813 13.8427135 7.62760234 11.3556959 5.96622222 13.1858713 10.3418012 17.5042339 17.9869942 8.07522807z'
                     ></path>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

CorrectIcon.defaultProps = {
   width: 24,
   height: 24
}

export default CorrectIcon
