import { observable, action } from 'mobx'

import { APIStatus, API_INITIAL } from '@ib/api-constants'
import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'

import PlayerProfileApi from '../../services/PlayerProfileService'
import { GetCricketGameDetailsAPIResponse } from '../types'

class CricketConstantsStore {
   gameId: number
   @observable getCricketGameDetailsAPIStatus!: APIStatus
   @observable getCricketGameDetailsAPIError!: Error | null
   myProfileService: PlayerProfileApi
   constructor(myProfileService: PlayerProfileApi) {
      this.gameId = -1
      this.myProfileService = myProfileService
      this.getCricketGameDetailsAPIStatus = API_INITIAL //To Make Routes component rended successfully.
      this.getCricketGameDetailsAPIError = null
   }

   getCricketGameId = (): number => this.gameId

   @action.bound
   setGetMyProfileAPIStatus(status): void {
      this.getCricketGameDetailsAPIStatus = status
   }

   @action.bound
   setGetMyProfileAPIError(error): void {
      this.getCricketGameDetailsAPIError = error
   }

   @action.bound
   setCricketGameDetailsAPIResponse(response): void {
      const cricketGameDetailsResponse = response as GetCricketGameDetailsAPIResponse
      this.gameId = cricketGameDetailsResponse.game_id
   }

   @action.bound
   getCricketGameDetails(): Promise<GetCricketGameDetailsAPIResponse | void> {
      const getResourcesPromise = this.myProfileService.getCricketGameDetailsAPI()
      return bindPromiseWithOnSuccess(getResourcesPromise)
         .to(
            this.setGetMyProfileAPIStatus,
            this.setCricketGameDetailsAPIResponse
         )
         .catch(this.setGetMyProfileAPIError)
   }
}

export default CricketConstantsStore
