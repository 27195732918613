import React from 'react'

interface TrophyWithoutBallIconProps {
   width: number
   height: number
}

const SuccessTrophyIcon = (props: TrophyWithoutBallIconProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={37}
      height={43}
      viewBox='0 0 37 43'
      id='prefix__WinnerTrophy'
      {...props}
   >
      <defs>
         <linearGradient
            id='prefix__winnerTrophy_a'
            x1='50%'
            x2='50%'
            y1='55.247%'
            y2='81.842%'
         >
            <stop offset='0%' stopColor='#FFC60C' />
            <stop offset='100%' stopColor='#FF9505' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_b'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#FBA343' />
            <stop offset='100%' stopColor='#F56A20' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_c'
            x1='0%'
            x2='97.358%'
            y1='50%'
            y2='50%'
         >
            <stop offset='0%' stopColor='#DD9D3D' />
            <stop offset='100%' stopColor='#FFCB1E' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_e'
            x1='50%'
            x2='50%'
            y1='5.952%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#FFF396' />
            <stop offset='4.813%' stopColor='#FFE239' />
            <stop offset='100%' stopColor='#FFC11A' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_f'
            x1='0%'
            x2='83.736%'
            y1='50%'
            y2='50%'
         >
            <stop offset='0%' stopColor='#FFA104' />
            <stop offset='22.168%' stopColor='#FFC810' />
            <stop offset='68.894%' stopColor='#ECA234' />
            <stop offset='100%' stopColor='#FFBA64' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_g'
            x1='50%'
            x2='50%'
            y1='5.952%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#FFF' stopOpacity={0.454} />
            <stop offset='100%' stopColor='#FFF' stopOpacity={0.138} />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_i'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#FBA343' />
            <stop offset='100%' stopColor='#F56A20' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_l'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#E38900' />
            <stop offset='100%' stopColor='#C35100' />
         </linearGradient>
         <linearGradient
            id='prefix__winnerTrophy_n'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopOpacity={0.028} />
            <stop offset='100%' stopOpacity={0.5} />
         </linearGradient>
         <path
            id='prefix__winnerTrophy_d'
            d='M13.118 24.863l-.305-.004C6.52 24.703 1.329 19.726.818 13.295L.022 3.27C-.078 2.006.812.881 2.046.72 5.722.24 9.419 0 13.118 0l1.232.009c3.287.047 6.573.284 9.84.711 1.233.161 2.124 1.286 2.023 2.55l-.796 10.025c-.52 6.534-5.87 11.568-12.3 11.568h.001z'
         />
         <path
            id='prefix__winnerTrophy_j'
            d='M18.265 6.25v7.861l-3.278 2.43 1.257-3.945-3.29-2.438h4.067l1.244-3.907zm.012-.036l1.257 3.944H23.6l-3.29 2.438 1.257 3.945-3.29-2.438-.012.008v-7.86l.012-.037z'
         />
         <path
            id='prefix__winnerTrophy_m'
            d='M30.776 42.948V31.97c0-1.13-.912-1.937-2.188-1.937H7.628c-1.276 0-2.187.807-2.187 1.937v10.978h25.335z'
         />
         <filter
            id='prefix__winnerTrophy_k'
            width='118.8%'
            height='119.4%'
            x='-9.4%'
            y='-9.7%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation={0.5}
            />
            <feOffset
               dy={1}
               in='shadowBlurInner1'
               result='shadowOffsetInner1'
            />
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2={-1}
               k3={1}
               operator='arithmetic'
               result='shadowInnerInner1'
            />
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 0.585406037 0 0 0 0 0.41819046 0 0 0 0 0.135010191 0 0 0 0.574813179 0'
            />
         </filter>
         <filter
            id='prefix__winnerTrophy_o'
            width='103.9%'
            height='107.7%'
            x='-2%'
            y='-3.9%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation={0.5}
            />
            <feOffset in='shadowBlurInner1' result='shadowOffsetInner1' />
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2={-1}
               k3={1}
               operator='arithmetic'
               result='shadowInnerInner1'
            />
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.196699502 0'
            />
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <path
            fill='url(#prefix__winnerTrophy_a)'
            d='M20.047 22.525l.639 6.477a1.477 1.477 0 001.46 1.342c.812 0 1.47.668 1.47 1.493v4.972h-5.574V22.525h2.005zm-2.005 0l-.016 14.284h-5.59v-4.973a1.48 1.48 0 011.469-1.492c.753 0 1.384-.58 1.46-1.342l.64-6.477h2.037z'
         />
         <path
            fill='url(#prefix__winnerTrophy_b)'
            d='M15.862 26.525h4.644a1.094 1.094 0 100-2.188h-4.644a1.094 1.094 0 000 2.188z'
         />
         <path
            fill='url(#prefix__winnerTrophy_c)'
            d='M8.873 18.692c-3.335 0-5.428-1.53-6.597-2.812C.83 14.291 0 12.196 0 10.13c0-1.55.487-2.96 1.373-3.973 1.006-1.152 2.464-1.76 4.216-1.76.566 0 1.026.456 1.026 1.02 0 .562-.46 1.019-1.026 1.019-1.156 0-2.053.356-2.666 1.058-.56.641-.87 1.577-.87 2.634 0 2.709 2.116 6.523 6.82 6.523.567 0 1.027.457 1.027 1.02s-.46 1.02-1.027 1.02m18.553.001c-.568 0-1.027-.457-1.027-1.02s.46-1.02 1.027-1.02c2.582 0 4.185-1.164 5.074-2.14 1.093-1.2 1.746-2.838 1.746-4.383 0-3.426-2.706-3.692-3.536-3.692-.567 0-1.026-.457-1.026-1.02s.46-1.019 1.026-1.019c3.343 0 5.59 2.303 5.59 5.731 0 2.067-.83 4.162-2.277 5.751-1.169 1.282-3.261 2.812-6.597 2.812'
         />
         <g transform='translate(5.084)'>
            <mask id='prefix__winnerTrophy_h' fill='#fff'>
               <use xlinkHref='#prefix__winnerTrophy_d' />
            </mask>
            <use
               fill='url(#prefix__winnerTrophy_e)'
               xlinkHref='#prefix__winnerTrophy_d'
            />
            <use
               fill='url(#prefix__winnerTrophy_f)'
               fillOpacity={0.5}
               xlinkHref='#prefix__winnerTrophy_d'
            />
            <path
               fill='url(#prefix__winnerTrophy_g)'
               d='M2.167-1.549v13.174c0 4.56 2.256 8.776 4.788 11.018 1.326 1.174 1.591 1.117 3.99 2.66-3.814-3.57-5.72-7.875-5.72-12.918 0-2.28.31-6.924.929-13.934-3.699 0-3.987-5.061-3.987 0z'
               mask='url(#prefix__winnerTrophy_h)'
            />
         </g>
         <g>
            <use
               fill='url(#prefix__winnerTrophy_i)'
               xlinkHref='#prefix__winnerTrophy_j'
            />
            <use
               fill='#000'
               filter='url(#prefix__winnerTrophy_k)'
               xlinkHref='#prefix__winnerTrophy_j'
            />
         </g>
         <g fillRule='nonzero'>
            <use
               fill='url(#prefix__winnerTrophy_l)'
               xlinkHref='#prefix__winnerTrophy_m'
            />
            <use
               fill='url(#prefix__winnerTrophy_n)'
               style={{
                  mixBlendMode: 'overlay'
               }}
               xlinkHref='#prefix__winnerTrophy_m'
            />
            <use
               fill='#000'
               filter='url(#prefix__winnerTrophy_o)'
               xlinkHref='#prefix__winnerTrophy_m'
            />
         </g>
      </g>
   </svg>
)

SuccessTrophyIcon.defaultProps = {
   width: 37,
   height: 43
}

export default SuccessTrophyIcon
