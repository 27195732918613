import styled from '@emotion/styled'
import tw from 'twin.macro'

export const MileStoneListWrapper = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
`
export const Container = styled.div`
   ${tw`flex flex-col`};
   margin-left: 7px;
   width: 48%;
`
export const MileStoneItemWrapper = styled.div`
   margin-right: 40px;
   margin-bottom: 16px;
   margin-top: 24px;
   margin-left: 16px;
`
