import React from 'react'
import { BowlingLevelEnum } from '../../constants/MatchConfigConstants'

interface Props {
   width: number
   height: number
   level: string
}

const BowlingLevelBg = (props: Props) => {
   const { width, height, level } = props
   const fillerId = `url(#PREFIX__${level})`

   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         xmlnsXlink='http://www.w3.org/1999/xlink'
         width={width}
         height={height}
         viewBox='0 0 256 44'
      >
         <defs>
            <filter
               id='prefix__BowlingLevelBg_c'
               width='101.6%'
               height='109.5%'
               x='-.8%'
               y='-4.8%'
               filterUnits='objectBoundingBox'
            >
               <feGaussianBlur
                  in='SourceAlpha'
                  result='shadowBlurInner1'
                  stdDeviation='1'
               ></feGaussianBlur>
               <feOffset
                  dy='2'
                  in='shadowBlurInner1'
                  result='shadowOffsetInner1'
               ></feOffset>
               <feComposite
                  in='shadowOffsetInner1'
                  in2='SourceAlpha'
                  k2='-1'
                  k3='1'
                  operator='arithmetic'
                  result='shadowInnerInner1'
               ></feComposite>
               <feColorMatrix
                  in='shadowInnerInner1'
                  values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
               ></feColorMatrix>
            </filter>
            <filter
               id='prefix__BowlingLevelBg_e'
               width='107.3%'
               height='130.6%'
               x='-3.6%'
               y='-15.3%'
               filterUnits='objectBoundingBox'
            >
               <feOffset
                  in='SourceAlpha'
                  result='shadowOffsetOuter1'
               ></feOffset>
               <feGaussianBlur
                  in='shadowOffsetOuter1'
                  result='shadowBlurOuter1'
                  stdDeviation='1'
               ></feGaussianBlur>
               <feColorMatrix
                  in='shadowBlurOuter1'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
               ></feColorMatrix>
            </filter>
            <filter
               id='prefix__BowlingLevelBg_g'
               width='100%'
               height='100%'
               x='0%'
               y='0%'
               filterUnits='objectBoundingBox'
            >
               <feOffset
                  in='SourceAlpha'
                  result='shadowOffsetOuter1'
               ></feOffset>
               <feColorMatrix
                  in='shadowOffsetOuter1'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.443019701 0'
               ></feColorMatrix>
            </filter>
            <filter
               id='prefix__BowlingLevelBg_i'
               width='100%'
               height='100%'
               x='0%'
               y='0%'
               filterUnits='objectBoundingBox'
            >
               <feOffset
                  in='SourceAlpha'
                  result='shadowOffsetOuter1'
               ></feOffset>
               <feColorMatrix
                  in='shadowOffsetOuter1'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.443019701 0'
               ></feColorMatrix>
            </filter>
            <path
               id='prefix__BowlingLevelBg_a'
               d='M23.486.594H256l-23.486 42H0z'
            ></path>
            <path
               id='prefix__BowlingLevelBg_f'
               d='M18.466 34.417c.817-.497 1.642-.983 2.477-1.46l-1.015-1.29c-.85.485-1.69.98-2.522 1.486l1.06 1.264zm5.006-2.856c.85-.454 1.709-.898 2.577-1.332l-.923-1.34c-.884.442-1.758.894-2.623 1.356l.969 1.316zm5.2-2.6c.878-.41 1.765-.81 2.66-1.2l-.829-1.382c-.912.397-1.814.804-2.708 1.22l.877 1.362zm5.38-2.341c.905-.367 1.82-.723 2.741-1.07l-.736-1.42c-.938.352-1.867.715-2.788 1.087l.783 1.403zm5.54-2.083c.927-.322 1.863-.636 2.807-.939l-.645-1.453c-.959.309-1.91.627-2.851.955l.69 1.437zm5.69-1.829c.945-.28 1.898-.55 2.86-.81l-.554-1.48c-.977.265-1.945.54-2.905.824l.599 1.466zm5.685-1.545c.96-.24 1.93-.47 2.906-.69l-.469-1.501c-.99.224-1.973.457-2.948.7l.511 1.49zm5.853-1.326c.973-.2 1.954-.39 2.943-.573l-.386-1.517a155.93 155.93 0 00-2.983.58l.426 1.51zm5.93-1.093c.983-.162 1.974-.316 2.972-.46l-.308-1.532c-1.01.148-2.014.303-3.01.467l.346 1.525zm5.985-.872c.99-.126 1.988-.244 2.993-.354l-.233-1.54c-1.017.111-2.026.231-3.029.359l.27 1.535zm6.03-.661c.997-.093 2-.177 3.009-.255l-.163-1.546c-1.021.078-2.035.164-3.043.257l.198 1.544zm6.065-.464c1-.06 2.006-.114 3.018-.16l-.098-1.551c-1.023.047-2.04.1-3.05.162l.13 1.549zm6.087-.278a213.72 213.72 0 013.023-.073l-.036-1.552c-1.024.017-2.042.041-3.053.073l.066 1.552zm5.87-.103c1.002-.005 2.01-.003 3.025.006l.018-1.553c-1.024-.009-2.041-.01-3.053-.006l.01 1.553zm6.06.052c.372.007.744.016 1.118.026l.055-1.552-1.128-.027-.045 1.553z'
            ></path>
            <path
               id='prefix__BowlingLevelBg_h'
               d='M82.991 5.546l-2.853-2.271c-1.583-1.211-47.225 5.814-75.51 41.99-1.062 6.821-4.734 3.003-.953 8.024 0 0 26.692-36.652 79.316-47.743z'
            ></path>
            <path
               id='prefix__BowlingLevelBg_j'
               d='M90.158 13.68s-46.32 5.381-80.29 41.176c0 0 3.603 2.97 5.595 2.597 1.994-.373 18.342-24.754 76.415-41.376l-1.72-2.396z'
            ></path>
            <linearGradient
               id='PREFIX__HARD'
               x1='0%'
               x2='100%'
               y1='48.654%'
               y2='51.346%'
            >
               <stop offset='0%' stopColor='#FF5463'></stop>
               <stop offset='3.053%' stopColor='#FD4154'></stop>
               <stop offset='3.798%' stopColor='#FF4F63'></stop>
               <stop offset='25.612%' stopColor='#CD253A'></stop>
               <stop offset='55.252%' stopColor='#C6243D'></stop>
               <stop offset='77.528%' stopColor='#CF243E'></stop>
               <stop offset='100%' stopColor='#DC445B'></stop>
            </linearGradient>
            <linearGradient
               id='PREFIX__MEDIUM'
               x1='-13.95%'
               x2='50%'
               y1='44.684%'
               y2='50%'
            >
               <stop offset='0%' stopColor='#FAD961'></stop>
               <stop offset='100%' stopColor='#F76B1C'></stop>
            </linearGradient>
            <linearGradient
               id='PREFIX__EASY'
               x1='-37.399%'
               x2='64.371%'
               y1='38.628%'
               y2='50%'
            >
               <stop offset='0%' stopColor='#00FF32'></stop>
               <stop offset='100%' stopColor='#00921D'></stop>
            </linearGradient>
         </defs>
         <g fill='none' fillRule='evenodd' transform='translate(0 .406)'>
            <mask id='prefix__d' fill='#fff'>
               <use xlinkHref='#prefix__BowlingLevelBg_a'></use>
            </mask>
            <use fill={fillerId} xlinkHref='#prefix__BowlingLevelBg_a'></use>
            <use
               fill='#000'
               filter='url(#prefix__BowlingLevelBg_c)'
               xlinkHref='#prefix__BowlingLevelBg_a'
            ></use>
            <g mask='url(#prefix__d)' opacity='0.5'>
               <g fillRule='nonzero' transform='rotate(-29 67.363 -73.586)'>
                  <use
                     fill='#000'
                     filter='url(#prefix__BowlingLevelBg_e)'
                     xlinkHref='#prefix__BowlingLevelBg_f'
                  ></use>
                  <use fill='#FFF' xlinkHref='#prefix__BowlingLevelBg_f'></use>
               </g>
               <g transform='rotate(-14 54.424 -179.478)'>
                  <use
                     fill='#000'
                     filter='url(#prefix__BowlingLevelBg_g)'
                     xlinkHref='#prefix__BowlingLevelBg_h'
                  ></use>
                  <use
                     fill='#FFCFCF'
                     xlinkHref='#prefix__BowlingLevelBg_h'
                  ></use>
               </g>
               <g transform='rotate(-17 56.254 -143.064)'>
                  <use
                     fill='#000'
                     filter='url(#prefix__BowlingLevelBg_i)'
                     xlinkHref='#prefix__BowlingLevelBg_j'
                  ></use>
                  <use
                     fill='#FFCFCF'
                     xlinkHref='#prefix__BowlingLevelBg_j'
                  ></use>
               </g>
            </g>
         </g>
      </svg>
   )
}

BowlingLevelBg.defaultProps = {
   width: 256,
   height: 44,
   level: BowlingLevelEnum.HARD
}

export default BowlingLevelBg
