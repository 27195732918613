import React, { ReactElement } from 'react'

import { RoundDetail, KnockoutMatchType } from '../../stores/types'
import FinalMatchHierarchy from '../FinalMatchHierarchy'
import RoundThreeBackgoundBox from '../RoundThreeBackgroundBox'
import RoundTwoBakgroundBox from '../RoundTwoBackgroundBox'
import KnockOutMatchHierarchyComponent from '../KnockOutMatchHierarchyComponent'

import { FINAL, WINNER } from '../../constants/tournamentConstants'
import {
   RoundTwoTabComponentContainer,
   KnockoutMatchesWrapper,
   ListOfKnockOutMatchesContainer
} from './styledComponent'

interface KnockOutRoundComponentProps {
   knockoutMatchesDict: Record<number, KnockoutMatchType[]>
   roundDetailsDict: Record<number, RoundDetail>
   roundNo: number
}

interface GetRoundNameReturnType {
   currentRoundName: string
   nextRoundName: string
}

const getRoundName = (
   roundDetailsDict,
   roundNo: number
): GetRoundNameReturnType => {
   const currentRoundDetails = roundDetailsDict[roundNo]
   const nextRoundNo = roundDetailsDict[roundNo + 1]

   let currentRoundName: string
   let nextRoundName: string
   if (currentRoundDetails.round_name === FINAL) {
      currentRoundName = currentRoundDetails.round_display_name
      nextRoundName = WINNER
      return { currentRoundName, nextRoundName }
   }
   currentRoundName = currentRoundDetails.round_display_name
   nextRoundName = nextRoundNo.round_display_name
   return { currentRoundName, nextRoundName }
}

const KnockoutRoundComponent = (
   props: KnockOutRoundComponentProps
): ReactElement => {
   const { roundDetailsDict, knockoutMatchesDict, roundNo } = props
   const givenRoundNumberDetails = roundDetailsDict[roundNo]
   const { currentRoundName, nextRoundName } = getRoundName(
      roundDetailsDict,
      roundNo
   )
   return (
      <RoundTwoTabComponentContainer>
         <RoundTwoBakgroundBox title={currentRoundName} />
         <RoundThreeBackgoundBox title={nextRoundName} />
         <KnockoutMatchesWrapper>
            {givenRoundNumberDetails.round_name === FINAL ? (
               <FinalMatchHierarchy
                  knockOutMatches={
                     knockoutMatchesDict[givenRoundNumberDetails.round_no]
                  }
               />
            ) : (
               <ListOfKnockOutMatchesContainer>
                  {knockoutMatchesDict[
                     givenRoundNumberDetails.round_no + 1
                  ].map((match, key) => (
                     <KnockOutMatchHierarchyComponent
                        key={key}
                        knockOutMatchModel={match}
                     />
                  ))}
               </ListOfKnockOutMatchesContainer>
            )}
         </KnockoutMatchesWrapper>
      </RoundTwoTabComponentContainer>
   )
}

export default KnockoutRoundComponent
