import React from 'react'

interface Props {
   width: number
   height: number
}

const PitchMarkerIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 38 40'
   >
      <defs>
         <path
            id='path-1'
            d='M20.3911804 23.236684L20.3911804 9 18.2064365 9 18.2064365 23.236684 15 23.236684 19.2988084 29 23.5976169 23.236684z'
         ></path>
         <filter
            id='filter-2'
            width='123.3%'
            height='120%'
            x='-11.6%'
            y='-5%'
            filterUnits='objectBoundingBox'
         >
            <feOffset
               dy='2'
               in='SourceAlpha'
               result='shadowOffsetOuter1'
            ></feOffset>
            <feColorMatrix
               in='shadowOffsetOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            ></feColorMatrix>
         </filter>
         <path
            id='path-3'
            d='M18.997 35.744v-2c.628 0 1.25-.041 1.865-.123l.263 1.983c-.701.093-1.412.14-2.128.14zm4.23-.565l-.528-1.929c.601-.164 1.19-.369 1.763-.612l.78 1.841c-.654.278-1.327.512-2.016.7zm3.996-1.708l-1.03-1.715c.535-.32 1.047-.677 1.534-1.066l1.248 1.563c-.557.444-1.142.851-1.752 1.218zm3.311-2.64l-1.442-1.386c.432-.45.834-.927 1.202-1.43l1.613 1.184c-.421.573-.88 1.118-1.373 1.631zm2.574-3.537l-1.763-.945c.294-.549.551-1.117.77-1.7l1.873.7c-.25.668-.544 1.317-.88 1.945zm1.536-4.191l-1.956-.418c.13-.607.22-1.224.267-1.848l1.995.154a15.988 15.988 0 01-.306 2.112zm.341-3.984l-1.998.077a14.053 14.053 0 00-.195-1.857l1.97-.341c.121.698.196 1.406.223 2.121zM3.18 17.322l1.977.3A14.095 14.095 0 005 19.483l-2-.036c.013-.716.073-1.425.18-2.125zm-.074 4.294l1.986-.232c.073.621.186 1.234.34 1.835l-1.938.495a15.94 15.94 0 01-.388-2.098zm1.067 4.157l1.852-.754c.235.577.508 1.137.818 1.677l-1.736.995a15.938 15.938 0 01-.934-1.918zm2.21 3.815l1.576-1.231c.384.49.8.955 1.245 1.391l-1.4 1.43a16.087 16.087 0 01-1.42-1.59zm2.989 2.938l1.204-1.597c.497.375 1.02.717 1.562 1.023l-.981 1.742a16.02 16.02 0 01-1.785-1.168zm3.853 2.145l.722-1.865c.58.224 1.174.41 1.781.555l-.465 1.945a15.883 15.883 0 01-2.038-.635zm3.98.974l.221-1.988c.51.057 1.025.086 1.544.087l-.004 2a16.16 16.16 0 01-1.761-.1zM34.459 14.86l-1.932.518c-2.001-7.469-9.678-11.9-17.146-9.9C8.797 7.244 4.47 13.482 5.053 20.196l-1.993.172c-.666-7.675 4.278-14.804 11.803-16.82C23.4 1.26 32.172 6.325 34.46 14.86z'
         ></path>
         <filter
            id='filter-4'
            width='106.3%'
            height='112.2%'
            x='-3.1%'
            y='-3.1%'
            filterUnits='objectBoundingBox'
         >
            <feOffset
               dy='2'
               in='SourceAlpha'
               result='shadowOffsetOuter1'
            ></feOffset>
            <feColorMatrix
               in='shadowOffsetOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         id='Page-1'
         fill='none'
         fillRule='evenodd'
         stroke='none'
         strokeWidth='1'
      >
         <g id='match_centre_configuration' transform='translate(-1240 -448)'>
            <g id='Group-13' transform='translate(1185 338)'>
               <g id='Group-3' transform='translate(43 99)'>
                  <g id='ic_pitch_marker' transform='translate(12 11)'>
                     <g id='Fill-36'>
                        <use
                           fill='#000'
                           filter='url(#filter-2)'
                           xlinkHref='#path-1'
                        ></use>
                        <use fill='#87FF00' xlinkHref='#path-1'></use>
                     </g>
                     <g
                        id='Oval-5'
                        fillRule='nonzero'
                        transform='rotate(8 18.993 19.37)'
                     >
                        <use
                           fill='#000'
                           filter='url(#filter-4)'
                           xlinkHref='#path-3'
                        ></use>
                        <use fill='#87FF00' xlinkHref='#path-3'></use>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

PitchMarkerIcon.defaultProps = {
   width: 38,
   height: 40
}

export default PitchMarkerIcon
