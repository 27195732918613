import React, { ReactElement } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line
import { observer } from 'mobx-react'

import { API_SUCCESS } from '@ib/api-constants'

import Loader from '../../../common/components/Loader'
import CricketConstantsStore from '../../../common/stores/CricketConstantsStore'
import QuickMatchCard from '../../components/MatchCards/QuickPlayCard/Completed'
import ScenarioMatchCard from '../../components/MatchCards/ScenarioCard/Completed'
import TournamentMatchCards from '../../components/MatchCards/Tournaments/TournamentsCards'
import SpecialChallengeCards from '../../components/MatchCards/SpecialChallengeCards'
import MatchesPlayedHeader from '../../components/MatchesPlayedHeader'
import QuickPlayMatch from '../../stores/models/QuickPlayMatch'
import ScenarioMatch from '../../stores/models/ScenarioMatch'
import TournamentMatch from '../../stores/models/TournamentMatch'
import MatchesStore from '../../stores/MatchesStore'
import SpecialChallengeMatch from '../../stores/models/SpecialChallengeMatch'
import NoMatchesPlayedView from '../../components/NoMatchesPlayedView'
import { MatchType } from '../../constants/MatchConfigConstants'

import { LoaderWrapper, CardsWrapper } from './styledComponents'
import './styles.css'

interface MatchesRouteProps extends WithTranslation {
   matchesStore: MatchesStore
   cricketConstantsStore: CricketConstantsStore
}

@observer
class MatchesRoute extends React.Component<MatchesRouteProps> {
   componentDidMount() {
      this.getMatches(0)
   }

   getMatchesStore = () => this.props.matchesStore

   getMatches = (offset = 0): void => {
      const {
         cricketConstantsStore: { gameId }
      } = this.props
      this.getMatchesStore().getMatchesList(gameId, offset)
   }

   fetchMoreData = (): void => {
      this.getMatches(this.getMatchesStore().matcheIds.length)
   }

   renderLoader = (): ReactElement => (
      <LoaderWrapper>
         <Loader height={60} width={60} />
      </LoaderWrapper>
   )

   renderCardUI = (match: any) => {
      switch (match.matchType) {
         case MatchType.QUICK_PLAY:
            return (
               <QuickMatchCard
                  key={match.matchId}
                  model={match as QuickPlayMatch}
               />
            )
         case MatchType.SCENARIO_CHALLENGE:
            return (
               <ScenarioMatchCard
                  key={match.matchId}
                  model={match as ScenarioMatch}
               />
            )
         case MatchType.TOURNAMENT:
            return (
               <TournamentMatchCards
                  key={match.matchId}
                  model={match as TournamentMatch}
               />
            )
         case MatchType.SPECIAL_CHALLENGE:
            return (
               <SpecialChallengeCards
                  key={match.matchId}
                  model={match as SpecialChallengeMatch}
               />
            )
      }
   }

   onDropdownValueChange = (value): void => {
      this.props.matchesStore.filterBy = value.value
      this.getMatches(0)
   }

   render(): JSX.Element {
      const {
         matches,
         matcheIds,
         totalMatchesLength,
         hasMoreMatches,
         getMatchesListAPIStatus
      } = this.getMatchesStore()
      const { t } = this.props
      return (
         <div>
            <MatchesPlayedHeader
               title={t('titles.matchesPlayed')}
               filterBy={this.props.matchesStore.filterBy}
               onChange={this.onDropdownValueChange}
            />
            {totalMatchesLength || getMatchesListAPIStatus !== API_SUCCESS ? (
               <CardsWrapper
                  dataLength={matcheIds.length}
                  next={this.fetchMoreData}
                  hasMore={hasMoreMatches}
                  loader={this.renderLoader()}
                  endMessage={null}
               >
                  {matcheIds.map(id => this.renderCardUI(matches[id]))}
               </CardsWrapper>
            ) : (
               <NoMatchesPlayedView />
            )}
         </div>
      )
   }
}

export default withTranslation()(MatchesRoute)
