import React from 'react'

interface SemiFinalDottedLineIconProps {
   width: number
   height: number
}

const SemiFinalDottedLineIcon = (props: SemiFinalDottedLineIconProps) => {
   const { width, height } = props
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width={width}
         height={height}
         viewBox='0 0 3 146'
         {...props}
      >
         <path
            d='M1.508 2.047v145.6'
            stroke='#BABABA'
            strokeWidth={2.446}
            fill='none'
            fillRule='evenodd'
            strokeDasharray='0 7.828'
            strokeLinecap='round'
            strokeLinejoin='round'
         />
      </svg>
   )
}
SemiFinalDottedLineIcon.defaultProps = {
   width: 3,
   height: 146
}

export default SemiFinalDottedLineIcon
