import React, { ReactElement } from 'react'

import {
   RoundTwoBackgroundBoxContainer,
   RoundTwoBackgroundBoxHeader,
   RoundTwoHeadingText
} from './styledComponents'

interface RoundTwoBackgroundBoxProps {
   title: string
}

const RoundTwoBackgroundBox = (
   props: RoundTwoBackgroundBoxProps
): ReactElement => {
   const { title } = props
   return (
      <RoundTwoBackgroundBoxContainer>
         <RoundTwoBackgroundBoxHeader>
            <RoundTwoHeadingText>{title}</RoundTwoHeadingText>
         </RoundTwoBackgroundBoxHeader>
      </RoundTwoBackgroundBoxContainer>
   )
}

export default RoundTwoBackgroundBox
