import React from 'react'

interface Props {
   width: number
   height: number
}

const HighestSuperRunsIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 30 30'
   >
      <defs>
         <linearGradient
            id='HighestSuperRunsIcon_5cw4qec7ob'
            x1='63.374%'
            x2='33.277%'
            y1='56.775%'
            y2='43.874%'
         >
            <stop offset='0%' stopColor='#FFC100' />
            <stop offset='100%' stopColor='#D6833E' />
         </linearGradient>
         <filter id='HighestSuperRunsIcon_cmcd37bw3a'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            />
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <g>
            <g>
               <g
                  filter='url(#HighestSuperRunsIcon_cmcd37bw3a)'
                  transform='translate(-938 -280) translate(300 275) translate(631)'
               >
                  <g>
                     <path
                        fill='#FFF'
                        d='M17.734 24.665H12.24l.936-1.998.366-.844c.467.242.95.461 1.446.657.498-.191.982-.407 1.45-.645l.36.832.937 1.998z'
                        opacity='.25'
                        transform='translate(7 5)'
                     />
                     <path
                        fill='#FFF'
                        d='M21.23 24.665H8.743c-.687 0-1.249.562-1.249 1.25v4.058h14.924v-4.059c.063-.687-.5-1.249-1.186-1.249M24.728 0H5.245c-.437 0-.75.375-.75.75 0 .436.313.811.75.811h19.483c.437 0 .75-.375.75-.75 0-.436-.313-.811-.75-.811'
                        transform='translate(7 5)'
                     />
                     <path
                        fill='#FFF'
                        d='M24.728 1.561v6.744c0 6.244-3.872 11.927-9.741 14.175-5.87-2.31-9.742-7.93-9.742-14.237V1.56h19.483z'
                        opacity='.25'
                        transform='translate(7 5)'
                     />
                     <g
                        fill='url(#HighestSuperRunsIcon_5cw4qec7ob)'
                        transform='translate(7 5) translate(12 5)'
                     >
                        <path
                           d='M2.319.594L.65 5.804c-.088.275.064.57.339.657.053.017.108.026.164.025l1.226-.01-1.571 4.91c-.054.17.039.35.208.405.032.01.066.015.1.015l.215-.002c.146-.001.285-.063.383-.172l4.746-5.235c.194-.214.177-.544-.037-.739-.097-.088-.224-.136-.355-.135l-1.666.014L7.125.99c.148-.248.068-.57-.18-.718C6.862.223 6.768.198 6.672.2L2.812.23c-.225.002-.424.149-.493.364z'
                           transform='rotate(4 4.017 6)'
                        />
                     </g>
                     <g fill='#FFF'>
                        <path
                           d='M7.23 12.33c-.679-.545-1.302-1.161-1.86-1.84-1.748-2.123-2.872-4.933-2.872-7.93v-.063h2.747V0H0v2.56c0 6.245 3.871 11.615 9.304 13.863.718.308 1.467.552 2.239.733C9.766 15.863 8.3 14.214 7.23 12.33zM24.728 0v2.497h2.685v.063h.063c0 2.997-1.062 5.745-2.873 7.93-.542.645-1.146 1.237-1.802 1.768-1.064 1.908-2.533 3.582-4.319 4.886.754-.18 1.486-.42 2.187-.721 5.433-2.248 9.304-7.618 9.304-13.863V0h-5.245z'
                           transform='translate(7 5) translate(0 3.434)'
                        />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

HighestSuperRunsIcon.defaultProps = {
   width: 30,
   height: 30
}
export default HighestSuperRunsIcon
