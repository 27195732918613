import { observable, action } from 'mobx'
import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'
import { APIStatus, API_INITIAL, API_SUCCESS } from '@ib/api-constants'

import TournamentLeaderboardSuperItem from '../../../leaderboard/stores/models/TournamentLeaderboardSuperItem'
import TournamentsService from '../../services/TournamentsService'

import { TournamentTabsStatus } from '../../constants/tournamentConstants'

import TournamentModel from '../models/TournamentModel'
import PopupLeaderboardModel from '../models/PopupLeaderBoardModel'
import ParticipantModel from '../models/ParticipantModel'
import TournamentInfoModel from '../models/TournamentInfoModel'

import {
   GetTournamentsMinimalDetailsAPIResponse,
   GetTournamentsInfoV2Response,
   RoundDetail,
   KnockoutMatchType,
   MatchDetailsWithParticipants
} from '../types'

class TournamentsStore {
   @observable getTournamentDetailsAPIStatus!: APIStatus
   @observable getTournamentDetailsAPIError

   @observable getTournamentsListAPIStatus
   @observable getTournamentsListAPIError

   @observable ongoingTournaments: any
   @observable previousTournaments: any
   @observable leaderboardItems: TournamentLeaderboardSuperItem[]
   @observable participantsDetailsDict: Record<string, ParticipantModel>
   @observable tournamentMatchesDict: Record<
      string,
      MatchDetailsWithParticipants
   >
   @observable knockoutMatchesDict: Record<number, KnockoutMatchType[]>
   @observable roundDetailsDict: Record<number, RoundDetail>
   @observable ongoingTournamentsTotalCount: number
   @observable previousTournamentsTotalCount: number
   @observable lbContributionCount: number

   @observable tournamentInfo!: TournamentInfoModel
   @observable popupLeaderBoardModels!: Array<PopupLeaderboardModel>

   LIMIT = 10
   getTournamentDetailsRequest

   tournamentDetailsService
   tournamentDetails
   @observable currentRoundIndex = 0
   @observable tournamentID: number
   constructor(tournamentsService: TournamentsService) {
      this.tournamentDetailsService = tournamentsService
      this.tournamentID = 0
      this.ongoingTournaments = []
      this.previousTournaments = []
      this.leaderboardItems = []
      this.participantsDetailsDict = {}
      this.knockoutMatchesDict = {}
      this.tournamentMatchesDict = {}
      this.roundDetailsDict = {}
      this.lbContributionCount = 0

      this.ongoingTournamentsTotalCount = 0
      this.previousTournamentsTotalCount = 0
      this.getTournamentDetailsRequest = {
         tournament_id: 0,
         round_type: ''
      }

      this.init()
   }
   init(): void {
      this.getTournamentDetailsAPIStatus = API_INITIAL
      this.getTournamentDetailsAPIError = ''

      this.getTournamentsListAPIStatus = API_INITIAL
      this.getTournamentsListAPIError = null
   }

   getTournamentsTabObjectsByStatus = (tabEnum: string): any => {
      const tournaments = this.getTournamentsByStatus(tabEnum)
      const totalCount = this.getTournamentsTotalCountsByStatus(tabEnum)
      return {
         totalCount,
         tournaments,
         currentTournamentsCount: tournaments.length,
         hasMoreTournaments:
            this.getTournamentsListAPIStatus !== API_SUCCESS ||
            tournaments.length < totalCount
      }
   }

   getTournamentsCountByStatus = (tabEnum: string): number =>
      this.getTournamentsByStatus(tabEnum).length

   getTournamentsByStatus = (tabEnum: string): Array<any> => {
      if (tabEnum === TournamentTabsStatus.ONGOING) {
         return this.ongoingTournaments
      }
      return this.previousTournaments
   }

   getTournamentsTotalCountsByStatus = (tabEnum: string): number => {
      if (tabEnum === TournamentTabsStatus.ONGOING) {
         return this.ongoingTournamentsTotalCount
      }
      return this.previousTournamentsTotalCount
   }

   @action.bound
   setGetTournamentDetailsResponse(response): void {
      this.tournamentInfo = new TournamentInfoModel(response)
      this.tournamentDetails = response
      this.currentRoundIndex = response.current_round - 1
   }

   @action.bound
   setGetTournamentDetailsAPIError(error): void {
      this.getTournamentDetailsAPIError = error
   }

   @action.bound
   setGetTournamentDetailsAPIStatus(status): void {
      this.getTournamentDetailsAPIStatus = status
   }

   @action.bound
   getTournamentDetails(
      tournament_id: string,
      round_type: string
   ): Promise<GetTournamentsInfoV2Response> {
      const getTournamentDetailsRequest = {
         tournament_id: tournament_id,
         round_type: round_type
      }
      const getResourcesPromise = this.tournamentDetailsService.getTournamentDataAPI(
         getTournamentDetailsRequest
      )
      return bindPromiseWithOnSuccess(getResourcesPromise)
         .to(
            this.setGetTournamentDetailsAPIStatus,
            this.setGetTournamentDetailsResponse
         )
         .catch(this.setGetTournamentDetailsAPIError) as Promise<
         GetTournamentsInfoV2Response
      >
   }

   @action.bound
   setCurrentRoundIndex(roundIndex): void {
      this.currentRoundIndex = roundIndex
   }

   resetLocalVars(): void {
      this.ongoingTournaments = []
      this.previousTournaments = []
   }

   @action.bound
   setTournamentsListAPIStatus(status): void {
      this.getTournamentsListAPIStatus = status
   }

   @action.bound
   setTournamentsListAPIError(error): void {
      console.log('error', error)
      this.getTournamentsListAPIError = error
   }

   @action.bound
   setTournamentsListResponse(response, request): void {
      const data = response as GetTournamentsMinimalDetailsAPIResponse
      const tournamentsObjects = data.tournaments.map(
         tournament =>
            new TournamentModel(tournament, request.tournament_status)
      )

      if (request.tournament_status === TournamentTabsStatus.ONGOING) {
         this.ongoingTournamentsTotalCount = data.total_tournaments_count
         if (request.offset === 0) {
            this.ongoingTournaments = tournamentsObjects
         } else {
            this.ongoingTournaments = this.ongoingTournaments.concat(
               tournamentsObjects
            )
         }
      } else {
         this.previousTournamentsTotalCount = data.total_tournaments_count
         if (request.offset === 0) {
            this.previousTournaments = tournamentsObjects
         } else {
            this.previousTournaments = this.previousTournaments.concat(
               tournamentsObjects
            )
         }
      }
   }

   @action.bound
   getTournamentsMinimalDetails(
      tournamentStatus: string,
      gameID: number
   ): Promise<GetTournamentsMinimalDetailsAPIResponse> {
      const { currentTournamentsCount } = this.getTournamentsTabObjectsByStatus(
         tournamentStatus
      )

      const request = {
         offset: currentTournamentsCount,
         limit: this.LIMIT,
         tournament_group_name_enum: '',
         tournament_status: tournamentStatus,
         game_id: gameID
      }

      const getTournamentsPromise = this.tournamentDetailsService.getTournamentsMinimalDetails(
         request
      )

      return bindPromiseWithOnSuccess(getTournamentsPromise)
         .to(this.setTournamentsListAPIStatus, response => {
            this.setTournamentsListResponse(response, request)
         })
         .catch(this.setTournamentsListAPIError) as Promise<
         GetTournamentsMinimalDetailsAPIResponse
      >
   }
}
export default TournamentsStore
