import styled from 'styled-components'
import Colors from '../../../themes/Colors'
import {
   Typo16Black2BarlowBoldItalic,
   Typo24WhiteBarlowBoldItalic
} from '../../../styleGuide/Typos'

import {
   ReverseSkew,
   SkewNegative20Angle,
   ResourceItemContainer,
   CommonResourceItemTitleBg
} from '../CommonResource/styledComponents'

import { CommonBottomBar } from '../ResourceItem/styledComponents'
import Image from '../../Image'

export const FanBaseResourceItemContainer = styled(ResourceItemContainer)`
   display: flex;
   flex-direction: column;
   width: 149px;
`

export const ResourceNameContainer = styled(CommonBottomBar)`
   position: relative;
   z-index: 2;
`

export const ResourceCountBg = styled.div`
   position: absolute;
   right: 0;
   min-width: 50px;
   min-height: 24px;
   background-image: ${Colors.linerGradients.resourceCountGradient};
   ${SkewNegative20Angle}
`
export const InnerResourceCountBg = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 2px;
   padding-right: 2px;
   ${ReverseSkew}
`

export const ResourceName = styled(Typo16Black2BarlowBoldItalic)`
   margin-left: -50px;
   padding-top: 2px;
   padding-bottom: 3px;
`
export const CurrentResourceCount = styled(CommonResourceItemTitleBg)`
   position: relative;
   padding: 0 0 0 0;
`
export const CurrentResourceCountText = styled(Typo24WhiteBarlowBoldItalic)`
   display: inline-block;
   padding: 5px 0px 6px 37px;
   ${ReverseSkew}
`
export const CurrentResourceImage = styled(Image)`
   position: absolute;
   width: 46px;
   height: 54px;
   object-fit: cover;
   z-index: 10;
   top: -19%;
   left: -13%;
   ${ReverseSkew}
`
