import React from 'react'
import { useTranslation } from 'react-i18next'

import SuccessTrophyIcon from '../../../../common/icons/SuccessTrophyIcon'
import ArrowMarkIcon from '../../../../common/icons/ArrowMarkIcon'
import pathToFinalsFixtures from '../../../fixtures/pathToFinalsFixtues.json'

import RoundLevelBackgroundBox from '../RoundLevelBackgroundBox'
import MatchesHierarchyWrapper from '../MatchesHierarchyWrapper'

import WinnerCardComponent from '../WinnerCardComponent'

import RoundOneLeaderBoardHeading from '../../RoundOneLeaderBoardComponents/RoundOneLeaderboardHeading'
import {
   Container,
   MatchesContainer,
   RoundsBackgroundBoxWrapper,
   RoundTwoBackgroundBoxWrapper,
   RoundThreeBackgroundBoxWrapper,
   SemiFinalBackgroundBoxWrapper,
   FinalBackgroundBoxWrapper,
   WinnerBackgroundBoxWrapper,
   MatchesHierarchyContainer,
   ArrowMarkIconWraper,
   WinnerCardComponentWrapper,
   WinnerTrohpyIconWrapper
} from './styledComponent'

const PathToFinalsComponent = () => {
   const { round_details } = pathToFinalsFixtures
   const { t } = useTranslation()
   return (
      <Container>
         <RoundOneLeaderBoardHeading
            RoundHeadingName={t('tournaments:roundTwoToKnockOutFinals')}
         />
         <MatchesContainer>
            <RoundsBackgroundBoxWrapper>
               <RoundTwoBackgroundBoxWrapper>
                  <RoundLevelBackgroundBox
                     roundHeight={491}
                     roundDetails={round_details[0]}
                  />
               </RoundTwoBackgroundBoxWrapper>
               <RoundThreeBackgroundBoxWrapper>
                  <RoundLevelBackgroundBox
                     roundHeight={491}
                     roundDetails={round_details[1]}
                  />
               </RoundThreeBackgroundBoxWrapper>
               <SemiFinalBackgroundBoxWrapper>
                  <RoundLevelBackgroundBox
                     roundHeight={265}
                     roundDetails={round_details[2]}
                  />
               </SemiFinalBackgroundBoxWrapper>
               <FinalBackgroundBoxWrapper>
                  <RoundLevelBackgroundBox
                     roundHeight={109}
                     roundDetails={round_details[3]}
                  />
                  <ArrowMarkIconWraper>
                     <ArrowMarkIcon />
                  </ArrowMarkIconWraper>
               </FinalBackgroundBoxWrapper>
               <WinnerBackgroundBoxWrapper>
                  <RoundLevelBackgroundBox
                     roundHeight={59}
                     roundDetails={round_details[4]}
                  />
               </WinnerBackgroundBoxWrapper>
               <WinnerCardComponentWrapper>
                  <WinnerCardComponent
                     playerName={pathToFinalsFixtures.winnerDetails.playerName}
                     bgColor={pathToFinalsFixtures.winnerDetails.bgColor}
                  />
                  <WinnerTrohpyIconWrapper>
                     <SuccessTrophyIcon width={28} height={33} />
                  </WinnerTrohpyIconWrapper>
               </WinnerCardComponentWrapper>
            </RoundsBackgroundBoxWrapper>
            <MatchesHierarchyContainer>
               <MatchesHierarchyWrapper />
            </MatchesHierarchyContainer>
         </MatchesContainer>
      </Container>
   )
}

export default PathToFinalsComponent
