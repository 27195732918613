import React from 'react'

import RoundHeaderComponent from '../RoundHeaderComponent'
import RoundPrizesComponent from '../RoundPrizesComponent'

import {
   RoundLevelBackgroundBoxContainer,
   RoundComponentsWrapper
} from './styledComponent'

interface RoundDetailsProps {
   roundName: string
   ibcRatingPoints: number
   coinsCount: number
   gemsCount: number
}

interface RoundLevelBackgroundBoxProps {
   roundDetails: RoundDetailsProps
   roundHeight: number
}

const RoundLevelBackgroundBox = (props: RoundLevelBackgroundBoxProps) => {
   const { roundName, gemsCount, ibcRatingPoints } = props.roundDetails
   const { roundHeight } = props
   return (
      <RoundComponentsWrapper>
         <RoundHeaderComponent roundName={roundName} />
         <RoundLevelBackgroundBoxContainer
            height={roundHeight}
         ></RoundLevelBackgroundBoxContainer>
         <RoundPrizesComponent
            gemsCount={gemsCount}
            ibcRatingPoints={ibcRatingPoints}
         />
      </RoundComponentsWrapper>
   )
}

export default RoundLevelBackgroundBox
