import React, { ReactElement, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getDateTimeDisplay } from '../../../../common/utils/TimeUtils'
import { MATCH_DETAILS_PATH } from '../../../../common/constants/NavigationConstants'
import {
   Typo42PaleGreyBarlowBoldItalicCapital,
   Typo14BattleShipGreyBarlowSemiBoldItalic
} from '../../../../common/styleGuide/Typos'

import BaseMatch from '../../../stores/models/BaseMatch'
import { TitleAndIcon } from '../../MatchesCommon/MatchesCommon'
import {
   HeaderWrapper,
   CardWrapper,
   TitleWrapper,
   DateTextWrapper,
   MatchExpiredText
} from './styledComponents'

interface BaseMatchCardProps {
   matchModel: BaseMatch
   renderHeaderChildren: () => ReactNode
   children?: any
}

const BaseMatchCardView = (props: BaseMatchCardProps): ReactElement => {
   const history = useHistory()
   const { matchModel } = props
   const { gameStats } = matchModel.getSelfUserMatchDetails()
   // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
   const onClickOfCard = () => {
      history.push(`${MATCH_DETAILS_PATH}/${matchModel.matchId}`, {
         matchId: matchModel.matchId
      })
   }
   const onClickHandler = (expired: boolean) =>
      expired === true ? null : onClickOfCard

   const { t } = useTranslation()
   return (
      <CardWrapper
         onClick={onClickHandler(matchModel.expired)}
         expired={matchModel.expired}
      >
         <HeaderWrapper>
            <TitleWrapper>
               <TitleAndIcon
                  title={matchModel.title}
                  url={matchModel.titleUrl}
               />
            </TitleWrapper>
            {props.renderHeaderChildren()}
            <Typo42PaleGreyBarlowBoldItalicCapital>
               {gameStats.score}-{gameStats.wickets}
            </Typo42PaleGreyBarlowBoldItalicCapital>
         </HeaderWrapper>
         {props.children}
         <DateTextWrapper>
            <Typo14BattleShipGreyBarlowSemiBoldItalic>
               {getDateTimeDisplay(matchModel.dateTime)}
            </Typo14BattleShipGreyBarlowSemiBoldItalic>
            {matchModel.expired ? (
               <MatchExpiredText>{t('matches.expiredText')}</MatchExpiredText>
            ) : (
               <></>
            )}
         </DateTextWrapper>
      </CardWrapper>
   )
}

BaseMatchCardView.defaultProps = {
   renderHeaderChildren: () => null
}
export default BaseMatchCardView
