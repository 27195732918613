import styled from 'styled-components'
import colors from '../../../common/themes/Colors'
import { Typo18LightBlueGrayBarlowBoldItalic } from '../../../common/styleGuide/Typos'

export const RoundTwoBackgroundBoxContainer = styled.div`
   background-color: ${colors.charcoalGrey};
   position: absolute;
   top: 0px;
   bottom: 0px;
   left: 0px;
`

export const RoundTwoBackgroundBoxHeader = styled.div`
   width: 371px;
   background-color: ${colors.oceanBlue};
   display: flex;
   align-items: center;
   padding: 15px 0px 11px 33px;
`

export const RoundTwoHeadingText = styled(Typo18LightBlueGrayBarlowBoldItalic)``
