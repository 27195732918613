import styled from 'styled-components'

import { leaderBoardConstants } from '../../constants/leaderBoardConstants'
import Colors from '../../../common/themes/Colors'

interface ContainerProps {
   isSamePerson: boolean
}

interface TrophyIconWrapperProps {
   isPlayerOne: boolean
}

export const Container = styled.div<ContainerProps>`
   width: 265px;
   height: 80px;
   display: flex;
   transform: skew(-${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   border-style: solid;
   border-width: 3.1px;
   margin-left: 25px;
   background-color: ${Colors.hanPurple};
   justify-content: space-between;
   position: relative;
   border-color: ${Colors.darkPumpkinOrange};
`

export const TrophyIconWrapper = styled.div<TrophyIconWrapperProps>`
   position: absolute;
   top: 10px;
   left: 280px;
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
`
