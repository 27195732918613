class ProfileDetails {
   name: string
   profileUrl: string

   constructor(name: string, profileUrl: string) {
      this.name = name
      this.profileUrl = profileUrl
   }
}

export default ProfileDetails
