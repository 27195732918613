import styled from 'styled-components'

import {
   TwoLineEllipsis,
   Typo20LightBlueGreySemiBoldBarlowText
} from '../../../styleGuide/Typos'
import { maxDeviceWidth } from '../../../utils/MixinUtils'
import ImageComponent from '../../BaseImageComponent'

export const ProfileNameContainer = styled.div`
   display: flex;
   align-items: center;
   @media (max-width: 1314px) {
      margin-right: 15px;
   }
   ${maxDeviceWidth(1630)} {
      flex: 2;
   }
`
export const ProfilePic = styled(ImageComponent)`
   border-radius: 50%;
   width: 60px;
   height: 60px;
   margin-right: 10px;
   object-fit: cover;
`
export const NameText = styled(Typo20LightBlueGreySemiBoldBarlowText)`
   display: inline-block;
   justify-content: flex-start;
   flex-wrap: wrap;
   max-width: 130px;
   ${TwoLineEllipsis};
   white-space: pre-wrap;
   word-break: break-all;
   word-wrap: break-word;
`
