import React from 'react'
import { useTranslation } from 'react-i18next'

import PlayerCardComponent from '../PlayerCardComponent'

import {
   Wrapper,
   FirstPlayerCardWrapper,
   SecondPlayerCardWrapper,
   VersusComponent,
   VersusText
} from './styledComponent'

interface PlayerDetails {
   playerName: string
   bgColor: string
}

interface KnockOutMatchCardProps {
   playerOne: PlayerDetails
   playerTwo: PlayerDetails
}

const KnockOutMatchCard = (props: KnockOutMatchCardProps) => {
   const { playerOne, playerTwo } = props
   const { t } = useTranslation()
   return (
      <Wrapper>
         <FirstPlayerCardWrapper>
            <PlayerCardComponent
               playerName={playerOne.playerName}
               bgColor={playerOne.bgColor}
            />
         </FirstPlayerCardWrapper>
         <VersusComponent>
            <VersusText>{t('tournaments:versusText')}</VersusText>
         </VersusComponent>
         <SecondPlayerCardWrapper>
            <PlayerCardComponent
               playerName={playerTwo.playerName}
               bgColor={playerTwo.bgColor}
            />
         </SecondPlayerCardWrapper>
      </Wrapper>
   )
}

export default KnockOutMatchCard
