import React from 'react'
interface Props {
   width: number
   height: number
}

const WrongIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 24 24'
   >
      <defs>
         <linearGradient
            id='wrong-linearGradient-1'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#C24B3C'></stop>
            <stop offset='100%' stopColor='#9A3326'></stop>
         </linearGradient>
      </defs>
      <g
         id='Page-1'
         fill='none'
         fillRule='evenodd'
         stroke='none'
         strokeWidth='1'
      >
         <g
            id='practice_selection_3'
            fillRule='nonzero'
            transform='translate(-761 -534)'
         >
            <g id='Group-10' transform='translate(502 286)'>
               <g id='Group-3' transform='translate(83 226)'>
                  <g id='ic_deactive_tick' transform='translate(176 22)'>
                     <g id='tick-copy-3' fill='url(#wrong-linearGradient-1)'>
                        <path
                           id='Shape'
                           d='M11.977 0C5.362 0 0 5.362 0 11.977c0 6.614 5.362 11.976 11.977 11.976 6.614 0 11.976-5.362 11.976-11.976C23.953 5.362 18.591 0 11.977 0z'
                        ></path>
                     </g>
                     <path
                        id='Shape'
                        fill='#FFF'
                        d='M17.761 10.602a.79.79 0 00-.58-.238h-3.545V6.818a.79.79 0 00-.238-.58.79.79 0 00-.58-.238h-1.636c-.227 0-.42.08-.58.239a.79.79 0 00-.238.58v3.545H6.818a.79.79 0 00-.58.238.789.789 0 00-.238.58v1.636c0 .228.08.42.239.58.159.159.352.238.58.238h3.545v3.546c0 .227.08.42.238.58a.79.79 0 00.58.238h1.636a.79.79 0 00.58-.238.79.79 0 00.238-.58v-3.546h3.546c.227 0 .42-.08.58-.238a.788.788 0 00.238-.58v-1.636c0-.228-.08-.42-.239-.58z'
                        transform='rotate(-45 12 12)'
                     ></path>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

WrongIcon.defaultProps = {
   width: 24,
   height: 24
}

export default WrongIcon
