import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import {
   Typo14CoolGreyBarlowBoldItalicCapital,
   Typo24PaleGreyBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

import BatSheildIcon from '../../icons/BatSheild'

import {
   ContentWrapper,
   IconAndTextWrapper,
   IconWrapper
} from './styledComponents'

interface BatSheildProps {
   isBatSheildOn: boolean
}

const BatSheildOnOrOff = (props: BatSheildProps): ReactElement => {
   const { t } = useTranslation()
   const { isBatSheildOn } = props
   return (
      <ContentWrapper>
         <IconAndTextWrapper>
            <IconWrapper>
               <BatSheildIcon />
            </IconWrapper>
            <Typo24PaleGreyBarlowBoldItalicCapital>
               {isBatSheildOn ? t('matches.on') : t('matches.off')}
            </Typo24PaleGreyBarlowBoldItalicCapital>
         </IconAndTextWrapper>
         <Typo14CoolGreyBarlowBoldItalicCapital>
            {t('matches.batShield')}
         </Typo14CoolGreyBarlowBoldItalicCapital>
      </ContentWrapper>
   )
}

export default BatSheildOnOrOff
