import { RewardResourceType } from '../../../types'

class RewardResource {
   displayName: string
   id: number
   name: string
   pic: string
   thumbnail: string
   value: number
   constructor(reward: RewardResourceType) {
      this.displayName = reward.display_name
      this.id = reward.id
      this.name = reward.name
      this.pic = reward.pic
      this.thumbnail = reward.thumbnail
      this.value = reward.value
   }
}

export default RewardResource
