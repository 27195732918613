import styled from 'styled-components'
import Colors from '../../themes/Colors'

export const DisplayNameWrapper = styled.div`
   transform: skew(30deg);
`
export const CommonDarkBg = styled.div`
   background: ${Colors.darkThree};
   transform: skew(-30deg);
   width: 222px;
   padding: 0px 5px;
`
export const GameModeBaseBg = styled.div`
   display: flex;
   justify-content: center;
   align-content: center;
   text-align: center;
   align-items: center;
   padding: 3px 0px;
`
export const NovoiceBg = styled(GameModeBaseBg)`
   background: ${Colors.linerGradients.gameMode.novoiceGradient};
`
export const AmetureBg = styled(GameModeBaseBg)`
   background: ${Colors.linerGradients.gameMode.amatureGradient};
`
export const ProBg = styled(GameModeBaseBg)`
   background: ${Colors.linerGradients.gameMode.proGradient};
`
export const SemiProBg = styled(GameModeBaseBg)`
   background: ${Colors.linerGradients.gameMode.semiProGradient};
`
export const WorldClassBg = styled.div`
   background: ${Colors.linerGradients.gameMode.worldClassGradient};
`
export const WorldClassBaseBg = styled.div`
   background: ${Colors.linerGradients.gameMode.worldClassBaseGradient};
`
export const UltimateBg = styled.div`
   background: ${Colors.linerGradients.gameMode.ultimateGradient};
`
export const UltimateBaseBg = styled.div`
   background: ${Colors.linerGradients.gameMode.ultimateGradientBase};
`
export const RectangularOverlay = styled(GameModeBaseBg)`
   background: ${Colors.linerGradients.gameMode.rectangularGradient};
`
