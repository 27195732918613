import React from 'react'

interface Props {
   width: number
   height: number
}

const LogoutIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 24 24'
   >
      <defs>
         <filter id='09preu8paa'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            />
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <g>
            <g
               filter='url(#09preu8paa)'
               transform='translate(-32 -1019) translate(0 997)'
            >
               <g transform='translate(32 22)'>
                  <path d='M0 0H24V24H0z' />
                  <path
                     fill='#000'
                     fillRule='nonzero'
                     d='M14.007 7.006c-.552 0-1-.447-1-1 0-.552.448-1 1-1h1c2.21 0 4 1.791 4 4v5.996c0 2.208-1.79 3.998-4 3.998h-12c-2.21 0-4-1.79-4-3.998V8.998C-.993 6.79.797 5 3.007 5h1c.552 0 1 .448 1 1s-.448 1-1 1h-1c-1.105 0-2 .894-2 1.998v6.004c0 1.104.895 1.999 2 1.999h12c1.105 0 2-.895 2-2V9.006c0-1.104-.895-1.999-2-1.999h-1z'
                     opacity='.3'
                     transform='matrix(0 -1 -1 0 21.007 21.007)'
                  />
                  <rect
                     width='2'
                     height='12'
                     x='13'
                     y='6'
                     fill='#000'
                     fillRule='nonzero'
                     rx='1'
                     transform='rotate(90 14 12)'
                  />
                  <path
                     fill='#000'
                     fillRule='nonzero'
                     d='M21.793 9.793c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-3 3c-.39.39-1.024.39-1.414 0l-3-3c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0l2.293 2.293 2.293-2.293z'
                     transform='rotate(-90 19.5 12)'
                  />
               </g>
            </g>
         </g>
      </g>
   </svg>
)

LogoutIcon.defaultProps = {
   width: 24,
   height: 30
}
export default LogoutIcon
