export enum StatsDisplayNames {
   MATCHES = 'MATCHES',
   AVERAGE = 'AVERAGE',
   STRIKE_RATE = 'STRIKE_RATE',
   OUTS = 'OUTS',
   RUNS = 'RUNS',
   HIGHEST_SCORE_WITH_1_WICKET = 'HIGHEST_SCORE_WITH_1_WICKET',
   HIGHEST_SUPER_RUNS_WITH_1_WICKET = 'HIGHEST_SUPER_RUNS_WITH_1_WICKET',
   FOURS = 'FOURS',
   SIXES = 'SIXES',
   DOT_BALLS = 'DOT_BALLS',
   OVERS = 'OVERS',
   ECONOMY = 'ECONOMY'
}
