import styled from 'styled-components'
import colors from '../../../../common/themes/Colors'

interface RoundLevelBackgroundBoxContainerProps {
   height: number
}

export const RoundLevelBackgroundBoxContainer = styled.div<
   RoundLevelBackgroundBoxContainerProps
>`
   width: 149px;
   opacity: 0.5;
   border-radius: 2.4px;
   background-color: ${colors.martinique};
   height: ${props => props.height}px;
`
export const RoundComponentsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 165px;
`
