import React, { Component, ReactNode } from 'react'
import UserControl from '../../../common/stores/models/UserControls'
import GroundIcon, {
   GROUND_VIEW_BOX_RADIUS,
   TEXT_MULTIPLIER,
   LINES_MULTIPLIER
} from '../../../common/icons/Ground'

import { GroundWrapper, PitchArea, PitchWrapper } from './styledComponents'

type Props = {
   userControls: UserControl | null
   displayStringFormat: string
   radius: number
   Typo: any
   className?: string
}

class WagonWheel extends Component<Props> {
   static defaultProps: {
      displayStringFormat: string
      userControls: null
      radius: number
      Typo: null
   }

   positionTop(angle: number, multiplier: number): number {
      //x+rcos0 - 0 in radians
      const offset = GROUND_VIEW_BOX_RADIUS * multiplier
      return offset + offset * Math.cos((360 - angle) * (Math.PI / 180))
   }

   positionLeft(angle: number, multiplier: number): number {
      //y+rsin0 - 0 in radians
      const offset = GROUND_VIEW_BOX_RADIUS * multiplier
      return offset + offset * Math.sin((360 - angle) * (Math.PI / 180))
   }

   getScoreTextPositionsAndEndPointPositions = () => {
      const { userControls } = this.props
      if (userControls) {
         return userControls.regionWiseScorePercentage.map(controlsInfo => {
            const { value, endAngle, startAngle } = controlsInfo
            const scoreAngle = 0.5 * (startAngle + endAngle)
            return {
               scoreTextPosition: {
                  top: this.positionTop(scoreAngle, TEXT_MULTIPLIER),
                  left: this.positionLeft(scoreAngle, TEXT_MULTIPLIER),
                  value: value
               },
               lineEndPosition: {
                  top: this.positionTop(endAngle, LINES_MULTIPLIER),
                  left: this.positionLeft(endAngle, LINES_MULTIPLIER)
               }
            }
         })
      }
   }

   render(): ReactNode {
      const { radius, displayStringFormat, className } = this.props
      const scoreTextAndLineEndPoints = this.getScoreTextPositionsAndEndPointPositions()
      return (
         <GroundWrapper className={className}>
            <GroundIcon
               width={2 * radius}
               height={2 * radius}
               scoreTextAndLineEndPoints={scoreTextAndLineEndPoints}
               displayStringFormat={displayStringFormat}
            />
            <PitchWrapper>
               <PitchArea width={0.195 * radius} height={0.5 * radius} />
            </PitchWrapper>
         </GroundWrapper>
      )
   }
}

WagonWheel.defaultProps = {
   displayStringFormat: `{0}`,
   userControls: null,
   radius: 0,
   Typo: null
}

export default WagonWheel
