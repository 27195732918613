import styled from 'styled-components'

import Colors from '../../../common/themes/Colors'
import { Typo16BlackBarlowExtraBoldItalicCapital } from '../../../common/styleGuide/Typos'

import { GameModeConfigUrls } from '../../constants/MatchConfigConstants'
import SuperRunsIcon from '../../icons/SuperRunWithNoOutline'

import { BottomBarBg, BottomBarGradeint } from '../ConfigItem/styledComponents'

export const ItemContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   text-align: center;
   align-items: center;
`
export const SuperRunsBg = styled.div`
   background-color: ${Colors.navyBlue};
   transform: skew(-30deg);
`

export const StyledSuperRunIcon = styled(SuperRunsIcon)`
   margin-left: 5px;
`

export const SuperRunsContentBg = styled.div`
   transform: skew(30deg);
   display: flex;
   align-items: center;
   justify-content: center;
   min-width: 349px;
   margin-top: 3%;
   margin-bottom: 3%;
`

export const SuperRunsIcopWrapper = styled.div`
   margin-left: 3%;
`

export const OvalBg = styled.div`
   width: 65px;
   height: 65px;
   box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5);
   border-radius: 50%;
   background: ${Colors.linerGradients.gameModePropertyGradient};
   z-index: 10;
   display: flex;
   align-items: center;
   justify-content: center;
`
export const GameModePropertyBg = styled(BottomBarBg)`
   width: 203px;
   margin-left: -7%;
`
export const GameModePropertyGradeintBg = styled(BottomBarGradeint)`
   min-width: 192px;
`
export const PropertyContent = styled.div`
   transform: skew(20deg);
   text-align: left;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   padding: 2% 0%;
`

export const EachPropertyWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 2%;
`
export const GameModePropertyDisplayName = styled(
   Typo16BlackBarlowExtraBoldItalicCapital
)`
   margin-right: 3%;
`

export const BackgroungImage = styled.div`
   display: flex;
   width: 278px;
   height: 175px;
   background-image: url(${GameModeConfigUrls.commonBg});
   flex-direction: column;
   justify-content: space-around;
   text-align: center;
   align-content: center;
   align-items: center;
`
