import { observable, action } from 'mobx'
import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'
import { API_INITIAL } from '@ib/api-constants'

import GlobalLeaderboardService from '../../services/GlobalLeaderboardService'
import {
   LeaderboardEntry,
   GetPlayerGlobalLeaderboardAPIResponse,
   GetTournamentsPointsGenericLeaderboardAPIResponse
} from '../types'
import LeaderboardEntryModel from '../models/LeaderboardEntryModel'

export class Leaderboard {
   globalLeaderboardService: GlobalLeaderboardService
   @observable getLeaderboardAPIStatus
   @observable getLeaderboardAPIError

   @observable getRatingLeaderboardAPIStatus
   @observable getRatingLeaderboardAPIError

   @observable rankingParamentResourcePic = ''
   @observable lbEntries: LeaderboardEntry[] = []
   rankingParameterEnum: string

   constructor(
      globalLeaderboardService: GlobalLeaderboardService,
      rankingParameterEnum
   ) {
      this.globalLeaderboardService = globalLeaderboardService
      this.rankingParameterEnum = rankingParameterEnum
      this.init()
   }

   @action.bound
   init(): void {
      this.getLeaderboardAPIStatus = API_INITIAL
      this.getLeaderboardAPIError = null
      this.getRatingLeaderboardAPIStatus = API_INITIAL
      this.getRatingLeaderboardAPIError = null
      this.lbEntries = []
   }

   @action.bound
   setGlobalLeaderboardAPIStatus(status): void {
      this.getLeaderboardAPIStatus = status
   }

   @action.bound
   setGlobalLeaderboardAPIError(error): void {
      this.getLeaderboardAPIError = error
   }

   @action.bound
   setGlobalLeaderboardResponse(response): void {
      const data = response as GetPlayerGlobalLeaderboardAPIResponse

      this.rankingParamentResourcePic = data.ranking_parameter_pic_url

      this.lbEntries = data.lb_entries.map(
         lbItem => new LeaderboardEntryModel(lbItem)
      )
   }

   //ibcRating
   @action.bound
   setRatingLeaderboardAPIStatus(status): void {
      this.getRatingLeaderboardAPIStatus = status
   }

   @action.bound
   setRatingLeaderboardAPIError(error): void {
      this.getRatingLeaderboardAPIError = error
   }

   @action.bound
   setRatingLeaderboardResponse(response): void {
      const data = response as GetTournamentsPointsGenericLeaderboardAPIResponse
      this.rankingParamentResourcePic = data.resource_details.pic_url
      this.lbEntries = data.lb_entries.map(
         lbItem => new LeaderboardEntryModel(lbItem)
      )
   }

   @action.bound
   getLeaderboardAPI(
      rankingParameter
   ): Promise<GetPlayerGlobalLeaderboardAPIResponse> {
      const request = {
         last: 0,
         around: 5,
         time_range_type: 'ALL_TIME',
         top: 10,
         ranking_parameter: rankingParameter,
         total_lb_entries: 15
      }
      const getGlobalLeaderboardPromise = this.globalLeaderboardService.getPlayerGlobalLeaderboardAPI(
         request
      )

      return bindPromiseWithOnSuccess(getGlobalLeaderboardPromise)
         .to(
            this.setGlobalLeaderboardAPIStatus,
            this.setGlobalLeaderboardResponse
         )
         .catch(this.setGlobalLeaderboardAPIError) as Promise<
         GetPlayerGlobalLeaderboardAPIResponse
      >
   }

   @action.bound
   getRatingLeaderboardAPI(): Promise<
      GetTournamentsPointsGenericLeaderboardAPIResponse
   > {
      const request = {
         top: 10,
         last: 0,
         around: 5
      }
      const getTournamentPointsLeaderboardPromise = this.globalLeaderboardService.getTournamentsPointsGenericLeaderboardAPI(
         request
      )
      return bindPromiseWithOnSuccess(getTournamentPointsLeaderboardPromise)
         .to(
            this.setRatingLeaderboardAPIStatus,
            this.setRatingLeaderboardResponse
         )
         .catch(this.setRatingLeaderboardAPIError) as Promise<
         GetTournamentsPointsGenericLeaderboardAPIResponse
      >
   }
}

class LeaderboardsStore {
   globalLeaderboardService: GlobalLeaderboardService

   @observable resourceWiseLBEntries: any // eslint-disable-line

   constructor(globalLeaderboardService) {
      this.globalLeaderboardService = globalLeaderboardService
      this.resourceWiseLBEntries = {
         SUPER_RUN: new Leaderboard(globalLeaderboardService, 'SUPER_RUN'),
         IBC_RATING: new Leaderboard(globalLeaderboardService, 'IBC_RATING'),
         FAN_BASE: new Leaderboard(globalLeaderboardService, 'FANB_BASE')
      }
   }
}

export default LeaderboardsStore
