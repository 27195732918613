import React, { Component } from 'react'

import { Typo42PaleGreyBarlowBoldItalicCapital } from '../../styleGuide/Typos/index'
import { ComingSoonContainer } from './styledComponents'

class ComingSoon extends Component {
   render() {
      return (
         <ComingSoonContainer>
            <Typo42PaleGreyBarlowBoldItalicCapital>
               ComingSoon
            </Typo42PaleGreyBarlowBoldItalicCapital>
         </ComingSoonContainer>
      )
   }
}

export default ComingSoon
