const endpoints = {
   loginOrRegister: 'login_or_register/v1/',
   getMatches: 'ib_match/matches/get/',
   loginSendOTP: 'gas_auth/user/login/otp/send/v1/',
   loginVerifyOTP: 'gas_auth/user/login/otp/verify/v1/',
   loginResendOTP: 'gas_auth/user/login/otp/resend/v1/',
   getRefreshToken: 'gas_auth/refresh_token/v1/',
   logout: 'gas_auth/user/logout/v1/',
   getProfileDetails: 'ar_vr_player/player/profile/overall/stats/',
   getProfileDetailsPublic: 'ibc_public/player/profile/',
   overDetails: 'ar_vr_match/match/innings/details/',
   getMatchOverView: 'getMatchOverView',
   getTournamentsMinimalDetails:
      'ib_tournament/player/tournament/minimal/details/',
   getTournamentDetials: 'ib_tournament/tournament/info/v2/',
   steamLogin: 'gas_auth/steam/openid/login/',
   getCricketGameDetails: 'ibc_public/cricket/game/details/',
   getPlayerGlobalLeaderboard: 'ar_vr_player/player/global/leaderboard/',
   getTournamentsPointsGenericLeaderboard:
      'ib_tournament/generic/leaderboard/tournament/points/'
}

export default endpoints
