import styled from 'styled-components'
import colors from '../../../../common/themes/Colors'

export const Container = styled.div`
   width: 1276px;
   background-color: ${colors.charcoalGrey};
   margin-top: 48px;
`

export const MatchesContainer = styled.div`
   padding: 23px 20px 26px 18px;
   background-color: ${colors.charcoalGrey};
   width: 1238px;
   height: 567px;
   flex-wrap: wrap;
   position: relative;
`

export const RoundsBackgroundBoxWrapper = styled.div`
   display: flex;
`

export const RoundTwoBackgroundBoxWrapper = styled.div`
   margin-right: 72px;
   display: flex;
`

export const RoundThreeBackgroundBoxWrapper = styled.div``

export const SemiFinalBackgroundBoxWrapper = styled.div`
   margin-right: 96px;
   margin-left: 185px;
   display: flex;
   align-items: center;
`

export const FinalBackgroundBoxWrapper = styled.div`
   margin-right: 27px;
   display: flex;
   align-items: center;
   margin-top: -20px;
   position: relative;
`

export const WinnerBackgroundBoxWrapper = styled.div`
   display: flex;
   align-items: center;
   margin-top: -20px;
   margin-left: 10px;
`

export const MatchesHierarchyContainer = styled.div`
   position: absolute;
   top: 52px;
   left: 27px;
`
export const ArrowMarkIconWraper = styled.div`
   position: absolute;
   right: -32px;
   top: 272px;
`
export const WinnerCardComponentWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   z-index: 3;
   top: 265px;
   left: 1085px;
`

export const WinnerTrohpyIconWrapper = styled.div`
   margin-left: 8px;
`
