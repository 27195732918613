import React from 'react'
import { useTranslation } from 'react-i18next'

import IbcRatingIcon from '../../../../common/icons/IbcRatingIcon'
import GemIcon from '../../../../common/icons/Gemicon'

import {
   PrizesContainer,
   IbcRatingIconWrapper,
   PrizesText,
   RewardsContainer,
   PrizesHeadingContiner,
   GemIconWrapper,
   PrizesHeadingText
} from './styledComponent'

interface RoundPrizesComponentProps {
   gemsCount: number
   ibcRatingPoints: number
}

const RoundPrizesComponent = (props: RoundPrizesComponentProps) => {
   const { t } = useTranslation()
   const { gemsCount, ibcRatingPoints } = props
   return (
      <PrizesContainer>
         <PrizesHeadingContiner>
            <PrizesHeadingText>{t('tournaments:prize')}</PrizesHeadingText>
         </PrizesHeadingContiner>
         <RewardsContainer>
            <IbcRatingIconWrapper>
               <IbcRatingIcon height={14} width={11} />
               <PrizesText>{ibcRatingPoints}</PrizesText>
            </IbcRatingIconWrapper>
            <GemIconWrapper>
               <GemIcon width={20} height={20} />
               <PrizesText>{gemsCount}</PrizesText>
            </GemIconWrapper>
         </RewardsContainer>
      </PrizesContainer>
   )
}

export default RoundPrizesComponent
