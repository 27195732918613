export const getPlayerPosition = (index: number): boolean => {
   if (index === 0) {
      return true
   }
   return false
}

export const getScoreFromGameStateBlob = (gameStateBlob: string): string => {
   const parsedJson = JSON.parse(gameStateBlob)
   const score = parsedJson.current_state.score
   const wickets = parsedJson.current_state.wickets
   const overs = parsedJson.current_state.overs

   return `${score}/${wickets} (${overs})`
}
