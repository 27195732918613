import { observer } from 'mobx-react'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlarmIcon from '../../../common/icons/AlarmIcon'

import CountDownTimerComponent from '../CountDownTimerComponent'

import {
   ExpiresText,
   TimerContainer,
   TimerAndIconWrapper,
   IconWrapper,
   RoundCompletedText
} from './styledComponent'

interface CountdownPropsTypes {
   time: number
}

const CountDownComponent = observer(
   (props: CountdownPropsTypes): ReactElement => {
      const [time, setTime] = useState(props.time)
      const { t } = useTranslation()

      function makeTimeZero(): void {
         setTime(0)
      }

      return time > 0 ? (
         <TimerContainer>
            <ExpiresText>{t('tournaments:expires')}</ExpiresText>
            <TimerAndIconWrapper>
               <IconWrapper>
                  <AlarmIcon />
               </IconWrapper>
               <CountDownTimerComponent
                  makeTimeZero={makeTimeZero}
                  time={time}
               />
            </TimerAndIconWrapper>
         </TimerContainer>
      ) : (
         <TimerContainer>
            <RoundCompletedText>
               {t('tournaments:roundCompleted')}
            </RoundCompletedText>
         </TimerContainer>
      )
   }
)

export default CountDownComponent
