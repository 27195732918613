import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'

export const HeaderWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   text-align: center;
   align-items: center;
   background: ${Colors.darkTwo};
   min-height: 46.5%;
   width: 94%;
   padding-left: 3%;
   padding-right: 3%;
`
export const ContentWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   text-align: center;
   align-items: center;
   height: 100%;
   width: 94%;
   padding-left: 3%;
   padding-right: 3%;
`

export const CardWrapper = styled.div`
   width: 31%;
   height: 200px;
   background: ${Colors.charcoalGrey};
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-direction: column;
   cursor: pointer;
   margin: 12px 12px 12px 12px;
   max-width: 520px;
   min-width: 400px;
`
export const TitleWrapper = styled.div`
   width: 60%;
`

export const IbcRatingWrapper = styled.div`
   margin-left: 10px;
`

export const LeaderBoardAndCurrentRoundTextWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
`
