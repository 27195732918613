import styled from 'styled-components'

import {
   Typo16CoolGreyBarlowMediumItalicCapital,
   Typo16PaleGreyBarlowBoldItalicCapital
} from '../../styleGuide/Typos'
import colors from '../../themes/Colors'

export const SideBarItemDiv = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   cursor: pointer;
   padding-left: 32px;
   padding-top: 22px;
   padding-bottom: 21px;
   background-color: ${props => (props.isActive ? colors.charcoalGrey : null)};
`
export const IconDiv = styled.div`
   width: 40px;
   height: 24px;
`

export const InactiveText = styled(Typo16CoolGreyBarlowMediumItalicCapital)`
   margin-top: 2px;
`

export const ActiveText = styled(Typo16PaleGreyBarlowBoldItalicCapital)`
   margin-top: 2px;
`
