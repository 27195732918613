import styled from 'styled-components'

import {
   Typo36SilverBarlowItalic,
   Typo18SilverBarlowItalic,
   Typo16WhiteBarlowBoldCapital
} from '../../styleGuide/Typos'
import Colors from '../../themes/Colors'
import { StyledButton } from '../../components/Button/styledComponents'
import { BaseImage } from '../../components/SteamSignPage/styledComponents'
import { FAILURE_VIEW_BACKGROUND_IMAGE_PATH } from '../../constants/PublicImageConstants'

export type TryAgainButtonProps = {
   isLoaderDisplaying: boolean
}

export const Container = styled.div`
   background-image: url(${FAILURE_VIEW_BACKGROUND_IMAGE_PATH});
   background-position: center;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: ${Colors.dark};
   padding-top: 10%;
`

export const ContentHeader = styled(Typo36SilverBarlowItalic)`
   margin-bottom: 2.1%;
`
export const ContentMatter = styled(Typo18SilverBarlowItalic)`
   margin-top: 16px;
   margin-bottom: 6.3%;
`

export const TryAgainButton = styled(StyledButton)`
   background: ${Colors.pumpkinOrange};
   padding-top: 12px;
   padding-bottom: 12px;
   padding-left: 24px;
   padding-right: 24px;
   margin-left: 16px;
   margin-right: 16px;
   margin-bottom: 32px;
   min-width: 200px;
   display: flex;
   justify-content: center;
   border-radius: 0px;
   box-shadow: 0 6px 20px 0 rgba(244, 116, 0, 0.3);
`

export const ErrorImg = styled(BaseImage)`
   width: 16.3%;
   height: 26.5%;
   margin-bottom: 7.1%;
`

export const TryAgainButtonWrapper = styled(Typo16WhiteBarlowBoldCapital)<
   TryAgainButtonProps
>`
   opacity: ${props => (props.isLoaderDisplaying ? 0.5 : 1)};
   text-decoration: none;
`
