import { BowlingLevelEnum } from '../../matches/constants/MatchConfigConstants'

export const bowlerLevelPics = {
   [BowlingLevelEnum[BowlingLevelEnum.EASY]]:
      'https://vrcricket.s3.ap-south-1.amazonaws.com/web/matches/bowling_level/easy-bowling.png',
   [BowlingLevelEnum[BowlingLevelEnum.MEDIUM]]:
      'https://vrcricket.s3.ap-south-1.amazonaws.com/web/matches/bowling_level/medium-bowling.png',
   [BowlingLevelEnum[BowlingLevelEnum.HARD]]:
      'https://vrcricket.s3.ap-south-1.amazonaws.com/web/matches/bowling_level/hard-bowling.png'
}

export const confettiImageUrl =
   'https://cdn.zeplin.io/5ed7809e1b493d84ad5e0aab/assets/01C8085D-C18F-42B8-9547-B782F3A77A07.png'
