import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import CountDownTimerComponent from '../CountDownTimerComponent'

import { EndedTextWrapper, EndsInText } from './styledComponent'

interface TourunamentRoundEndsInComponentTypes {
   roundEndsIn: number
}

const TourunamentRoundEndsInComponent = observer(
   (props: TourunamentRoundEndsInComponentTypes) => {
      const [time, setTime] = useState(props.roundEndsIn)
      const { t } = useTranslation()

      function makeTimeZero(): void {
         setTime(0)
      }
      return (
         <EndedTextWrapper>
            {time > 0 ? (
               <>
                  <EndsInText>{t('tournaments.common.endsIn')}</EndsInText>
                  <CountDownTimerComponent
                     makeTimeZero={makeTimeZero}
                     time={time}
                  />
               </>
            ) : (
               <></>
            )}
         </EndedTextWrapper>
      )
   }
)

export default TourunamentRoundEndsInComponent
