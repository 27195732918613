import styled from 'styled-components'

import Image from '../../../common/components/Image'
import {
   TwoLineEllipsis,
   Typo20LightBlueGrayBarlowBoldItalic
} from '../../../common/styleGuide/Typos'

export const TitleAndValueRightAlignedWrapper = styled.div`
   min-width: 50px;
   display: flex;
   justify-content: flex-end;
   flex-direction: column;
   align-items: ${props => (props.leftAligned ? 'flex-start' : 'flex-end')};
`

export const TitleWrapper = styled.div`
   display: flex;
   width: 100%;
   flex-direction: column;
   padding-right: 10px;
   align-items: flex-end;
   justify-content: flex-end;
   margin-bottom: 4px;
`
export const MatchIcon = styled(Image)`
   max-width: 60px;
   max-height: 60px;
   margin-right: 2%;
`
export const IconAndTitleWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   text-align: left;
`
export const TitleText = styled(Typo20LightBlueGrayBarlowBoldItalic)`
   overflow: hidden;
   ${TwoLineEllipsis}
   word-wrap: break-word;
   white-space: pre-wrap;
`
