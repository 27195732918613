import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import ResourceItem from '../../../../../common/components/Resources/ResourceItem'
import TournamentMatch from '../../../../stores/models/TournamentMatch'

import { TitleAndValue } from '../../../MatchesCommon/MatchesCommon'
import WinOrLost from '../../../WinAndLose'

import { ContentWrapper } from '../../BaseMatchCard/styledComponents'
import BaseMatchCardView from '../../BaseMatchCard'

interface TournamentMatchCardProps {
   model: TournamentMatch
}

const TournamentMatchCard = (props: TournamentMatchCardProps): ReactElement => {
   const { model } = props
   const { t } = useTranslation()
   const { gameStats } = model.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <BaseMatchCardView
         matchModel={model}
         renderHeaderChildren={() => <WinOrLost isWon={model.isWon} />}
      >
         <ContentWrapper>
            <ResourceItem resource={model.resource} />
            <TitleAndValue
               title={t('tournaments.round')}
               value={model.roundDisplayName}
            />
            <TitleAndValue
               title={t('matches.over', {
                  count: overs
               })}
               value={overs.toString()}
            />
         </ContentWrapper>
      </BaseMatchCardView>
   )
}

export default TournamentMatchCard
