import { PaticipantBasicDetails } from '../stores/types'

export const sortArrayByIndex = (
   data: PaticipantBasicDetails[],
   src,
   dest
): PaticipantBasicDetails[] | void => {
   if (src === dest) return
   const tmp = data[src]
   if (src < dest) {
      data.copyWithin(src, src + 1, dest + 1)
   } /* if (src > dest) */ else {
      data.copyWithin(dest + 1, dest, src)
   }
   data[dest] = tmp
   return data
}
