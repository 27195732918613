import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import Heading from '../../../common/components/Heading'
import {
   Typo14CoolGreyBarlowSemiBoldItalicCapital,
   Typo24OceanBlueBoldItalicCapital,
   Typo24LightYellowBoldItalicCapital,
   Typo18PaleGreyBarlowBoldItalicCapital,
   Typo14PaleGreyBarlowBoldItalicCapital,
   Typo30LightYellowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

import SpecialChallengeMatch from '../../stores/models/SpecialChallengeMatch'
import {
   MatchCurrentStatusContainer,
   MatchStatContainer,
   MatchStatusCotainer,
   RemainingMatchStatusContainer,
   MatchCurrentStatusTargetDiv,
   MatchCurrentStatsWrapper
} from './styledComponents'

interface SurvivalMatchStatusProps {
   matchModel: SpecialChallengeMatch
}

const SurvivalMatchStatus = (props: SurvivalMatchStatusProps): ReactElement => {
   const { t } = useTranslation()
   const {
      matchModel: { specialChallengeDetails, basicMatchDetails }
   } = props
   const matchCurrentStats = [
      {
         value: `${specialChallengeDetails.specialBallsFrequency}`,
         title: t('matchStats.specialBalls')
      },
      {
         value: `${basicMatchDetails.matchOvers} Overs`,
         title: t('matchStats.oversRemaining')
      },
      {
         value: `${basicMatchDetails.matchWickets} Wickets`,
         title: t('matchStats.wicketsRemaining')
      }
   ]

   return (
      <MatchStatusCotainer>
         <Heading title={'status'} />
         <RemainingMatchStatusContainer>
            {matchCurrentStats.map(stats => (
               <MatchStatContainer key={stats.title}>
                  <Typo24OceanBlueBoldItalicCapital>
                     {stats.value}
                  </Typo24OceanBlueBoldItalicCapital>
                  <Typo14CoolGreyBarlowSemiBoldItalicCapital>
                     {stats.title}
                  </Typo14CoolGreyBarlowSemiBoldItalicCapital>
               </MatchStatContainer>
            ))}
         </RemainingMatchStatusContainer>
         <MatchCurrentStatusContainer>
            <MatchCurrentStatsWrapper>
               <Typo18PaleGreyBarlowBoldItalicCapital>
                  {t('matchStats.currentScore')}
               </Typo18PaleGreyBarlowBoldItalicCapital>
               <Typo24LightYellowBoldItalicCapital>
                  {`${specialChallengeDetails.score}/${specialChallengeDetails.wickets}`}
               </Typo24LightYellowBoldItalicCapital>
               <Typo14PaleGreyBarlowBoldItalicCapital>
                  {t('matchStats.in')}
               </Typo14PaleGreyBarlowBoldItalicCapital>
               <Typo24LightYellowBoldItalicCapital>
                  {`${specialChallengeDetails.overs}/${
                     basicMatchDetails.matchOvers
                  }  ${t(`matchStats.overs`)}`}
               </Typo24LightYellowBoldItalicCapital>
            </MatchCurrentStatsWrapper>
         </MatchCurrentStatusContainer>
         <MatchCurrentStatusTargetDiv>
            <MatchCurrentStatsWrapper>
               <Typo18PaleGreyBarlowBoldItalicCapital>
                  {t('matchStats.youShouldScore')}
               </Typo18PaleGreyBarlowBoldItalicCapital>
               <Typo30LightYellowBoldItalicCapital>
                  {`${specialChallengeDetails.target} ${t('matchStats.runs')}`}
               </Typo30LightYellowBoldItalicCapital>
            </MatchCurrentStatsWrapper>
         </MatchCurrentStatusTargetDiv>
      </MatchStatusCotainer>
   )
}

export default SurvivalMatchStatus
