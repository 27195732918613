import { observable, action, computed } from 'mobx'
import { APIStatus, API_INITIAL, API_FAILED } from '@ib/api-constants'

import AuthService from '../../services/AuthService'
import {
   setRefreshToken,
   setUserId,
   clearUserSession,
   setIdToken,
   getUserId,
   getIdToken,
   setUsername,
   getUsername,
   getAccessToken,
   setAccessToken,
   setSignWithFixture,
   getSignWithFixture
} from '../../utils/StorageUtils'
import { bindPromiseWithOnSuccess } from '../../utils/MobxPromise'
import { userLoginState } from '../../constants/AuthConstants'

import {
   Tokens,
   LoginResendOTPRequest,
   LoginSendOTPRequest,
   LoginVerifyOTPRequest,
   GetRefreshTokenRequest
} from '../types'
import AuthTokens from '../models/AuthTokens'
import { getAPIErrorMessage } from '../../utils/APIUtils'
import { statusCodes } from '../../constants/APIErrorConstants'

class AuthStore {
   @observable loginResendOTPStatus!: APIStatus
   @observable loginResendOTPError: any

   @observable loginSendOTPStatus!: APIStatus
   @observable loginSendOTPError: any

   @observable loginVerifyOTPStatus!: APIStatus
   @observable loginVerifyOTPError: any

   @observable getRefreshTokenStatus!: APIStatus
   @observable getRefreshTokenError: any

   @observable logoutAPIStatus!: APIStatus
   @observable logoutAPIError: any

   @observable steamLoginAPIStatus!: APIStatus
   @observable steamLoginAPIError!: any

   @observable authTokens!: AuthTokens
   @observable loginCurrentState!: string
   @observable authenticatedUsername!: string
   @observable inMaintenanceMode: boolean

   shouldLoginWithFixture!: boolean
   getRefreshTokenPromise

   authService: AuthService
   clearAllStores!: Function

   constructor(authService) {
      this.authService = authService
      this.clearAllStores = () => {}
      this.initAuthStore()
      this.inMaintenanceMode = false
   }

   setClearAllStoresFunction(clearAllStores) {
      this.clearAllStores = clearAllStores
   }

   initAuthStore = () => {
      this.initAPIStatusAndErrors()
      this.authTokens = new AuthTokens()
      this.setLoginCurrentState(userLoginState.login)
      this.authTokens.setIdToken(getIdToken())
      this.authTokens.setUserID(getUserId())
      this.setAuthenticatedUsername(getUsername())
      this.shouldLoginWithFixture = getSignWithFixture() === 'true'
   }

   @action.bound
   initAPIStatusAndErrors() {
      this.loginResendOTPStatus = API_INITIAL
      this.loginResendOTPError = {}

      this.loginSendOTPStatus = API_INITIAL
      this.loginSendOTPError = {}

      this.loginVerifyOTPStatus = API_INITIAL
      this.loginVerifyOTPError = {}

      this.getRefreshTokenStatus = API_INITIAL
      this.getRefreshTokenError = {}

      this.logoutAPIStatus = API_INITIAL
      this.logoutAPIError = {}

      this.steamLoginAPIError = null
      this.steamLoginAPIStatus = API_INITIAL
   }

   setAuthCookies(response: Tokens) {
      setIdToken(response.id_token)
      setRefreshToken(response.refresh_token)
      setAccessToken(response.access_token)
      if (response.user_id) {
         setUserId(response.user_id)
      }
   }

   @computed
   get isLoggedIn(): boolean {
      const idToken = this.authTokens && this.authTokens.idToken
      return idToken !== '' && idToken !== undefined && idToken !== null
   }

   @action.bound
   setShouldLoginWithFixture(value: boolean) {
      this.shouldLoginWithFixture = value
      setSignWithFixture(value)
   }

   @action.bound
   setAuthenticatedUsername(username: string) {
      this.authenticatedUsername = username
   }

   @action.bound
   setLoginResendOTPStatus(status: APIStatus) {
      this.loginResendOTPStatus = status
   }

   @action.bound
   setLoginResendOTPError(error: any) {
      this.loginResendOTPError = error
   }

   loginResendOTP = (
      requestObject: LoginResendOTPRequest,
      onSuccess: Function = () => {},
      onFailure: Function = () => {}
   ) => {
      const loginResendOTPPromise = this.authService.loginResendOTP(
         requestObject
      )
      return bindPromiseWithOnSuccess(loginResendOTPPromise)
         .to(this.setLoginResendOTPStatus, response => {
            onSuccess()
         })
         .catch(err => {
            this.setLoginResendOTPError(err)
            onFailure()
         })
   }

   @action.bound
   setLoginSendOTPStatus(status: APIStatus) {
      this.loginSendOTPStatus = status
   }

   @action.bound
   setLoginSendOTPError(error: any) {
      this.loginSendOTPError = error
   }

   loginSendOTP = (
      requestObject: LoginSendOTPRequest,
      onSuccess: Function = () => {},
      onFailure: Function = () => {}
   ) => {
      const loginSendOTPPromise = this.authService.loginSendOTP(requestObject)
      return bindPromiseWithOnSuccess(loginSendOTPPromise)
         .to(this.setLoginSendOTPStatus, response => {
            this.setLoginCurrentState(userLoginState.otp)
            onSuccess()
         })
         .catch(err => {
            this.setLoginSendOTPError(err)
            onFailure()
         })
   }

   @action.bound
   setLoginVerifyOTPStatus(status: APIStatus) {
      this.loginVerifyOTPStatus = status
   }

   @action.bound
   setLoginVerifyOTPError(error: any) {
      this.loginVerifyOTPError = error
   }

   @action.bound
   setLoginVerifyOTPResponse(response: Tokens) {
      this.setAuthCookies(response)
      this.authTokens.setAuthTokens(response)
   }

   @action.bound
   storeAuthenticatedUsername(username: string) {
      this.setAuthenticatedUsername(username)
      setUsername(username)
   }

   loginVerifyOTP = (
      requestObject: LoginVerifyOTPRequest,
      onSuccess: Function = () => {},
      onFailure: Function = () => {}
   ) => {
      const loginVerifyOTPPromise = this.authService.loginVerifyOTP(
         requestObject
      )
      return bindPromiseWithOnSuccess(loginVerifyOTPPromise)
         .to(this.setLoginVerifyOTPStatus, response => {
            this.setLoginVerifyOTPResponse(response as Tokens)
            this.storeAuthenticatedUsername(requestObject.username)
            onSuccess()
         })
         .catch(err => {
            this.setLoginVerifyOTPError(err)
            onFailure()
         })
   }

   @action.bound
   setGetRefreshTokenResponse(response: Tokens) {
      this.setAuthCookies(response)
      this.getRefreshTokenPromise = undefined
   }

   @action.bound
   setGetRefreshTokenError(error: any) {
      clearUserSession()
      this.clearStore()
      this.setGetRefreshTokenStatus(API_FAILED)
      this.getRefreshTokenError = error
   }

   @action.bound
   setGetRefreshTokenStatus(status: APIStatus) {
      this.getRefreshTokenStatus = status
   }

   getRefreshToken(
      requestObject: GetRefreshTokenRequest,
      onSuccess: Function = () => {},
      onFailure: Function = () => {}
   ) {
      if (this.getRefreshTokenPromise) {
         return this.getRefreshTokenPromise
      }
      this.getRefreshTokenPromise = this.authService.getRefreshToken(
         requestObject
      )
      return bindPromiseWithOnSuccess(this.getRefreshTokenPromise)
         .to(this.setGetRefreshTokenStatus, response => {
            this.setGetRefreshTokenResponse(response as Tokens)
            onSuccess()
         })
         .catch(err => {
            this.setGetRefreshTokenError(err)
            onFailure()
         })
   }

   @action.bound
   setLogoutAPIStatus(status: APIStatus) {
      this.logoutAPIStatus = status
   }

   @action.bound
   setLogoutAPIError(error: any) {
      this.logoutAPIError = error
   }

   @action.bound
   logoutAPI(onSuccess: Function = () => {}, onFailure: Function = () => {}) {
      const logoutRequest = {
         access_token: getAccessToken()
      }
      const logoutPromise = this.authService.logoutAPI(logoutRequest)
      return bindPromiseWithOnSuccess(logoutPromise)
         .to(this.setLogoutAPIStatus, () => {
            this.clearStore()
            onSuccess()
         })
         .catch(err => {
            this.setLogoutAPIError(err)
            this.clearStore()
            onFailure()
         })
   }

   @action.bound
   setSteamLoginAPIStatus(status: APIStatus) {
      this.steamLoginAPIStatus = status
   }

   @action.bound
   setSteamLoginAPIError(error: any) {
      this.steamLoginAPIError = getAPIErrorMessage(error)
   }

   @action.bound
   loginWithSteamId(
      steamLoginRes: string,
      onSuccess: Function = () => {},
      onFailure: Function = () => {}
   ) {
      const steamLoginRequest = {
         open_id_login_response: steamLoginRes
      }

      const logoutPromise = this.authService.loginWithSteamIdAPI(
         steamLoginRequest
      )
      return bindPromiseWithOnSuccess(logoutPromise)
         .to(this.setSteamLoginAPIStatus, response => {
            response && this.setLoginVerifyOTPResponse(response as Tokens)
            onSuccess()
         })
         .catch(err => {
            this.setSteamLoginAPIError(err)
            onFailure()
         })
   }

   @action.bound
   setLoginCurrentState(currentState: string) {
      this.loginCurrentState = currentState
   }

   clearStore = () => {
      clearUserSession()
      this.clearAllStores()
      this.initAuthStore()
   }

   getloggedInUserID = (): string => this.authTokens.userId || ''

   setMaintenanceErrorCode = (status: number) => {
      this.inMaintenanceMode =
         status === statusCodes.noInternetErrorCode ? true : false
   }
}

export default AuthStore
