import React, { ReactElement } from 'react'

import { LeaderboardHeaderTitleProps } from '../../stores/types'
import UsernameAndPic from '../UsernameAndPic'

import {
   LeaderboardItemCardDiv,
   TitleText,
   CountryImage
} from '../LeaderboardItemCard/styledComponents'
import {
   RessourceCountContainer,
   TextContainer,
   CountryAndRankContainer,
   ScoreContainer
} from './styledComponents'

export type LeaderboardHeaderProps = LeaderboardHeaderTitleProps & {
   isSelf: boolean
}

const LeaderboardItemCard = (props: LeaderboardHeaderProps): ReactElement => (
   <LeaderboardItemCardDiv isSelf={props.isSelf}>
      <CountryAndRankContainer>
         <CountryImage src={props.countryPic} />
         <TitleText>{props.rank}</TitleText>
      </CountryAndRankContainer>
      <UsernameAndPic name={props.name} url={props.profilePic} />
      <ScoreContainer>
         <TextContainer>{props.score}</TextContainer>
      </ScoreContainer>
      <RessourceCountContainer>
         <TextContainer>{props.resourceRating}</TextContainer>
      </RessourceCountContainer>
   </LeaderboardItemCardDiv>
)

export default LeaderboardItemCard
