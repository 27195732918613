import styled from 'styled-components'
import ImageComponent from '../../../common/components/BaseImageComponent'
import {
   Typo18LightBlueGrayBarlowBoldItalic,
   Typo14CoolGreyBarlowBoldItalic,
   TwoLineEllipsis
} from '../../../common/styleGuide/Typos'
export const LogoAndNameAndDateWrapper = styled.div`
   display: flex;
   align-items: center;
`
export const TournamentLogo = styled(ImageComponent)`
   width: 67px;
   height: 60px;
   margin: 0 10px 0 0;
   object-fit: contain;
`
export const TournamentTitleText = styled(Typo18LightBlueGrayBarlowBoldItalic)`
   width: 116px;
   text-align: left;
   margin: 0px;
   margin-bottom: -3px;
   ${TwoLineEllipsis};
   word-wrap: break-word;
   white-space: pre-wrap;
   align-self: center;
`

export const DateTextComponent = styled(Typo14CoolGreyBarlowBoldItalic)`
   margin-left: 10px;
   align-self: flex-end;
`
