import { RegionWiseScorePercentage } from '../../types'

type RegionWiseScoreType = {
   endAngle: number
   value: number
   startAngle: number
}
class UserControl {
   regionWiseScorePercentage!: Array<RegionWiseScoreType>
   constructor(userControls: Array<RegionWiseScorePercentage>) {
      this.setRegionWiseScorePercentage(userControls)
   }

   setRegionWiseScorePercentage = regionWiseScores => {
      this.regionWiseScorePercentage = regionWiseScores.map(regionInfo => ({
         endAngle: regionInfo.end_angle,
         value: regionInfo.score_percentage || regionInfo.score || 0,
         startAngle: regionInfo.start_angle
      }))
   }
}

export default UserControl
