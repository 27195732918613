import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import QuickPlayMatch from '../../../../stores/models/QuickPlayMatch'

import { TitleAndValue } from '../../../MatchesCommon/MatchesCommon'
import MatchCardBowlingLevel from '../../../BowllingLevel'
import BatSheildOnOrOff from '../../../BatSheild'

import { ContentWrapper } from '../../BaseMatchCard/styledComponents'
import BaseMatchCardView from '../../BaseMatchCard'

interface QuickPlayMatchCardProps {
   model: QuickPlayMatch
}

const QuickMatchCard = (props: QuickPlayMatchCardProps): ReactElement => {
   const { model: quickPlayModel } = props
   const { basicMatchDetails } = quickPlayModel
   const { gameStats } = quickPlayModel.getSelfUserMatchDetails()
   const { overs } = gameStats
   const { t } = useTranslation()
   return (
      <BaseMatchCardView matchModel={quickPlayModel}>
         <ContentWrapper>
            <BatSheildOnOrOff isBatSheildOn={basicMatchDetails.batShield} />
            <MatchCardBowlingLevel
               bowlingLevel={basicMatchDetails.getBowlingLevel()}
            />
            <TitleAndValue
               title={t('matches.over', { count: overs })}
               value={overs.toString()}
            />
         </ContentWrapper>
      </BaseMatchCardView>
   )
}
export default QuickMatchCard
