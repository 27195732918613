import React from 'react'
import { inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { MatchConfigConstants } from '../../constants/tournamentConstants'
import PlayerCardComponent from '../PlayerCardComponent'

import TbdCardComponent from '../TbdCardComponent'
import {
   Wrapper,
   VersusSymbolWrapper,
   VersusText,
   VersusContainer
} from './styledComponents'

interface KnockOutMatchCardProps {
   match: any
   authStore?: any
}

const getplayerPosition = (index: number): boolean => {
   let isPlayerOne: boolean
   if (index === 0) {
      isPlayerOne = true
   } else {
      isPlayerOne = false
   }
   return isPlayerOne
}

const renderPlayerCmp = (
   participant,
   index: number,
   match: any,
   requestUserId: string
) => {
   switch (participant.participant_status) {
      case MatchConfigConstants.TBD:
         return (
            <TbdCardComponent
               key={index}
               isPlayerOne={getplayerPosition(index)}
               participantMatchStatus={participant.participant_status}
            />
         )
      case MatchConfigConstants.BLANK:
         return (
            <TbdCardComponent
               key={index}
               isPlayerOne={getplayerPosition(index)}
               participantMatchStatus={match.participant_status}
            />
         )
      default:
         return (
            <PlayerCardComponent
               key={index}
               isWon={match.winner_id === participant.participant_id}
               isPlayerOne={getplayerPosition(index)}
               playerModel={participant}
               requestUserId={requestUserId}
            />
         )
   }
}

const KnockOutMatchCard = (props: KnockOutMatchCardProps) => {
   const { match, authStore } = props
   const particpant_details = match.participants_details || []
   const requestUserId = authStore?.getloggedInUserID()
   const { t } = useTranslation()

   return (
      <Wrapper>
         {particpant_details.map((participant, index) =>
            renderPlayerCmp(participant, index, match, requestUserId)
         )}
         <VersusSymbolWrapper>
            <VersusContainer>
               <VersusText>{t('tournaments:versusText')}</VersusText>
            </VersusContainer>
         </VersusSymbolWrapper>
      </Wrapper>
   )
}

export default inject('authStore')(KnockOutMatchCard)
