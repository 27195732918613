import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import TournamentMatch from '../../../../stores/models/TournamentMatch'

import { TitleAndValue } from '../../../MatchesCommon/MatchesCommon'
import UserRank from '../../../UserRank'

import { ContentWrapper } from '../../BaseMatchCard/styledComponents'
import BaseMatchCardView from '../../BaseMatchCard'

import GotoTournament from '../GotoTournament'

interface LeaderBoardMatchCardProps {
   model: TournamentMatch
}

const LeaderBoardMatchCard = (
   props: LeaderBoardMatchCardProps
): ReactElement => {
   const { model } = props
   const { t } = useTranslation()
   const { gameStats } = model.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <BaseMatchCardView
         matchModel={model}
         renderHeaderChildren={() => <UserRank rank={model.rank} />}
      >
         <ContentWrapper>
            <TitleAndValue
               title={t('tournaments.round')}
               value={t('tournaments.common.leaderBoard')}
               leftAligned={true}
            />
            <TitleAndValue
               title={t('matches.over', {
                  count: overs
               })}
               value={overs.toString()}
            />
            <GotoTournament tournamentId={model.tournamentId} />
         </ContentWrapper>
      </BaseMatchCardView>
   )
}

export default LeaderBoardMatchCard
