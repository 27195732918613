import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import GameModeTitleAndBg from '../../../common/components/GameModeTitleAndBg'
import { Typo24WhiteBarlowBoldItalicCapital } from '../../../common/styleGuide/Typos'

import CorrectIcon from '../../icons/Correct'
import WrongIcon from '../../icons/Wrong'
import PitchMarkerIcon from '../../icons/PitchMarker'
import BatSheildIcon from '../../icons/BatSheild'

import {
   ItemContainer,
   SuperRunsBg,
   EachPropertyWrapper,
   OvalBg,
   GameModePropertyBg,
   GameModePropertyGradeintBg,
   GameModePropertyDisplayName,
   BackgroungImage,
   SuperRunsContentBg,
   PropertyContent,
   StyledSuperRunIcon
} from './styledComponents'

interface GameModeConfigProps {
   runs?: number
   superRuns?: number
   gameMode: string
   gameModeDisplayName: string
   batShield?: boolean
   pitchMarker?: boolean
}

interface EachPropertyProps {
   iconSvg?: any
   name?: string
   onOrOff?: boolean
}

const EachProperty = (props: EachPropertyProps): ReactElement => {
   const { iconSvg: Icon } = props
   const OnOffIcon = props.onOrOff ? CorrectIcon : WrongIcon
   return (
      <EachPropertyWrapper>
         <OvalBg>
            <Icon />
         </OvalBg>
         <GameModePropertyBg>
            <GameModePropertyGradeintBg>
               <PropertyContent>
                  <GameModePropertyDisplayName>
                     {props.name}
                  </GameModePropertyDisplayName>
                  <OnOffIcon />
               </PropertyContent>
            </GameModePropertyGradeintBg>
         </GameModePropertyBg>
      </EachPropertyWrapper>
   )
}

const GameModeConfig = (props: GameModeConfigProps): ReactElement => {
   const { t } = useTranslation()
   const {
      pitchMarker,
      batShield,
      runs,
      superRuns,
      gameMode,
      gameModeDisplayName
   } = props
   return (
      <ItemContainer>
         <GameModeTitleAndBg
            gameMode={gameMode}
            displayName={gameModeDisplayName}
         />
         <SuperRunsBg>
            <SuperRunsContentBg>
               <Typo24WhiteBarlowBoldItalicCapital>
                  {runs} {t('matches.run')} = {superRuns}{' '}
                  {t('matches.superRun')}
               </Typo24WhiteBarlowBoldItalicCapital>
               <StyledSuperRunIcon />
            </SuperRunsContentBg>
         </SuperRunsBg>
         <BackgroungImage>
            <EachProperty
               onOrOff={pitchMarker}
               name={t('matches.pitchMarker')}
               iconSvg={PitchMarkerIcon}
            />
            <EachProperty
               onOrOff={batShield}
               name={t('matches.batShield')}
               iconSvg={BatSheildIcon}
            />
         </BackgroungImage>
      </ItemContainer>
   )
}
export default GameModeConfig
