import React, { PropsWithChildren, ReactElement } from 'react'

import Colors from '../../../common/themes/Colors'
import {
   Typo24PaleGreyBarlowSemiBoldItalicCapital,
   Typo14CoolGreyBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'
import {
   IconAndTitleWrapper,
   MatchIcon,
   TitleAndValueRightAlignedWrapper,
   TitleText
} from './styledComponents'

interface TitleAndValueProps {
   title: string
   value?: string
   leftAligned?: boolean
}

export const TitleAndValue = (props: TitleAndValueProps): ReactElement => (
   <TitleAndValueRightAlignedWrapper leftAligned={props.leftAligned}>
      <Typo24PaleGreyBarlowSemiBoldItalicCapital>
         {props.value}
      </Typo24PaleGreyBarlowSemiBoldItalicCapital>

      <Typo14CoolGreyBarlowBoldItalicCapital>
         {props.title}
      </Typo14CoolGreyBarlowBoldItalicCapital>
   </TitleAndValueRightAlignedWrapper>
)

interface TitleAndIconProps {
   title: string
   url: string
}

export const TitleAndIcon = (props: TitleAndIconProps): ReactElement => (
   <IconAndTitleWrapper>
      {props.url ? <MatchIcon src={props.url} /> : null}
      <TitleText>{props.title}</TitleText>
   </IconAndTitleWrapper>
)

export const WrapperDiv = (props: PropsWithChildren<{}>): ReactElement => (
   <div style={{ padding: '10px', backgroundColor: Colors.black }}>
      {props.children}
   </div>
)

TitleAndValue.defaultProps = {
   leftAligned: false
}
