import React from 'react'

interface Props {
   width: number
   height: number
   fillColor: string
}

const ProfileIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 24 24'
   >
      <defs>
         <filter id='ProfileIcon-active'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.007843 0 0 0 0 0.552941 0 0 0 0 0.847059 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
         <filter id='ProfileIcon-inactive'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
         <g transform='translate(-32 -121)'>
            <g transform='translate(0 99)'>
               <g filter={`url(#ProfileIcon-${props.fillColor})`}>
                  <g transform='translate(32 22)'>
                     <path d='M0 0H24V24H0z'></path>
                     <g fill='#FFF' transform='translate(3 1)'>
                        <path
                           fillRule='nonzero'
                           d='M14 5A5 5 0 114 5a5 5 0 0110 0z'
                        ></path>
                        <path
                           d='M15.818 13.125c-.616-.715-1.675-.678-2.503-.225A8.96 8.96 0 019 14a8.96 8.96 0 01-4.315-1.1c-.828-.453-1.887-.49-2.503.225A8.965 8.965 0 000 19v1a2 2 0 002 2h14a2 2 0 002-2v-1a8.965 8.965 0 00-2.182-5.875z'
                           opacity='0.25'
                        ></path>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

ProfileIcon.defaultProps = {
   width: 24,
   height: 24,
   fillColor: 'inactive'
}
export default ProfileIcon
