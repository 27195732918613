import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from '../../../common/components/Heading'
import ChallengeMileStone from '../../stores/models/SpecialChallengeMatch/ChallengeMileStone'
import MileStoneItem from '../MileStoneItem'

import {
   MileStoneListWrapper,
   Container,
   MileStoneItemWrapper
} from './styledComponents'

type Props = {
   mileStones: Array<ChallengeMileStone>
}

function MileStoneList(props: Props) {
   const { mileStones } = props
   const { t } = useTranslation()
   return (
      <Container>
         <Heading title={t('milestones')} />
         <MileStoneListWrapper>
            {mileStones.map((mileStoneItem, index) => (
               <MileStoneItemWrapper key={index}>
                  <MileStoneItem mileStoneItem={mileStoneItem} />
               </MileStoneItemWrapper>
            ))}
         </MileStoneListWrapper>
      </Container>
   )
}

export default MileStoneList
