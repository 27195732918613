import * as Sentry from '@sentry/browser'

import EnvironmentConstants from '../constants/EnvironmentConstants'

const isNotDevEnvironment = process.env.NODE_ENV === 'production'

export function initializeSentry() {
   if (isNotDevEnvironment) {
      const { SENTRY_KEY } = EnvironmentConstants
      Sentry.init({
         dsn: SENTRY_KEY
      })
   }
}

export function captureSentryExceptionWithScope(
   errorInfo: Record<string, any>,
   error: Record<string, any>
) {
   if (isNotDevEnvironment) {
      Sentry.configureScope(scope => {
         Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key])
         })
      })
      Sentry.captureException(error)
   }
}

export function captureSentryException(error: Record<string, any>) {
   if (isNotDevEnvironment) {
      Sentry.captureException(error)
   }
}
