import React, { ReactElement } from 'react'

import Image from '../../../common/components/BaseImageComponent'
import { UserDetailsContainer, ProfilePic, NameText } from './styledComponents'

interface UserNameAndPicProps {
   url: string
   name: string
}

const UsernameAndPic = (props: UserNameAndPicProps): ReactElement => (
   <UserDetailsContainer>
      <Image Img={ProfilePic} src={props.url} />
      <NameText>{props.name}</NameText>
   </UserDetailsContainer>
)

export default UsernameAndPic
