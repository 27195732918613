import styled from 'styled-components'

import { CommonBottomBarBg } from '../../../../matches/components/ConfigItem'
import { Typo16Black2BarlowBoldItalic } from '../../../styleGuide/Typos'
import { CommonResourceIcon } from '../CommonResource/styledComponents'

export const IBCResourceIconDimesion = styled(CommonResourceIcon)`
   left: -10%;
   width: 35px;
   height: 42px;
`
export const CommonBottomBar = styled(CommonBottomBarBg)`
   min-width: 100%;
   display: inline-block;
   transform: skew(-5deg);
`
export const ResourceName = styled(Typo16Black2BarlowBoldItalic)`
   display: inline-block;
   padding-top: 2px;
   padding-bottom: 3px;
`
