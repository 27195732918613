import React from 'react'
import { useTranslation } from 'react-i18next'

import WinnerArrowMarkIcon from '../../../../common/icons/WinnerArrowMarkIcon'

import KnockoutMatchComponent from '../KnockoutMatchCardComponent'

import {
   KnockoutMatchContainer,
   DependencyMatchOneWrapper,
   DependencyMatchTwoWrapper,
   ResultMatchWrapper,
   DependencyMatchesWrapper,
   ArrowIconWrapper,
   FirstArrowTextWrapper,
   SecondArrowTextWrapper,
   ArrowText
} from './styledComponent'

interface PlayerProps {
   playerName: string
   bgColor: string
}

interface DependencyMatchProps {
   playerOne: PlayerProps
   playerTwo: PlayerProps
}

interface MatchDetailsProps {
   playerOne: PlayerProps
   playerTwo: PlayerProps
   dependencyMatchOne: DependencyMatchProps
   dependencyMatchTwo: DependencyMatchProps
}

interface KnockOutMatchHierarchyProps {
   matchDetails: MatchDetailsProps
}

const KnockOutMatchHierarchyAtSemiFinalRound = (
   props: KnockOutMatchHierarchyProps
) => {
   const {
      playerOne,
      playerTwo,
      dependencyMatchOne,
      dependencyMatchTwo
   } = props.matchDetails
   const { t } = useTranslation()
   return (
      <KnockoutMatchContainer>
         <DependencyMatchesWrapper>
            <DependencyMatchOneWrapper>
               <KnockoutMatchComponent
                  playerOne={dependencyMatchOne.playerOne}
                  playerTwo={dependencyMatchOne.playerTwo}
               />
            </DependencyMatchOneWrapper>
            <DependencyMatchTwoWrapper>
               <KnockoutMatchComponent
                  playerOne={dependencyMatchTwo.playerOne}
                  playerTwo={dependencyMatchTwo.playerTwo}
               />
            </DependencyMatchTwoWrapper>
         </DependencyMatchesWrapper>
         <ArrowIconWrapper>
            <FirstArrowTextWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </FirstArrowTextWrapper>
            <WinnerArrowMarkIcon width={150} height={139} />
            <SecondArrowTextWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </SecondArrowTextWrapper>
         </ArrowIconWrapper>
         <ResultMatchWrapper>
            <KnockoutMatchComponent
               playerOne={playerOne}
               playerTwo={playerTwo}
            />
         </ResultMatchWrapper>
      </KnockoutMatchContainer>
   )
}

export default KnockOutMatchHierarchyAtSemiFinalRound
