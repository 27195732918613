import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { DottedBackGroundContainer } from './styledComponents'

interface DottedBackGroundProps {
   children: React.ReactNode
   containerCSS: React.CSSProperties
   containerStyle: Record<string, any>
   className: string
}

@observer
class DottedBackGround extends Component<DottedBackGroundProps> {
   static defaultProps = {
      containerStyle: {},
      containerCSS: {},
      className: ''
   }
   render() {
      const { children, containerCSS, containerStyle, className } = this.props
      return (
         <DottedBackGroundContainer
            css={containerCSS}
            className={className}
            style={containerStyle}
         >
            {children}
         </DottedBackGroundContainer>
      )
   }
}

export default DottedBackGround
