import React from 'react'

interface Props {
   width: number
   height: number
}

const OversLeftBg = (props: Props) => (
   <svg width={props.width} height={props.height} viewBox='0 0 249 65'>
      <title>{'1E58F746-E9CE-4FD6-8E49-EEDCF2D3FF4F'}</title>
      <defs>
         <linearGradient
            x1='50%'
            y1='0%'
            x2='50%'
            y2='100%'
            id='OversLeftBg_prefix__c'
         >
            <stop stopColor='#FAD961' offset='0%' />
            <stop stopColor='#F76B1C' offset='100%' />
         </linearGradient>
         <linearGradient
            x1='47.598%'
            y1='-18.503%'
            x2='50%'
            y2='79.769%'
            id='OversLeftBg_prefix__d'
         >
            <stop stopColor='#FFF' stopOpacity={0} offset='0%' />
            <stop offset='100%' />
         </linearGradient>
         <linearGradient
            x1='47.16%'
            y1='-12.087%'
            x2='47.16%'
            y2='84.997%'
            id='OversLeftBg_prefix__f'
         >
            <stop stopColor='#FFF' stopOpacity={0} offset='0%' />
            <stop offset='100%' />
         </linearGradient>
         <linearGradient
            x1='100%'
            y1='33.555%'
            x2='47.16%'
            y2='61.51%'
            id='OversLeftBg_prefix__g'
         >
            <stop stopColor='#FFF' stopOpacity={0} offset='0%' />
            <stop offset='100%' />
         </linearGradient>
         <path
            id='OversLeftBg_prefix__a'
            d='M27.53 0h220.172l-27.53 63.516H0z'
         />
         <path
            id='OversLeftBg_prefix__b'
            d='M38.395 0h198.098L208.56 63.516H10.462z'
         />
      </defs>
      <g transform='translate(0 .484)' fill='none' fillRule='evenodd'>
         <use fill='#353535' xlinkHref='#OversLeftBg_prefix__a' />
         <mask id='OversLeftBg_prefix__e' fill='#fff'>
            <use xlinkHref='#OversLeftBg_prefix__b' />
         </mask>
         <use
            fill='url(#OversLeftBg_prefix__c)'
            fillRule='nonzero'
            xlinkHref='#OversLeftBg_prefix__b'
         />
         <path
            fillOpacity={0.2}
            fill='url(#OversLeftBg_prefix__d)'
            fillRule='nonzero'
            mask='url(#OversLeftBg_prefix__e)'
            transform='matrix(-1 0 0 1 49.39 0)'
            d='M-16.463 63.516L57.537 0l8.317 63.516z'
         />
         <path
            fillOpacity={0.2}
            fill='url(#OversLeftBg_prefix__f)'
            fillRule='nonzero'
            mask='url(#OversLeftBg_prefix__e)'
            d='M166.88 63.516L275.39 0l-8.38 63.516z'
         />
         <path
            fillOpacity={0.2}
            fill='url(#OversLeftBg_prefix__g)'
            fillRule='nonzero'
            mask='url(#OversLeftBg_prefix__e)'
            transform='matrix(-1 0 0 1 311.31 0)'
            d='M100.278 63.516L211.032 0v63.516z'
         />
      </g>
   </svg>
)

OversLeftBg.defaultProps = {
   width: 249,
   height: 65
}
export default OversLeftBg
