import React from 'react'

interface Props {
   width: number
   height: number
}

const ConfigItemBg = (props: Props) => {
   const { width, height } = props

   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width={width}
         height={height}
         viewBox='0 0 101 101'
      >
         <defs>
            <linearGradient
               id='prefix__config__a'
               x1='-4.221%'
               x2='75.01%'
               y1='-39.444%'
               y2='88.977%'
            >
               <stop offset='0%' stopColor='#5D6A94'></stop>
               <stop offset='100%' stopColor='#5D6A94' stopOpacity='0'></stop>
            </linearGradient>
         </defs>
         <circle
            cx='50.5'
            cy='50.5'
            r='40.022'
            fill='none'
            stroke='url(#prefix__config__a)'
            strokeWidth='20.955'
         ></circle>
      </svg>
   )
}

ConfigItemBg.defaultProps = {
   width: 101,
   height: 101
}

export default ConfigItemBg
