import React from 'react'

interface Props {
   width: number
   height: number
}

const OutsIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 23 35'
   >
      <defs>
         <path
            id='o8opxw6f8b'
            d='M.244.204C.241.2.236.194.233.187l.011.017'
         ></path>
         <path id='lg701vm1jd' d='M0 .139h4.928v6.805H0z'></path>
         <path
            id='ueum290q4f'
            d='M.23.148C.226.142.222.138.219.13l.01.017'
         ></path>
         <path id='kgyxxwfyph' d='M.019.178h7.208v25.173H.019z'></path>
         <filter id='tigce5zkqa'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#tigce5zkqa)'
         transform='translate(0 -1)'
      >
         <path
            fill='#FFF'
            d='M8.337 2.427c0 .114.02.227.06.334a.294.294 0 01-.039-.066c.035.11.09.21.164.296.097.124.23.22.385.268l1.59.357c.01.208.104.397.25.534.102.107.247.205.425.184a.454.454 0 00.127-.034c-.012-.003.02-.015.064-.06a.579.579 0 00.165-.394l.424.089.425.089c.012.214.11.407.263.545.125.13.313.246.547.14-.018-.005.064-.031.134-.16a.662.662 0 00.09-.303l.863.182c.013.224.12.426.286.565.064.06.142.11.237.137l.002.001h.002l.04.01.014.001c.063.01.131.01.207-.01 0 0 .257-.058.294-.476V4.65l1.545.317c.312.09.578-.205.571-.612L17.467 4a.694.694 0 00-.019-.103l.002-.01a.833.833 0 00-.616-.729l-1.547-.309a.793.793 0 00-.523-.673c-.267-.077-.485.166-.502.483l-.013.046v1.378l-.838-.175V3.9l-.01-.002-.012-.494.022.496.839.183-.034-1.38.013-.045-.85-.171.002-.006a.792.792 0 00-.527-.696c-.267-.077-.483.158-.501.483l-.014.046.023 1.36h-.003l-.035-1.365.013-.046-.84-.177.004-.013a.795.795 0 00-.527-.696c-.267-.076-.48.15-.5.476l-1.59-.35c-.313-.09-.575.19-.554.576l.007.349z'
         ></path>
         <g transform='translate(8.734 4.142)'>
            <mask id='navue1nm9c' fill='#fff'>
               <use xlinkHref='#o8opxw6f8b'></use>
            </mask>
            <path
               d='M.244.204C.241.2.236.194.233.187l.011.017'
               mask='url(#navue1nm9c)'
            ></path>
         </g>
         <g transform='translate(0 2.864)'>
            <mask id='6zjlwe52ue' fill='#fff'>
               <use xlinkHref='#lg701vm1jd'></use>
            </mask>
            <path
               fill='#FFF'
               d='M2.377 2.357l1.115.31-1.087-.292-.028-.018M.359 6.787l.273.067c.08.039.164.063.251.074a.248.248 0 01-.068.016c.07 0 .14-.011.206-.032a.666.666 0 00.38-.26l.022-.036c.006-.009.013-.016.018-.025l.657-1.072a.66.66 0 00.67-.223.347.347 0 00.11-.29c-.018.03-.02-.054-.132-.147a.489.489 0 00-.179-.104l.401-.611a.667.667 0 00.567-.164c.113-.072.213-.185.198-.36-.049.053-.043-.122-.335-.253l.386-.587.007-.011a.666.666 0 00.493-.106c.11-.062.213-.163.223-.33 0 0 .022-.216-.292-.343l-.014-.004.67-1.14C5.02.62 4.862.35 4.547.261L4.267.18 4.242.178 4.2.15a.725.725 0 00-.737.31l-.647 1.142-.033-.021a.664.664 0 00-.682.251c-.127.195-.004.419.243.502 0 0 .002 0 .028.018l-.366.618-.028-.018a.66.66 0 00-.681.252c-.128.194-.01.414.237.497l.033.022 1.082.29-.026.039-1.057-.266-.033-.022-.373.568a.664.664 0 00-.646.257c-.128.195-.01.415.237.498L.06 6.224c-.15.228-.01.487.299.562'
               mask='url(#6zjlwe52ue)'
            ></path>
         </g>
         <g transform='translate(5.113 4.355)'>
            <mask id='8pjshnfczg' fill='#fff'>
               <use xlinkHref='#ueum290q4f'></use>
            </mask>
            <path
               d='M.23.148C.226.142.222.138.219.13l.01.017'
               mask='url(#8pjshnfczg)'
            ></path>
         </g>
         <g transform='translate(1.705 8.829)'>
            <mask id='tzc9eb88pi' fill='#fff'>
               <use xlinkHref='#kgyxxwfyph'></use>
            </mask>
            <path
               fill='#FFF'
               d='M7.227 24.473l-3.215.878L.034 1.373A.43.43 0 01.336.844L2.72.194a.43.43 0 01.529.301l3.978 23.978z'
               mask='url(#tzc9eb88pi)'
               opacity='0.25'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M15.508 29.87l-3.33.145L7.64 10.437a.43.43 0 01.411-.449l2.469-.108a.432.432 0 01.449.412l4.539 19.578zm6.858 2.07l-3.24.778-2.32-24.403a.43.43 0 01.318-.52l2.402-.577a.432.432 0 01.52.319l2.32 24.403z'
            opacity='0.25'
         ></path>
         <g fill='#FFF' fillRule='nonzero'>
            <path d='M16.351 23.745a4.47 4.47 0 002.093-4.959l-7.434 4.292a4.47 4.47 0 005.342.667zm1.414-6.442l-7.7 4.445a4.197 4.197 0 00.407.703l7.699-4.445c-.057-.122-.118-.243-.186-.361s-.143-.232-.22-.342zm-5.88-1.293a4.47 4.47 0 00-2.094 4.959l7.435-4.292a4.468 4.468 0 00-5.34-.667z'></path>
         </g>
      </g>
   </svg>
)

OutsIcon.defaultProps = {
   width: 23,
   height: 35
}
export default OutsIcon
