import React from 'react'

interface Props {
   width: number
   height: number
   onClick?: any
}

const MenuIcon = (props: Props) => (
   <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      onClick={props.onClick}
      focusable='false'
      role='presentation'
   >
      <path
         d='M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z'
         fill='currentColor'
         fillRule='evenodd'
      ></path>
   </svg>
)

MenuIcon.defaultProps = {
   width: 32,
   height: 32
}
export default MenuIcon
