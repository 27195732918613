import { action } from 'mobx'

import {
   BaseGameModeDetailsType,
   MatchInfoGameModeDetailsType
} from '../../types'

class BaseGameModeDetails {
   displayName: string
   name: string

   constructor() {
      this.displayName = ''
      this.name = ''
   }

   @action.bound
   setBaseGameModeDetailsFromAPI(
      gameModeDetails: BaseGameModeDetailsType
   ): void {
      const { display_name: displayName, name } = gameModeDetails
      this.displayName = displayName
      this.name = name
   }

   @action.bound
   setBaseGameModeDetailsFromMatchInfoAPI(
      gameModeDetails: MatchInfoGameModeDetailsType
   ): void {
      this.name = gameModeDetails.name_enum
      this.displayName = gameModeDetails.name
   }
}

export default BaseGameModeDetails
