import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Type30LightGreyBarlowBoldText } from '../../styleGuide/Typos'
import { StatsItem } from '../../stores/types'

import {
   MatchesIconContainer,
   MatchesTextContainer,
   MatchIconText,
   DescriptionText
} from './styledComponents'

interface StatItemProps {
   item: StatsItem
}

const StatItem = (props: StatItemProps): ReactElement => {
   const { t } = useTranslation()
   const { displayName, value, Icon } = props.item
   return (
      <MatchesIconContainer props={props}>
         <MatchIconText>
            <Icon />
            <MatchesTextContainer>
               <Type30LightGreyBarlowBoldText>
                  {value}
               </Type30LightGreyBarlowBoldText>
            </MatchesTextContainer>
         </MatchIconText>
         <DescriptionText>{t(displayName)}</DescriptionText>
      </MatchesIconContainer>
   )
}

export default StatItem
