import styled from 'styled-components'
import { Typo18SilverBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'

export const RessourceCountContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   width: 23.36%;
   margin-right: 5.217%;
`

export const TextContainer = styled(Typo18SilverBarlowSemiBoldCapital)``

export const CountryAndRankContainer = styled.div`
   display: inline-block;
   display: flex;
   flex-direction: row;
   align-items: center;
   min-width: 23.04%;
   margin-left: 3.47%;
`

export const ScoreContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   min-width: 21.4%;
`
