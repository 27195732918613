import styled from 'styled-components'
import colors from '../../../common/themes/Colors'

export const ShareWithFriendsPopupContainer = styled.div`
   background-color: ${colors.darkTwo};
   border: 1px solid ${colors.darkBlueGray};
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
`
