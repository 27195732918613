import React from 'react'

interface Props {
   width: number
   height: number
   level: string
}

const MatchCardBowlingLevelBg = (props: Props) => {
   const { width, height, level } = props
   const fillerId = `url(#prefix__BwlLvl__${level})`

   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         xmlnsXlink='http://www.w3.org/1999/xlink'
         width={width}
         height={height}
         viewBox='0 0 112 36'
      >
         <defs>
            <filter
               id='prefix__BwlLvl__c'
               width='102.7%'
               height='108.7%'
               x='-1.3%'
               y='-4.4%'
               filterUnits='objectBoundingBox'
            >
               <feGaussianBlur
                  in='SourceAlpha'
                  result='shadowBlurInner1'
                  stdDeviation='1'
               ></feGaussianBlur>
               <feOffset
                  dy='1'
                  in='shadowBlurInner1'
                  result='shadowOffsetInner1'
               ></feOffset>
               <feComposite
                  in='shadowOffsetInner1'
                  in2='SourceAlpha'
                  k2='-1'
                  k3='1'
                  operator='arithmetic'
                  result='shadowInnerInner1'
               ></feComposite>
               <feColorMatrix
                  in='shadowInnerInner1'
                  values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
               ></feColorMatrix>
            </filter>
            <filter
               id='prefix__BwlLvl__e'
               width='116.7%'
               height='137.4%'
               x='-8.4%'
               y='-18.7%'
               filterUnits='objectBoundingBox'
            >
               <feOffset
                  in='SourceAlpha'
                  result='shadowOffsetOuter1'
               ></feOffset>
               <feGaussianBlur
                  in='shadowOffsetOuter1'
                  result='shadowBlurOuter1'
                  stdDeviation='1'
               ></feGaussianBlur>
               <feColorMatrix
                  in='shadowBlurOuter1'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
               ></feColorMatrix>
            </filter>
            <filter
               id='prefix__BwlLvl__g'
               width='100%'
               height='100%'
               x='0%'
               y='0%'
               filterUnits='objectBoundingBox'
            >
               <feOffset
                  in='SourceAlpha'
                  result='shadowOffsetOuter1'
               ></feOffset>
               <feColorMatrix
                  in='shadowOffsetOuter1'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.443019701 0'
               ></feColorMatrix>
            </filter>
            <filter
               id='prefix__BwlLvl__i'
               width='100%'
               height='100%'
               x='0%'
               y='0%'
               filterUnits='objectBoundingBox'
            >
               <feOffset
                  in='SourceAlpha'
                  result='shadowOffsetOuter1'
               ></feOffset>
               <feColorMatrix
                  in='shadowOffsetOuter1'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.443019701 0'
               ></feColorMatrix>
            </filter>
            <path
               id='prefix__BwlLvl__a'
               d='M10.209.486h101.064L101.064 34.85H0z'
            ></path>
            <path
               id='prefix__BwlLvl__f'
               d='M8.027 26.34a49.45 49.45 0 011.076-1.193l-.441-1.056c-.37.397-.735.802-1.096 1.216l.46 1.034zm2.175-2.336c.37-.371.743-.735 1.12-1.09l-.401-1.095c-.384.361-.764.73-1.14 1.11l.421 1.075zm2.26-2.126c.382-.336.768-.663 1.157-.982l-.36-1.131c-.397.324-.79.657-1.178.998l.381 1.115zm2.34-1.916c.393-.3.79-.592 1.19-.876l-.32-1.162a44.19 44.19 0 00-1.211.89l.34 1.148zm2.407-1.704c.403-.264.81-.52 1.22-.769l-.28-1.188c-.417.252-.83.512-1.24.78l.3 1.177zm2.473-1.497c.41-.228.825-.45 1.243-.663l-.24-1.21c-.425.216-.846.44-1.263.673l.26 1.2zm2.471-1.264c.418-.196.839-.384 1.263-.565l-.203-1.228c-.431.184-.858.375-1.282.573l.222 1.22zm2.544-1.085c.423-.163.85-.32 1.28-.468l-.168-1.242c-.436.151-.868.31-1.297.475l.185 1.235zm2.578-.895c.427-.132.858-.257 1.292-.376l-.134-1.253c-.44.12-.876.248-1.309.382l.15 1.247zm2.601-.712c.431-.103.864-.2 1.301-.29l-.101-1.26c-.442.09-.88.189-1.317.293l.117 1.257zm2.622-.542c.433-.075.868-.145 1.307-.208l-.07-1.265c-.445.064-.885.134-1.323.21l.086 1.263zm2.635-.38a46.6 46.6 0 011.312-.13l-.042-1.27c-.445.04-.887.083-1.326.134l.056 1.267zm2.646-.227a56.7 56.7 0 011.314-.06l-.016-1.27a57.49 57.49 0 00-1.326.06l.028 1.27zm2.551-.084c.436-.004.874-.002 1.315.005l.008-1.27a49.01 49.01 0 00-1.327-.006l.004 1.27zm2.635.042l.486.022.024-1.27-.49-.022-.02 1.27z'
            ></path>
            <path
               id='prefix__BwlLvl__h'
               d='M35.545 3.842l-1.306-1.93C33.516.882 13.78 5.398 2.398 34.174c-.282 5.54-1.986 2.325-.202 6.525 0 0 10.674-29.208 33.35-36.857z'
            ></path>
            <path
               id='prefix__BwlLvl__j'
               d='M39.046 9.64S18.943 13.733 4.414 42.788c0 0 1.586 2.454 2.45 2.163.864-.292 7.808-20.129 32.946-33.338l-.764-1.971z'
            ></path>
            <linearGradient
               id='prefix__BwlLvl__HARD'
               x1='0%'
               x2='100%'
               y1='48.654%'
               y2='51.346%'
            >
               <stop offset='0%' stopColor='#FF5463'></stop>
               <stop offset='3.053%' stopColor='#FD4154'></stop>
               <stop offset='3.798%' stopColor='#FF4F63'></stop>
               <stop offset='25.612%' stopColor='#CD253A'></stop>
               <stop offset='55.252%' stopColor='#C6243D'></stop>
               <stop offset='77.528%' stopColor='#CF243E'></stop>
               <stop offset='100%' stopColor='#DC445B'></stop>
            </linearGradient>
            <linearGradient
               id='prefix__BwlLvl__MEDIUM'
               x1='-13.95%'
               x2='50%'
               y1='44.684%'
               y2='50%'
            >
               <stop offset='0%' stopColor='#FAD961'></stop>
               <stop offset='100%' stopColor='#F76B1C'></stop>
            </linearGradient>
            <linearGradient
               id='prefix__BwlLvl__EASY'
               x1='-37.399%'
               x2='64.371%'
               y1='38.628%'
               y2='50%'
            >
               <stop offset='0%' stopColor='#00FF32'></stop>
               <stop offset='100%' stopColor='#00921D'></stop>
            </linearGradient>
         </defs>
         <g fill='none' fillRule='evenodd' transform='translate(0 .332)'>
            <mask id='prefix__BwlLvl__d' fill='#fff'>
               <use xlinkHref='#prefix__BwlLvl__a'></use>
            </mask>
            <use fill={fillerId} xlinkHref='#prefix__BwlLvl__a'></use>
            <use
               fill='#000'
               filter='url(#prefix__BwlLvl__c)'
               xlinkHref='#prefix__BwlLvl__a'
            ></use>
            <g mask='url(#prefix__BwlLvl__d)' opacity='0.5'>
               <g fillRule='nonzero' transform='rotate(-29 28.234 -23.44)'>
                  <use
                     fill='#000'
                     filter='url(#prefix__BwlLvl__e)'
                     xlinkHref='#prefix__BwlLvl__f'
                  ></use>
                  <use fill='#CFFFD7' xlinkHref='#prefix__BwlLvl__f'></use>
               </g>
               <g transform='rotate(-14 21.713 -68.61)'>
                  <use
                     fill='#000'
                     filter='url(#prefix__BwlLvl__g)'
                     xlinkHref='#prefix__BwlLvl__h'
                  ></use>
                  <use fill='#CFFFD7' xlinkHref='#prefix__BwlLvl__h'></use>
               </g>
               <g transform='rotate(-17 22.814 -53.066)'>
                  <use
                     fill='#000'
                     filter='url(#prefix__BwlLvl__i)'
                     xlinkHref='#prefix__BwlLvl__j'
                  ></use>
                  <use fill='#CFFFD7' xlinkHref='#prefix__BwlLvl__j'></use>
               </g>
            </g>
         </g>
      </svg>
   )
}

MatchCardBowlingLevelBg.defaultProps = {
   width: 112,
   height: 36,
   level: 'HARD'
}

export default MatchCardBowlingLevelBg
