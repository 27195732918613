import React from 'react'

interface Props {
   width: number
   height: number
}

const AverageIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 30 24'
   >
      <defs>
         <filter id='6biiah3bra'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <g fill='#FFF' filter='url(#6biiah3bra)'>
            <path d='M2 21.636h28V24H2z' opacity='0.25'></path>
            <path d='M0 .636h2.5v23H0z' opacity='0.25'></path>
            <path d='M4.364 9.636h3v12h-3zm5.636 3h3v9h-3zm6-3h3v12h-3zm6 2h3v10h-3z'></path>
         </g>
         <path
            stroke='#FFF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M5.818 5.837l5.417 4.546 5.716-3.512 8.23 2.292'
            opacity='0.25'
         ></path>
      </g>
   </svg>
)

AverageIcon.defaultProps = {
   width: 30,
   height: 24
}
export default AverageIcon
