import { create, ApisauceInstance } from 'apisauce'
import Config from '../../../common/constants/EnvironmentConstants'
import {
   GetTournamentsMinimalDetailsAPIResponse,
   GetTournamentsMinimalDetailsAPIRequest,
   GetTournamentsInfoV2Response
} from '../../stores/types'
import endpoints from '../../../common/services/endpoints'
import { apiMethods } from '../../../common/constants/APIConstants'
import TournamentsService from '.'

class TournamentsAPI implements TournamentsService {
   api: ApisauceInstance
   networkCallWithApisauce: Function

   constructor(networkCallWithApisauce: Function) {
      this.api = create({
         baseURL: Config.BASE_URL
      })
      this.networkCallWithApisauce = networkCallWithApisauce
   }
   getTournamentDataAPI(requestObject): Promise<GetTournamentsInfoV2Response> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getTournamentDetials,
         requestObject,
         apiMethods.post
      )
   }

   async getTournamentsMinimalDetails(
      request: GetTournamentsMinimalDetailsAPIRequest
   ): Promise<GetTournamentsMinimalDetailsAPIResponse> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getTournamentsMinimalDetails,
         request,
         apiMethods.post
      )
   }
}

export default TournamentsAPI
