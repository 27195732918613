import styled from 'styled-components'

import { Heading } from '../../../common/components/Heading'
import { Typo14BattleShipGreyBarlowSemiBoldItalic } from '../../../common/styleGuide/Typos'

export const MatchesPlayedHeaderContainer = styled(Heading)`
   box-sizing: border-box;
   min-width: 100%;
   margin-top: 32px;
   padding-left: 24px;
   padding-right: 24px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`
export const FilterByContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: flex-end;
   padding-bottom: 8px;
`
export const FilterByText = styled(Typo14BattleShipGreyBarlowSemiBoldItalic)`
   padding-top: 9px;
   padding-bottom: 6px;
   padding-right: 8px;
`
