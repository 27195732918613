import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TOURNAMENTS } from '../../../common/constants/NavigationConstants'
import RightArrowIcon from '../../../common/icons/RightArrowIcon'
import ResourceItemCmp from '../../../common/components/Resources/ResourceItem'
import {
   Typo24PaleGreyBarlowSemiBoldItalicCapital,
   Typo14CoolGreyBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

import { TitleAndIcon } from '../../../matches/components/MatchesCommon/MatchesCommon'

import TourunamentRoundEndsInComponent from '../TourunamentRoundEndsInComponent'

import TournamentModel from '../../stores/models/TournamentModel'
import { TournamentTabsStatus } from '../../constants/tournamentConstants'
import WinOrLost from '../../../matches/components/WinAndLose'
import {
   CardWrapper,
   HeaderWrapper,
   ContentWrapper,
   TitleWrapper,
   LeaderBoardAndCurrentRoundTextWrapper
} from './styledComponent'

type PlayerTournamentsProps = {
   tournament: TournamentModel
}

const TournamenstsBaseMatchCard = (
   props: PlayerTournamentsProps
): ReactElement => {
   const history = useHistory()
   const { tournament } = props
   const {
      resources,
      tournamentName,
      tournamentId,
      tournamentGroupPicUrl,
      tournamentCurrRoundDetails,
      tournamentEnds,
      tournamentStatus,
      userCurrentRoundName,
      userCurrentRoundResultWinStatus
   } = tournament

   const { roundName } = tournamentCurrRoundDetails

   const onClickOfCard = (): void => {
      history.push(`${TOURNAMENTS}/${tournamentId}`, {
         tournamentId: tournamentId
      })
   }

   const { t } = useTranslation()

   const renderRoundNameAndResult = () => {
      if (tournamentStatus === TournamentTabsStatus.ONGOING) {
         return (
            <LeaderBoardAndCurrentRoundTextWrapper>
               <Typo24PaleGreyBarlowSemiBoldItalicCapital>
                  {roundName}
               </Typo24PaleGreyBarlowSemiBoldItalicCapital>
               <Typo14CoolGreyBarlowBoldItalicCapital>
                  {t('tournaments:common.currentRound')}
               </Typo14CoolGreyBarlowBoldItalicCapital>
            </LeaderBoardAndCurrentRoundTextWrapper>
         )
      }

      return (
         <WinOrLost
            isWon={userCurrentRoundResultWinStatus}
            round={userCurrentRoundName}
         />
      )
   }

   return (
      <CardWrapper onClick={onClickOfCard}>
         <HeaderWrapper>
            <TitleWrapper>
               <TitleAndIcon
                  title={tournamentName}
                  url={tournamentGroupPicUrl}
               />
            </TitleWrapper>
            {renderRoundNameAndResult()}
         </HeaderWrapper>
         <ContentWrapper>
            {resources.map((resource, index) => (
               <ResourceItemCmp key={index} resource={resource} />
            ))}
            <TourunamentRoundEndsInComponent roundEndsIn={tournamentEnds} />
            <RightArrowIcon />
         </ContentWrapper>
      </CardWrapper>
   )
}

export default TournamenstsBaseMatchCard
