import { ChallengeMilestonesType } from '../../../types'

import RewardResource from '../RewardResource'
class ChallengeMileStone {
   challengeId: number
   maxValue: number
   milestoneId: number
   minValue: number
   currValue: number
   isTargetAchieved: boolean
   name: string
   nameEnum: string
   regionConfig: null | any
   rewards: Array<RewardResource>
   rewardConfigId: number

   constructor(
      mileStoneItem: ChallengeMilestonesType,
      isAchievedMilestoneFn: Function = () => {}
   ) {
      const userMileStoneData = isAchievedMilestoneFn(
         mileStoneItem.milestone_id
      )

      this.challengeId = mileStoneItem.challenge_id
      this.maxValue = mileStoneItem.max_value
      this.milestoneId = mileStoneItem.milestone_id
      this.minValue = mileStoneItem.min_value
      this.currValue = userMileStoneData.curr_value
      this.name = mileStoneItem.name
      this.nameEnum = mileStoneItem.name_enum
      this.rewardConfigId = mileStoneItem.reward_config_id
      this.isTargetAchieved = userMileStoneData.is_achieved
      this.rewards = mileStoneItem.reward.resources.map(
         reward => new RewardResource(reward)
      )
   }
}

export default ChallengeMileStone
