import React, { ReactElement } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { Typo18BattleShipGreyBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'
import TournamentLeaderboardTable from '../../../leaderboard/components/TournamentLeaderboardTable'
import TournamentInfoModel from '../../stores/models/TournamentInfoModel'
import { tournamentConstants } from '../../constants/tournamentConstants'
import KnockOutRoundComponent from '../KnockOutRoundComponent'
import { TabsWrapper } from './styledComponents'

interface TabProps {
   title: string
}

const TabComponent = (props: TabProps): ReactElement => (
   <Typo18BattleShipGreyBarlowSemiBoldCapital>
      {props.title}
   </Typo18BattleShipGreyBarlowSemiBoldCapital>
)

interface TournamentRoundsTabProps {
   setCurrentRoundIndex(roundNumber: number)
   tournamentInfo: TournamentInfoModel
   currentRoundIndex: number
}

const renderComponentBasedOnRoundType = (
   tournamentInfo: TournamentInfoModel,
   roundNo: number,
   time: number
): ReactElement => {
   const {
      lbContributionCount,
      leaderboardItems,
      roundDetailsDict,
      knockoutMatchesDict
   } = tournamentInfo
   switch (roundDetailsDict[roundNo].round_type) {
      case tournamentConstants.knockOutType:
         return (
            <KnockOutRoundComponent
               roundDetailsDict={roundDetailsDict}
               knockoutMatchesDict={knockoutMatchesDict}
               roundNo={roundNo}
            />
         )
      default:
         return (
            <TournamentLeaderboardTable
               leaderboards={leaderboardItems}
               time={time}
               lbContributionCount={lbContributionCount}
            />
         )
   }
}

const TournamentRoundsTabCmp = (
   props: TournamentRoundsTabProps
): ReactElement => {
   const { currentRoundIndex, setCurrentRoundIndex, tournamentInfo } = props
   const { roundDetailsList } = tournamentInfo
   return (
      <TabsWrapper>
         <Tabs
            defaultIndex={currentRoundIndex}
            onSelect={(index: number): void => {
               setCurrentRoundIndex(index)
            }}
         >
            <TabList>
               {roundDetailsList.map((round, key) => (
                  <Tab key={key}>
                     <TabComponent title={round.round_display_name} />
                  </Tab>
               ))}
            </TabList>
            {roundDetailsList.map((round, key) => (
               <TabPanel key={key}>
                  {renderComponentBasedOnRoundType(
                     tournamentInfo,
                     round.round_no,
                     round.round_ends_in
                  )}
               </TabPanel>
            ))}
         </Tabs>
      </TabsWrapper>
   )
}
export default TournamentRoundsTabCmp
