import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import IBCTrophy from '../../icons/iBCTrophy'
import {
   LeaderboardHeaderContainer,
   TitleText,
   ResourceText,
   RankContainer,
   SuperRunsContainer,
   ScoreContainer,
   NameContainer
} from './styledComponents'

const TournamentLeaderboardHeader = (): ReactElement => {
   const { t } = useTranslation()
   return (
      <LeaderboardHeaderContainer>
         <RankContainer>
            <TitleText>{t('leaderboards.rank')}</TitleText>
         </RankContainer>
         <NameContainer>
            <TitleText>{t('leaderboards.name')}</TitleText>
         </NameContainer>
         <ScoreContainer>
            <TitleText>{t('leaderboards.score')}</TitleText>
         </ScoreContainer>
         <SuperRunsContainer>
            <IBCTrophy />
            <ResourceText>{t('leaderboards.ibcRating')}</ResourceText>
         </SuperRunsContainer>
      </LeaderboardHeaderContainer>
   )
}

export default TournamentLeaderboardHeader
