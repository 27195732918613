import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import getResourcesInOrder from '../../../utils/getResourcesInOrder'

import {
   MainContainer,
   Container,
   RankRange,
   NextRoundContainer,
   NextRoundTextWrapper,
   TrophyContainer,
   TrophyIconWrapper,
   TrophyPointsWrapper,
   DiamondIconWrapper,
   DiamondPointsWrapper,
   CoinIconWrapper,
   CoinPointsWrapper,
   GemImageComponent,
   CoinsImageComponent,
   IbcRatingImageComponent
} from './styledComponents'

interface ResourceTypes {
   display_name: 'string'
   description: 'string'
   pic: 'string'
   value: 100
   thumbnail: 'string'
   name: 'IBC_RATING_POINTS'
}

type Props = {
   minRank: number
   maxRank: number
   totalLbsCount: number
   lbContributionCount: number
   resources: Array<any>
}

const RenderNextRoundContainer = (
   isPromotedToNxtRound: boolean
): React.ReactNode => {
   const { t } = useTranslation()
   if (isPromotedToNxtRound)
      return (
         <NextRoundContainer>
            <NextRoundTextWrapper>
               {t('tournaments:nextRound')}
            </NextRoundTextWrapper>
         </NextRoundContainer>
      )

   return null
}
const renderTrophyContainer = (
   isTrophyPointsExist: boolean,
   trophyPoints?: number,
   ibcRatingPointsImage?: string
): React.ReactNode => {
   if (isTrophyPointsExist)
      return (
         <TrophyContainer>
            <TrophyIconWrapper>
               <IbcRatingImageComponent
                  src={ibcRatingPointsImage}
                  width={26}
                  height={33}
               />
            </TrophyIconWrapper>
            <TrophyPointsWrapper>{trophyPoints}</TrophyPointsWrapper>
         </TrophyContainer>
      )
   return (
      <TrophyContainer>
         <TrophyIconWrapper></TrophyIconWrapper>
         <TrophyPointsWrapper></TrophyPointsWrapper>
      </TrophyContainer>
   )
}

export const LeaderBoardItem = (props: Props): ReactElement => {
   const { minRank, maxRank, resources, lbContributionCount } = props

   const resourcesDictionary = getResourcesInOrder(resources)
   const { gems, coins } = resourcesDictionary

   const isPromotedToNextRound =
      minRank <= lbContributionCount && lbContributionCount <= maxRank

   const isTrophyPointsExist = resourcesDictionary.ibcRatingPoints
      ? true
      : false

   const ibcRatingPoints = resourcesDictionary.ibcRatingPoints
      ? resourcesDictionary.ibcRatingPoints.value
      : 0

   const ibcRatingPointsImage = resourcesDictionary.ibcRatingPoints
      ? resourcesDictionary.ibcRatingPoints.pic
      : ''

   return (
      <MainContainer>
         <Container>
            <RankRange>
               {minRank} - {maxRank}
            </RankRange>
            {RenderNextRoundContainer(isPromotedToNextRound)}
            {renderTrophyContainer(
               isTrophyPointsExist,
               ibcRatingPoints,
               ibcRatingPointsImage
            )}
            {gems && (
               <DiamondIconWrapper>
                  <GemImageComponent src={gems.pic} width={29} height={29} />
               </DiamondIconWrapper>
            )}
            <DiamondPointsWrapper>{gems?.value}</DiamondPointsWrapper>
            {coins && (
               <CoinIconWrapper>
                  <CoinsImageComponent src={coins.pic} width={30} height={29} />
               </CoinIconWrapper>
            )}
            <CoinPointsWrapper>{coins?.value}</CoinPointsWrapper>
         </Container>
      </MainContainer>
   )
}

export default LeaderBoardItem
