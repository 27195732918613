import styled from 'styled-components'
import colors from '../../../../common/themes/Colors'
import { Typo12PointFiveWhiteBarlowBoldItalic } from '../../../../common/styleGuide/Typos'

export const PrizesContainer = styled.div`
   width: 149px;
`

export const PrizesHeadingContiner = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: ${colors.blackPearl};
   padding: 2px 0px;
`

export const PrizesText = styled(Typo12PointFiveWhiteBarlowBoldItalic)`
   margin-left: 2px;
`

export const PrizesHeadingText = styled(Typo12PointFiveWhiteBarlowBoldItalic)``

export const RewardsContainer = styled.div`
   display: flex;
   justify-content: space-around;
   padding: 5px 0px;
   background-color: ${colors.bluishBlack};
`
export const IbcRatingIconWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`

export const GemIconWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`

export const CoinIconWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`
