import React from 'react'

import {
   PlayerCardContainer,
   PlayerNameText,
   ColorContainer
} from './styledComponent'

interface PlayerCardComponentProps {
   playerName: string
   bgColor: string
}

const PlayerCardComponent = (props: PlayerCardComponentProps) => {
   const { playerName, bgColor } = props
   return (
      <PlayerCardContainer>
         <ColorContainer bgColor={bgColor}></ColorContainer>
         <PlayerNameText>{playerName}</PlayerNameText>
      </PlayerCardContainer>
   )
}

export default PlayerCardComponent
