import styled from 'styled-components'
import { Typo18LightBlueGrayBarlowBoldItalicCapital } from '../../../common/styleGuide/Typos'

export const Container = styled.div`
   display: flex;
`
export const DependencyMatchesWrapper = styled.div``

export const ArrowMarkIconWrapper = styled.div`
   padding-top: 75px;
   position: relative;
`
export const ThirdRoundMatchWrapper = styled.div`
   padding-top: 110px;
   margin-left: -35px;
   padding-right: 32px;
`
export const FirstWinnerTextComponent = styled(
   Typo18LightBlueGrayBarlowBoldItalicCapital
)`
   position: absolute;
   left: 18%;
   top: 60px;
`

export const SecondWinnerTextComponent = styled(
   Typo18LightBlueGrayBarlowBoldItalicCapital
)`
   left: 18%;
   position: absolute;
   bottom: 130px;
`
