const linerGradients = {
   resourceItemGradient: 'linear-gradient(to bottom, #00aaff 1%, #004aa6)',
   resourceItemBGGradient:
      'linear-gradient(to bottom,rgba(255, 255, 255, 1) 0%,rgba(130, 148, 200, 1) 100%,rgba(0, 44, 170, 0.88) 100%)',
   resourceCountGradient: 'linear-gradient(to bottom, #00aaff, #004aa6)',
   superRunsCompletionGradient: 'linear-gradient(to bottom, #ffc800, #b34100)',
   matchconfigItemGradient:
      'linear-gradient(to bottom, #ffffff 0%, #8294c8 100%, rgba(0, 44, 170, 0.88) 100%)',
   matchConfigIconBgGradient:
      'linear-gradient(148deg, #5d6a94 -26%, rgba(93, 106, 148, 0) 84%)',
   gameModePropertyGradient: 'linear-gradient(to bottom, #00aaff 1%, #004aa6)',
   gameMode: {
      novoiceGradient:
         'linear-gradient(to bottom, #b99bff 2%, #9066f1 11%, #814bff 50%)',
      amatureGradient:
         'linear-gradient(to bottom, #ffa1e2 1%, #f645c5 12%, #be2c85 50%)',
      semiProGradient:
         'linear-gradient(to bottom, #3affc3 2%, #2ae2d8 15%, #2e70ff 60%)',
      proGradient:
         'linear-gradient(to bottom, #ffdcaa 5%, #fb7d24 25%, #d34475 49%)',
      worldClassGradient:
         'linear-gradient(to top, rgba(33, 71, 255, 0.54), rgba(112, 135, 255, 0) 80%, rgba(73, 170, 255, 0.39) 40%, #31deff59 20%)',
      worldClassBaseGradient:
         'linear-gradient(to bottom, #d0ff46 2%, #0bc99f 12%, #06848d 19%, #6a4cac 81%, #3198ff)',
      ultimateGradient:
         'linear-gradient(to top, rgba(154, 58, 150, 0.54), rgba(112, 135, 255, 0) 80%, rgba(73, 170, 255, 0.39) 40%, #c531ff4d 20%)',
      ultimateGradientBase:
         'linear-gradient(to bottom, #ffd846 2%, #eb247a 25%, #003db5 81%, #0ad2c2)',
      rectangularGradient:
         'linear-gradient(300deg, rgba(255, 255, 255, 0.1) 47%, rgba(255, 255, 255, 0) 47%)'
   },
   profileLineBarGradient:
      'linear-gradient(to right, #0852b2 0%, #f47400 100%)',
   headerScoreGradient:
      'linear-gradient(to right, #0852b2 3%, rgba(8, 82, 178, 0.3))',
   tournamentKnockoutMatchPlayerCardGradient:
      'linear-gradient(to bottom, #4400cc 0%, #24006b 100%);',
   tournamentPlayingContainerGradient:
      'linear-gradient(to bottom, #ff9e5e, #fa6400);',
   youWonIbcRatingPointsBottomLinearGradient:
      'linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, #8294c8 100%, rgba(0, 44, 170, 0.88) 100%)',
   winnerCardBorderGradient: 'linear-gradient(to bottom, #ffa519, #f7b500)',
   leaderBoardNextRoundGradient:
      'linear-gradient(to bottom, #00c6ff, #0072ff), linear-gradient(to bottom, #002967, #002967);'
}

const colors = {
   allScreenBg: '#131418',
   azulTwo: '#1665d8',
   battleshipGrey: ' #848482',
   battleShipGrey: '#6c7285',
   black: '#252421',
   black10: 'rgba(0,0,0,0.1)',
   black12: 'rgba(0, 0, 0, 0.12)',
   black2: '#252421',
   black32: 'rgba(0, 0, 0, 0.32)',
   black50: 'rgba(0, 0, 0, 0.5)',
   black80: 'rgba(0, 0, 0, 0.8)',
   blackPearl: '#000a18',
   blackBg: '#252421',
   blackRussian: ' #191a1f',
   blackSeven: '#101010',
   blackTwo: '#262626',
   blue: '#1f2c8c',
   blueGreyTwo: '#7b8fac',
   bluePurple: '#3800ff',
   blueShade: '#4d5db7',
   blueyGrey: '#8892a4',
   bluishBlack: '#151e2a',
   blush: '#f4c4a1',
   brown: '#733900',
   brown70: 'rgba(109, 54, 0, 0.7)',
   charcoalGrey: '#2a2c34',
   coolGrey: '#a3a7b3',
   cricketPitchBg: '#cec682',
   dark: '#131418',
   darkBlue80: 'rgba(1,7,79,0.8)',
   darkBlueGray: '#1c2342',
   darkBlueGrayTwo: '#131937',
   darkBlueGrey: '#1c2342',
   darkBlueGreyTwoProfile: '#131937',
   darkGreyBlueTwoSix: 'rgba(53, 78, 110, 0.6)',
   darkIndigo: '#070b24',
   darkMaroon: '#46040a',
   darkSideBar: '#131418',
   darkSkyBlue: '#3bb6e7',
   darkPurlple: '#3404a4',
   darkOrange: '#fa6400',
   darkRum: '#2d3744',
   darkThree: '#292d37',
   darkTwo: `#212329`,
   dodgerBlueOne: '#0072ff',
   dusk: '#4c637d',
   darkPumpkinOrange: '#ffa519',
   deepGreen: '#1d6200',
   everGreen: '#04401d',
   gameModeDarkBg: '#292d37',
   hanPurple: '#4400cc',
   greyishBrown: '#404040',
   lightBlue: '#62d2f3',
   lightBlueGrey: '#dde5f6',
   lightBlueGreyTwo: '#becbed',
   lightGrey: '#dde5f6',
   lightGreyTwo: ' #dae5f2',
   lightYellow: '#ffe200',
   lightBlack: '#2d3744',
   lightSeaBlue: '#0096ff',
   linerGradients,
   mango: '#ffbc2f',
   marineBlue: '#002a77',
   darkMarineBle: '#002166',
   marineBlueTwo: '#002967',
   marineBlueThree: '#002b54',
   martinique: '#363f4c',
   mariGold: '#ffc800',
   myProfileTitleSilver: '#d1d4da',
   navyBlue: '#00224d',
   oceanBlue: '#016db9',
   orangeBrown: '#ba5d00',
   orangeYellow: '#ffa600',
   pageNotFoundBottomBgColor: '#cad9f0',
   pageNotFoundInfoSectionBgColor: '#f6f6f8',
   pageNotFoundPointHyperlink: '#ff6b3c',
   pageNotFoundText: '#5e6977',
   pageNotFoundTopBgColor: '#f3f4f8',
   paleBlue: '#caf8fd',
   paleGrey: '#e7e9ec',
   paleGreyFour: '#fbfcfc',
   paleGreyThree: '#bec2ca',
   peacockBlue: '#004aa6',
   pencilGrey: '#787878',
   pinkishOrange: '#ff6b3c',
   primaryColor: '#2196f3',
   pumpkinOrange: '#f47400',
   pumpkinOrangeWithOpacity: 'rgb(244, 116, 0,0.3)',
   redBrown: '#7e1d10',
   silver: '#d1d4da',
   shamrockTwo: '#01b84d',
   white: 'rgba(255,255,255,1)',
   white20: 'rgba(255,255,255,0.2)',
   white50: 'rgba(255,255,255,0.5)',
   whiteFour: 'rgba(255, 255, 255, 0.4)',
   whiteTwo: 'rgba(255,255,255,0.2)',
   whiteTwoWithoutGradient: '#d8d8d8',
   yellowOrange: '#f7b500',
   lightRedBrown: '#932f16',
   leaderboardItemBlueColor: '#002967',
   prussianBlue: '#003051',
   dodgerBlue: '#008CEF'
}

export default colors
