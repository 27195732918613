import styled from 'styled-components'
import Select from 'react-select'

import Colors from '../../themes/Colors'
import ReactSelectDropDownArrow from '../../icons/ReactSelectDropDownArrow'
import {
   Typo14DarkRR,
   Typo12PinkishOrangeRoboto,
   Typo14BattleshipGreyBarlow
} from '../../styleGuide/Typos'

export const DropDownContainer = styled('div')`
   width: 191px;
   height: 32px;
   border: solid 1px ${Colors.battleShipGrey};
   color: ${Colors.battleShipGrey};
`

export const SelectField = styled(Select)`
   outline: none;
   height: 32px;
   padding: 0px;
   align-items: center;
   justify-content: center;

   .Select-container {
      height: 32px;
      border-color: ${Colors.battleShipGrey};
      border: solid 1px ${Colors.battleShipGrey};
   }
   .Select__control {
      min-height: 32px !important;
      background-color: ${Colors.blackRussian};
      border-radius: 0;
      border: solid 0px ${Colors.battleShipGrey} !important;
      box-shadow: none !important;
      font-family: Barlow;
   }
   .Select__placeholder {
      color: ${Colors.battleShipGrey};
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;

      letter-spacing: 0.5px;
   }
   .Select__value-container {
      padding: 0 0 0 15px;
      display: flex;
      align-items: center;
      cursor: text;
   }
   .Select__single-value {
      margin: 0px;
      padding-left: 2px;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: ${Colors.battleShipGrey};
   }
   .Select__indicator-separator {
      opacity: 0;
   }
   .Select__dropdown-indicator {
      padding: 0 16px 0 0;
      cursor: pointer;
   }
   .Select__menu {
      margin-top: 0px !important;
      padding: 0px;
   }
   .Select__menu-list {
      padding: 0px;
      border: solid 1px ${Colors.blackRussian};
      max-height: 200px;
      overflow: auto;
   }
   .Select__option {
      height: 32px;
      cursor: pointer;
      color: ${Colors.battleShipGrey};
      background-color: ${Colors.blackRussian};
      border-color: ${Colors.battleShipGrey};
      padding-left: 16px;
      vertical-align: middle;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
   }
   .Select__option--is-focused {
      background-color: ${Colors.charcoalGrey};
      color: ${Colors.battleShipGrey};
   }
   .Select__option--is-selected {
      color: ${Colors.battleShipGrey};
   }
   .Select__control--is-disabled {
      border-width: 0px;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: ${Colors.battleShipGrey};
      background-color: ${Colors.blackRussian};
   }
`

export const MenuOption = styled(Typo14BattleshipGreyBarlow)`
   line-height: 1.5;
   letter-spacing: 0.15px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
`

export const DropDownIcon = styled(ReactSelectDropDownArrow)`
   transform: ${props =>
      props.isInverted ? 'rotate(0deg)' : 'rotate(180deg)'};
`

export const SelectContainer = styled('div')`
   display: flex;
   flex-direction: row;
   align-items: center;
`

export const Typo14DarkRRWithDiv = Typo14DarkRR.withComponent('div')

export const ValueOptionText = styled(Typo14DarkRRWithDiv)`
   opacity: 0.9;
   font-family: Roboto;
   font-size: 16px;
   text-align: left;
   color: ${Colors.dark};
   line-height: unset;
   padding-left: 0px;
`

export const ErrorView = styled.div`
   flex-direction: row;
   margin-top: 3px;
   margin-left: 3px;
`

export const ErrorMessage = styled(Typo12PinkishOrangeRoboto)`
   line-height: 2.03;
   letter-spacing: 0.11px;
   pointer-events: none;
`
