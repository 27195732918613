import React, { ReactElement } from 'react'

import { SpecialChallengeType } from '../../../constants/MatchConfigConstants'
import SpecialChallengeMatch from '../../../stores/models/SpecialChallengeMatch'

import SteakChallengeCard from './SteakChallenge'
import SurvivalChallengeCard from './SurvivalChallengeCard'

interface SpecialChallengeCardsProps {
   model: SpecialChallengeMatch
}
const SpecialChallengeCards = (
   props: SpecialChallengeCardsProps
): ReactElement => {
   const { model } = props
   switch (props.model.specialChallengeDetails.nameEnum) {
      case SpecialChallengeType.SURVIVAL_CHALLENGE:
         return <SurvivalChallengeCard model={model} />
      case SpecialChallengeType.STEAK_CHALLENGE:
      case SpecialChallengeType.WAGON_WHEEL_CHALLENGE:
         return <SteakChallengeCard model={model} />
      default:
         return <SurvivalChallengeCard model={model} />
   }
}

export default SpecialChallengeCards
