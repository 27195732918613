import React, { ReactElement } from 'react'

import { Typo36SliverBarlowExtraBoldItalic } from '../../styleGuide/Typos'
import { LineBar, ProfileHeaderContainer } from './styledComponents'

interface ProfileTitleProps {
   title: string
   width: number
}

const SectionTitle = (props: ProfileTitleProps): ReactElement => (
   <ProfileHeaderContainer>
      <Typo36SliverBarlowExtraBoldItalic>
         {props.title}
      </Typo36SliverBarlowExtraBoldItalic>
      <LineBar width={props.width}></LineBar>
   </ProfileHeaderContainer>
)

export default SectionTitle
