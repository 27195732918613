import styled from '@emotion/styled'
import Colors from '../../themes/Colors'

import { Typo20SilverBarlowBoldItalic } from '../../styleGuide/Typos'

export const TextContainer = styled(Typo20SilverBarlowBoldItalic)`
   line-height: normal;
   box-sizing: border-box;
   padding-bottom: 8px;
`
export const Container = styled.div`
   border-bottom: solid 1px ${Colors.charcoalGrey};
   padding-bottom: 8px;
`
