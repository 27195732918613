import { action } from 'mobx'

import { EmptyResourceItemForTournamentCard } from '../../../../common/constants/ResourceConstants'
import { WIN } from '../../../constants/tournamentConstants'

import { getResourceItem } from '../../../utils/getResourceItem'

import {
   TournamentCurrentRoundDetails,
   ResourceDetails,
   Tournament
} from '../../types'
import ResourceModel from '../ResourceModel'

class TournamentModel {
   tournamentId: number
   tournamentGroupPicUrl: string
   tournamentEnds: number
   tournamentName: string
   tournamentCurrRoundDetails: TournamentCurrentRoundDetails
   resources: ResourceDetails[] = []
   tournamentStatus: string
   userCurrentRoundName: string | null = null
   userCurrentRoundResultWinStatus: boolean | null = null

   constructor(tournament: Tournament, tournamentStatus: string) {
      this.tournamentId = tournament.tournament_id

      this.tournamentGroupPicUrl = tournament.tournament_group_pic_url
      this.tournamentEnds = tournament.tournament_ends_in_secs
      this.tournamentName = tournament.tournament_name
      this.tournamentCurrRoundDetails = {
         roundName: tournament.tournament_curr_round_details.round_name,
         roundExpiresIn:
            tournament.tournament_curr_round_details.round_expires_in_secs
      }
      this.tournamentStatus = tournamentStatus
      this.setUserCurrentRoundDetails(tournament)
      this.setResourcesForTournaments(tournament)
   }

   @action.bound
   setUserCurrentRoundDetails(tournament: Tournament): void {
      const userCurrentRoundDetails = tournament.user_curr_round_details
      if (userCurrentRoundDetails) {
         this.userCurrentRoundName = userCurrentRoundDetails.round_name
         this.userCurrentRoundResultWinStatus =
            userCurrentRoundDetails.user_round_result === WIN
      }
   }

   @action.bound
   setResourcesForTournaments(tournament: Tournament): void {
      const resources = getResourceItem(tournament.user_rewards).map(
         resource => new ResourceModel(resource)
      )
      if (resources.length === 0) {
         resources.push(new ResourceModel(EmptyResourceItemForTournamentCard))
      }
      this.resources = resources
   }
}

export default TournamentModel
