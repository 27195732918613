import { CurrentStadiumDetailsType } from '../../types'

class CurrentStadiumDetails {
   id: number
   picThumbnail: string
   pic: string
   displayName: string
   name: string
   description: string

   constructor(currentStadiumDetails: CurrentStadiumDetailsType) {
      this.id = currentStadiumDetails.id ? currentStadiumDetails.id : 0
      this.picThumbnail = currentStadiumDetails.pic_thumbnail
      this.pic = currentStadiumDetails.pic
      this.displayName = currentStadiumDetails.display_name
      this.name = currentStadiumDetails.name
      this.description = currentStadiumDetails.description
   }
}

export default CurrentStadiumDetails
