import { MatchUserType } from '../../types'
import UserGameStats from '../GameStats'

class MatchUserDetails {
   userId: string
   name: string
   picUrl: string | null
   result: string
   rank: number
   gameStats: UserGameStats

   constructor(userDetails: MatchUserType) {
      this.userId = userDetails.user_id
      this.name = userDetails.name
      this.picUrl = userDetails.pic_url
      this.result = userDetails.result
      this.rank = userDetails.rank
      this.gameStats = new UserGameStats(userDetails.game_stats_blob)
   }

   getUserMileStoneObject = (milestoneId: number): object => {
      let userMileStoneData = {}
      this.gameStats.achievedMileStonesInfo.forEach((itemData): void => {
         if (itemData.milestone_id === milestoneId) {
            userMileStoneData = itemData
         }
      })
      return userMileStoneData
   }
}

export default MatchUserDetails
