import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IBC_LOGO_PATH } from '../../constants/PublicImageConstants'
import { Typo18PaleGreyBarlowBoldItalicCapital } from '../../styleGuide/Typos'
import { MobileView, MobileLogo } from './styledComponents'

export const MobileLayout = (): ReactElement => {
   const { t } = useTranslation()
   return (
      <MobileView>
         <MobileLogo src={IBC_LOGO_PATH} />
         <Typo18PaleGreyBarlowBoldItalicCapital>
            {t('mobileNotSupportedNote')}
         </Typo18PaleGreyBarlowBoldItalicCapital>
      </MobileView>
   )
}
