import { create, ApisauceInstance } from 'apisauce'
import Config from '../../constants/EnvironmentConstants'
import {
   GetCricketGameDetailsAPIResponse,
   PlayerProfileRequestType,
   PlayerProfileResponseType
} from '../../stores/types'
import endpoints from '../endpoints'
import { apiMethods } from '../../constants/APIConstants'
import PlayerProfileService from '.'

class PlayerProfileApi implements PlayerProfileService {
   api: ApisauceInstance
   networkCallWithApisauce: Function

   constructor(networkCallWithApisauce: Function) {
      this.api = create({
         baseURL: Config.BASE_URL
      })
      this.networkCallWithApisauce = networkCallWithApisauce
   }
   getPlayerProfileDetailsAPI(
      requestObject: PlayerProfileRequestType
   ): Promise<PlayerProfileResponseType> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getProfileDetails,
         requestObject,
         apiMethods.post
      )
   }

   getCricketGameDetailsAPI(): Promise<GetCricketGameDetailsAPIResponse> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getCricketGameDetails,
         {},
         apiMethods.post
      )
   }
}

export default PlayerProfileApi
