import React from 'react'

interface ArrowMarkIconProps {
   width: number
   height: number
}

const WinnerArrowMarkIcon = (props: ArrowMarkIconProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 267 206'
      {...props}
   >
      <g fill='none' fillRule='evenodd'>
         <path
            fill='#DDE5F6'
            fillRule='nonzero'
            d='M149.126.461a1.54 1.54 0 011.533 1.399l.006.14v100.035h95.294v-7a1.15 1.15 0 011.01-1.15l.123-.006c.176 0 .35.042.507.122l16.733 8.538a1.168 1.168 0 010 2.069l-16.733 8.539c-.56.285-1.24.054-1.52-.518a1.168 1.168 0 01-.12-.517v-6.999h-96.833a1.54 1.54 0 01-1.532-1.399l-.007-.14V3.539H2A1.54 1.54 0 01.467 2.14L.461 2A1.54 1.54 0 011.86.467L2 .461h147.126z'
         />
         <path
            stroke='#DDE5F6'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={3.078}
            d='M2 204.378h146.96V102.804'
         />
      </g>
   </svg>
)

WinnerArrowMarkIcon.defaultProps = {
   width: 262,
   height: 203
}

export default WinnerArrowMarkIcon
