import * as React from 'react'

import { CLOUDINARY_CONFIGURED_PREFIX } from '../../constants/Cloudinary'
import { getResponsiveImageSourceProps } from '../../utils/ImageUtils'

import { ReactImage } from './styledComponents'

interface ImageProps {
   src: string
   configString?: string
   alt: string
   className?: string
   loading?: string
   imageCSS?: any
   onLoad?: () => void
   isKbcResponsiveImage?: boolean
   onError?: () => void
   onClick?: () => void
}

class Image extends React.Component<ImageProps> {
   static defaultProps = {
      configString: 'dpr_auto,w_auto,f_auto,q_auto',
      isKbcResponsiveImage: true
   }
   render() {
      const {
         src,
         configString,
         alt,
         className,
         loading,
         imageCSS,
         onLoad,
         isKbcResponsiveImage,
         onError,
         onClick
      } = this.props
      let updatedSrc = src
      const pattern = /^((http|https|www):\/\/)/
      let kbcResponsiveImageSource = {}
      if (isKbcResponsiveImage && src) {
         kbcResponsiveImageSource = getResponsiveImageSourceProps(src)
      }
      if (pattern.test(src)) {
         updatedSrc = `${CLOUDINARY_CONFIGURED_PREFIX}/image/fetch/${configString}/${src}`
      }
      return (
         <ReactImage
            alt={alt}
            loading={loading}
            className={className}
            src={updatedSrc}
            draggable={false}
            css={imageCSS}
            onLoad={onLoad}
            onError={onError}
            onClick={onClick}
            {...kbcResponsiveImageSource}
         />
      )
   }
}

export default Image
