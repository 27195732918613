import styled from 'styled-components'

import {
   Typo16WhiteBarlowBoldItalicCapital,
   Typo11WhiteBarlowBolditalicCapital,
   Typo14PointOneWhiteBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'
import { leaderBoardConstants } from '../../constants/leaderBoardConstants'

import Colors from '../../../common/themes/Colors'

interface BottomContainerProps {
   isPlayerOne: boolean
}

interface TopPartProps {
   isPlayerOne: boolean
}

export const PlayerDetailsContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   flex-grow: 1;
`

export const PlayerDetailsBaseText = styled(
   Typo16WhiteBarlowBoldItalicCapital
)``

export const PlayerNameText = styled(PlayerDetailsBaseText)`
   max-width: 150px;
   overflow: hidden;
   text-overflow: ellipsis;
`

export const ScoreCardText = styled(PlayerDetailsBaseText)<TopPartProps>`
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   margin-top: ${props => (props.isPlayerOne ? '-5px' : '0px')};
`

export const YetToPlayText = styled(Typo14PointOneWhiteBarlowBoldItalicCapital)<
   TopPartProps
>`
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   margin-top: ${props => (props.isPlayerOne ? '-5px' : '0px')};
   margin-left: -2px;
`

export const BottomContainer = styled.div<BottomContainerProps>`
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 118%;
   box-sizing: border-box;
   height: 58%;
   background-color: ${Colors.darkPurlple};
   transform: skew(-${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   margin-left: -7px;
   padding-left: 24px;
   padding-right: 35px;
   margin-left: ${props => (props.isPlayerOne ? '-22%' : '')};
`
export const AntiScrewWrapper = styled.div`
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
`

export const TopPart = styled.div<TopPartProps>`
   height: 42%;
   display: flex;
   align-items: center;
   padding-left: ${props => (props.isPlayerOne ? '0px' : '20px')};
   margin-left: ${props => (props.isPlayerOne ? '-13px' : '0px')};
`

export const PlayingTextContainer = styled.div`
   width: 74px;
   height: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: ${Colors.linerGradients
      .tournamentPlayingContainerGradient};
   border-radius: 15px;
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
`
export const PlayingText = styled(Typo11WhiteBarlowBolditalicCapital)`
   margin: 0px;
`
