import React from 'react'

interface Props {
   width: number
   height: number
}

const StrikeBackIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 30 33'
   >
      <defs>
         <path id='s08tr1ldrb' d='M0 .082h7.355v9.572H0z'></path>
         <path id='gf6bun6kpd' d='M.011.055h20.86v21.696H.011z'></path>
         <filter id='nd8sdlwypa'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#nd8sdlwypa)'
         transform='translate(0 -1)'
      >
         <g transform='translate(0 1.052)'>
            <mask id='50wtan44tc' fill='#fff'>
               <use xlinkHref='#s08tr1ldrb'></use>
            </mask>
            <path
               fill='#FFF'
               d='M1.922 1.047C1.558.518 1.395.133 1.165.095.445-.024-.082.743.01.92c.164.314.23.49.493.921.28.457 4.977 7.813 4.977 7.813 1.164-.456 1.874-.631 1.874-.631L1.922 1.047z'
               mask='url(#50wtan44tc)'
               opacity='0.25'
            ></path>
         </g>
         <g transform='translate(3.894 11.256)'>
            <mask id='u1m7qdhsve' fill='#fff'>
               <use xlinkHref='#gf6bun6kpd'></use>
            </mask>
            <path
               fill='#FFF'
               d='M.01.055l.026.649s7.812 15.13 14.909 20.994c0 0 4.346.585 5.926-2.131l-.205-.638s-.87 1.296-5.594 1.868L.01.055z'
               mask='url(#u1m7qdhsve)'
               opacity='0.25'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M3.904 11.311s13.299 19.345 15.061 20.742c0 0 4.293.04 5.595-1.868L9.212 9.839s-2.972.173-5.308 1.472'
         ></path>
         <path
            fill='#FFF'
            fillRule='nonzero'
            d='M25.455 23.505a3.663 3.663 0 001.716-4.064l-6.093 3.518a3.665 3.665 0 004.377.546zm1.159-5.28l-6.31 3.644c.046.1.095.199.151.296.057.097.118.19.181.28l6.31-3.644a3.657 3.657 0 00-.151-.296 3.635 3.635 0 00-.181-.28zm-4.82-1.06a3.665 3.665 0 00-1.715 4.064l6.094-3.518a3.663 3.663 0 00-4.378-.546z'
         ></path>
      </g>
   </svg>
)

StrikeBackIcon.defaultProps = {
   width: 30,
   height: 33
}
export default StrikeBackIcon
