import React from 'react'
interface Props {
   width: number
   height: number
}

const BackArrow = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 8 14'
   >
      <path
         fill='#6C7285'
         fillRule='evenodd'
         d='M7.707 1.707A1 1 0 006.293.293l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 001.474-1.352L2.384 7.03l5.323-5.323z'
      ></path>
   </svg>
)

BackArrow.defaultProps = {
   width: 8,
   height: 14
}

export default BackArrow
