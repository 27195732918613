import styled from 'styled-components'
import Colors from '../../themes/Colors'
import { Typo36SliverBarlowExtraBoldItalic } from '../../styleGuide/Typos'

export const HeaderTitle = styled(Typo36SliverBarlowExtraBoldItalic)``
export const LineBar = styled.div<{ width: number }>`
   display: block;
   max-width: ${props => props.width}px;
   margin-top: 8px;
   height: 2px;
   background-image: ${Colors.linerGradients.profileLineBarGradient};
`
export const ProfileHeaderContainer = styled.div`
   display: inline-block;
   display: flex;
   flex-direction: column;
   padding-top: 32px;
   padding-left: 32px;
   padding-bottom: 16px;
`
