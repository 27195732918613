import React from 'react'

interface Props {
   width: number
   height: number
   fillColor: string
}

const ActiveTournamentsIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 24 24'
   >
      <defs>
         <path id='lwyud0n8na' d='M0 0h14.053v14.053H0z'></path>
         <path
            id='jilx2ftt9c'
            d='M6.787 2.568c3.348-1.022 6.895.848 7.922 4.177 1.028 3.328-.853 6.855-4.2 7.877-3.348 1.022-6.895-.848-7.922-4.177-1.028-3.328.853-6.855 4.2-7.877'
         ></path>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <path d='M0 0h24v24H0z'></path>
         <path
            fill='#028DD8'
            fillRule='nonzero'
            d='M10.56 13.62h2.522v7.938H10.56z'
         ></path>
         <path
            fill='#028DD8'
            fillRule='nonzero'
            d='M13.42 15.558h-3.152c-.29 0-.526.198-.526.442v1.768h4.203V16c0-.244-.235-.442-.525-.442z'
         ></path>
         <path
            fill='#028DD8'
            fillRule='nonzero'
            d='M15.185 17.2h-6.87a.468.468 0 00-.458.352l-1.1 4.562c-.07.287.154.56.457.56h9.146c.306 0 .53-.278.456-.566l-1.175-4.563a.467.467 0 00-.456-.345z'
         ></path>
         <path
            fill='#20435C'
            fillRule='nonzero'
            d='M13.736 19.386H9.701c-.278 0-.504-.183-.504-.409 0-.226.226-.41.504-.41h4.035c.279 0 .505.184.505.41 0 .226-.226.41-.505.41zm-.919 1.915h-2.242c-.31 0-.56-.183-.56-.409 0-.226.25-.409.56-.409h2.242c.31 0 .56.183.56.41 0 .225-.25.408-.56.408zm6.992-15.392h-1.865c-.348 0-.602.243-.602.586 0 .343.254.586.602.586h1.865c.099 0 .143.042.143.128v2.247a.777.777 0 01-.265.584l-.633.607a.822.822 0 01-.625.257l-1.684-.089c-.36 0-.614.243-.614.586 0 .312.21.541.51.58l1.776.095c.6 0 1.107-.206 1.484-.607l.625-.598a1.86 1.86 0 00.63-1.415V7.21c0-.736-.59-1.3-1.347-1.3zM4.245 5.909h1.691c.332 0 .568.245.568.58 0 .336-.236.58-.568.58h-1.69c-.075 0-.11.037-.11.123v2.213c0 .221.079.41.24.58l.576.596c.13.161.328.246.545.246l1.973.089c.321 0 .557.244.557.58 0 .335-.236.58-.567.58l-1.973-.088c-.474 0-.896-.163-1.227-.48l-.687-.714c-.367-.342-.572-.853-.572-1.389V7.192c0-.722.54-1.283 1.245-1.283z'
         ></path>
         <g transform='translate(4.942 1)'>
            <mask id='5wh9mb4erb' fill='#fff'>
               <use xlinkHref='#lwyud0n8na'></use>
            </mask>
            <g mask='url(#5wh9mb4erb)'>
               <g transform='translate(-1.465 -1.757)'>
                  <mask id='3x480pc8jd' fill='#fff'>
                     <use xlinkHref='#jilx2ftt9c'></use>
                  </mask>
                  <use
                     fill='#028DD8'
                     transform='scale(-1 1) rotate(62 0 -5.798)'
                     xlinkHref='#jilx2ftt9c'
                  ></use>
                  <path
                     fill='#20435C'
                     d='M15.07 3.54l-.43-.409s-7.885.767-12.042 7.138c-.146 1.21.146.703.719 1.602 0 0 3.918-6.459 11.752-8.331z'
                     mask='url(#3x480pc8jd)'
                     transform='scale(-1 1) rotate(66 0 -6.073)'
                  ></path>
                  <path
                     fill='#20435C'
                     d='M15.372 5.417s-6.906.934-11.956 7.278c0 0 .593.635.89.57.298-.066 2.507-4.231 11.27-7.567l-.204-.281z'
                     mask='url(#3x480pc8jd)'
                     transform='scale(-1 1) rotate(63 0 -6.153)'
                  ></path>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

ActiveTournamentsIcon.defaultProps = {
   width: 24,
   height: 24,
   fillColor: 'active'
}
export default ActiveTournamentsIcon
