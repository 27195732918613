import React from 'react'

interface Props {
   width: number
   height: number
}

const AlarmIncon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 32 32'
      {...props}
   >
      <defs>
         <filter id='prefix__a'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.950000 0 0 0 0 0.950000 0 0 0 0 0.950000 0 0 0 1.000000 0'
            />
         </filter>
      </defs>
      <g
         filter='url(#prefix__a)'
         transform='translate(-992 -288)'
         fill='none'
         fillRule='evenodd'
      >
         <path d='M992 288h32v32h-32z' />
         <path
            fill='#000'
            fillRule='nonzero'
            d='M1001.524 313.81c.704.539 1.477.992 2.304 1.343l-1.305 2.261a1.334 1.334 0 01-2.31-1.333l1.311-2.271zm10.658 1.339c.826-.353 1.6-.806 2.303-1.346l1.291 2.237a1.334 1.334 0 01-2.309 1.334l-1.285-2.225z'
         />
         <path
            fill='#000'
            fillRule='nonzero'
            d='M1008 316c-5.891 0-10.667-4.776-10.667-10.667 0-5.89 4.776-10.666 10.667-10.666s10.667 4.775 10.667 10.666S1013.89 316 1008 316zm9.425-23.661l2.333 2.332c.78.781.78 2.048 0 2.829-.782.78-2.048.78-2.829 0l-2.332-2.333a2 2 0 112.828-2.828zm-18.36-.496a2 2 0 112.828 2.828l-2.828 2.829c-.781.78-2.048.78-2.829 0a2.003 2.003 0 010-2.829l2.829-2.828z'
            opacity={0.3}
         />
         <path
            fill='#000'
            fillRule='nonzero'
            d='M1007.95 298h.113c.343 0 .63.26.664.6l.606 6.067 4.331 2.474c.208.12.336.34.336.58V308a.51.51 0 01-.643.491l-6.159-1.68a.668.668 0 01-.49-.694l.578-7.501a.667.667 0 01.665-.616z'
         />
      </g>
   </svg>
)

AlarmIncon.defaultProps = {
   width: 32,
   height: 32
}

export default AlarmIncon
