import { observable } from 'mobx'
import { ResourceItem } from '../../types'

class ResourceModel {
   @observable pic: string
   @observable value: number
   @observable name: string
   @observable display_name
   @observable thumbnail: string

   constructor(resource: ResourceItem) {
      this.pic = resource.pic
      this.value = resource.value
      this.display_name = resource.display_name
      this.name = resource.name
      this.thumbnail = resource.thumbnail
   }
}

export default ResourceModel
