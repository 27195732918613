import { create } from 'apisauce'

import { apiMethods } from '../../constants/APIConstants'
import Config from '../../constants/EnvironmentConstants'
import {
   LoginResendOTPRequest,
   LoginSendOTPRequest,
   LoginVerifyOTPRequest,
   GetRefreshTokenRequest,
   LoginWithSteamIdRequest
} from '../../stores/types'
import endpoints from '../endpoints'

import AuthService from '.'

const AUTH_URL = Config.BASE_PUBLIC_URL

class AuthAPI implements AuthService {
   api: Record<string, any>
   networkCallWithAPISauce: Function

   constructor(networkCallWithAPISauce: Function) {
      this.api = create({
         baseURL: AUTH_URL
      })
      this.networkCallWithAPISauce = networkCallWithAPISauce
   }

   loginSendOTP(requestObject: LoginSendOTPRequest) {
      return this.networkCallWithAPISauce(
         this.api,
         endpoints.loginSendOTP,
         requestObject,
         apiMethods.post
      )
   }

   loginVerifyOTP(requestObject: LoginVerifyOTPRequest) {
      return this.networkCallWithAPISauce(
         this.api,
         endpoints.loginVerifyOTP,
         requestObject,
         apiMethods.post
      )
   }

   loginResendOTP(requestObject: LoginResendOTPRequest) {
      return this.networkCallWithAPISauce(
         this.api,
         endpoints.loginResendOTP,
         requestObject,
         apiMethods.post
      )
   }

   getRefreshToken(requestObject: GetRefreshTokenRequest) {
      return this.networkCallWithAPISauce(
         this.api,
         endpoints.getRefreshToken,
         requestObject,
         apiMethods.post
      )
   }

   logoutAPI(requestObject) {
      return this.networkCallWithAPISauce(
         this.api,
         endpoints.logout,
         requestObject,
         apiMethods.post
      )
   }

   loginWithSteamIdAPI = (requestObject: LoginWithSteamIdRequest) =>
      this.networkCallWithAPISauce(
         this.api,
         endpoints.steamLogin,
         requestObject,
         apiMethods.post
      )
}

export default AuthAPI
