import styled from 'styled-components'
import {
   Typo24PaleGreyBarlowSemiBoldItalic,
   Typo14CoolGreyBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

export const EndsInText = styled(Typo14CoolGreyBarlowBoldItalicCapital)`
   text-align: right;
`
export const DeadLineValue = styled(Typo24PaleGreyBarlowSemiBoldItalic)``

export const EndedText = styled(Typo24PaleGreyBarlowSemiBoldItalic)`
   text-transform: uppercase;
`

export const EndedTextWrapper = styled.div`
   text-align: left;
   display: flex;
   flex-direction: column;
`

export const TournamentDeadLineTextWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
`
