import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
   BiChevronLeft
   // , BiShareAlt
} from 'react-icons/bi'
import { withRouter, RouteComponentProps } from 'react-router'
import OutsideClickHandler from 'react-outside-click-handler'

import ProfileIcon from '../../icons/ProfileIcon'
import LeaderboardIcon from '../../icons/LeaderboardIcon'
import TournamentsIcon from '../../icons/TournamentsIcon'
import ActiveTournamentsIcon from '../../icons/ActiveTournamentsIcon'
// import HomeIcon from '../../icons/HomeIcon'
// import TeamIcon from '../../icons/TeamIcon'
// import SettingsIcon from '../../icons/SettingsIcon'
// import ChallengeIcon from '../../icons/ChallengeIcon'
// import FriendsIcon from '../../icons/FriendsIcon'
// import WallOfFameIcon from '../../icons/WallOfFameIcon'

import {
   MY_PROFILE,
   TOURNAMENTS,
   LEADERBOARDS
   // MY_TEAM,
   // HOME_SCREEN_PATH,
   // FRIENDS,
   // WALL_OF_FAME,
   // SETTINGS,
   // CHALLENGES
} from '../../constants/NavigationConstants'
import ShareWithFriendsPopup from '../../../matches/components/ShareWithFriendsPopup'

import SideNavBarItem from '../SideNavBarItem'
import MenuIcon from '../../icons/MenuIcon'
import { DESKTOP_MIN_WIDTH } from '../../constants/ResponsiveConstants'
// import { Typo16WhiteBarlowBoldCapital } from '../../styleGuide/Typos'
import LogoutPopUp from '../LogoutPopUp'
import { IBC_LOGO_PATH } from '../../../common/constants/PublicImageConstants'

import {
   SideNavBarDiv,
   BetaVersionInfo,
   FloatingMenu,
   SideBarHandler,
   SideBarClickDetector,
   // ShareYourProfileButton,
   // ShareIcon,
   LogoutButtonWrapper,
   IBCLogo,
   SideNavBarItemsWrapper,
   SlideNavBarBottom,
   ShareWithFriendsPopupWrapper,
   MenuButton,
   SpanButton
} from './styledComponents'

interface SideNavBarItemProps {
   title: string
   icon: any
   path: string
}
type ListSideNavBarItems = WithTranslation &
   RouteComponentProps<{}> & {
      listItems: SideNavBarItemProps[]
   }
export const SideBarList = [
   // {
   //    title: 'Home',
   //    icon: HomeIcon,
   //    path: HOME_SCREEN_PATH
   // },
   {
      title: 'My Profile',
      icon: ProfileIcon,
      path: MY_PROFILE
   },
   // {
   //    title: 'My Team',
   //    icon: TeamIcon,
   //    path: MY_TEAM
   // },
   // {
   //    title: 'Friends',
   //    icon: FriendsIcon,
   //    path: FRIENDS
   // },
   // {
   //    title: 'Challenges',
   //    icon: ChallengeIcon,
   //    path: CHALLENGES
   // },
   {
      title: 'Tournaments',
      icon: TournamentsIcon,
      activeIcon: ActiveTournamentsIcon,
      path: TOURNAMENTS
   },
   {
      title: 'Leaderboards',
      icon: LeaderboardIcon,
      path: LEADERBOARDS
   }
   // {
   //    title: 'Wall of Fame',
   //    icon: WallOfFameIcon,
   //    path: WALL_OF_FAME
   // },
   // {
   //    title: 'Settings',
   //    icon: SettingsIcon,
   //    path: SETTINGS
   // }
]

//TODO: Need to move iBC logo url into image constants
@observer
class SideNavBar extends Component<ListSideNavBarItems> {
   @observable isMobile: boolean
   @observable shouldShowShareWithFriendsPopup!: boolean
   @observable showSideNavBar: boolean

   constructor(props) {
      super(props)
      this.isMobile = false
      this.showSideNavBar = true
   }

   handleWindowResize = (): void => {
      this.isMobile = window.innerWidth < DESKTOP_MIN_WIDTH
      this.showSideNavBar = !this.isMobile
   }

   handleShareWithFriendsButtonClick = () => {
      this.shouldShowShareWithFriendsPopup = true
   }

   componentDidMount(): void {
      this.handleWindowResize()
      window.addEventListener('resize', this.handleWindowResize)
   }

   componentWillUnmount(): void {
      window.removeEventListener('resize', this.handleWindowResize)
   }

   hideShareWithFriendsPopup = () => {
      this.shouldShowShareWithFriendsPopup = false
   }

   handleOnClick = (index): void => {
      const itemData = SideBarList[index]
      this.props.history.push(itemData.path)
   }

   handleSidebarVisibility = (): void => {
      this.showSideNavBar = true
   }

   disableHandleSidebarVisibility = (): void => {
      this.showSideNavBar = false
   }

   openOrCloseSideNavBar = (): void => {
      this.showSideNavBar = !this.showSideNavBar
   }

   getCurrentWindowUrl = () => window.location.origin

   render(): JSX.Element {
      const { t, location, listItems } = this.props
      return (
         <SideBarHandler showSideNavBar={this.showSideNavBar}>
            {this.isMobile && this.showSideNavBar && (
               <SideBarClickDetector onClick={this.handleWindowResize} />
            )}
            <SideNavBarDiv
               showSideNavBar={this.showSideNavBar}
               className='content-desktop'
            >
               {this.showSideNavBar && (
                  <>
                     <IBCLogo src={IBC_LOGO_PATH} />
                     <SideNavBarItemsWrapper>
                        {listItems.map((data, index) => (
                           <SideNavBarItem
                              key={index}
                              index={index}
                              isActive={location.pathname.includes(data.path)}
                              onClick={this.handleOnClick}
                              {...data}
                           />
                        ))}
                        <LogoutButtonWrapper>
                           <LogoutPopUp />
                        </LogoutButtonWrapper>
                     </SideNavBarItemsWrapper>
                     <SlideNavBarBottom>
                        {/* <ShareYourProfileButton
                           onClick={this.handleShareWithFriendsButtonClick}
                        >
                           <Typo16WhiteBarlowBoldCapital>
                              {t('shareYourProfile')}
                           </Typo16WhiteBarlowBoldCapital>
                           <ShareIcon>
                              <BiShareAlt />
                           </ShareIcon>
                        </ShareYourProfileButton> */}

                        <BetaVersionInfo>
                           {t('sideBar.betaVersion')}
                        </BetaVersionInfo>
                     </SlideNavBarBottom>
                  </>
               )}
            </SideNavBarDiv>
            <FloatingMenu
               className='content-mobile'
               onClick={this.openOrCloseSideNavBar}
            >
               <MenuButton
                  data-resize-button='true'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                  type='button'
                  data-testid='ContextualNavigation-resize-button'
               >
                  <SpanButton role='presentation'>
                     {!this.showSideNavBar && <MenuIcon />}
                     {this.showSideNavBar && <BiChevronLeft size='20' />}
                  </SpanButton>
               </MenuButton>
            </FloatingMenu>
            {this.shouldShowShareWithFriendsPopup && (
               <OutsideClickHandler
                  onOutsideClick={this.hideShareWithFriendsPopup}
               >
                  <ShareWithFriendsPopupWrapper shouldShowShareWithFriendsPopup>
                     <ShareWithFriendsPopup url={this.getCurrentWindowUrl()} />
                  </ShareWithFriendsPopupWrapper>
               </OutsideClickHandler>
            )}
         </SideBarHandler>
      )
   }
}

export default withRouter(withTranslation()(SideNavBar))
