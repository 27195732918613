export const tournamentConstants = {
   playing: 'PLAYING',
   completed: 'COMPLETED',
   yetToPlay: 'YET_TO_START',
   knockOutType: 'KNOCK_OUT',
   leaderBoardType: 'LEADER_BOARD'
}

export const WIN = 'WIN'

export const TOURNAMENTS = 'Tournaments'

export const FINAL = 'FINAL'
export const WINNER = 'WINNER'

export const TournamentMatchStatus = {
   COMPLETED: 'COMPLETED',
   YET_TO_START: 'YET_TO_START',
   IN_PROGRESS: 'IN_PROGRESS'
}

export const MatchConfigConstants = {
   TBD: 'TBD',
   BLANK: 'BLANK'
}

export const userRewardsResource = {
   resource: 'RESOURCE',
   treasure: 'TREASURE'
}

export const TournamentTabsStatus = {
   ONGOING: 'IN_PROGRESS',
   PREVIOUS: 'COMPLETED'
}

export const tournamentsTabs = [
   {
      label: 'Ongoing',
      value: TournamentTabsStatus.ONGOING
   },
   {
      label: 'Previous',
      value: TournamentTabsStatus.PREVIOUS
   }
]
