import React, { Component, ReactElement } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line
import { API_FETCHING } from '@ib/api-constants'

import { Typo18SilverBarlowItalic } from '../../styleGuide/Typos'
import Loader from '../../components/Loader'
import { API_FAILED_400 } from '../../constants/APIConstants'
import { FAILURE_VIEW_IMAGE_PATH } from '../../constants/PublicImageConstants'

import {
   ContentHeader,
   ContentMatter,
   ErrorImg,
   TryAgainButton,
   Container,
   TryAgainButtonWrapper
} from './styledComponents'

interface FailureViewProps extends WithTranslation {
   apiStatus?: number
   onRetry?: () => void
   failureText: string
   buttonText?: string
   className?: string
}

class FailureView extends Component<FailureViewProps> {
   renderTryAgainText = (shouldDisplayLoader: boolean) => {
      const { t } = this.props
      return (
         <TryAgainButtonWrapper isLoaderDisplaying={shouldDisplayLoader}>
            {t('common.failureView.tryAgain')}
         </TryAgainButtonWrapper>
      )
   }

   renderTryAgainButton = (onRetry, shouldDisplayLoader): ReactElement => (
      <TryAgainButton onClick={onRetry}>
         <>
            {shouldDisplayLoader ? (
               <Loader height={25} width={25} />
            ) : (
               this.renderTryAgainText(shouldDisplayLoader)
            )}
         </>
      </TryAgainButton>
   )

   displayTryAgainButton = (
      isStatus400,
      onRetry,
      shouldDisplayLoader
   ): ReactElement | null => {
      if (!isStatus400) {
         return this.renderTryAgainButton(onRetry, shouldDisplayLoader)
      }
      return null
   }

   render() {
      const { apiStatus, onRetry, failureText, className, t } = this.props
      const shouldDisplayLoader = apiStatus === API_FETCHING
      const isStatus400 = apiStatus === API_FAILED_400
      return (
         <Container className={className}>
            <ContentHeader>
               {t('common.failureView.SomethingWentWrong')}
            </ContentHeader>
            <ContentMatter>
               {isStatus400 ? (
                  failureText
               ) : (
                  <Typo18SilverBarlowItalic>
                     {t('common.failureView.failureText')}
                  </Typo18SilverBarlowItalic>
               )}
            </ContentMatter>
            <ErrorImg src={FAILURE_VIEW_IMAGE_PATH} />
            {this.displayTryAgainButton(
               isStatus400,
               onRetry,
               shouldDisplayLoader
            )}
         </Container>
      )
   }
}

export default withTranslation()(FailureView)
