import { observable } from 'mobx'
import { observer, Provider } from 'mobx-react'
import React, { Component, ReactElement } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import * as Sentry from '@sentry/browser'
import 'react-tabs/style/react-tabs.css'

import stores from '../../stores'
import SideNavBar, { SideBarList } from '../../components/SideNavBar'
import PageNotFound404 from '../../components/PageNotFound404'
import FailureView from '../../LoadingWrapper/FailureView'
import {
   captureSentryExceptionWithScope,
   initializeSentry
} from '../../utils/SentryUtils'
import { SERVER_ERROR_CODE } from '../../constants/APIConstants'
import {
   LOGIN_SCREEN_PATH,
   NOT_FOUND_PAGE,
   NOT_FOUND_PAGE_PATH,
   STEAM_RETURN_PATH
} from '../../constants/NavigationConstants'
import { TABLET_MIN_WIDTH } from '../../constants/ResponsiveConstants'
import MaintenanceComponent from '../../components/MaintenanceComponent'
import { MobileLayout } from '../../components/MobileLayout'

import Routes from '../index'
import LoginPage from '../LoginPage'
import SteamReturnPage from '../SteamReturnPage'
import './App.css'
import {
   AppContainer,
   FailureViewWithDottedBackground
} from './styledComponent'

declare global {
   interface String {
      format(...args: any[]): string
   }
}

function stringFormat(template: string, ...args: any[]): string {
   return template.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match
   })
}

// eslint-disable-next-line no-extend-native
String.prototype.format = function(...args: any[]): string {
   return stringFormat(String(this), ...args)
}

//TODO: Need wrap side nav bar with individual component, not in routes
//TODO: FailureViewWithDottedBackground condition Need to update

@observer
class App extends Component<WithTranslation> {
   @observable isMobile: boolean

   constructor(props) {
      super(props)
      this.isMobile = false
   }

   componentDidMount(): void {
      this.handleWindowResize()
      initializeSentry()
      window.addEventListener('resize', this.handleWindowResize)
   }

   componentWillUnmount(): void {
      window.removeEventListener('resize', this.handleWindowResize)
   }

   state = { error: null }
   componentDidCatch(error, errorInfo): void {
      this.setState({ error })
      captureSentryExceptionWithScope(errorInfo, error)
   }

   onClickRetry = (): void => {
      Sentry.showReportDialog()
   }

   handleWindowResize = (): void => {
      this.isMobile = window.innerWidth < TABLET_MIN_WIDTH
   }

   renderRouteComponent = (): ReactElement => (
      <Provider {...stores}>
         {stores.authStore.inMaintenanceMode === true ? (
            <MaintenanceComponent />
         ) : (
            <>
               <Router>
                  <Switch>
                     <Route
                        exact
                        path={LOGIN_SCREEN_PATH}
                        component={LoginPage}
                     />
                     <Route
                        exact
                        path={STEAM_RETURN_PATH}
                        component={SteamReturnPage}
                     />
                     <AppContainer>
                        <SideNavBar listItems={SideBarList} />
                        <Routes
                           cricketConstantsStore={stores.cricketConstantsStore}
                           authStore={stores.authStore}
                        />
                     </AppContainer>
                     <Route
                        path={NOT_FOUND_PAGE_PATH}
                        key={NOT_FOUND_PAGE}
                        component={PageNotFound404}
                     />
                  </Switch>
               </Router>
               <ToastContainer
                  position='top-right'
                  autoClose={1000}
                  hideProgressBar={true}
                  newestOnTop={true}
                  closeOnClick
                  rtl={false}
                  draggable
                  pauseOnHover
               />
            </>
         )}
      </Provider>
   )

   render(): JSX.Element {
      const { t } = this.props
      if (this.state.error) {
         return (
            <FailureViewWithDottedBackground>
               <FailureView
                  apiStatus={SERVER_ERROR_CODE} // It has to be constantly in Server failed state
                  onRetry={this.onClickRetry}
                  failureText={t('common.failureView.failureText')}
               />
            </FailureViewWithDottedBackground>
         )
      }
      switch (this.isMobile) {
         case true:
            return <MobileLayout />
         default:
            return this.renderRouteComponent()
      }
   }
}

export default withTranslation()(App)
