import { action } from 'mobx'

import { LevelDetailsType, MatchInfoLevelDetailsType } from '../../types'

class LevelDetails {
   levelNumber!: number
   levelId!: number
   levelName: string

   constructor() {
      this.levelName = ''
   }

   @action.bound
   setTournamentLevelDetailsFromAPI(
      tournamentLevelDetails: LevelDetailsType
   ): void {
      if (tournamentLevelDetails !== null) {
         const {
            level_no: levelNumber,
            level_id: levelId,
            level_name: levelName
         } = tournamentLevelDetails
         this.levelNumber = levelNumber
         this.levelId = levelId
         this.levelName = levelName
      }
   }

   @action.bound
   setLevelDetailsFromMatchInfoAPI(
      levelDetails: MatchInfoLevelDetailsType
   ): void {
      this.levelNumber = levelDetails.level_no
      this.levelName = levelDetails.level_group_name_enum
   }
}

export default LevelDetails
