import BaseMatch from '../BaseMatch'
import { Resource, MatchDetailType } from '../../types'
import { ResourceEnums } from '../../../../common/constants/ResourceConstants'
import { RoundType } from '../../../constants/MatchConfigConstants'
import { getResourceItems } from '../../../Utils/MatchUtils'

class TournamentMatch extends BaseMatch {
   roundType?: string
   roundDisplayName?: string
   resource: Resource
   tournamentId?: number
   rank: number

   constructor(matchObj: MatchDetailType, matchesService) {
      super(matchObj, matchesService)
      this.tournamentId =
         matchObj.tournament_match_details?.tournament_type_details?.tournament_id
      this.roundType = matchObj.tournament_match_details?.round_type
      this.rank = matchObj.tournament_match_details?.participant_lb_rank || -1
      this.resource = this.getiBCRatingResource()
      this.roundDisplayName = this.getRoundDisplayNameByRoundType(
         this.roundType,
         matchObj.tournament_match_details?.round_display_name
      )
   }

   getiBCRatingResource(): Resource {
      const defaultResource = {
         display_name: '',
         value: -1,
         pic: '',
         thumbnail: '',
         name: '',
         description: ''
      }
      let resource
      const matchUserDetails = this.getSelfUserMatchDetails()
      if (matchUserDetails.gameStats.totalPrize) {
         const userRewardResource = getResourceItems(
            matchUserDetails.gameStats.totalPrize
         )
         resource = userRewardResource.find(
            item => item.name === ResourceEnums.FAN_BASE
         )
      }
      return resource === undefined ? defaultResource : resource
   }

   getRoundDisplayNameByRoundType = (
      roundType: any,
      roundDisplayName: string | undefined
   ) => {
      const leaderboardText = RoundType.LEADERBOARD.toString()
      return roundType === leaderboardText ? leaderboardText : roundDisplayName
   }
}

export default TournamentMatch
