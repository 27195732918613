import { GameMode } from '../../common/constants/GameModeContants'

export enum BowlingLevelEnum {
   EASY,
   MEDIUM,
   HARD
}

export enum MatchConfigType {
   OVERS = 'OVERS',
   WICKETS = 'WICKETS',
   WEATHER = 'WEATHER',
   STADIUM = 'STADIUM'
}

export enum PitchMarkerOnOff {
   ON = 'ON',
   OFF = 'OFF'
}

const COMMON_BG =
   'https://vrcricket.s3.ap-south-1.amazonaws.com/web/matches/common-bg.png'

export const GameModeConfigUrls = {
   commonBg: COMMON_BG
}

export enum MatchType {
   ALL = 'ALL',
   QUICK_PLAY = 'QUICK_PLAY',
   SCENARIO_CHALLENGE = 'SCENARIO_CHALLENGE',
   TOURNAMENT = 'TOURNAMENT',
   SPECIAL_CHALLENGE = 'SPECIAL_CHALLENGE'
}

export enum SpecialChallengeType {
   SURVIVAL_CHALLENGE = 'SURVIVAL_CHALLENGE',
   STEAK_CHALLENGE = 'SCENARIO',
   WAGON_WHEEL_CHALLENGE = 'WAGON_WHEEL_CHALLENGE'
}

export enum MatchStatus {
   YET_TO_START = 'YET_TO_START',
   IN_PROGRESS = 'IN_PROGRESS',
   COMPLETED = 'COMPLETED'
}
export enum MatchTypeDropDownValue {
   ALL = 'ALL',
   QUICK_PLAY = 'QUICK_PLAY',
   SPECIAL_CHALLENGE = 'SPECIAL_CHALLENGE',
   TOURNAMENT = 'TOURNAMENT'
}

//#region  TOURNAMENT
export enum TournamentCategory {
   MULTI_ROUND_LB_TYPE
}

export enum RoundType {
   LEADERBOARD = 'LEADERBOARD',
   KNOCK_OUT = 'KNOCK_OUT'
}

export enum RoundResult {
   WIN = 'WIN',
   LOSE = 'LOSE',
   PENDING = 'PENDING',
   DRAW = 'v'
}

//#endregion

export enum BallStatus {
   BOWLED = 'BOWLED',
   HIT_WICKET = 'HIT_WICKET',
   SIX = 'SIX',
   FOUR = 'FOUR',
   CAUGHT = 'CAUGHT',
   WIDE = 'WIDE',
   WIDE_LINE = 'WIDE_LINE',
   WIDE_HEIGHT = 'WIDE_HEIGHT',
   CALCULATE_SCORE = 'CALCULATE_SCORE',
   MISS = 'MISS',
   NO_BALL = 'NO_BALL'
}

export const gameModeConfigData = {
   [GameMode.M1]: {
      pitchMarker: true,
      batShield: true
   },
   [GameMode.M2]: {
      pitchMarker: false,
      batShield: false
   },
   [GameMode.M3]: {
      pitchMarker: true,
      batShield: true
   },
   [GameMode.M4]: {
      pitchMarker: false,
      batShield: false
   },
   [GameMode.M5]: {
      pitchMarker: true,
      batShield: true
   },
   [GameMode.M6]: {
      pitchMarker: true,
      batShield: true
   }
}

export const anglesList = [0, 90, 150, 180, 210, 270, 360]

export const groundRadius = 220

export const userProfileGroundRadius = 110
