import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'

export const OvalItem = styled.div`
   width: 4px;
   height: 4px;
   background-color: ${Colors.whiteTwoWithoutGradient};
   border-radius: 4px;
`

export const BaseOvalContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   height: 100%;
`

export const LeftOvalContainer = styled(BaseOvalContainer)`
   margin-left: 15.21%;
`

export const RightOvalContainer = styled(BaseOvalContainer)`
   margin-left: auto;
   margin-right: 20.32%;
`
