import { MatchWithParticipantDetails, MatchesMap } from '../../types'

class KnockoutMatch {
   parentMatch: MatchWithParticipantDetails
   childMatches: MatchWithParticipantDetails[]

   constructor(knockOutMatch: MatchesMap, tournamentMatches) {
      this.parentMatch = tournamentMatches[knockOutMatch.match_id]
      this.childMatches = knockOutMatch.dependent_match_ids.map(
         (match, index) => tournamentMatches[match]
      )
   }
}
export default KnockoutMatch
