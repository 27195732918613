import styled from 'styled-components'
import colors from '../../../common/themes/Colors'
import { Typo18LightBlueGrayBarlowBoldItalic } from '../../../common/styleGuide/Typos'
import { tablet } from '../../../common/utils/MixinUtils'

export const RoundThreeBackgroundBoxContainer = styled.div`
   background-color: ${colors.charcoalGrey};
   top: 91px;
   left: 576px;
   bottom: 0px;
   position: absolute;
   ${tablet} {
      left: 568px;
   }
`

export const RoundThreeBackgroundBoxHeader = styled.div`
   width: 338px;
   background-color: ${colors.oceanBlue};
   display: flex;
   align-items: center;
   padding: 15px 0px 11px 33px;
`

export const RoundThreeHeadingText = styled(
   Typo18LightBlueGrayBarlowBoldItalic
)``
