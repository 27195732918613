import { observable, action } from 'mobx'

import { GameDetailsType } from '../../types'

import CurrentStadiumDetails from '../CurrentStadiumDetails'

class GameDetails {
   @observable roundWiseStadiumDetails: Map<number, CurrentStadiumDetails>
   stadiumDetails!: CurrentStadiumDetails
   pitchType: string
   bowlingMode: string
   overs: number

   constructor() {
      this.pitchType = ''
      this.bowlingMode = ''
      this.overs = 0
      this.roundWiseStadiumDetails = observable.map({})
   }

   @action.bound
   setGameDetailsFromAPI(gameDetails: GameDetailsType): void {
      const {
         stadium_details,
         pitch_type,
         bowling_mode,
         overs,
         round_wise_stadium_details
      } = gameDetails
      this.stadiumDetails = new CurrentStadiumDetails(stadium_details)
      if (round_wise_stadium_details) {
         round_wise_stadium_details.forEach(eachRoundStadiumDetails =>
            this.roundWiseStadiumDetails.set(
               eachRoundStadiumDetails.round_no,
               new CurrentStadiumDetails(
                  eachRoundStadiumDetails.stadium_details
               )
            )
         )
      }
      this.pitchType = pitch_type
      this.bowlingMode = bowling_mode
      this.overs = overs
   }

   @action.bound
   getStadiumDetailsByRoundNumber(roundNumber: number): CurrentStadiumDetails {
      const stadiumDetails:
         | CurrentStadiumDetails
         | undefined = this.roundWiseStadiumDetails.get(roundNumber)
      if (stadiumDetails) {
         return stadiumDetails
      }
      return this.stadiumDetails
   }
}

export default GameDetails
