import Colors from '../../common/themes/Colors'
import { BallStatus, BowlingLevelEnum } from '../constants/MatchConfigConstants'
import {
   RewardsResourcesType,
   TotalPrizeType,
   MatchScoreDetailsType
} from '../stores/types'
import { userRewardsResource } from '../constants/MatchPrizesConstants'

export const GetBallScoreDisplayTextAndBgAndTextColor = (
   ballStatus: string,
   ballSummary: string
) => {
   let bgColor: string = Colors.lightBlueGrey
   let textColor: string = Colors.darkIndigo
   let scoreText = ''
   const { byes, normal_score }: MatchScoreDetailsType = JSON.parse(
      ballSummary
   ).score_details
   const nbScore = normal_score + byes
   switch (ballStatus) {
      case BallStatus.CALCULATE_SCORE:
         switch (normal_score) {
            case 4:
               bgColor = Colors.darkSkyBlue
               textColor = Colors.darkIndigo
               break
            case 6:
               bgColor = Colors.oceanBlue
               textColor = Colors.paleBlue
               break
            default:
               bgColor = Colors.lightBlueGrey
               textColor = Colors.darkIndigo
               break
         }
         scoreText = `${normal_score}`
         break
      case BallStatus.NO_BALL:
         scoreText = `${nbScore ? nbScore : ''}nb`
         bgColor = Colors.lightBlueGrey
         textColor = Colors.darkIndigo
         break
      case BallStatus.WIDE:
      case BallStatus.WIDE_LINE:
      case BallStatus.WIDE_HEIGHT:
         scoreText = `${byes ? byes : ''}wd`
         bgColor = Colors.lightBlueGrey
         textColor = Colors.darkIndigo
         break
      case BallStatus.MISS:
         scoreText = `${nbScore}${nbScore ? 'b' : ''}`
         bgColor = Colors.lightBlueGrey
         textColor = Colors.darkIndigo
         break

      case BallStatus.BOWLED:
      case BallStatus.HIT_WICKET:
         bgColor = Colors.redBrown
         textColor = Colors.blush
         scoreText = 'W'
         break
      case BallStatus.CAUGHT:
         bgColor = Colors.redBrown
         textColor = Colors.blush
         scoreText = 'C'
         break
      default:
         return { bgColor, textColor, scoreText }
   }
   return {
      bgColor,
      textColor,
      scoreText
   }
}

export const GetScoreBgAndTextColor = (score: number, ballStatus: string) => {
   switch (ballStatus) {
      case BallStatus.CALCULATE_SCORE:
         if (score === 4)
            return { bgColor: Colors.darkSkyBlue, textColor: Colors.darkIndigo }
         else if (score === 6)
            return { bgColor: Colors.oceanBlue, textColor: Colors.paleBlue }
         return { bgColor: Colors.lightBlueGrey, textColor: Colors.darkIndigo }
      case BallStatus.BOWLED:
      case BallStatus.CAUGHT:
      case BallStatus.HIT_WICKET:
         return { bgColor: Colors.redBrown, textColor: Colors.blush }
      default:
         return { bgColor: Colors.lightBlueGrey, textColor: Colors.darkIndigo }
   }
}

export const GetDisplayScore = (score: number, ballStatus: string): string => {
   switch (ballStatus) {
      case BallStatus.WIDE:
      case BallStatus.WIDE_LINE:
      case BallStatus.WIDE_HEIGHT:
         return `${score > 0 ? score : ''}Wd`
      case BallStatus.BOWLED:
      case BallStatus.HIT_WICKET:
         return 'W'
      case BallStatus.CAUGHT:
         return 'C'
      case BallStatus.NO_BALL:
         return `${score}Nb`
      default:
         return `${score}`
   }
}

export const getBowlerLevelText = (level: number): string => {
   if (level >= 0 && level < 5) {
      return BowlingLevelEnum[BowlingLevelEnum.EASY]
   } else if (level < 7) {
      return BowlingLevelEnum[BowlingLevelEnum.MEDIUM]
   } else if (level > 6) {
      return BowlingLevelEnum[BowlingLevelEnum.HARD]
   }
   return BowlingLevelEnum[BowlingLevelEnum.EASY]
}

export const getResourceItems = (
   userRewards: TotalPrizeType
): Array<RewardsResourcesType> => {
   const rewardMode = userRewards.reward_mode
   if (rewardMode === userRewardsResource.resource) {
      return userRewards.resources
   } else if (rewardMode === userRewardsResource.treasure) {
      const treasure = userRewards.treasure
      return treasure?.resource_details
   }
   const resourceDetails = [
      {
         name: '',
         pic: '',
         display_name: '',
         value: 0,
         thumbnail: '',
         description: ''
      }
   ]
   return resourceDetails
}
