import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
   FacebookShareButton,
   LinkedinShareButton,
   TwitterShareButton,
   WhatsappShareButton,
   RedditShareButton,
   FacebookIcon,
   TwitterIcon,
   LinkedinIcon,
   WhatsappIcon,
   RedditIcon
} from 'react-share'

import { ShareWithFriendsPopupContainer } from './styledComponent'
import './index.css'

type ShareWithFriendsProps = {
   url: string
}

const ShareWithFriendsPopup = (props: ShareWithFriendsProps): ReactElement => {
   const { url } = props
   const { t } = useTranslation()
   const title = t('shareUrlText')
   return (
      <ShareWithFriendsPopupContainer>
         <div className='Demo__some-network'>
            <FacebookShareButton
               url={url}
               quote={title}
               className='Demo__some-network__share-button'
               hashtag={''}
               translate={'yes'}
            >
               <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
         </div>

         <div className='Demo__some-network twitter-button-wrapper'>
            <TwitterShareButton
               url={url}
               title={title}
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <div className='Demo__some-network__share-count'>&nbsp;</div>
         </div>

         <div className='Demo__some-network whatsapp-button-wrapper'>
            <WhatsappShareButton
               url={url}
               title={title}
               separator=':: '
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <div className='Demo__some-network__share-count'>&nbsp;</div>
         </div>

         <div className='Demo__some-network'>
            <LinkedinShareButton
               url={url}
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
         </div>

         <div className='Demo__some-network'>
            <RedditShareButton
               url={url}
               title={title}
               windowWidth={660}
               windowHeight={460}
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <RedditIcon size={32} round={true} />
            </RedditShareButton>
         </div>

         {/* <div className='Demo__some-network telegram-button-wrapper'>
            <TelegramShareButton
               url={url}
               title={title}
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <TelegramIcon size={32} round={true} />
            </TelegramShareButton>

            <div className='Demo__some-network__share-count'>&nbsp;</div>
         </div>

         <div className='Demo__some-network'>
            <PinterestShareButton
               url={url}
               media={url}
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <PinterestIcon size={32} round={true} />
            </PinterestShareButton>
         </div>

         <div className='Demo__some-network'>
            <FacebookMessengerShareButton
               url={url}
               appId='521270401588372'
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>
         </div>

         <div className='Demo__some-network'>
            <EmailShareButton
               url={url}
               subject={title}
               body='body'
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <EmailIcon size={32} round={true} />
            </EmailShareButton>
         </div>
         <div className='Demo__some-network'>
            <ViberShareButton
               url={url}
               title={title}
               translate={'yes'}
               className='Demo__some-network__share-button'
            >
               <ViberIcon size={32} round={true} />
            </ViberShareButton>
         </div>

         <div className='Demo__some-network'>
            <InstapaperShareButton
               url={url}
               title={title}
               className='Demo__some-network__share-button'
               translate={'yes'}
            >
               <InstapaperIcon size={32} round={true} />
            </InstapaperShareButton>
         </div> */}
      </ShareWithFriendsPopupContainer>
   )
}

export default ShareWithFriendsPopup
