import React, { ReactElement } from 'react'

import { ProfileNameContainer, ProfilePic, NameText } from './styledComponents'

interface UserNameAndPicProps {
   url: string
   name: string
}

const UserNameAndPic = (props: UserNameAndPicProps): ReactElement => (
   <ProfileNameContainer>
      <ProfilePic src={props.url}></ProfilePic>
      <NameText>{props.name}</NameText>
   </ProfileNameContainer>
)

export default UserNameAndPic
