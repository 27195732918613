import styled from 'styled-components'
import { tablet, mobile } from '../../../common/utils/MixinUtils'

export const TabsWraper = styled.div`
   display: flex;
   justify-content: center;
   width: 100%;
   ${tablet} {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
   }
   ${mobile} {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
   }
`

export const TabsListWrapper = styled.div`
   display: flex;
   justify-content: center !important;
   border-bottom: 0px !important;
`

export const TabsListsBarWrapper = styled.div`
   width: 100%;
   display: flex;
   justify-content: center !important;
`
