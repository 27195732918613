import { ApisauceInstance, create } from 'apisauce'
import Config from '../../../common/constants/EnvironmentConstants'

import {
   GetPlayerGlobalLeaderboardAPIRequest,
   GetPlayerGlobalLeaderboardAPIResponse,
   GetTournamentsPointsGenericLeaderboardAPIRequest,
   GetTournamentsPointsGenericLeaderboardAPIResponse
} from '../../stores/types'

import { apiMethods } from '../../../common/constants/APIConstants'

import endpoints from '../../../common/services/endpoints'
import GlobalLeaderboardService from '.'

class GlobalLeaderboardAPI implements GlobalLeaderboardService {
   api: ApisauceInstance
   networkCallWithApisauce: Function

   constructor(networkCallWithApisauce: Function) {
      this.api = create({
         baseURL: Config.BASE_URL
      })
      this.networkCallWithApisauce = networkCallWithApisauce
   }

   async getPlayerGlobalLeaderboardAPI(
      request: GetPlayerGlobalLeaderboardAPIRequest
   ): Promise<GetPlayerGlobalLeaderboardAPIResponse> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getPlayerGlobalLeaderboard,
         request,
         apiMethods.post
      )
   }

   async getTournamentsPointsGenericLeaderboardAPI(
      request: GetTournamentsPointsGenericLeaderboardAPIRequest
   ): Promise<GetTournamentsPointsGenericLeaderboardAPIResponse> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getTournamentsPointsGenericLeaderboard,
         request,
         apiMethods.post
      )
   }
}

export default GlobalLeaderboardAPI
