import React from 'react'

interface Props {
   width: number
   height: number
}

const DotBallsIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 27 28'
   >
      <defs>
         <filter id='kiozgesp5a'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#kiozgesp5a)'
         transform='translate(0 -7)'
      >
         <g fill='#FFF'>
            <path
               d='M16.034 7.39a7.305 7.305 0 00-2.425 5.451v.024L2.111 28.819a14.069 14.069 0 01-2.11-7.428C0 13.584 6.33 7.258 14.132 7.258c.644 0 1.28.042 1.901.131'
               opacity='0.25'
            ></path>
            <path d='M17.87 19.513L7.676 33.655a15.128 15.128 0 01-1.402-.631l10.297-14.287c.402.303.836.566 1.299.776m-3.22-2.902L4.065 31.304a13.247 13.247 0 01-.85-.943l10.83-15.025c.159.448.364.874.607 1.275'></path>
            <path
               d='M26.868 20.456c0 7.807-6.33 14.133-14.137 14.133a13.96 13.96 0 01-3.247-.38l10.185-14.132c.42.08.85.117 1.294.117a7.317 7.317 0 005.61-2.602c.192.925.295 1.883.295 2.864'
               opacity='0.25'
            ></path>
            <path d='M26.804 12.84a5.84 5.84 0 11-11.68 0 5.84 5.84 0 0111.68 0'></path>
         </g>
      </g>
   </svg>
)

DotBallsIcon.defaultProps = {
   width: 27,
   height: 28
}
export default DotBallsIcon
