import styled from 'styled-components'

import { leaderBoardConstants } from '../../../constants/leaderBoardConstants'

import { Typo11DarkRumBoldItalicCapital } from '../../../../common/styleGuide/Typos'
import colors from '../../../../common/themes/Colors'

interface ColorContainerProps {
   bgColor: string
}

export const PlayerCardContainer = styled.div`
   width: 90px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: ${colors.linerGradients
      .youWonIbcRatingPointsBottomLinearGradient};
   transform: skew(-${leaderBoardConstants.tenDegrees}deg);
   padding: 4px;
`

export const PlayerNameText = styled(Typo11DarkRumBoldItalicCapital)`
   transform: skew(${leaderBoardConstants.tenDegrees}deg);
   padding: 1px 0px;
`

export const ColorContainer = styled.div<ColorContainerProps>`
   width: 13px;
   height: 13px;
   margin: 0px 4px 0 0;
   object-fit: contain;
   background-color: ${props => props.bgColor};
   border-radius: 50%;
   transform: skew(${leaderBoardConstants.tenDegrees}deg);
`
