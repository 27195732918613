import React from 'react'

interface Props {
   width: number
   height: number
}

const FoursIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 49 26'
   >
      <defs>
         <path id='tmw3nl4x9b' d='M0 .349h24.254v3.972H0z'></path>
         <path id='hligyep6td' d='M.244.208h15.601v19.355H.244z'></path>
         <path id='btufyn2g7f' d='M.368.351h15.604v19.358H.368z'></path>
         <filter id='e5fs1linwa'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#e5fs1linwa)'
         transform='translate(0 -8)'
      >
         <path
            fill='#FFF'
            d='M24.607 15.97c-.182.426-.344.86-.484 1.307l-6.811.6 7.295-1.906zm5.269 16.5l-9.726.262 7.972-1.546c.547.475 1.134.904 1.754 1.284m-6.182-13.304c-.09.596-.145 1.202-.15 1.817L8.18 22.207l15.514-3.041zm2.376 9.793l-14.632 1.165 13.68-2.684c.28.529.6 1.035.953 1.519'
         ></path>
         <g transform='translate(0 23.309)'>
            <mask id='0zk1b8vhkc' fill='#fff'>
               <use xlinkHref='#tmw3nl4x9b'></use>
            </mask>
            <path
               fill='#FFF'
               d='M24.254 2.161L0 4.321 23.775.35c.113.62.276 1.225.479 1.812'
               mask='url(#0zk1b8vhkc)'
            ></path>
         </g>
         <g transform='translate(24.323 8.212)'>
            <mask id='cxxmwdgdne' fill='#fff'>
               <use xlinkHref='#hligyep6td'></use>
            </mask>
            <path
               fill='#FFF'
               d='M12.93.207C5.925.207.245 5.887.245 12.894c0 2.447.694 4.732 1.895 6.67L15.845.547c-.937-.22-1.911-.34-2.914-.34'
               mask='url(#cxxmwdgdne)'
               opacity='0.25'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M44.863 11.199l-14.667 20.35c.407.21.826.399 1.258.564l14.344-19.9a12.817 12.817 0 00-.935-1.014m-3.071-1.94L27.449 29.157c.242.294.497.576.764.847l14.625-20.29c-.34-.167-.69-.319-1.046-.455'
         ></path>
         <g transform='translate(32.71 13.244)'>
            <mask id='o3fg9ugzxg' fill='#fff'>
               <use xlinkHref='#btufyn2g7f'></use>
            </mask>
            <path
               fill='#FFF'
               d='M.368 19.368c.937.22 1.913.341 2.917.341 7.007 0 12.687-5.679 12.687-12.686 0-2.448-.695-4.734-1.897-6.672L.368 19.368z'
               mask='url(#o3fg9ugzxg)'
               opacity='0.25'
            ></path>
         </g>
      </g>
   </svg>
)

FoursIcon.defaultProps = {
   width: 49,
   height: 26
}
export default FoursIcon
