import styled from 'styled-components'
import tw from 'twin.macro'

import {
   Typo24PaleGreyBarlowSemiBoldItalic,
   Typo14BattleShipGreyBarlowSemiBoldItalic
} from '../../../common/styleGuide/Typos'

export const RankNumber = styled(Typo24PaleGreyBarlowSemiBoldItalic)`
   text-align: right;
`

export const UserRankContainer = styled.div`
   ${tw`flex flex-col`}
`

export const RankText = styled(Typo14BattleShipGreyBarlowSemiBoldItalic)`
   letter-spacing: 0.44px;
   text-transform: uppercase;
`
