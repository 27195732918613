import React from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import { Typo24WhiteBarlowBoldItalicCapital } from '../../styleGuide/Typos'

import { GameMode } from '../../constants/GameModeContants'
import {
   CommonDarkBg,
   NovoiceBg,
   AmetureBg,
   ProBg,
   SemiProBg,
   WorldClassBg,
   UltimateBg,
   DisplayNameWrapper,
   UltimateBaseBg,
   WorldClassBaseBg,
   RectangularOverlay
} from './styledComponents'

interface BgWrapperProps {
   children: GameMode
}

const UltimateBgWrapper = (props: BgWrapperProps) => (
   <UltimateBaseBg>
      <UltimateBg>
         <RectangularOverlay>{props.children}</RectangularOverlay>
      </UltimateBg>
   </UltimateBaseBg>
)

const WorldClassBgWrapper = (props: BgWrapperProps) => (
   <WorldClassBaseBg>
      <WorldClassBg>
         <RectangularOverlay>{props.children}</RectangularOverlay>
      </WorldClassBg>
   </WorldClassBaseBg>
)

interface GameModeTitleAndBgProps {
   gameMode: string
   displayName?: string
}

const bgConfigData = (gameMode: string, t) => {
   switch (gameMode) {
      case GameMode[GameMode.M2]:
         return {
            Bg: AmetureBg,
            title: t('gameMode.m2')
         }
      case GameMode[GameMode.M3]:
         return {
            Bg: SemiProBg,
            title: t('gameMode.m3')
         }
      case GameMode[GameMode.M4]:
         return {
            Bg: ProBg,
            title: t('gameMode.m4')
         }
      case GameMode[GameMode.M5]:
         return {
            Bg: WorldClassBgWrapper,
            title: t('gameMode.m5')
         }
      case GameMode[GameMode.M6]:
         return {
            Bg: UltimateBgWrapper,
            title: t('gameMode.m6')
         }
      case GameMode[GameMode.M1]:
      default:
         return {
            Bg: NovoiceBg,
            title: t('gameMode.m1')
         }
   }
}

const GameModeTitleAndBg = (props: GameModeTitleAndBgProps) => {
   const { t } = useTranslation()
   const { gameMode, displayName } = props
   const { Bg, title } = bgConfigData(gameMode, t)

   return (
      <CommonDarkBg>
         <Bg>
            <DisplayNameWrapper>
               <Typo24WhiteBarlowBoldItalicCapital>
                  {displayName || title}
               </Typo24WhiteBarlowBoldItalicCapital>
            </DisplayNameWrapper>
         </Bg>
      </CommonDarkBg>
   )
}

export default GameModeTitleAndBg
