import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import {
   Typo14CoolGreyBarlowBoldItalicCapital,
   Typo19Point6WhiteBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

import { BowlingLevelEnum } from '../../constants/MatchConfigConstants'
import MatchCardBowlingLevelBg from '../../backgrounds/MatchCardBowlingLevel'
import { BowllingLevelItem } from '../../stores/types'

import {
   ContentWrapper,
   BowlingLevelBgWrapper,
   BowlingLevelTextWrapper
} from './styledComponents'
interface MatchCardBowlingLevelProps {
   bowlingLevel: BowllingLevelItem
}

const bowlerLevelText = (level, t) => {
   switch (level) {
      case BowlingLevelEnum[BowlingLevelEnum.EASY]:
         return t('bowlingLevel.easy')
      case BowlingLevelEnum[BowlingLevelEnum.MEDIUM]:
         return t('bowlingLevel.medium')
      case BowlingLevelEnum[BowlingLevelEnum.HARD]:
         return t('bowlingLevel.hard')
   }
   return t('bowlingLevel.easy')
}

const MatchCardBowlingLevel = (
   props: MatchCardBowlingLevelProps
): ReactElement => {
   const { t } = useTranslation()
   const { bowlingLevel } = props
   return (
      <ContentWrapper>
         <BowlingLevelBgWrapper>
            <MatchCardBowlingLevelBg level={bowlingLevel.level} />
            <BowlingLevelTextWrapper>
               <Typo19Point6WhiteBarlowBoldItalicCapital>
                  {bowlerLevelText(bowlingLevel.displayName, t)}
               </Typo19Point6WhiteBarlowBoldItalicCapital>
            </BowlingLevelTextWrapper>
         </BowlingLevelBgWrapper>
         <Typo14CoolGreyBarlowBoldItalicCapital>
            {t('matches.bowlingLevel')}
         </Typo14CoolGreyBarlowBoldItalicCapital>
      </ContentWrapper>
   )
}

export default MatchCardBowlingLevel
