import { observable, action } from 'mobx'

import { Tokens } from '../../types'

class AuthTokens {
   @observable accessToken!: string
   @observable idToken!: string
   @observable expiresIn!: string
   @observable refreshToken!: string
   @observable userId?: string

   constructor() {
      this.initAuthTokens()
   }

   @action.bound
   initAuthTokens() {
      this.accessToken = ''
      this.idToken = ''
      this.expiresIn = ''
      this.refreshToken = ''
      this.userId = ''
   }

   @action.bound
   setAuthTokens(authTokens: Tokens) {
      const {
         access_token,
         refresh_token,
         expires_in,
         user_id,
         id_token
      } = authTokens
      this.accessToken = access_token
      this.refreshToken = refresh_token
      this.userId = user_id
      this.expiresIn = expires_in
      this.idToken = id_token
   }

   @action.bound
   setAccessToken(accessToken: string): void {
      this.accessToken = accessToken
   }

   @action.bound
   setIdToken(idToken: string): void {
      this.idToken = idToken
   }

   @action.bound
   setUserID(userId: string): void {
      this.userId = userId
   }
}

export default AuthTokens
