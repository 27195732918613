import { observable, action } from 'mobx'
import { APIStatus } from '@ib/api-constants'
import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'

import { StatsItem } from '../../../../common/stores/types'
import { getStats } from '../../../../common/utils/ProfileUtils'

import MatchesService from '../../../services/MatchesService'
import { GetMatcheOverviewResponse } from '../../types'

class MatchOverview {
   @observable getMatchOverviewAPIStatus!: APIStatus
   @observable getMatchOverviewAPIError: any
   @observable stats: Array<StatsItem>
   @observable scoreRanges: Array<number>
   matchesService: MatchesService

   constructor(matchesService) {
      this.matchesService = matchesService
      this.stats = []
      this.scoreRanges = []
   }

   @action.bound
   private setGetMatchOverviewStatus(status: APIStatus): void {
      this.getMatchOverviewAPIStatus = status
   }

   @action.bound
   private setGetMatchOverviewError(error: any): void {
      this.getMatchOverviewAPIError = error
   }

   @action.bound
   private setGetMatchOverviewResponse(
      response: GetMatcheOverviewResponse
   ): void {
      const overview = response as GetMatcheOverviewResponse
      this.stats = getStats(overview.stats)
      this.scoreRanges = overview.score_ranges
   }

   getMatchesOverview(
      matchId: string,
      onSuccess = (response: GetMatcheOverviewResponse) => {}
   ): Promise<GetMatcheOverviewResponse> {
      const request = {
         matchId: matchId
      }
      const getMatchOverviewPromise = this.matchesService.getMatchOverview(
         request
      )
      return bindPromiseWithOnSuccess(getMatchOverviewPromise)
         .to(this.setGetMatchOverviewStatus, response => {
            const responseOfData = response as GetMatcheOverviewResponse
            this.setGetMatchOverviewResponse(responseOfData)
            onSuccess(responseOfData)
         })
         .catch((error: any) => {
            this.setGetMatchOverviewError(error)
         }) as Promise<GetMatcheOverviewResponse>
   }
}
export default MatchOverview
