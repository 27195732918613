import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'
import { Typo16BattleShipGreyBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'
import ImageComponent from '../../../common/components/BaseImageComponent'

export const TitleText = styled(Typo16BattleShipGreyBarlowSemiBoldCapital)``

export const RankText = styled(TitleText)`
   padding-left: 124px;
`

export const NameText = styled(TitleText)`
   padding-left: 144px;
`

export const LeaderboardSuperItemText = styled(TitleText)`
   padding-left: 10px;
   padding-right: 80px;
   padding-top: 1px;
   padding-bottom: 4px;
   text-align:center;
`
export const RankandNameTextDiv = styled.div`
   display: inline-block;
   flex-direction: row;
   max-width: 356px;
`

export const LeaderboardSuperItemDiv = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items:center;
`

export const LeaderboardHeaderDiv = styled.div`
   display: flex;
   flex-direction: row;
   max-width: 920px;
   height: 67px;
   align-items: center;
   justify-content: space-between;
   background: ${Colors.darkTwo};
`
export const ResourceIcon = styled(ImageComponent)`
   width: 30px;
   height: 35px;
   object-fit: contain;
`
