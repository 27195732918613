import React from 'react'
interface Props {
   width: number
   height: number
   className?: string
}

const WrongIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 25 25'
      className={props.className}
   >
      <defs>
         <linearGradient
            id='prefix__wrongicon__b'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#F5515F'></stop>
            <stop offset='100%' stopColor='#9F041B'></stop>
         </linearGradient>
         <filter
            id='prefix__a'
            width='150.9%'
            height='150.9%'
            x='-25.4%'
            y='-25.4%'
            filterUnits='objectBoundingBox'
         >
            <feOffset
               dy='1'
               in='SourceAlpha'
               result='shadowOffsetOuter1'
            ></feOffset>
            <feGaussianBlur
               in='shadowOffsetOuter1'
               result='shadowBlurOuter1'
               stdDeviation='1'
            ></feGaussianBlur>
            <feColorMatrix
               in='shadowBlurOuter1'
               result='shadowMatrixOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            ></feColorMatrix>
            <feMerge>
               <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
               <feMergeNode in='SourceGraphic'></feMergeNode>
            </feMerge>
         </filter>
      </defs>
      <g fill='none' filter='url(#prefix__a)'>
         <path
            fill='url(#prefix__wrongicon__b)'
            d='M12.5 1.389c-5.753 0-10.417 4.664-10.417 10.417S6.747 22.222 12.5 22.222s10.417-4.663 10.417-10.416S18.253 1.389 12.5 1.389z'
         ></path>
         <path
            fill='#FFF'
            d='M13.79 11.806l2.826-2.826a.174.174 0 000-.246L15.572 7.69c-.033-.033-.077-.051-.123-.051s-.09.018-.123.05L12.5 10.517 9.674 7.69a.18.18 0 00-.246 0L8.384 8.734a.174.174 0 000 .246l2.826 2.826-2.826 2.826a.173.173 0 000 .245l1.044 1.044c.033.033.077.051.123.051s.09-.018.123-.05l2.826-2.827 2.826 2.826c.033.033.077.051.123.051s.09-.018.123-.05l1.044-1.045a.173.173 0 000-.245l-2.826-2.826z'
         ></path>
      </g>
   </svg>
)

WrongIcon.defaultProps = {
   width: 23,
   height: 23
}

export default WrongIcon
