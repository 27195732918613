import React, { ReactElement } from 'react'

import SuperRunsResource from '../SuperRunsResource'
import FanBaseResource from '../FanBaseResource'
import ResourceItemCmp from '../ResourceItem'
import CommonResourceItemCmp from '../CommonResource'
import OversLeft from '../OversLeft'
import { ResourceEnums } from '../../../constants/ResourceConstants'
import { ResourceDetail } from '../../../stores/types'

export interface ResourceItemWrapperProps {
   resource: ResourceDetail
   fanBaseATH: number
}

const ResourceItemWrapper = (props: ResourceItemWrapperProps): ReactElement => {
   const { resource, fanBaseATH } = props
   switch (resource.name) {
      case ResourceEnums.SUPER_RUN:
         return <SuperRunsResource resource={resource}></SuperRunsResource>
      case ResourceEnums.FAN_BASE:
         return <FanBaseResource resource={resource} fanBaseATH={fanBaseATH} />
      case ResourceEnums.IBC_RATING:
      case ResourceEnums.GOLD_TROPHY:
         return <ResourceItemCmp resource={resource} />
      case ResourceEnums.COIN:
      case ResourceEnums.GEM:
      case ResourceEnums.SKILL:
         return <CommonResourceItemCmp resource={resource} />
      case ResourceEnums.OVERS_LEFT:
         return <OversLeft resource={resource} />
      default:
         return <div />
   }
}

export default ResourceItemWrapper
