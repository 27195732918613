import React from 'react'

interface IbcRatingIconProps {
   width: number
   height: number
}

const IbcRatingIcon = (props: IbcRatingIconProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 37 45'
      {...props}
   >
      <defs>
         <linearGradient
            id='prefix_ibcrating_a'
            x1='100%'
            x2='0%'
            y1='50%'
            y2='50%'
         >
            <stop offset='0%' stopColor='#DCB72F' />
            <stop offset='100%' stopColor='#D39F0A' />
         </linearGradient>
         <linearGradient
            id='prefix_ibcrating_b'
            x1='30.76%'
            x2='53.282%'
            y1='63.626%'
            y2='35.313%'
         >
            <stop offset='0%' stopColor='#D47D00' />
            <stop offset='18.315%' stopColor='#F7B400' />
            <stop offset='45.337%' stopColor='#FFC000' />
            <stop offset='49.081%' stopColor='#FFD027' />
            <stop offset='100%' stopColor='#FFF137' />
         </linearGradient>
         <linearGradient
            id='prefix_ibcrating_e'
            x1='50%'
            x2='50%'
            y1='0%'
            y2='100%'
         >
            <stop offset='0%' stopColor='#FFEB34' />
            <stop offset='100%' stopColor='#F1AB00' />
         </linearGradient>
         <linearGradient
            id='prefix_ibcrating_i'
            x1='30.76%'
            x2='53.282%'
            y1='95.937%'
            y2='.485%'
         >
            <stop offset='0%' stopColor='#D47D00' />
            <stop offset='18.315%' stopColor='#F7B400' />
            <stop offset='45.337%' stopColor='#FFC000' />
            <stop offset='49.081%' stopColor='#FFD027' />
            <stop offset='100%' stopColor='#FFF137' />
         </linearGradient>
         <path
            id='prefix_ibcrating_c'
            d='M16.115 6.866H3.136a.883.883 0 00-.864.665l-2.08 8.606c-.13.54.292 1.057.864 1.057h17.279c.578 0 1.002-.526.861-1.07l-2.22-8.606a.881.881 0 00-.86-.652z'
         />
         <path id='prefix_ibcrating_f' d='M0 0h26.547v26.509H0z' />
         <path
            id='prefix_ibcrating_h'
            d='M12.833 4.834C19.15 2.9 25.84 6.428 27.776 12.712c1.936 6.285-1.617 12.948-7.935 14.881C13.524 29.527 6.833 26 4.898 19.714 2.962 13.43 6.515 6.768 12.833 4.834'
         />
         <filter
            id='prefix_ibcrating_d'
            width='105.2%'
            height='109.7%'
            x='-2.6%'
            y='-4.8%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation={0.5}
            />
            <feOffset in='shadowBlurInner1' result='shadowOffsetInner1' />
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2={-1}
               k3={1}
               operator='arithmetic'
               result='shadowInnerInner1'
            />
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
            />
         </filter>
         <filter
            id='prefix_ibcrating_j'
            width='104.2%'
            height='104.2%'
            x='-2.1%'
            y='-2.1%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation={0.5}
            />
            <feOffset in='shadowBlurInner1' result='shadowOffsetInner1' />
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2={-1}
               k3={1}
               operator='arithmetic'
               result='shadowInnerInner1'
            />
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
            />
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <path
            fill='#0C2F5B'
            fillRule='nonzero'
            stroke='#0C2F5B'
            strokeWidth={2.585}
            d='M15.446 25.694l1.136 3.704h4.147c.498 0 .908.326.964.747l.007.098h0v2.354h0l2.29-.061a.87.87 0 01.843.66h0l2.17 8.72A.87.87 0 0126.161 43h0H9.264a.871.871 0 01-.846-1.07h0l2.034-8.721a.87.87 0 01.846-.673h0l2.636-.001.001-2.292c0-.433.375-.79.858-.839l.113-.006h.54v-3.704zM9.852 5.52C14.439.888 21.905.936 26.529 5.627a12.085 12.085 0 013.08 5.39h2.565c1.1 0 1.941.872 1.941 2.015v4.232c0 .873-.323 1.747-.97 2.351l-1.1 1.142c-.583.672-1.36 1.008-2.265 1.008l-2.394-.136c-.246.304-.51.599-.79.882-4.587 4.633-12.053 4.585-16.677-.107-.157-.16-.308-.322-.455-.488l-3.368-.15c-.906 0-1.683-.337-2.265-1.009h0l-1.1-1.142c-.647-.604-.97-1.478-.97-2.351h0v-4.232c0-1.143.84-2.016 1.94-2.016h3.077A11.805 11.805 0 019.852 5.52zm-3.323 6.84H3.701c-.388 0-.647.268-.647.671h0v4.233c0 .537.195 1.008.583 1.41h0l1.1 1.143c.323.403.84.604 1.359.604h0l2.307.104a12.14 12.14 0 01-1.874-8.166zm23.356 0c.404 2.73-.125 5.565-1.59 7.977l1.485.084c.517 0 1.035-.201 1.359-.604l1.1-1.142c.388-.403.582-.874.582-1.411V13.03c0-.403-.259-.671-.647-.671z'
         />
         <g fillRule='nonzero'>
            <path
               fill='url(#prefix_ibcrating_a)'
               d='M7.378.114h4.764v14.974H7.378z'
               transform='translate(8.231 24.692)'
            />
            <path
               fill='#FFD03E'
               d='M21.011 28.462h-5.954c-.549 0-.993.373-.993.834v3.335h7.94v-3.335c0-.46-.444-.834-.993-.834z'
            />
            <g transform='translate(8.231 24.692)'>
               <use
                  fill='url(#prefix_ibcrating_b)'
                  xlinkHref='#prefix_ibcrating_c'
               />
               <use
                  fill='#000'
                  filter='url(#prefix_ibcrating_d)'
                  xlinkHref='#prefix_ibcrating_c'
               />
            </g>
            <path
               fill='#A18832'
               d='M21.609 35.682h-7.622c-.527 0-.953-.345-.953-.771 0-.427.426-.772.953-.772h7.622c.527 0 .953.345.953.772 0 .426-.426.772-.953.772zm-1.737 3.613h-4.234c-.585 0-1.059-.345-1.059-.772 0-.426.474-.771 1.059-.771h4.234c.585 0 1.059.345 1.059.771 0 .427-.474.772-1.059.772z'
            />
         </g>
         <path
            fill='url(#prefix_ibcrating_e)'
            fillRule='nonzero'
            stroke='url(#prefix_ibcrating_e)'
            strokeWidth={0.7}
            d='M29.47 20.462l-3.204-.169c-.423 0-.704-.269-.704-.673s.281-.673.704-.673l3.205.168c.563 0 1.126-.202 1.479-.605l1.197-1.145c.423-.403.634-.875.634-1.413v-4.24c0-.404-.282-.674-.704-.674h-3.522c-.423 0-.704-.269-.704-.673 0-.403.281-.673.704-.673h3.522c1.197 0 2.113.875 2.113 2.02v4.24c0 .875-.353 1.75-1.057 2.356l-1.197 1.144c-.634.673-1.48 1.01-2.465 1.01z'
            transform='translate(1.004 1)'
         />
         <path
            fill='url(#prefix_ibcrating_e)'
            fillRule='nonzero'
            stroke='url(#prefix_ibcrating_e)'
            strokeWidth={0.7}
            d='M5.102 20.296l-3.707.166c-.383 0-.639-.266-.639-.663 0-.398.256-.663.64-.663l3.706-.166c.511 0 1.023-.198 1.342-.596l1.087-1.127c.383-.397.575-.861.575-1.391V11.68c0-.397-.256-.662-.64-.662H4.272c-.383 0-.639-.265-.639-.663s.256-.663.64-.663h3.195c1.086 0 1.917.862 1.917 1.988v4.176c0 .861-.32 1.723-.958 2.32L7.34 19.302c-.575.663-1.342.994-2.237.994z'
            transform='matrix(-1 0 0 1 11.144 1)'
         />
         <g transform='translate(4.996 1)'>
            <mask id='prefix_ibcrating_g' fill='#fff'>
               <use xlinkHref='#prefix_ibcrating_f' />
            </mask>
            <g mask='url(#prefix_ibcrating_g)'>
               <g transform='translate(-2.768 -3.314)'>
                  <mask id='prefix_ibcrating_k' fill='#fff'>
                     <use xlinkHref='#prefix_ibcrating_h' />
                  </mask>
                  <g transform='scale(-1 1) rotate(62 0 -10.976)'>
                     <use
                        fill='url(#prefix_ibcrating_i)'
                        xlinkHref='#prefix_ibcrating_h'
                     />
                     <use
                        fill='#000'
                        filter='url(#prefix_ibcrating_j)'
                        xlinkHref='#prefix_ibcrating_h'
                     />
                  </g>
                  <path
                     fill='#CA8B0F'
                     d='M28.456 6.662l-.81-.77S12.767 7.347 4.918 19.383c-.276 2.284.274 1.328 1.355 3.025 0 0 7.398-12.202 22.182-15.747z'
                     mask='url(#prefix_ibcrating_k)'
                     transform='scale(-1 1) rotate(66 0 -11.492)'
                  />
                  <path
                     fill='#CA8B0F'
                     d='M29.03 10.203S15.998 11.975 6.463 23.962c0 0 1.118 1.198 1.679 1.075.56-.124 4.734-7.993 21.273-14.302l-.385-.532z'
                     mask='url(#prefix_ibcrating_k)'
                     transform='scale(-1 1) rotate(63 0 -11.65)'
                  />
               </g>
            </g>
         </g>
      </g>
   </svg>
)

IbcRatingIcon.defaultProps = {
   width: 16,
   height: 20,
   fillColor: 'inactive'
}

export default IbcRatingIcon
