import styled from 'styled-components'
import { Typo16BattleShipGreyBarlowBoldItalicCapital } from '../../../common/styleGuide/Typos'
import colors from '../../themes/Colors'

export const BackArrowText = styled(
   Typo16BattleShipGreyBarlowBoldItalicCapital
)`
   padding-bottom: 2px;
`

export const NavigateBackWrapper = styled.div`
   display: flex;
   justify-content: flex-start;
   text-align: center;
   align-items: center;
   background: ${colors.dark};
   overflow: hidden;
   padding-bottom: 15px;
   padding-top: 15px;
   padding-left: 24px;
   cursor: pointer;
`

export const BackArrowWrapper = styled.div`
   margin-right: 5px;
`
