const SUPER_RUN = 'SUPER_RUN'
const FAN_BASE = 'FAN_BASE'
const IBC_RATING = 'RATING'
const COIN = 'COIN'
const GEM = 'GEM'
const OVERS_LEFT = 'OVER'
const GOLD_TROPHY = 'GOLD_TROPHY'
const SKILL = 'SKILL'

export const IBC_RATING_ENUM = 'IBC_RATING'

export const ResourceEnums = {
   SUPER_RUN: SUPER_RUN,
   FAN_BASE: FAN_BASE,
   IBC_RATING: IBC_RATING,
   GOLD_TROPHY: GOLD_TROPHY,
   COIN: COIN,
   GEM: GEM,
   OVERS_LEFT: OVERS_LEFT,
   SKILL: SKILL
}

export const OrderedResourceEnumsList = [
   SUPER_RUN,
   FAN_BASE,
   IBC_RATING,
   GEM,
   SKILL
]

export const EmptyResourceItemForTournamentCard = {
   display_name: 'iBC Rating',
   value: 0,
   pic:
      'https://vrcricket.s3.ap-south-1.amazonaws.com/pe-beta/common/icons/rewards/trophies/trophies@3x.png',
   name: IBC_RATING_ENUM,
   thumbnail: '',
   description: ''
}
