import { BattingStats, StatsItem } from '../../stores/types'
import { StatsDisplayNames } from '../../constants/StatsConstants'
import MatchesIcon from '../../icons/MatchesIcon'
import StrikeBackIcon from '../../icons/StrikeBackIcon'
import OutsIcon from '../../icons/OutsIcon'
import RunsIcon from '../../icons/RunsIcon'
import FoursIcon from '../../icons/FoursIcon'
import SixesIcon from '../../icons/SixesIcon'
import DotBallsIcon from '../../icons/DotBallsIcon'
import OversIcon from '../../icons/OversIcon'
import AverageIcon from '../../icons/AverageIcon'
import HighestSuperRunsIcon from '../../icons/HighestSuperRunsIcon'

export function getStats(stats: BattingStats): StatsItem[] {
   const statItemsList: StatsItem[] = []
   stats.matches_count !== undefined &&
      statItemsList.push({
         name: StatsDisplayNames.MATCHES,
         value: stats.matches_count,
         displayName: 'stats.matches',
         Icon: MatchesIcon
      })
   statItemsList.push({
      name: StatsDisplayNames.AVERAGE,
      value: stats.average,
      displayName: 'stats.average',
      Icon: AverageIcon
   })
   statItemsList.push({
      name: StatsDisplayNames.STRIKE_RATE,
      value: stats.strike_rate,
      displayName: 'stats.strikeRate',
      Icon: StrikeBackIcon
   })
   statItemsList.push({
      name: StatsDisplayNames.OUTS,
      value: stats.outs,
      displayName: 'stats.outs',
      Icon: OutsIcon
   })
   statItemsList.push({
      name: StatsDisplayNames.RUNS,
      value: stats.runs,
      displayName: 'stats.runs',
      Icon: RunsIcon
   })
   stats.highest_super_runs_with_one_bat !== undefined &&
      statItemsList.push({
         name: StatsDisplayNames.HIGHEST_SUPER_RUNS_WITH_1_WICKET,
         value: stats.highest_super_runs_with_one_bat,
         displayName: 'stats.highestSuperRunsWith1Wicket',
         Icon: HighestSuperRunsIcon
      })
   statItemsList.push({
      name: StatsDisplayNames.FOURS,
      value: stats.fours,
      displayName: 'stats.fours',
      Icon: FoursIcon
   })
   statItemsList.push({
      name: StatsDisplayNames.SIXES,
      value: stats.sixes,
      displayName: 'stats.sixes',
      Icon: SixesIcon
   })
   stats.dot_balls &&
      statItemsList.push({
         name: StatsDisplayNames.DOT_BALLS,
         value: stats.dot_balls,
         displayName: 'stats.dotBalls',
         Icon: DotBallsIcon
      })
   stats.overs &&
      statItemsList.push({
         name: StatsDisplayNames.OVERS,
         value: stats.overs,
         displayName: 'stats.overs',
         Icon: OversIcon
      })
   return statItemsList
}
