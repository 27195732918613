import { EntryFeeDetails, SpecialChallengeDetailsType } from '../../../types'

class SpecialChallengeDetails {
   challengeDifficultyMode: string
   challengeId: number
   challengeRewardDetails: string | null
   challengeType: string
   description: string
   discountAppliedInPercentage: number
   discountedEntryFeeDetails: EntryFeeDetails
   endsIn: string
   endsInSeconds: string
   entryFeeDetails: EntryFeeDetails
   name: string
   nameEnum: string
   newChallengeStartsInSeconds: number
   pic: string
   relatedGameModeChallenges: any
   thumbnail: string
   score!: number
   wickets!: number
   overs!: number
   target!: number
   specialBallsFrequency!: string
   constructor(challengeDetails: SpecialChallengeDetailsType) {
      // const { challenge_details, challenge_details_blob }
      const challenge_details = challengeDetails?.challenge_details
      const challenge_details_blob = challengeDetails?.challenge_details_blob
      this.challengeDifficultyMode = challenge_details.challenge_difficulty_mode
      this.challengeId = challenge_details.challenge_id
      this.challengeRewardDetails = challenge_details.challenge_reward_details
      this.challengeType = challenge_details.challenge_type
      this.description = challenge_details.description
      this.discountAppliedInPercentage =
         challenge_details.discount_applied_in_percentage
      this.discountedEntryFeeDetails =
         challenge_details.discounted_entry_fee_details
      this.endsIn = challenge_details.ends_in
      this.endsInSeconds = challenge_details.ends_in_seconds
      this.entryFeeDetails = challenge_details.entry_fee_details
      this.name = challenge_details.name
      this.nameEnum = challenge_details.name_enum
      this.newChallengeStartsInSeconds =
         challenge_details.new_challenge_starts_in_seconds
      this.pic = challenge_details.pic
      this.thumbnail = challenge_details.thumbnail
      this.relatedGameModeChallenges =
         challenge_details.related_game_mode_challenges
      this.setChallengeBlobDetails(challenge_details_blob)
   }

   setChallengeBlobDetails = (challenge_details_blob: string): void => {
      const blobType = typeof challenge_details_blob
      const challengeDetailsBlobDetails =
         blobType === 'object'
            ? challenge_details_blob
            : JSON.parse(challenge_details_blob)
      this.score = challengeDetailsBlobDetails.current_state.score
      this.overs = challengeDetailsBlobDetails.current_state.overs
      this.wickets = challengeDetailsBlobDetails.current_state.wickets
      this.target = challengeDetailsBlobDetails.min_score_to_reach
      this.specialBallsFrequency =
         challengeDetailsBlobDetails.special_ball_frequency_name || ''
   }
}

export default SpecialChallengeDetails
