import React from 'react'

interface Props {
   width: number
   height: number
   fill: string
}

const SteamIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.height}
      height={props.width}
      viewBox='0 0 30 30'
   >
      <g fill='none' fillRule='evenodd'>
         <g fill={props.fill} fillRule='nonzero'>
            <g>
               <g>
                  <path
                     d='M14.8 0C6.999 0 .608 6.07 0 13.785l7.96 3.322c.675-.466 1.49-.738 2.366-.738.08 0 .157.003.234.007l3.54-5.179v-.072c0-3.117 2.513-5.653 5.601-5.653 3.089 0 5.601 2.536 5.601 5.652 0 3.117-2.512 5.654-5.6 5.654-.043 0-.085-.001-.128-.003l-5.05 3.635c.004.067.006.135.006.2 0 2.341-1.886 4.244-4.204 4.244-2.034 0-3.737-1.466-4.121-3.406L.51 19.072C2.274 25.363 8 29.975 14.8 29.975c8.202 0 14.852-6.71 14.852-14.987C29.653 6.71 23.003 0 14.8 0zM9.308 22.741l-1.824-.76c.323.679.883 1.248 1.625 1.56 1.606.675 3.456-.093 4.125-1.715.324-.784.326-1.65.006-2.436-.32-.787-.927-1.4-1.704-1.727-.772-.324-1.6-.312-2.326-.035l1.885.786c1.184.498 1.744 1.87 1.25 3.065-.492 1.195-1.853 1.76-3.037 1.262zm14.126-11.616c0-2.077-1.674-3.767-3.731-3.767-2.058 0-3.733 1.69-3.733 3.767 0 2.076 1.675 3.765 3.733 3.765 2.057 0 3.731-1.689 3.731-3.765zm-6.529-.007c0-1.562 1.256-2.828 2.803-2.828 1.549 0 2.804 1.266 2.804 2.828 0 1.563-1.255 2.829-2.804 2.829-1.547 0-2.803-1.266-2.803-2.829z'
                     transform='translate(-868 -875) translate(828 860) translate(40 15)'
                  />
               </g>
            </g>
         </g>
      </g>
   </svg>
)

SteamIcon.defaultProps = {
   width: 26,
   height: 24,
   fill: '#FFF'
}

export default SteamIcon
