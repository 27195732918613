import React, { ReactElement } from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line

import CricketConstantsStore from '../../../common/stores/CricketConstantsStore'
import { Typo18BattleShipGreyBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'
import SectionTitle from '../../../common/components/SectionTitle'

import TournamentsTabContent from '../../components/TournamentsTabContent'
import TournamentsStore from '../../stores/TournamentsStore'
import { tournamentsTabs } from '../../constants/tournamentConstants'

import { Container } from './styledComponents'

import './styles.css'

export type TournamentsProps = WithTranslation & {
   tournamentsStore: TournamentsStore
   cricketConstantsStore: CricketConstantsStore
}

export type TabProps = {
   title: string
}

const TabComponent = (props: TabProps): ReactElement => (
   <Typo18BattleShipGreyBarlowSemiBoldCapital>
      {props.title}
   </Typo18BattleShipGreyBarlowSemiBoldCapital>
)

@inject('tournamentsStore', 'cricketConstantsStore')
@observer
class TournamentsRoute extends React.Component<TournamentsProps> {
   activeTabIndex = 0
   componentDidMount() {
      this.getPlayerTournaments()
   }
   gettournamentsStore = () => this.props.tournamentsStore

   getPlayerTournaments(): void {
      this.gettournamentsStore().getTournamentsMinimalDetails(
         tournamentsTabs[this.activeTabIndex].value,
         this.props.cricketConstantsStore.getCricketGameId()
      )
   }

   handleOnSelect = (index): void => {
      this.activeTabIndex = index
      this.getPlayerTournaments()
   }

   render() {
      const { t } = this.props
      return (
         <Container>
            <SectionTitle title={t('tournaments:heading')} width={240} />
            <Tabs onSelect={this.handleOnSelect} className={'TournamentsList'}>
               <TabList>
                  {tournamentsTabs.map((data, index) => (
                     <Tab key={index}>
                        <TabComponent title={data.label} />
                     </Tab>
                  ))}
               </TabList>
               {tournamentsTabs.map((data, index) => (
                  <TabPanel key={index}>
                     <TournamentsTabContent
                        tabEnum={data.value}
                        tournamentsStore={this.props.tournamentsStore}
                        cricketConstantsStore={this.props.cricketConstantsStore}
                     />
                  </TabPanel>
               ))}
            </Tabs>
         </Container>
      )
   }
}
export default withTranslation()(TournamentsRoute)
