import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
   MessageContainer,
   NoMatchesMsgText,
   NoMatchesPlayedContainer,
   NoMatchesTitleText
} from './styledComponents'

const NoMatchesPlayedView = (): ReactElement => {
   const { t } = useTranslation()
   return (
      <NoMatchesPlayedContainer>
         <MessageContainer>
            <NoMatchesTitleText>
               {t('noDataMsg.matches.title')}
            </NoMatchesTitleText>
            <NoMatchesMsgText>
               {t('noDataMsg.matches.message')}
            </NoMatchesMsgText>
         </MessageContainer>
      </NoMatchesPlayedContainer>
   )
}

export default NoMatchesPlayedView
