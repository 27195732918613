import React from 'react'

interface Props {
   width: number
   height: number
   fillColor: string
}

const TournamentsIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 21 24'
   >
      <defs>
         <path id='oph1d0nmta' d='M0 0h15.33v15.33H0z'></path>
         <path
            id='pcjjr0weuc'
            d='M7.404 2.802c3.652-1.115 7.521.925 8.642 4.556 1.122 3.631-.93 7.479-4.582 8.593-3.652 1.115-7.521-.925-8.642-4.556-1.121-3.631.93-7.478 4.582-8.593'
         ></path>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <g fillRule='nonzero'>
            <path fill='#6C7285' d='M9.145 13.768h2.751v8.66H9.145z'></path>
            <path
               fill='#6C7285'
               d='M12.264 15.882H8.826c-.317 0-.573.216-.573.482v1.93h4.584v-1.93c0-.266-.256-.482-.573-.482z'
            ></path>
            <path
               fill='#A3A7B3'
               d='M14.19 17.673H6.695a.512.512 0 00-.5.384l-1.2 4.977a.502.502 0 00.5.612h9.977a.501.501 0 00.497-.619l-1.282-4.977a.51.51 0 00-.497-.377z'
            ></path>
            <path
               fill='#6C7285'
               d='M12.609 20.058H8.208c-.304 0-.55-.2-.55-.446 0-.247.246-.447.55-.447h4.401c.305 0 .55.2.55.447 0 .246-.245.446-.55.446zm-1.002 2.089H9.161c-.338 0-.611-.2-.611-.446 0-.247.273-.446.611-.446h2.446c.337 0 .61.2.61.446 0 .247-.273.446-.61.446z'
            ></path>
         </g>
         <path
            fill='#6C7285'
            fillRule='nonzero'
            stroke='#6C7285'
            strokeWidth='0.5'
            d='M17.73 11.833l-1.851-.097c-.244 0-.407-.156-.407-.39 0-.233.163-.389.407-.389l1.85.098c.326 0 .651-.117.854-.35l.692-.662c.244-.234.366-.506.366-.818V6.773c0-.234-.163-.39-.407-.39h-2.033c-.244 0-.407-.155-.407-.389 0-.233.163-.389.407-.389h2.033c.692 0 1.22.506 1.22 1.168v2.452a1.79 1.79 0 01-.61 1.363l-.691.661c-.366.39-.854.584-1.424.584zm-14.11-.096l2.14.096c.222 0 .37-.153.37-.383s-.148-.383-.37-.383l-2.14-.096c-.295 0-.59-.115-.775-.345l-.627-.652a1.12 1.12 0 01-.332-.804V6.755c0-.23.147-.383.369-.383H4.1c.222 0 .37-.154.37-.384s-.148-.383-.37-.383H2.255c-.628 0-1.107.498-1.107 1.15V9.17c0 .498.184.996.553 1.341l.628.652c.332.383.775.574 1.291.574z'
         ></path>
         <g transform='translate(3.016)'>
            <mask id='v0esmzfldb' fill='#fff'>
               <use xlinkHref='#oph1d0nmta'></use>
            </mask>
            <g mask='url(#v0esmzfldb)'>
               <g transform='translate(-1.599 -1.916)'>
                  <mask id='0hpg42njmd' fill='#fff'>
                     <use xlinkHref='#pcjjr0weuc'></use>
                  </mask>
                  <use
                     fill='#A3A7B3'
                     transform='scale(-1 1) rotate(62 0 -6.325)'
                     xlinkHref='#pcjjr0weuc'
                  ></use>
                  <path
                     fill='#6C7285'
                     d='M16.439 3.861l-.469-.445s-8.601.837-13.135 7.787c-.16 1.319.158.767.783 1.747 0 0 4.274-7.046 12.821-9.089z'
                     mask='url(#0hpg42njmd)'
                     transform='scale(-1 1) rotate(66 0 -6.625)'
                  ></path>
                  <path
                     fill='#6C7285'
                     d='M16.77 5.909s-7.534 1.02-13.043 7.94c0 0 .647.693.97.622.325-.072 2.735-4.616 12.295-8.254l-.223-.308z'
                     mask='url(#0hpg42njmd)'
                     transform='scale(-1 1) rotate(63 0 -6.713)'
                  ></path>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

TournamentsIcon.defaultProps = {
   width: 21,
   height: 24,
   fillColor: 'inactive'
}
export default TournamentsIcon
