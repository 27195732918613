const STEAM_LOGIN_URL = 'http://steamcommunity.com/openid/login'

const encodeGetParams = p =>
   Object.entries(p)
      .map(kv => kv.map(encodeURIComponent).join('='))
      .join('&')

export function get_login_url() {
   const redirect_url = `${window.location.origin}/steam/return`
   const auth_parameters = {
      'openid.ns': 'http://specs.openid.net/auth/2.0',
      'openid.mode': 'checkid_setup',
      'openid.return_to': redirect_url,
      'openid.realm': redirect_url,
      'openid.identity': 'http://specs.openid.net/auth/2.0/identifier_select',
      'openid.claimed_id': 'http://specs.openid.net/auth/2.0/identifier_select'
   }

   return `${STEAM_LOGIN_URL}?${encodeGetParams(auth_parameters)}`
}
