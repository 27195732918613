import styled from 'styled-components'
import Colors from '../../../themes/Colors'
import {
   Typo14WhiteBarlowBoldItalic,
   Typo24WhiteBarlowBoldItalic
} from '../../../styleGuide/Typos'

import {
   ReverseSkew,
   CommonResourceItemContainer
} from '../CommonResource/styledComponents'
import Image from '../../Image'

export const SuperRunsResourceContainer = styled(CommonResourceItemContainer)`
   display: flex;
   position: relative;
   flex-direction: column;
`

export const LevelCompletionContainer = styled.div`
   min-height: 21px;
   background-color: ${Colors.marineBlue};
`

export const LevelCompletionBg = styled.div`
   position: relative;
   width: ${props => props.width}%;
   min-height: 21px;
   background-image: ${Colors.linerGradients.superRunsCompletionGradient};
   text-align: center;
`

export const LevelText = styled(Typo14WhiteBarlowBoldItalic)`
   display: inline-block;
   position: absolute;
   top: 37%;
   left: 30%;
   z-index: 2;
   padding-top: 2px;
   padding-bottom: 2px;
   ${ReverseSkew}
`
export const SuperRunsText = styled(Typo24WhiteBarlowBoldItalic)`
   display: inline-block;
   padding-left: 6px;
   ${ReverseSkew}
`
export const SuperRunsIcon = styled(Image)`
   position: absolute;
   width: 22px;
   height: 40px;
   object-fit: cover;
   top: 12%;
   left: -10%;
   z-index: 1;
   ${ReverseSkew}
`
