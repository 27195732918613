export const formatNum = n => Math.round(n * 1e4) / 1e2

export const resourceValue = (value: number) => {
   const fraction = value % 1
   const percentage = formatNum(fraction.toFixed(2))

   const valueString = value.toString()
   const decimal = valueString.split('.')[0]

   return {
      decimal: parseInt(decimal),
      fraction: percentage,
      levelCompletion: percentage.toString().concat('/100')
   }
}
