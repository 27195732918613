import React, { ReactElement } from 'react'

import { getScoreFromGameStateBlob } from '../../utils/getPlayerPosition'
import { PaticipantBasicDetails } from '../../stores/types'

import PlayerDetailsComponent from '../PlayerDetailsComponent'

import SuccessTrophyIcon from '../../../common/icons/SuccessTrophyIcon'
import {
   Container,
   TrophyIconWrapper,
   ProfilePicAndCountryPicWrapper,
   PlayerProfilePic,
   PlayerCountryFlagPic
} from './styledComponent'

interface PlayerCardProps {
   playerModel: PaticipantBasicDetails
   isPlayerOne: boolean
   isWon: boolean
   requestUserId: string
}

const PlayerCardComponent = (props: PlayerCardProps): ReactElement => {
   const { playerModel, isPlayerOne, isWon, requestUserId } = props
   const {
      participant_model,
      participant_status,
      game_state_blob
   } = playerModel
   const score = getScoreFromGameStateBlob(game_state_blob)
   const { name, pic, participant_id, country_flag_pic_url } = participant_model
   return (
      <Container
         isSelf={participant_id === requestUserId}
         isPlayerOne={isPlayerOne}
      >
         {!isPlayerOne && (
            <PlayerDetailsComponent
               playerName={name}
               matchStatus={participant_status}
               score={score}
               isPlayerOne={isPlayerOne}
            />
         )}
         <ProfilePicAndCountryPicWrapper>
            <PlayerProfilePic src={pic} isPlayerOne={isPlayerOne} />
            <PlayerCountryFlagPic
               src={country_flag_pic_url}
               isPlayerOne={isPlayerOne}
            />
         </ProfilePicAndCountryPicWrapper>
         {isPlayerOne && (
            <PlayerDetailsComponent
               playerName={name}
               matchStatus={participant_status}
               score={score}
               isPlayerOne={isPlayerOne}
            />
         )}
         {isWon && (
            <TrophyIconWrapper isPlayerOne={isPlayerOne}>
               <SuccessTrophyIcon width={37} height={43} />
            </TrophyIconWrapper>
         )}
      </Container>
   )
}
export default PlayerCardComponent
