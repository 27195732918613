import styled from 'styled-components'
import Colors from '../../../themes/Colors'
import {
   tablet,
   mobile,
   minDeviceWidth,
   maxDeviceWidth
} from '../../../utils/MixinUtils'

export const ProfileContainer = styled.div`
   display: flex;
   flex-direction: column;
`
export const ProfileResourceContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding-left: 24px;
   ${tablet} {
      padding-right: 10px;
   }
   ${mobile} {
      padding-right: 5px;
   }
   background-color: ${Colors.darkBlueGreyTwoProfile};
`

export const ResourcesContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: flex-end;
   flex-wrap: wrap;
   justify-content: space-around;
   ${minDeviceWidth(1630)} {
      min-width: 70%;
   }
   ${maxDeviceWidth(1630)} {
      flex: 5;
      justify-content: space-around;
   }
`

export const ProfileStatsContainer = styled.div`
   display: flex;
   background-color: ${Colors.darkBlueGrey};
   padding-right: 18.36%;
   flex-wrap: wrap;
   @media (max-width: 1630px) {
      padding-right: 0px;
   }
`

export const GroundWrapper = styled.div`
   display: flex;
   justify-content: center;
   flex-grow: 1;
   ${minDeviceWidth(1630)} {
      margin-right: 24px;
      position: absolute;
      right: 0;
      top: 132px;
   }
`

export const ProfileResourceItemWrapper = styled.div`
   margin-left: 15px;
   margin-right: 15px;
   margin-top: 45px;
   margin-bottom: 40px;
`
