import { observer } from 'mobx-react'
import React from 'react'
import Modal from 'react-modal'

import CloseCircularButtonIcon from '../../../common/icons/CloseCircularButtonIcon'

import PopupLeaderboardModel from '../../stores/models/PopupLeaderBoardModel'

import TournamentRoundDetailsTabs from '../TournamentRoundDetailsTabs'

import {
   ModalContainer,
   TournamentRoundDetailsContainer,
   CloseIconWrapper,
   CloseIconBar,
   TournamentRoundDetailsTabsWrapper
} from './styledComponents'

import './index.css'

const customStyles = {
   content: {
      top: '8%',
      left: '5.8%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      BorderRadius: '0px',
      border: '0px',
      background: 'transparent',
      minHeight: '89.5%'
   },
   overlay: {
      backgroundColor: 'rgba(0, 0, 0,0.7)',
      zIndex: 2000,
      overflow: 'auto',
      width: '100%'
   }
}

interface TournamentRoundDetailsPopupProps {
   shouldShowRoundDetails: boolean
   hideRoundDetails: () => void
   popupLeaderBoardModels: Array<PopupLeaderboardModel>
}

const TournamentRoundDetailsPopup = observer(
   (props: TournamentRoundDetailsPopupProps) => {
      function closeModal(): void {
         props.hideRoundDetails()
      }

      const { popupLeaderBoardModels } = props
      return (
         <ModalContainer>
            <Modal
               isOpen={props.shouldShowRoundDetails}
               onRequestClose={closeModal}
               style={customStyles}
               contentLabel='Example Modal'
               preventScroll={true}
            >
               <TournamentRoundDetailsContainer>
                  <CloseIconBar>
                     <CloseIconWrapper onClick={closeModal}>
                        <CloseCircularButtonIcon />
                     </CloseIconWrapper>
                  </CloseIconBar>
                  <TournamentRoundDetailsTabsWrapper>
                     <TournamentRoundDetailsTabs
                        popupLeaderBoardModels={popupLeaderBoardModels}
                     />
                  </TournamentRoundDetailsTabsWrapper>
               </TournamentRoundDetailsContainer>
            </Modal>
         </ModalContainer>
      )
   }
)

export default TournamentRoundDetailsPopup
