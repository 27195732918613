import React, { ReactElement } from 'react'

import { LeaderboardItemCardDiv } from '../LeaderboardItemCard/styledComponents'

import {
   OvalItem,
   RightOvalContainer,
   LeftOvalContainer
} from './styledComponents'

const NO_OF_DOTS = 6

const DotsItemCard = (): ReactElement => {
   const dots: Array<ReactElement> = []
   let i = 0
   for (i = 0; i < NO_OF_DOTS; i++) {
      dots.push(<OvalItem key={i} />)
   }

   return (
      <LeaderboardItemCardDiv>
         <LeftOvalContainer>{dots}</LeftOvalContainer>
         <RightOvalContainer>{dots}</RightOvalContainer>
      </LeaderboardItemCardDiv>
   )
}

export default DotsItemCard
