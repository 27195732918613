import styled from 'styled-components'

export const ModalContainer = styled.div`
   display: flex;
   justify-content: center;
`
export const TournamentRoundDetailsContainer = styled.div`
   display: flex;
   flex-direction: column;
   overflow: hidden;
   align-items: center;
`

export const CloseIconWrapper = styled.div`
   cursor: pointer;
`

export const CloseIconBar = styled.div`
   display: flex;
   justify-content: flex-end;
   width: 100%;
   padding-right: 80px;
   padding-top: 40px;
`

export const TournamentRoundDetailsTabsWrapper = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
`
