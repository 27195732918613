import styled from '@emotion/styled'

import PitchIcon from '../../../common/icons/PitchIcon'

export const GroundWrapper = styled.div`
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const PitchArea = styled(PitchIcon)``

export const PitchWrapper = styled.div`
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 5px;
`
