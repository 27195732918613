import styled from '@emotion/styled'
import colors from '../../../../common/themes/Colors'
import { Typo24WhiteBarlowBoldItalicCapital } from '../../../../common/styleGuide/Typos'

export const TextContainer = styled(Typo24WhiteBarlowBoldItalicCapital)`
   line-height: normal;
   font-size: 32px;
   font-weight: 800px;
   letter-spacing: 0.5px;
   display: flex;
`
export const Container = styled.div`
   display: flex;
   justify-content: center;
   background-color: ${colors.oceanBlue};
   padding: 10px;
`
