import React, { ReactElement } from 'react'

interface Props {
   width: number
   height: number
}

const IBCTrophy = (props: Props): ReactElement => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 21 26'
   >
      <defs>
         <linearGradient id='jo5645px4a' x1='100%' x2='0%' y1='50%' y2='50%'>
            <stop offset='0%' stopColor='#DCB72F'></stop>
            <stop offset='100%' stopColor='#D39F0A'></stop>
         </linearGradient>
         <linearGradient
            id='vynmuzg7vb'
            x1='30.76%'
            x2='53.282%'
            y1='63.626%'
            y2='35.313%'
         >
            <stop offset='0%' stopColor='#D47D00'></stop>
            <stop offset='18.315%' stopColor='#F7B400'></stop>
            <stop offset='45.337%' stopColor='#FFC000'></stop>
            <stop offset='49.081%' stopColor='#FFD027'></stop>
            <stop offset='100%' stopColor='#FFF137'></stop>
         </linearGradient>
         <linearGradient id='ja08z87y7e' x1='50%' x2='50%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor='#FFEB34'></stop>
            <stop offset='100%' stopColor='#F1AB00'></stop>
         </linearGradient>
         <linearGradient
            id='ii62otogbi'
            x1='30.76%'
            x2='53.282%'
            y1='95.937%'
            y2='.485%'
         >
            <stop offset='0%' stopColor='#D47D00'></stop>
            <stop offset='18.315%' stopColor='#F7B400'></stop>
            <stop offset='45.337%' stopColor='#FFC000'></stop>
            <stop offset='49.081%' stopColor='#FFD027'></stop>
            <stop offset='100%' stopColor='#FFF137'></stop>
         </linearGradient>
         <path
            id='6ggt9a1foc'
            d='M9.209 3.924H1.792a.505.505 0 00-.494.38L.11 9.22a.495.495 0 00.494.604h9.873c.33 0 .573-.3.492-.61L9.701 4.295a.505.505 0 00-.492-.372z'
         ></path>
         <path id='562xxoc27f' d='M0 0h15.17v15.148H0z'></path>
         <path
            id='49yu9rw4mh'
            d='M7.333 2.762c3.61-1.105 7.433.91 8.54 4.502 1.105 3.592-.925 7.399-4.535 8.504-3.61 1.104-7.433-.911-8.54-4.503-1.105-3.59.925-7.398 4.535-8.503'
         ></path>
         <filter
            id='09x2nry18d'
            width='109.2%'
            height='116.9%'
            x='-4.6%'
            y='-8.5%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation='0.5'
            ></feGaussianBlur>
            <feOffset
               in='shadowBlurInner1'
               result='shadowOffsetInner1'
            ></feOffset>
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2='-1'
               k3='1'
               operator='arithmetic'
               result='shadowInnerInner1'
            ></feComposite>
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
            ></feColorMatrix>
         </filter>
         <filter
            id='u9441pt0qj'
            width='107.3%'
            height='107.3%'
            x='-3.7%'
            y='-3.7%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation='0.5'
            ></feGaussianBlur>
            <feOffset
               in='shadowBlurInner1'
               result='shadowOffsetInner1'
            ></feOffset>
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2='-1'
               k3='1'
               operator='arithmetic'
               result='shadowInnerInner1'
            ></feComposite>
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <path
            fill='#0C2F5B'
            fillRule='nonzero'
            stroke='#0C2F5B'
            strokeWidth='1.477'
            d='M9.253 15.111l.65 2.116h2.368c.273 0 .5.171.546.397l.01.086h0l-.002 1.31h1.31c.2 0 .376.12.454.298l.027.08 1.24 4.983a.497.497 0 01-.48.619h0H5.72c-.32 0-.556-.3-.483-.612h0l1.162-4.983a.498.498 0 01.483-.385H8.39v-1.31c0-.237.196-.434.455-.475l.1-.007h.308V15.11zM6.056 3.583c2.621-2.647 6.888-2.62 9.53.061a6.913 6.913 0 011.76 3.08h1.465c.629 0 1.11.499 1.11 1.151v2.419c0 .499-.185.998-.555 1.343l-.629.653c-.332.384-.776.576-1.294.576l-1.368-.077c-.14.173-.29.341-.451.503-2.621 2.648-6.888 2.62-9.53-.061a8.439 8.439 0 01-.259-.278l-1.926-.087c-.517 0-.96-.192-1.294-.576h0l-.628-.653c-.37-.345-.555-.844-.555-1.343h0V7.875c0-.652.48-1.152 1.11-1.152h0l1.757.001a6.73 6.73 0 011.757-3.141zM4.157 7.49H2.541c-.221 0-.37.154-.37.384h0v2.419c0 .307.112.575.333.806h0l.629.652c.185.23.48.346.776.346h0l1.32.06A6.925 6.925 0 014.156 7.49zm13.347 0a6.856 6.856 0 01-.91 4.559l.85.048c.295 0 .59-.115.776-.346l.628-.652c.222-.23.333-.5.333-.806V7.875c0-.23-.148-.384-.37-.384z'
         ></path>
         <g fillRule='nonzero'>
            <path
               fill='url(#jo5645px4a)'
               d='M4.216.065h2.722v8.557H4.216z'
               transform='translate(5.13 14.538)'
            ></path>
            <path
               fill='#FFD03E'
               d='M12.433 16.692H9.03c-.313 0-.567.213-.567.477v1.906H13v-1.907c0-.263-.254-.477-.567-.477z'
            ></path>
            <g transform='translate(5.13 14.538)'>
               <use fill='url(#vynmuzg7vb)' xlinkHref='#6ggt9a1foc'></use>
               <use
                  fill='#000'
                  filter='url(#09x2nry18d)'
                  xlinkHref='#6ggt9a1foc'
               ></use>
            </g>
            <path
               fill='#A18832'
               d='M12.775 20.818H8.419c-.3 0-.544-.197-.544-.44 0-.244.243-.442.544-.442h4.356c.3 0 .544.198.544.441 0 .244-.243.441-.544.441zm-.993 2.065h-2.42c-.334 0-.604-.198-.604-.441 0-.244.27-.441.604-.441h2.42c.335 0 .605.197.605.44 0 .244-.27.442-.605.442z'
            ></path>
         </g>
         <path
            fill='url(#ja08z87y7e)'
            fillRule='nonzero'
            stroke='url(#ja08z87y7e)'
            strokeWidth='0.5'
            d='M16.84 11.692l-1.83-.096c-.242 0-.403-.154-.403-.384 0-.231.16-.385.402-.385l1.831.096c.322 0 .644-.115.846-.346l.684-.654c.241-.23.362-.5.362-.808V6.692c0-.23-.161-.384-.403-.384h-2.012c-.241 0-.402-.154-.402-.385 0-.23.16-.385.402-.385h2.012c.685 0 1.208.5 1.208 1.154v2.423c0 .5-.201 1-.604 1.347l-.684.653c-.362.385-.845.577-1.409.577z'
            transform='translate(1 1)'
         ></path>
         <path
            fill='url(#ja08z87y7e)'
            fillRule='nonzero'
            stroke='url(#ja08z87y7e)'
            strokeWidth='0.5'
            d='M2.916 11.598l-2.119.094c-.219 0-.365-.151-.365-.378 0-.228.146-.38.365-.38l2.119-.094c.292 0 .584-.113.766-.34l.621-.644a1.11 1.11 0 00.329-.796V6.675c0-.228-.146-.38-.365-.38H2.44c-.22 0-.365-.15-.365-.378 0-.227.146-.379.365-.379h1.826c.62 0 1.095.493 1.095 1.137V9.06c0 .493-.182.985-.547 1.326l-.621.644a1.633 1.633 0 01-1.278.568z'
            transform='matrix(-1 0 0 1 6.795 1)'
         ></path>
         <g transform='translate(3.281 1)'>
            <mask id='7ht3wm9stg' fill='#fff'>
               <use xlinkHref='#562xxoc27f'></use>
            </mask>
            <g mask='url(#7ht3wm9stg)'>
               <g transform='translate(-1.582 -1.893)'>
                  <mask id='2cr8y0czuk' fill='#fff'>
                     <use xlinkHref='#49yu9rw4mh'></use>
                  </mask>
                  <g transform='scale(-1 1) rotate(62 0 -6.272)'>
                     <use fill='url(#ii62otogbi)' xlinkHref='#49yu9rw4mh'></use>
                     <use
                        fill='#000'
                        filter='url(#u9441pt0qj)'
                        xlinkHref='#49yu9rw4mh'
                     ></use>
                  </g>
                  <path
                     fill='#CA8B0F'
                     d='M16.26 3.807l-.463-.44s-8.5.832-12.986 7.71c-.158 1.305.156.759.774 1.728 0 0 4.228-6.973 12.676-8.998z'
                     mask='url(#2cr8y0czuk)'
                     transform='scale(-1 1) rotate(66 0 -6.567)'
                  ></path>
                  <path
                     fill='#CA8B0F'
                     d='M16.588 5.83S9.142 6.843 3.693 13.692c0 0 .64.685.96.615.32-.071 2.705-4.568 12.156-8.173l-.22-.304z'
                     mask='url(#2cr8y0czuk)'
                     transform='scale(-1 1) rotate(63 0 -6.657)'
                  ></path>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

IBCTrophy.defaultProps = {
   width: 20,
   height: 24
}
export default IBCTrophy
