import React from 'react'

interface Props {
   width: number
   height: number
}
//url(#filter-1)
const SixesIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 58 26'
   >
      <defs>
         <filter id='1itwahi7fa'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
         <path id='srk3t39nbb' d='M.019.214H16.14v20.001H.019z'></path>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#1itwahi7fa)'
         transform='translate(0 -7)'
      >
         <path
            fill='#FFF'
            d='M37.264 30.247c-5.148-4.283-5.85-11.93-1.566-17.078a12.09 12.09 0 011.99-1.901c-1.75.749-4.276 1.598-5.98 1.875-2.342.343-4.481.226-6.554.04-3.992-.299-7.796-1.105-12.68-.942.984.026 3.278.203 6.2.745 3.87.628 8.85 1.899 12.854 2.133-.365.068-1.361.406-2.873.506-1.895.18-4.608-.022-7.604-.301-6.026-.717-13.053-1.134-16.65 1.93-.003-.012 3.117-1.95 8.118-1.488 4.848.123 11.516 2.567 17.15 3.751-.458-.01-1.467.047-2.941-.133-2.025-.19-4.934-.834-8.182-1.318C12.024 16.974 4.262 17.048 0 24.92c.026.12 1.72-3.285 6.264-4.894 2.283-.75 5.319-.87 9.183-.02 3.847.774 8.523 2.519 13.703 3.73.04.185-3.976-.404-8.413-1.425-4.397-.946-9.562-2.083-12.25-.91.41-.081 3.78-.1 8.622 1.535 5.131 1.498 12.307 4.654 17.656 5.8.015.068-3.22-.204-7.621-1.536-4.361-1.155-9.887-3.37-13.557-3.075.02.088.888.142 2.508.63 1.615.46 3.982 1.355 6.866 2.516 2.879 1.134 6.274 2.535 9.814 3.4 1.985.472 4.016.775 6.002.653a12.125 12.125 0 01-1.513-1.076'
         ></path>
         <g transform='translate(32.933 7.219)'>
            <mask id='y1e88hqu2c' fill='#fff'>
               <use xlinkHref='#srk3t39nbb'></use>
            </mask>
            <path
               fill='#FFF'
               d='M13.129.214c-7.24 0-13.11 5.87-13.11 13.11 0 2.529.718 4.89 1.959 6.891L16.14.566a13.099 13.099 0 00-3.01-.352'
               mask='url(#y1e88hqu2c)'
               opacity='0.25'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M53.926 10.306L38.77 31.334c.42.217.853.412 1.3.583l14.822-20.564a13.471 13.471 0 00-.966-1.047M50.752 8.3l-14.82 20.563c.25.303.513.594.789.874L51.833 8.771c-.351-.173-.712-.33-1.081-.47'
         ></path>
         <path
            fill='#FFF'
            d='M41.748 32.433c.968.228 1.977.352 3.014.352 7.24 0 13.11-5.869 13.11-13.11 0-2.529-.718-4.89-1.96-6.893l-14.164 19.65z'
            opacity='0.25'
         ></path>
      </g>
   </svg>
)

SixesIcon.defaultProps = {
   width: 58,
   height: 26
}
export default SixesIcon
