import styled from 'styled-components'

import { Typo16BlackBarlowBoldItalicCapital } from '../../../common/styleGuide/Typos'
import ImageComponent from '../../../common/components/BaseImageComponent'

export const ItemContainer = styled.div`
   width: 156px;
   display: flex;
   flex-direction: column;
`
export const StadiumIcon = styled(ImageComponent)`
   min-width: 156px;
   height: 124px;
   margin-bottom: 1%;
   padding-bottom: 5px;
`
export const StadiumTitle = styled(Typo16BlackBarlowBoldItalicCapital)`
   padding-top: 2%;
   padding-bottom: 2%;
`
