import styled from '@emotion/styled'

import { Typo28WhiteBarlowBoldItalicCapital } from '../../../../common/styleGuide/Typos'
import colors from '../../../../common/themes/Colors'

export const Container = styled.div`
   display: flex;
   justify-content: space-between;
   padding-left: 100px;
   padding-right: 206px;
   padding-top: 24px;
   padding-bottom: 24px;
   width: 550px;
   background-color: ${colors.charcoalGrey};
`
export const TextComponent = styled(Typo28WhiteBarlowBoldItalicCapital)`
   font-size: 24px;
`
