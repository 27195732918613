import styled from 'styled-components'
import tw from 'twin.macro'
import Colors from '../../themes/Colors'

import {
   Typo10LightBlueBarlowSemiBoldItalic,
   Typo16WhiteBarlowBoldItalic
} from '../../styleGuide/Typos'
import { StyledButton } from '../Button/styledComponents'
import { tablet, mobile, minDeviceWidth } from '../../utils/MixinUtils'
import ImageComponent from '../BaseImageComponent'

interface SideNavBarDivProps {
   showSideNavBar: boolean
}

export const BetaVersionInfo = styled(Typo10LightBlueBarlowSemiBoldItalic)`
   padding: 4px 16px;
   border-radius: 5px;
   background-color: ${Colors.marineBlueTwo};
`

export const SideBarHandler = styled.div`
   ${tablet} {
      position: fixed;
      width: ${props => (!props.showSideNavBar ? '17px' : '276px')};
      transition: width 0.3s linear, background-color 0.3s linear;
      scroll-behavior: smooth;
      z-index: 2;
   }
`

export const SideBarClickDetector = styled.div`
   position: fixed;
   width: 100%;
   height: 100%;
   background: ${Colors.dark};
   opacity: 0.8;
   transition: all 0.3s linear;
`

export const SideNavBarDiv = styled.div<SideNavBarDivProps>`
   ${tw`flex flex-col justify-center  text-xl  text-red-400`};

   width: ${props => (!props.showSideNavBar ? '17px' : '276px')};
   background-color: ${props =>
      !props.showSideNavBar ? `${Colors.dark}` : `${Colors.darkTwo}`};
   overflow-y: ${props => (!props.showSideNavBar ? 'hidden' : '')};

   transition: width 0.3s linear, background-color 0.3s linear;
   scroll-behavior: smooth;
   height: 100vh;
   position: fixed;

   ${tablet} {
      box-shadow: 1px 2px ${Colors.charcoalGrey};
   }
`

export const LaunchButtonContainer = styled.div`
   position: fixed;
   bottom: 29px;
   left: 24px;
   right: 24px;
`

export const ButtonContainer = styled(StyledButton)`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   width: 228px;
   height: 60px;
   background: ${Colors.pumpkinOrange};
`

export const LaunchGameText = styled(Typo16WhiteBarlowBoldItalic)`
   padding-top: 1px;
   padding-left: 5px;
`

export const LogoutButtonWrapper = styled.div`
   padding: 22px 144px 21px 32px;
   max-width: 276px;
`
export const ShareYourProfileButton = styled(StyledButton)`
   background: ${Colors.pumpkinOrange};
   padding-top: 12px;
   padding-bottom: 12px;
   padding-left: 24px;
   padding-right: 24px;
   margin-left: 16px;
   margin-right: 16px;
   margin-bottom: 16px;
   min-width: 245px;
   display: flex;
   justify-content: center;
   border-radius: 0px;
`

export const ShareIcon = styled.span`
   font-size: 20px;
   margin-left: 5px;
   color: ${Colors.white};
`
export const IBCLogo = styled(ImageComponent)`
   width: 180px;
   height: 142px;
   margin: 16px 47px 48px 48px;
   object-fit: contain;
`

export const SideNavBarItemsWrapper = styled.div`
   flex-grow: 1;
`

export const SlideNavBarBottom = styled.div`
   padding-top: 15px;
   padding-bottom: 30px;
   text-align: center;
`

export const ShareWithFriendsPopupWrapper = styled.div`
   position: fixed;
   bottom: 180px;
   left: 0px;
   z-index: 2;
   height: ${props =>
      !props.shouldShowShareWithFriendsPopup ? '0%' : '140px'};
   transition: height 0.3s;
`
export const FloatingMenu = styled.div`
   cursor: pointer;
   position: absolute;
   top: 32px;
   right: -22px;
   ${tablet} {
      visibility: visible;
   }
   ${mobile} {
      visibility: visible;
   }
`

export const MenuButton = styled.button`
   background-color: rgb(255, 255, 255);
   transform: translateX(-40%);
   border: 0px;
   border-radius: 50%;
   box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
      rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
   color: rgb(107, 119, 140);
   cursor: pointer;
   height: 24px;
   outline: 0px;
   padding: 0px;
   transition: background-color 100ms linear 0s, color 100ms linear 0s,
      opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
   width: 24px;
   &:hover {
      background-color: rgb(38, 132, 255);
      color: rgb(255, 255, 255);
      opacity: 1;
   }
   ${minDeviceWidth(1366)} {
      display: none;
   }
`

export const SpanButton = styled.span`
   display: flex;
   justify-content: center;
`
