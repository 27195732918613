import styled, { css } from 'styled-components'
import Colors from '../../themes/Colors'

export const BaseText = styled.span``

export const TwoLineEllipsis = css`
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
`

//#region OTHERS
export const BasePoppinsText = styled(BaseText)`
   font-family: 'Poppins';
`

export const Typo16BlackPoppins = styled(BasePoppinsText)`
   font-size: 16px;
   color: ${Colors.black};
`

export const BaseRobotoText = styled(BaseText)`
   font-family: 'Roboto';
`

export const BaseRobotoMediumText = styled(BaseRobotoText)`
   font-weight: 500;
`

export const Typo12BlackRoboto = styled(BaseRobotoText)`
   font-size: 12px;
   color: ${Colors.black};
`

export const Typo12PinkishOrangeRoboto = styled(BaseRobotoText)`
   font-size: 12px;
   color: ${Colors.pinkishOrange};
`

export const Typo14DarkRR = styled(BaseRobotoText)`
   font-size: 14px;
   color: ${Colors.dark};
`

export const Typo16DuskRR = styled(BaseRobotoText)`
   font-size: 16px;
   color: ${Colors.dusk};
`

export const Typo26BlackRoboto = styled(BaseRobotoText)`
   font-size: 26px;
`
export const BaseDimboText = styled.span`
   font-family: 'Dimbo';
`
export const Typo14WhiteDimbo = styled(BaseDimboText)`
   font-size: 14px;
   color: ${Colors.white};
`
export const Typo16WhiteDimbo = styled(BaseDimboText)`
   font-size: 16px;
   color: ${Colors.white};
`
export const Typo36WhiteDimbo = styled(BaseDimboText)`
   color: ${Colors.white};
   font-size: 36px;
`
export const Typo27WhiteDimbo = styled(BaseDimboText)`
   color: ${Colors.white};
   font-size: 27px;
`
//#endregion

//#region  BASE TYPOS
export const BaseBarlowText = styled(BaseText)`
   font-family: Barlow;
`

export const BaseBarlowSemiBoldText = styled(BaseBarlowText)`
   font-weight: 600;
`
export const BaseBarlowSemiBoldItalic = styled(BaseBarlowSemiBoldText)`
   font-style: italic;
`
export const BaseBarlowBoldText = styled(BaseBarlowText)`
   font-weight: bold;
`

export const Typo14PaleGreyThreeBarlowBoldItalicText = styled(
   BaseBarlowBoldText
)`
   color: ${Colors.paleGreyThree};
`

export const BaseBarlowBoldItalicText = styled(BaseBarlowBoldText)`
   font-style: italic;
`

export const BaseBarlowExtraBoldItalicText = styled(BaseBarlowBoldItalicText)`
   font-weight: 800;
`

//#endregion

//#region TYPO 6

export const Typo6PointThreeWhiteBarlowBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 6.3px;
   color: white;
   font-weight: bold;
`

//#endregion

//#region TYPO 6

export const Typo10LightBlueBarlowSemiBoldItalic = styled(
   BaseBarlowSemiBoldText
)`
   font-size: 10px;
   color: ${Colors.lightBlue};
   font-weight: bold;
`
//#endregion

//#region TYPO 11

export const Typo11WhiteBarlowBolditalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 11px;
   color: white;
   text-transform: uppercase;
   font-weight: bold;
`

export const Typo11DarkRumBoldItalicCapital = styled(
   Typo11WhiteBarlowBolditalicCapital
)`
   color: ${Colors.darkRum};
`

//#endregion

//#region TYPO 12
export const Typo12YellowOrangeBarlowBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 12px;
   color: ${Colors.yellowOrange};
   text-transform: uppercase;
`

export const Typo12PointFiveWhiteBarlowBoldItalic = styled(
   Typo12YellowOrangeBarlowBoldItalic
)`
   color: white;
   font-size: 12.5px;
   font-weight: bold;
   text-transform: none;
`

//#endregion

//#region  TYPO 14
export const Typo14WhiteBarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 14px;
   color: ${Colors.white};
   text-transform: uppercase;
`

export const Typo14PointOneWhiteBarlowBoldItalicCapital = styled(
   Typo14WhiteBarlowBoldItalic
)`
   text-transform: uppercase;
   font-size: 14.1px;
`

export const Typo14WhiteBarlowExtraoldItalic = styled(
   BaseBarlowExtraBoldItalicText
)`
   font-size: 14px;
   color: ${Colors.white};
`
export const Typo14BattleShipGreyBarlowSemiBoldItalic = styled(
   BaseBarlowSemiBoldItalic
)`
   font-size: 14px;
   letter-spacing: 0.5px;
   color: ${Colors.battleShipGrey};
`
export const Typo14CoolGreyBarlowBoldItalic = styled(
   Typo14BattleShipGreyBarlowSemiBoldItalic
)`
   font-size: 14px;
   letter-spacing: 0.5px;
   color: ${Colors.coolGrey};
`

export const Typo14CoolGreyBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 14px;
   letter-spacing: 0.5px;
   color: ${Colors.coolGrey};
   text-transform: uppercase;
`

export const Typo14BattleshipGreyBarlow = styled(BaseBarlowText)`
   font-size: 14px;
   color: ${Colors.battleShipGrey};
   letter-spacing: 0.5px;
   text-transform: uppercase;
`

export const Typo14PumpkinOrangeBarlowBoldItalic = styled(
   Typo14BattleshipGreyBarlow
)`
   color: ${Colors.pumpkinOrange};
   text-transform: none !important;
`

export const Typo14CoolGreyBarlowSemiBoldItalicCapital = styled(
   BaseBarlowSemiBoldItalic
)`
   font-size: 14px;
   color: ${Colors.coolGrey};
   text-transform: uppercase;
`

export const Typo14PaleGreyBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 14px;
   color: ${Colors.paleGrey};
   text-transform: uppercase;
`

export const Typo14PumpkinOrangeBarlowItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 14px;
   letter-spacing: 0.29px;
   text-align: right;
   color: ${Colors.pumpkinOrange};
   text-transform: uppercase;
`

export const Typo14DodgerBlueBarlowBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 14px;
   color: ${Colors.dodgerBlue};
`
//#endregion

//#region  TYPO 16
export const Typo16Black2BarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 16px;
   color: ${Colors.black2};
   text-transform: uppercase;
`
export const Typo16WhiteBarlowBoldCapital = styled(BaseBarlowBoldText)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.white};
   text-transform: uppercase;
`
export const Typo16WhiteBarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.white};
`

export const Typo16WhiteBarlowBoldItalicCapital = styled(
   Typo16WhiteBarlowBoldItalic
)`
   text-transform: uppercase;
`

export const Typo16BlackBarlowExtraBoldItalic = styled(
   Typo14WhiteBarlowExtraoldItalic
)`
   font-size: 16px;
   color: ${Colors.black};
`
export const Typo16BlackBarlowExtraBoldItalicCapital = styled(
   Typo16BlackBarlowExtraBoldItalic
)`
   text-transform: uppercase;
`

export const Typo16BlackBarlowBold = styled(BaseBarlowBoldText)`
   font-size: 16px;
   letter-spacing: 0.5px;
`
export const Typo16BlackBarlowBoldItalic = styled(Typo16BlackBarlowBold)`
   font-style: italic;
`
export const Typo16BlackBarlowBoldItalicCapital = styled(
   Typo16BlackBarlowBoldItalic
)`
   text-transform: uppercase;
`

export const BarlowMediumItalicText = styled(BaseBarlowText)`
   font-weight: 500;
   font-style: italic;
`
export const Typo16YellowOrangeBarlowBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 16px;
   color: ${Colors.yellowOrange};
   text-transform: uppercase;
`

export const Type16LightGreyTwoBarlowBoldText = styled(BaseBarlowBoldText)`
   font-size: 30px;
   color: ${Colors.lightGrey};
`

export const Type16LightBlueGreyTwoBarlowMediumItalicText = styled(
   BaseBarlowText
)`
   font-size: 16px;
   font-weight: 500;
   font-style: italic;
   color: ${Colors.lightBlueGreyTwo};
`

export const Typo16CoolGreyBarlowMediumItalicCapital = styled(
   BarlowMediumItalicText
)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.coolGrey};
   text-transform: uppercase;
`

export const Typo16PaleGreyBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.paleGrey};
   text-transform: uppercase;
`
export const Typo30LightYellowBarlowBoldItalic = styled(BaseText)`
   font-size: 30px;
   letter-spacing: 1px;
   color: ${Colors.lightYellow};
   font-style: italic;
   font-weight: bold;
   font-family: Barlow;
`
export const Typo36CharcoalGreyBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 36px;
   color: ${Colors.charcoalGrey};
`
export const Typo14CharcoalGreyBoldItalic = styled(
   Typo36CharcoalGreyBoldItalic
)`
   font-size: 14px;
`

export const Typo24PaleGreyBarlowBoldItalicCapital = styled(
   Typo16PaleGreyBarlowBoldItalicCapital
)`
   font-size: 24px;
`

export const Typo16BattleShipGreyBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.battleShipGrey};
   text-transform: uppercase;
`
export const Typo16BattleShipGreyBarlowSemiBoldCapital = styled(
   BaseBarlowSemiBoldText
)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.battleShipGrey};
   text-transform: uppercase;
`

export const Typo16BattleShipGreyBarlowBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.battleShipGrey};
`

export const Typo16CoolGreyBarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 16px;
   letter-spacing: 0.57px;
   color: ${Colors.coolGrey};
`

export const Typo16PaleGreyBarlowSemiBoldItalicCapital = styled(
   BaseBarlowSemiBoldItalic
)`
   font-size: 16px;
   letter-spacing: 0.5px;
   color: ${Colors.paleGrey};
`
//#endregion

//#region  TYPO 18
export const Typo18BlackBarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: #37342c;
`
export const Typo18MidGreenBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 18px;
   color: #4bb747;
   text-transform: uppercase;
`
export const Typo18AdobeBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 18px;
   color: #be6644;
   text-transform: uppercase;
`
export const Typo18LightBlueGrayBarlowBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: ${Colors.lightBlueGrey};
`

export const Typo18LightBlueGrayBarlowBoldItalicCapital = styled(
   Typo18LightBlueGrayBarlowBoldItalic
)`
   text-transform: uppercase;
`

export const Typo18WhiteBarlowBoldItaclicCapital = styled(
   Typo18LightBlueGrayBarlowBoldItalicCapital
)`
   color: white;
`

export const Typo18PaleGreyBarlowSemiBoldItalicCapital = styled(
   BaseBarlowSemiBoldItalic
)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: ${Colors.paleGrey};
   text-transform: uppercase;
`

export const Typo18PaleGreyBarlowSemiBoldItalic = styled(
   BarlowMediumItalicText
)`
   font-size: 18px;
   color: ${Colors.paleGrey};
   line-height: 1.67;
`
export const Typo18BattleShipGreyBarlowSemiBoldCapital = styled(
   BaseBarlowSemiBoldText
)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: ${Colors.battleShipGrey};
   text-transform: uppercase;
`
export const Typo18BattleShipGreyBarlowSemiBold = styled(
   BaseBarlowSemiBoldText
)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: ${Colors.battleShipGrey};
`

export const Typo18SilverBarlowSemiBoldCapital = styled(BaseBarlowSemiBoldText)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: ${Colors.silver};
   text-transform: uppercase;
`

export const Typo18SilverBarlowSemiBold = styled(BaseBarlowSemiBoldText)`
   font-size: 18px;
   letter-spacing: 0.5px;
   color: ${Colors.silver};
`

export const Typo18PaleGreyBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 18px;
   color: ${Colors.paleGrey};
   text-transform: uppercase;
   white-space: pre-wrap;
`

export const Typo18SilverBarlowBoldItalic = styled(BaseBarlowSemiBoldItalic)`
   font-size: 18px;
   color: ${Colors.silver};
   line-height: 1.67;
   letter-spacing: 0.3px;
   text-align: center;
`

export const Typo18SilverBarlowItalic = styled(BaseBarlowText)`
   font-family: Barlow;
   font-size: 18px;
   font-weight: 600;
   font-stretch: normal;
   font-style: italic;
   line-height: 1.67;
   letter-spacing: 0.3px;
   text-align: center;
   color: ${Colors.silver};
`

//#endregion

//#region  TYPO 19
export const Typo19Point6WhiteBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 19.6px;
   color: ${Colors.white};
   text-transform: uppercase;
`
//#endregion

//#region  TYPO 20
export const Typo20WhiteBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 24px;
   color: ${Colors.white};
   text-transform: uppercase;
`
export const Typo20LightBlueGrayBarlowBoldItalic = styled(
   Typo18LightBlueGrayBarlowBoldItalic
)`
   font-size: 20px;
`

export const Typo20SilverBarlowBoldCapital = styled(BaseBarlowBoldText)`
   font-size: 20px;
   letter-spacing: 0.5px;
   color: ${Colors.silver};
   text-transform: uppercase;
`
export const Typo20SilverBarlowBoldItalic = styled(
   Typo20SilverBarlowBoldCapital
)`
   font-style: italic;
`

export const Typo20SilverBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 20px;
   letter-spacing: 0.5px;
   color: ${Colors.silver};
   text-transform: uppercase;
`
export const Typo20PointSevenLightBlackBarlowSemiBoldItalicCapital = styled(
   Typo20SilverBarlowBoldItalicCapital
)`
   font-weight: 800;
   color: ${Colors.lightBlack};
`

export const Typo20LightBlueGreySemiBoldBarlowText = styled(
   BaseBarlowSemiBoldText
)`
   font-size: 20px;
   color: ${Colors.lightBlueGrey};
   text-transform: uppercase;
`
//#endregion

//#region  TYPO 22
export const BaseBarlowExtraBoldTextItalic = styled(BaseBarlowText)`
   font-weight: 800;
   font-style: italic;
`
export const Typo22WhiteSemiBoldBarlowText = styled(
   BaseBarlowExtraBoldTextItalic
)`
   font-size: 22.4px;
   color: ${Colors.white};
`
//#endregion

//#region  TYPO 24
export const Typo24WhiteBarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 24px;
   color: ${Colors.white};
`

export const Typo24WhiteBarlowSemiBoldItalic = styled(
   Typo24WhiteBarlowBoldItalic
)`
   font-weight: 600;
`

export const Typo24WhiteBarlowBoldItalicCapital = styled(
   Typo24WhiteBarlowBoldItalic
)`
   text-transform: uppercase;
`

export const Typo24LightBlueGrayBarlowBoldItalicCapital = styled(
   Typo24WhiteBarlowBoldItalicCapital
)`
   color: ${Colors.lightBlueGrey};
`

export const Typo24BlackBarlowBoldItalicCapital = styled(
   Typo24LightBlueGrayBarlowBoldItalicCapital
)`
   color: ${Colors.black};
`

export const Typo24BlackBarlowBoldNormalCapital = styled(
   Typo24BlackBarlowBoldItalicCapital
)`
   font-style: normal;
`

export const Typo16LightBlueGrayBarlowBoldItalicCapital = styled(
   Typo24LightBlueGrayBarlowBoldItalicCapital
)`
   font-size: 16px;
`

export const Typo24PaleGreyBarlowSemiBoldItalic = styled(
   BaseBarlowSemiBoldItalic
)`
   font-size: 24px;
   letter-spacing: 0.5px;
   color: ${Colors.paleGrey};
`

export const Typo24SilverBarlowSemiBoldItalic = styled(
   Typo24PaleGreyBarlowSemiBoldItalic
)`
   color: ${Colors.silver};
`

export const Typo24PaleGreyBarlowSemiBoldItalicCapital = styled(
   Typo24PaleGreyBarlowSemiBoldItalic
)`
   text-transform: uppercase;
`

export const Typo24OceanBlueBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 24px;
   letter-spacing: 0.5px;
   color: ${Colors.oceanBlue};
   text-transform: uppercase;
`
export const Typo24LightYellowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 24px;
   color: ${Colors.lightYellow};
   text-transform: uppercase;
`

export const Typo24DarkTwoBoldItalic = styled(BaseBarlowSemiBoldItalic)`
   font-size: 24px;
   color: ${Colors.darkTwo};
`
//#endregion

//#region TYPO 28

export const Typo28WhiteBarlowBoldItalicCapital = styled(
   Typo24PaleGreyBarlowSemiBoldItalicCapital
)`
   color: white;
   font-size: 28px;
`

//#endregion

//#region TYPO 30

export const Typo30LightYellowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 30px;
   color: ${Colors.lightYellow};
   text-transform: uppercase;
`

export const Typo30WhiteBarlowExtraBoldItalic = styled(
   Typo14WhiteBarlowExtraoldItalic
)`
   font-size: 30px;
`
export const Type30LightGreyBarlowBoldText = styled(BaseBarlowBoldText)`
   font-size: 30px;
   color: ${Colors.lightGrey};
`
//#endregion

export const Typo32LightBlueGreyBarlowBoldItalicCapital = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 32px;
   letter-spacing: 0.67px;
   color: ${Colors.lightBlueGrey};
   text-transform: uppercase;
`

export const Typo32BlackBarlowBoldItalic = styled(
   Typo30WhiteBarlowExtraBoldItalic
)`
   font-size: 32px;
   color: ${Colors.black};
   font-weight: bold;
`

export const Typo32LightBlueGreyItalic = styled(BaseBarlowText)`
   font-family: Barlow;
   font-size: 32px;
   font-weight: bold;
   font-stretch: normal;
   font-style: italic;
   line-height: normal;
   letter-spacing: 0.67px;
   text-align: center;
   color: ${Colors.lightBlueGrey};
`

export const Typo36SliverBarlowExtraBoldItalicCapital = styled(
   BaseBarlowExtraBoldItalicText
)`
   font-size: 36px;
   color: ${Colors.myProfileTitleSilver};
   text-transform: uppercase;
`

export const Typo36SliverBarlowExtraBoldItalic = styled(
   BaseBarlowBoldItalicText
)`
   font-size: 36px;
   color: ${Colors.myProfileTitleSilver};
   letter-spacing: 0.6px;
`

export const Typo36SilverBarlowItalic = styled(BaseBarlowText)`
   font-family: Barlow;
   font-size: 36px;
   font-weight: 600;
   font-stretch: normal;
   font-style: italic;
   line-height: normal;
   letter-spacing: 0.6px;
   text-align: center;
   color: ${Colors.silver};
`

export const Typo36DarkTwoBoldItalic = styled(BaseBarlowSemiBoldItalic)`
   font-size: 36px;
   color: ${Colors.darkTwo};
`

export const Typo42PaleGreyBarlowBoldItalicCapital = styled(
   Typo16PaleGreyBarlowBoldItalicCapital
)`
   font-size: 42px;
`
export const Typo48WhiteBarlowBoldItalicCapital = styled(
   Typo42PaleGreyBarlowBoldItalicCapital
)`
   font-size: 48px;
   color: white;
`

export const Typo45WhiteBarlowBoldItalicCapital = styled(
   Typo48WhiteBarlowBoldItalicCapital
)`
   font-size: 45px;
`

export const Typo54WhiteBarlowBoldItalic = styled(BaseBarlowBoldItalicText)`
   font-size: 54px;
   letter-spacing: 0.64px;
   color: ${Colors.white};
`
