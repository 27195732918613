import React from 'react'
import '../../../matches/components/WagonWheel/index.css'
interface Props {
   width: number
   height: number
   className?: string
   scoreTextAndLineEndPoints: any
   displayStringFormat: string
}

export const LINES_MULTIPLIER = 1.17
export const TEXT_MULTIPLIER = 0.7
export const GROUND_VIEW_BOX_RADIUS = 220

const round = (value: number, precision: number): number => {
   const multiplier = Math.pow(10, precision || 0)
   return Math.round(value * multiplier) / multiplier
}

const Ground = (props: Props) => (
   <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${GROUND_VIEW_BOX_RADIUS * 2} ${GROUND_VIEW_BOX_RADIUS *
         2}`}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={props.className}
   >
      <defs>
         <radialGradient
            cx='50%'
            cy='50%'
            fx='50%'
            fy='50%'
            r='50%'
            gradientTransform='translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.991810),translate(-0.500000,-0.500000)'
            id='ground-radialGradient-1'
         >
            <stop stopColor='#689E0F' offset='0%'></stop>
            <stop stopColor='#6AAD0A' offset='100%'></stop>
         </radialGradient>
         <circle
            id='ground-circle-path-2'
            cx='220'
            cy='220'
            r='219'
            strokeWidth='3'
            stroke='#f6f88b'
         ></circle>
         <clipPath id='clip'>
            <use xlinkHref='#ground-circle-path-2' />
         </clipPath>
      </defs>
      <g
         id='ground-Page-1'
         stroke='none'
         strokeWidth='1'
         fill='none'
         fillRule='evenodd'
      >
         <g
            id='ground-match_centre_Survival'
            transform='translate(-416.000000, -491.000000)'
         >
            <g
               id='ground-wagonWheelIcon'
               clipPath='url(#clip)'
               transform='translate(416.000000, 491.000000)'
            >
               <mask id='ground-mask-3' fill='white'>
                  <use xlinkHref='#ground-circle-path-2'></use>
               </mask>
               <use
                  id='ground-Mask'
                  fill='url(#ground-radialGradient-1)'
                  xlinkHref='#ground-circle-path-2'
               ></use>
               <polygon
                  id='ground-Rectangle-3'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='27.9542567 -10.6226175 55.9085133 -10.6226175 55.9085133 456.772554 27.9542567 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-2'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='139.212198 -10.6226175 167.166455 -10.6226175 167.166455 456.772554 139.212198 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-4'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='251.029225 -10.6226175 278.983482 -10.6226175 278.983482 456.772554 251.029225 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-7'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='305.819568 -10.6226175 333.773825 -10.6226175 333.773825 456.772554 305.819568 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='83.86277 -10.6226175 111.817027 -10.6226175 111.817027 456.772554 83.86277 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-3'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='195.120712 -10.6226175 223.074968 -10.6226175 223.074968 456.772554 195.120712 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-5'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='361.728081 -10.6226175 389.682338 -10.6226175 389.682338 456.772554 361.728081 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-8'
                  fillOpacity='0.2'
                  fill='#89C337'
                  mask='url(#mask-3)'
                  points='417.636595 -10.6226175 445.590851 -10.6226175 445.590851 456.772554 417.636595 456.772554'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 63.735705) rotate(-270.000000) translate(-213.011436, -63.735705) '
                  points='199.034307 -169.961881 226.988564 -169.961881 226.988564 297.433291 199.034307 297.433291'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-10'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 8.945362) rotate(-270.000000) translate(-213.011436, -8.945362) '
                  points='199.034307 -224.752224 226.988564 -224.752224 226.988564 242.642948 199.034307 242.642948'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-6'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 119.644219) rotate(-270.000000) translate(-213.011436, -119.644219) '
                  points='199.034307 -114.053367 226.988564 -114.053367 226.988564 353.341804 199.034307 353.341804'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-9'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 175.552732) rotate(-270.000000) translate(-213.011436, -175.552732) '
                  points='199.034307 -58.1448539 226.988564 -58.1448539 226.988564 409.250318 199.034307 409.250318'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-2'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 286.810673) rotate(-270.000000) translate(-213.011436, -286.810673) '
                  points='199.034307 53.1130877 226.988564 53.1130877 226.988564 520.508259 199.034307 520.508259'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-4'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 398.068615) rotate(-270.000000) translate(-213.011436, -398.068615) '
                  points='199.034307 164.371029 226.988564 164.371029 226.988564 631.766201 199.034307 631.766201'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 230.902160) rotate(-270.000000) translate(-213.011436, -230.902160) '
                  points='199.034307 -2.79542567 226.988564 -2.79542567 226.988564 464.599746 199.034307 464.599746'
               ></polygon>
               <polygon
                  id='ground-Rectangle-3-Copy-3'
                  fillOpacity='0.15'
                  fill='#7CB627'
                  mask='url(#mask-3)'
                  transform='translate(213.011436, 342.719187) rotate(-270.000000) translate(-213.011436, -342.719187) '
                  points='199.034307 109.021601 226.988564 109.021601 226.988564 576.416773 199.034307 576.416773'
               ></polygon>
            </g>
         </g>
      </g>
      {props.scoreTextAndLineEndPoints.map(
         (eachTextAndEndPoint: any, index: number) => (
            <g clipPath='url(#clip)' key={`lines - ${index} `}>
               <line
                  key={`line - ${index} `}
                  className='line'
                  x1={GROUND_VIEW_BOX_RADIUS * LINES_MULTIPLIER - 40} // props.width/2 * 1.17 - 40
                  y1={GROUND_VIEW_BOX_RADIUS * LINES_MULTIPLIER} // props.width/2 * 1.17
                  x2={eachTextAndEndPoint.lineEndPosition.left - 38}
                  y2={eachTextAndEndPoint.lineEndPosition.top}
               />
               <text
                  key={`text - ${index} `}
                  x={eachTextAndEndPoint.scoreTextPosition.left + 65}
                  y={eachTextAndEndPoint.scoreTextPosition.top + 85}
                  className='text'
                  textAnchor='middle'
               >
                  {props.displayStringFormat.format(
                     round(eachTextAndEndPoint.scoreTextPosition.value, 1)
                  )}
               </text>
            </g>
         )
      )}
   </svg>
)

Ground.defaultProps = {
   displayStringFormat: `{0}`,
   width: 444,
   height: 444,
   scoreTextAndLineEndPoints: []
}
export default Ground
