import React, { ReactElement } from 'react'

import { StatsItem } from '../../stores/types'

import StatItem from '../StatItem'

import { MatchesInfoContainer } from './styledComponents'

interface StatsListProps {
   listItems: StatsItem[]
   className?: string
}

const StatsList = (props: StatsListProps): ReactElement => {
   const { listItems, className } = props

   return (
      <MatchesInfoContainer className={className}>
         {listItems.map((data, index) => (
            <StatItem key={index} item={data} />
         ))}
      </MatchesInfoContainer>
   )
}

export default StatsList
