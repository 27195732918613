import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import UsernameAndPic from '../UsernameAndPic'
import {
   LeaderboardItemCardDiv,
   UserDetails,
   TitleText,
   LeaderboardResourceDetails,
   LeaderboardResourceText,
   CountryImage
} from './styledComponents'

interface LeaderboardHeaderTitleProps {
   countryPic: string
   rank: number
   profilePic: string
   name: string
   score: number
   isSelf: boolean
}

const LeaderboardItemCard = (
   props: LeaderboardHeaderTitleProps
): ReactElement => {
   const { t } = useTranslation()
   return (
      <LeaderboardItemCardDiv isSelf={props.isSelf}>
         <UserDetails>
            <CountryImage src={props.countryPic} />
            <TitleText>{t('leaderboards.hashString') + props.rank}</TitleText>
         </UserDetails>
         <UsernameAndPic name={props.name} url={props.profilePic} />
         <LeaderboardResourceDetails>
            <LeaderboardResourceText>{props.score}</LeaderboardResourceText>
         </LeaderboardResourceDetails>
      </LeaderboardItemCardDiv>
   )
}

export default LeaderboardItemCard
