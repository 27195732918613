import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next' // eslint-disable-line

import { Container, TextContainer } from './styledComponents'

export type Props = {
   title: string
   className?: string
   children?: React.ReactNode
}

export const Heading = (props: Props): ReactElement => {
   const { title } = props

   return (
      <Container className={props.className}>
         <TextContainer>{title}</TextContainer>
         {props.children}
      </Container>
   )
}

export default Heading
