const dayjs = require('dayjs') //eslint-disable-line
const utc = require('dayjs/plugin/utc') //eslint-disable-line
const timezone = require('dayjs/plugin/timezone') //eslint-disable-line
const advancedFormat = require('dayjs/plugin/advancedFormat') //eslint-disable-line
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const DEFAULT_TIME_ZONE = 'Asia/Kolkata'

const getUserTimeZone = (): string =>
   Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIME_ZONE

export const getHours = (seconds: number): number =>
   Math.floor(seconds / (60 * 60))

export const getMinutes = (seconds: number): number =>
   Math.floor((seconds % (60 * 60)) / 60)

export const getSeconds = (seconds: number): number =>
   Math.ceil((seconds % (60 * 60)) % 60)

export const getTimeFormat = (seconds: number): string => {
   const hours: number = getHours(seconds)
   const minutes: number = getMinutes(seconds)
   const updatedSeconds: number = getSeconds(seconds)
   let currentHours = ''

   if (hours > 0) {
      currentHours = hours < 10 ? `0${hours} : ` : `${hours} : `
   }
   const currentMinutes: string =
      minutes < 10 ? `0${minutes} : ` : `${minutes} : `
   const currentSeconds =
      updatedSeconds < 10 ? `0${updatedSeconds}` : `${updatedSeconds}`
   return currentHours + currentMinutes + currentSeconds
}

export const getDateTimeDisplay = (date: string): string =>
   `${dayjs
      .tz(date, DEFAULT_TIME_ZONE)
      .tz(getUserTimeZone())
      .format('DD MMM YYYY - HH:mm (z)')}`
