export const knockoutMatchConstants = {
   playing: 'IN_PROGRESS',
   completed: 'COMPLETED',
   yetToPlay: 'YET_TO_START',
   knockOutType: 'KNOCKOUT',
   finalRound: 'FINAL'
}

export const RoundType = {
   leaderboard: 'LEADERBOARD'
}
