import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, TextComponent } from './styledComponents'

const RankPrizeList = () => {
   const { t } = useTranslation()
   return (
      <Container>
         <TextComponent>{t('tournaments:rank')}</TextComponent>
         <TextComponent>{t('tournaments:prize')}</TextComponent>
      </Container>
   )
}

export default RankPrizeList
