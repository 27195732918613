import React, { ReactElement } from 'react'

import { ResourceDetail } from '../../../stores/types'

import {
   ResourceCountText,
   CommonResourceItemTitleBg,
   CommonResourceItemContainer
} from '../CommonResource/styledComponents'

import {
   IBCResourceIconDimesion,
   CommonBottomBar,
   ResourceName
} from './styledComponents'

interface ResourceItemProps {
   resource: ResourceDetail
}
const ResourceItemCmp = (props: ResourceItemProps): ReactElement => {
   const { resource } = props
   if (resource.value === -1) {
      return <div />
   }
   return (
      <CommonResourceItemContainer>
         <CommonResourceItemTitleBg name={resource.display_name}>
            <IBCResourceIconDimesion
               src={resource.pic}
               name={resource.display_name}
            />
            <ResourceCountText>{resource.value}</ResourceCountText>
         </CommonResourceItemTitleBg>
         <CommonBottomBar>
            <ResourceName>{resource.display_name}</ResourceName>
         </CommonBottomBar>
      </CommonResourceItemContainer>
   )
}

export default ResourceItemCmp
