import React from 'react'

interface CloseCircularButtonIconProps {
   width: number
   height: number
}

const CloseCircularButtonIcon = (props: CloseCircularButtonIconProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 36 36'
      {...props}
   >
      <path
         d='M24.116 11.42a1.117 1.117 0 00-1.582 0l-4.772 4.77-4.705-4.704a1.11 1.11 0 10-1.57 1.57l4.705 4.705-4.737 4.737a1.118 1.118 0 101.582 1.583l4.737-4.738 4.706 4.706a1.11 1.11 0 101.57-1.57l-4.705-4.706 4.771-4.771a1.118 1.118 0 000-1.583zM17.768 0C7.954 0 0 7.954 0 17.768c0 9.813 7.954 17.767 17.768 17.767 9.812 0 17.767-7.955 17.767-17.767S27.581 0 17.768 0zm0 33.315c-8.587 0-15.547-6.961-15.547-15.547 0-8.587 6.96-15.547 15.547-15.547 8.586 0 15.547 6.96 15.547 15.547 0 8.586-6.961 15.547-15.547 15.547z'
         fill='#A3A7B3'
         fillRule='nonzero'
      />
   </svg>
)

CloseCircularButtonIcon.defaultProps = {
   width: 36,
   height: 36
}

export default CloseCircularButtonIcon
