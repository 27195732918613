import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Typo14DodgerBlueBarlowBoldItalic } from '../../../../../common/styleGuide/Typos'
import TournamentMatch from '../../../../stores/models/TournamentMatch'
import WinOrLost from '../../../WinAndLose'

import { ContentWrapper } from '../../BaseMatchCard/styledComponents'
import BaseMatchCardView from '../../BaseMatchCard'

import GotoTournament from '../GotoTournament'
import ProfileWithScoreCard from '../ProfileWithScoreCard'

import { MatchContestantsContainer, VersusContainer } from './styledComponents'

interface LeaderBoardMatchCardProps {
   model: TournamentMatch
}

const KnockOutMatchMatchCard = (
   props: LeaderBoardMatchCardProps
): ReactElement => {
   const { model } = props
   const { t } = useTranslation()
   const { getSelfUserMatchDetails, getOpponentUserMatchDetails } = model
   return (
      <BaseMatchCardView
         matchModel={model}
         renderHeaderChildren={() => (
            <WinOrLost isWon={model.isWon} round={model.roundDisplayName} />
         )}
      >
         <ContentWrapper>
            <MatchContestantsContainer>
               <ProfileWithScoreCard
                  userMatchDetails={getSelfUserMatchDetails()}
                  isSelfUser={true}
               />
               <VersusContainer>
                  <Typo14DodgerBlueBarlowBoldItalic>
                     {t('matches.vs')}
                  </Typo14DodgerBlueBarlowBoldItalic>
               </VersusContainer>
               <ProfileWithScoreCard
                  userMatchDetails={getOpponentUserMatchDetails()}
               />
            </MatchContestantsContainer>
            <GotoTournament tournamentId={model.tournamentId} />
         </ContentWrapper>
      </BaseMatchCardView>
   )
}

export default KnockOutMatchMatchCard
