import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'
import { tablet, mobile } from '../../utils/MixinUtils'

export const MatchesInfoContainer = styled(`div`)`
   display: flex;
   flex-direction: row;
   flex-grow: 1;
   justify-content: space-between;
   align-items: flex-start;
   background-color: ${Colors.darkBlueGrey};
   flex-wrap: wrap;
   ${tablet} {
      justify-content: space-around;
   }
   ${mobile} {
      justify-content: space-around;
   }
`
