import styled from 'styled-components'

import { Typo14PointOneWhiteBarlowBoldItalicCapital } from '../../../../common/styleGuide/Typos'

export const KnockoutMatchContainer = styled.div`
   display: flex;
   align-items: center;
   margin-top: 25px;
`

export const DependencyMatchOneWrapper = styled.div`
   margin-bottom: 14px;
   margin-top: -45px;
`

export const DependencyMatchTwoWrapper = styled.div`
   margin-top: 8px;
`

export const DependencyMatchesWrapper = styled.div``

export const ResultMatchWrapper = styled.div`
   margin-left: -3px;
`

export const ArrowIconWrapper = styled.div`
   margin-top: 1px;
   position: relative;
   margin-left: 5px;
`

export const ArrowText = styled(Typo14PointOneWhiteBarlowBoldItalicCapital)``

export const FirstArrowTextWrapper = styled.div`
   position: absolute;
   top: -19px;
   right: 55px;
`

export const SecondArrowTextWrapper = styled.div`
   position: absolute;
   bottom: 10px;
   right: 55px;
`
