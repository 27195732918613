import React from 'react'
interface Props {
   width: number
   height: number
}

const WeatherConfigIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      id='Layer_1'
      width={props.width}
      height={props.height}
      x='0'
      y='0'
      version='1.1'
      viewBox='0 0 42 43'
      xmlSpace='preserve'
   >
      <image
         id='image0'
         width='42'
         height='43'
         x='0'
         y='0'
         xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAQAAACkltJ1AAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQfkBwkNGw+/eCX2AAADtUlEQVRIx6WXWWyUZRSGn3+m7Uxblm6MVhqwEosLgmAkJYhAAppo3KJx Sww3IImJBiVojDHRCy/0SonGGJULTdB4QYwEEtSb1rBEA8EYvDFQKGuhG9BOF9o+XpTS6cz/z5T2 vZr5lifnfOd853x/QF4Zp4xSZjKLgF66GKCfwcD8u4ryT1POAyxlPWtIcoTdnOQ4J71KXzAwdegI Q1RSRS/FLGMZ0EML+/nVQ3QHfUxdznGTf3nVcQ36hxuttXgaWHCBX9npRO3zUcunh036vhezsO2+ ZcW0sOA2L5mtz62eHjTwI6/kYL+1Mt+mEoOcsQrjGf9muMvBHOynkWdr3FXWZmJNmPJjl1g+Pupi TzmSBR32NXPT08C4De5zo5UZgAf90bQHfd5kxtoP7cuxtcPl2chS7/dJv7HdP33Ddc4dBTvLn2z1 qPcYy1hd57EcW7U562RN+YmnHFI1bbPrx5xxs1t9LzsQbncgBzrshmxb73WXvaqe9lVnXx+NmTQw kX1ePj7hfo3pgDOysds8b4/X/N27C6bWPE+HQAddB3DjpIwxmxP8TBOQKATlIm2M5IwW8zRkVqk4 R/iNw8xhJVeMBSP5mEG/HYRV1dWWBelxhzKjG6Og3HM9rBPVa0OG+5mW5bcSwIBk6EQZ9RnQm1Q5 NQShMwumDr2NVMTexlCoSZeM5WmkVhO1YkVIj7KeRp6g2T2cjTpbS3iEqEZyPqzxVfMia6nlEOci 7VxEI/GIueYw91vp5QydXCLazg2kIsIER8MsvcwP7CZNZ+STYQ1PRTp/jeOAsYm13rglBiaMsMQG 94am/aharAK8xfmT797O88uQAj2unQYQo5SHWWLJpJANvMlLEXcJYJhfAgHL3ewOH8uphDnhcYU7 vGw+HTM1tny5/9jsFu8zouRZ5EI3uTeki06s/O+Mri8C/uUgL1DPSpr8mxO0j7/oTFDJHSxiFauo i8zNUR1l5+iP0fa2kM9YSzE9XOIirZyliwESVDCX+aSoYWZkXo6pjWeD/RPde+ZGfxy2zy7bvOAF u/JGOlPX3JITbGf4emjXmay+sCosGDW+67kpIr+3LippUm61JeSRkF/Dfm19vlys8RX3238TyA4/ cF6BEDrTFW737KTsHbbJl51DYVlknc/5nR15wf0e8G0XWxbGCM0+S7mVpTxEI3dSnbWmm5Mc5gCH aaU7vDxGprRJqqhmLrdTSy13McR/XKCN05yhk/agN9rbAvfEBOWUUUYFcpk0adIMFPri+x/dO068 UQ6I7gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0wOVQxMzoyNzoxNSswMzowMI2LTLAAAAAl dEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMDlUMTM6Mjc6MTUrMDM6MDD81vQMAAAAAElFTkSuQmCC'
      ></image>
   </svg>
)

WeatherConfigIcon.defaultProps = {
   width: 42,
   height: 43
}

export default WeatherConfigIcon
