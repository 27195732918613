import { networkCallWithApisauce as networkCallWithAPISauceWithoutAuth } from '../utils/AuthAPIUtils'
import { networkCallWithApisauce as networkCallWithAPISauceWithAuth } from '../utils/APIUtils'

import TournamentApiFixture from '../../tournaments/services/TournamentsService/index.fixture'
import TournamentsApi from '../../tournaments/services/TournamentsService/index.api'
import TournamentsStore from '../../tournaments/stores/TournamentsStore'
import PlayerProfileService from '../services/PlayerProfileService/index api'
import AuthService from '../services/AuthService/index.api'
import MatchesService from '../../matches/services/MatchesService/index.api'
import MatchesStore from '../../matches/stores/MatchesStore'
import LeaderboardsStore from '../../leaderboard/stores/LeaderboardsStore'
import LeaderboardsApi from '../../leaderboard/services/GlobalLeaderboardService/index.api'

import PlayerProfileStore from './PlayerProfileStore'
import AuthStore from './AuthStore'
import CricketConstantsStore from './CricketConstantsStore'

const isFixtures = false

const authServiceInstance = new AuthService(networkCallWithAPISauceWithoutAuth)
const authStore = new AuthStore(authServiceInstance)

export const authNetworkCallWithApisauce = networkCallWithAPISauceWithAuth(
   authStore
)

const playerProfileServiceInstance = new PlayerProfileService(
   authNetworkCallWithApisauce
)
const myProfileStore = new PlayerProfileStore(playerProfileServiceInstance)

const matchesServiceInstance = new MatchesService(authNetworkCallWithApisauce)
const matchesStore = new MatchesStore(matchesServiceInstance)

const gettournamentsServiceInstance = () => {
   if (isFixtures) {
      return new TournamentApiFixture()
   }
   return new TournamentsApi(authNetworkCallWithApisauce)
}

const tournamentsStore = new TournamentsStore(gettournamentsServiceInstance())

const cricketConstantsStore = new CricketConstantsStore(
   playerProfileServiceInstance
)
const leaderboardServicesInstance = new LeaderboardsApi(
   authNetworkCallWithApisauce
)
const leaderboardStore = new LeaderboardsStore(leaderboardServicesInstance)

export default {
   myProfileStore,
   matchesStore,
   authStore,
   tournamentsStore,
   cricketConstantsStore,
   leaderboardStore
}
