import styled from '@emotion/styled'
import { Typo14WhiteBarlowBoldItalic } from '../../../common/styleGuide/Typos'
import colors from '../../../common/themes/Colors'

export const Wrapper = styled.div`
   margin-bottom: 36px;
   position: relative;
   margin-right: 6px;
`
export const SecondKnockOutMatchCardWrapper = styled.div`
   margin-top: 1.3px;
   margin-left: -32px;
`
export const VersusSymbolWrapper = styled.div`
   position: absolute;
   left: 46.5%;
   top: 43.5%;
`

export const VersusContainer = styled.div`
   width: 41px;
   height: 25px;
   background-color: ${colors.lightSeaBlue};
   border-radius: 15.4px;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const VersusText = styled(Typo14WhiteBarlowBoldItalic)`
   margin: 0px;
`
