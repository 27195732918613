import React, { ReactElement } from 'react'

import ArrowMarkIcon from '../../../common/icons/ArrowMarkIcon'

import { KnockoutMatchType, PaticipantBasicDetails } from '../../stores/types'

import { MatchConfigConstants } from '../../constants/tournamentConstants'

import WinnerPlayerCardComponent from '../WinnerPlayerCard'
import KnockOutMatchCard from '../KnockOutMatchCard'
import TbdCardComponent from '../TbdCardComponent'
import {
   FinalMatchHierarchyContainer,
   ArrowIconWrapper,
   WinnerPlayerComponentWrapper,
   TbdComponentWrapper,
   KnockOutMatchCardWrapper
} from './styledComponent'

interface FinalMatchHierarchyProps {
   knockOutMatches: KnockoutMatchType[]
}

const getMatchWonParticipantDetails = (
   winnerID: string,
   participantsDetails: PaticipantBasicDetails[]
): PaticipantBasicDetails => {
   let winnerParticipant: any
   participantsDetails.forEach((participant, index): void => {
      if (participantsDetails[index].participant_id === winnerID) {
         winnerParticipant = participant
      }
   })
   return winnerParticipant
}

const FinalMatchHierarchy = (props: FinalMatchHierarchyProps): ReactElement => {
   const knockOutMatchModel = props.knockOutMatches[0]
   const { parentMatch } = knockOutMatchModel
   const { winner_id, participants_details } = parentMatch
   return (
      <FinalMatchHierarchyContainer>
         <KnockOutMatchCardWrapper>
            <KnockOutMatchCard match={parentMatch} />
         </KnockOutMatchCardWrapper>
         <ArrowIconWrapper>
            <ArrowMarkIcon height={60} width={120} />
         </ArrowIconWrapper>
         {winner_id ? (
            <WinnerPlayerComponentWrapper>
               <WinnerPlayerCardComponent
                  isPlayerOne={false}
                  playerModel={getMatchWonParticipantDetails(
                     winner_id,
                     participants_details
                  )}
               />
            </WinnerPlayerComponentWrapper>
         ) : (
            <TbdComponentWrapper>
               <TbdCardComponent
                  isPlayerOne={false}
                  participantMatchStatus={MatchConfigConstants.TBD}
               />
            </TbdComponentWrapper>
         )}
      </FinalMatchHierarchyContainer>
   )
}

export default FinalMatchHierarchy
