import styled from 'styled-components'
import colors from '../../../../common/themes/Colors'
import { mobile, tablet } from '../../../../common/utils/MixinUtils'

export const LeaderboardWrapper = styled.div`
   display: flex;
   flex-direction: column;
   background-color: ${colors.charcoalGrey};
   padding-bottom: 54px;
   width: 856px;
   margin-top: 84px;
   ${mobile} {
      width: 100%;
   }
   ${tablet} {
      width: 100%;
   }
`

export const ItemsWrapper = styled.div`
   background-color: ${colors.charcoalGrey};
   margin-left: 27px;
`
