import styled from '@emotion/styled'
import tw from 'twin.macro'

import ImageComponent from '../../../../../common/components/BaseImageComponent'
import { Typo16PaleGreyBarlowSemiBoldItalicCapital } from '../../../../../common/styleGuide/Typos'

export const ProfilePic = styled(ImageComponent)`
   width: 48px;
   height: 48px;
   object-fit: contain;
   border-radius: 50%;
`
export const ProfileWithScoreCardContainer = styled.div`
   ${tw`flex items-center text-left`};
`
export const NameAndScoreCardContainer = styled.div`
   ${tw`flex flex-col ml-2`}
`
export const NameText = styled(Typo16PaleGreyBarlowSemiBoldItalicCapital)`
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   width: 100%;
`
