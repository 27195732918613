import styled from 'styled-components'

import Colors from '../../../common/themes/Colors'
import ImageComponent from '../../../common/components/BaseImageComponent'

import { leaderBoardConstants } from '../../constants/leaderBoardConstants'
interface ContainerProps {
   isSamePerson: boolean
   isPlayerOne: boolean
}

interface TrophyIconWrapperProps {
   isPlayerOne: boolean
}

interface PlayerCountryFlagPicProps {
   isPlayerOne: boolean
}

export const Container = styled.div<ContainerProps>`
   width: 265px;
   height: 80px;
   display: flex;
   transform: skew(-${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   border: 3.1px solid
      ${props => (props.isSelf ? Colors.darkOrange : Colors.pencilGrey)};
   margin-left: ${props => (props.isPlayerOne ? '64px' : '32px')};
   background-color: ${Colors.hanPurple};
   justify-content: space-between;
   position: relative;
   margin-bottom: 0.7px;
`

export const TrophyIconWrapper = styled.div<TrophyIconWrapperProps>`
   position: absolute;
   top: ${props => (props.isPlayerOne ? '-10px' : 'null')};
   right: ${props => (props.isPlayerOne ? '-20px' : 'null')};
   bottom: ${props => (!props.isPlayerOne ? '-10px' : 'null')};
   left: ${props => (!props.isPlayerOne ? '-20px' : 'null')};
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
`

export const PlayerSecondWraper = styled.div<{ isPlayerOne: boolean }>`
   margin-left: ${props => (props.isPlayerOne ? '-36px' : 'null')};
`

export const ProfilePicAndCountryPicWrapper = styled.div`
   height: 80px;
   width: 96px;
   position: relative;
   z-index: 2;
`

export const PlayerProfilePic = styled(ImageComponent)<
   PlayerCountryFlagPicProps
>`
   height: 80px;
   width: 96px;
   clip-path: polygon(31% 0, 97% 0, 68% 100%, 0% 100%);
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   margin-left: ${props => (props.isPlayerOne ? '-15px' : '17px')};
   object-fit: cover;
`

export const PlayerCountryFlagPic = styled(ImageComponent)<
   PlayerCountryFlagPicProps
>`
   position: absolute;
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
   width: 24px;
   height: 17px;
   bottom: 4px;
   right: ${props => (props.isPlayerOne ? '18px' : 'null')};
   left: ${props => (props.isPlayerOne ? 'null' : '18px')};
`
