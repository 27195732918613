import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { resourceValue } from '../../../utils/resourceUtils'

import { ResourceDetail } from '../../../stores/types'

import { ResourceName, CommonBottomBar } from '../ResourceItem/styledComponents'

import {
   SuperRunsResourceContainer,
   LevelCompletionContainer,
   LevelCompletionBg,
   LevelText,
   SuperRunsText,
   SuperRunsIcon
} from './styledComponents'

interface SuperRunsResourceProps {
   resource: ResourceDetail
}
const SuperRunsResource = (props: SuperRunsResourceProps): ReactElement => {
   const { t } = useTranslation()
   const { decimal, fraction, levelCompletion } = resourceValue(
      props.resource.value
   )
   return (
      <SuperRunsResourceContainer>
         <SuperRunsIcon src={props.resource.pic}></SuperRunsIcon>
         <SuperRunsText>{decimal}</SuperRunsText>
         <LevelCompletionContainer>
            <LevelCompletionBg width={fraction}></LevelCompletionBg>
            <LevelText>{levelCompletion}</LevelText>
         </LevelCompletionContainer>
         <CommonBottomBar>
            <ResourceName>{t('resources.superRuns')}</ResourceName>
         </CommonBottomBar>
      </SuperRunsResourceContainer>
   )
}

export default SuperRunsResource
