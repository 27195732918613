import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import {
   LeaderboardHeaderDiv,
   RankText,
   RankandNameTextDiv,
   NameText,
   LeaderboardSuperItemDiv,
   LeaderboardSuperItemText,
   ResourceIcon
} from './styledComponents'

interface LeaderboardHeaderProps {
   picURL: string
   resourceDisplayName: string
}

const LeaderboardHeader = (props: LeaderboardHeaderProps): ReactElement => {
   const { t } = useTranslation()
   const { picURL, resourceDisplayName } = props
   return (
      <LeaderboardHeaderDiv>
         <RankandNameTextDiv>
            <RankText>{t('leaderboards.rank')}</RankText>
            <NameText>{t('leaderboards.name')}</NameText>
         </RankandNameTextDiv>
         <LeaderboardSuperItemDiv>
            <ResourceIcon src={picURL} />
            <LeaderboardSuperItemText>
               {resourceDisplayName}
            </LeaderboardSuperItemText>
         </LeaderboardSuperItemDiv>
      </LeaderboardHeaderDiv>
   )
}

export default LeaderboardHeader
