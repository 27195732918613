import styled from 'styled-components'

import { Typo6PointThreeWhiteBarlowBoldItalic } from '../../../../common/styleGuide/Typos'
import colors from '../../../../common/themes/Colors'

export const Wrapper = styled.div`
   margin-left: 8px;
   position: relative;
`

export const FirstPlayerCardWrapper = styled.div`
   margin-left: 4px;
`

export const SecondPlayerCardWrapper = styled.div<{ isPlayer: boolean }>`
   margin-left: ${props => (props.isPlayer ? '-32px' : 0)};
   /* margin-left: -4px; */
`
export const VersusComponent = styled.div`
   width: 19px;
   height: 11px;
   border-radius: 6.9px;
   background-color: ${colors.lightSeaBlue};
   position: absolute;
   top: 18px;
   left: 40px;
   z-index: 2;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const VersusText = styled(Typo6PointThreeWhiteBarlowBoldItalic)`
   font-size: 8px;
   margin-top: -2px;
`
