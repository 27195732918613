import React from 'react'

interface PathtoFinalsArrowIconProps {
   height: number
   width: number
}

const PathtoFinalsArrowIcon = (props: PathtoFinalsArrowIconProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 148 64'
      {...props}
   >
      <g fill='none' fillRule='evenodd'>
         <path
            stroke='#FFF'
            strokeWidth={1.468}
            d='M.744.943h99.807v61.645H.744'
         />
         <path
            fill='#FFF'
            fillRule='nonzero'
            d='M134.684 25.118l11 5.67-11 5.669-.001-4.936h-34.132v-1.468h34.132v-4.935z'
         />
      </g>
   </svg>
)

PathtoFinalsArrowIcon.defaultProps = {
   width: 148,
   height: 64
}

export default PathtoFinalsArrowIcon
