import React, { Component } from 'react'

import { observer, inject } from 'mobx-react'

import Profile from '../../components/Profile/UserProfileDetails'

import LoadingWrapper from '../../LoadingWrapper'

import MatchesRoute from '../../../matches/routes/MatchesRoute'
import MatchStore from '../../../matches/stores/MatchesStore'

import PlayerProfileStore from '../../stores/PlayerProfileStore'
import CricketConstantsStore from '../../stores/CricketConstantsStore'

import { getUsername } from '../../utils/StorageUtils'
import { MyProfileWrapper } from './styledComponents'

interface ProfileProps {
   myProfileStore: PlayerProfileStore
   matchesStore: MatchStore
   cricketConstantsStore: CricketConstantsStore
}

@inject('myProfileStore', 'matchesStore', 'cricketConstantsStore')
@observer
class MyProfile extends Component<ProfileProps> {
   componentDidMount() {
      this.getProfile()
   }

   getProfile = () => {
      this.props.myProfileStore.getPlayerProfileDetails({
         username: getUsername()
      })
   }

   render() {
      const {
         getUserProfileDetailsAPIStatus,
         getUserProfileDetailsAPIError
      } = this.props.myProfileStore

      return (
         <MyProfileWrapper>
            <LoadingWrapper
               apiStatus={getUserProfileDetailsAPIStatus}
               apiError={getUserProfileDetailsAPIError}
               onRetry={this.getProfile}
            >
               <Profile profileStore={this.props.myProfileStore} />
               <MatchesRoute
                  matchesStore={this.props.matchesStore}
                  cricketConstantsStore={this.props.cricketConstantsStore}
               />
            </LoadingWrapper>
         </MyProfileWrapper>
      )
   }
}

export default MyProfile
