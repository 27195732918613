import React from 'react'

interface Props {
   width: number
   height: number
}

const MaxIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 20 20'
   >
      <defs>
         <linearGradient
            id='MaxIcon-linearGradient-1'
            x1='50%'
            x2='50%'
            y1='61.436%'
            y2='61.436%'
         >
            <stop offset='0%' stopColor='#FFF' stopOpacity='0.5'></stop>
            <stop offset='100%' stopOpacity='0.5'></stop>
         </linearGradient>
         <path
            id='MaxIcon-path-2'
            d='M12.333 3.287A6.577 6.577 0 009.928.885 6.473 6.473 0 006.61 0c-1.2 0-2.305.295-3.318.885A6.575 6.575 0 00.887 3.287 6.449 6.449 0 000 6.6a6.45 6.45 0 00.886 3.313 6.577 6.577 0 002.406 2.402 6.473 6.473 0 003.318.885c1.199 0 2.305-.295 3.318-.885a6.575 6.575 0 002.405-2.402 6.45 6.45 0 00.886-3.313 6.45 6.45 0 00-.886-3.313z'
         ></path>
         <filter
            id='MaxIcon-filter-3'
            width='145.4%'
            height='145.5%'
            x='-22.7%'
            y='-22.7%'
            filterUnits='objectBoundingBox'
         >
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur
               in='shadowOffsetOuter1'
               result='shadowBlurOuter1'
               stdDeviation='1'
            ></feGaussianBlur>
            <feColorMatrix
               in='shadowBlurOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
         <g transform='translate(-772 -188)'>
            <g transform='translate(300 135)'>
               <g transform='translate(373 1)'>
                  <g transform='translate(101.147 51)'>
                     <g transform='translate(.801 .4)'>
                        <g transform='translate(0 3.2)'>
                           <g fillRule='nonzero'>
                              <use
                                 fill='#000'
                                 filter='url(#MaxIcon-filter-3)'
                                 xlinkHref='#MaxIcon-path-2'
                              ></use>
                              <use
                                 fill='#FF9B33'
                                 xlinkHref='#MaxIcon-path-2'
                              ></use>
                              <use
                                 fill='url(#MaxIcon-linearGradient-1)'
                                 fillOpacity='0.2'
                                 xlinkHref='#MaxIcon-path-2'
                              ></use>
                           </g>
                           <path
                              fill='#FFF'
                              d='M10.728 6.999l-.747.746a.505.505 0 01-.37.156.506.506 0 01-.369-.156l-1.551-1.55v4.116a.504.504 0 01-.156.369.505.505 0 01-.37.156h-1.05a.505.505 0 01-.37-.156.504.504 0 01-.156-.37V6.197L4.037 7.745a.502.502 0 01-.37.147.502.502 0 01-.369-.147l-.747-.746a.501.501 0 01-.148-.369.5.5 0 01.148-.369l2.972-2.967.747-.746a.502.502 0 01.37-.148c.148 0 .27.05.37.148l.746.746 2.972 2.967a.5.5 0 01.148.369.5.5 0 01-.148.369z'
                           ></path>
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </g>
   </svg>
)

MaxIcon.defaultProps = {
   width: 20,
   height: 20
}
export default MaxIcon
