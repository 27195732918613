import styled from 'styled-components'

import { mobile } from '../../../common/utils/MixinUtils'

export const LoaderWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 1%;
   position: relative;
`

export const Container = styled.div`
   display: flex;
   margin-left: 24px;
   margin-right: 44px;
   flex-wrap: wrap;
   margin-top: 16px;
   ${mobile} {
      justify-content: center;
   }
`
