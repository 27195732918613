import * as React from 'react'

import { getResponsiveImageSourceProps } from '../../utils/ImageUtils'
import { ImageWrapper } from './styledComponents'

const ImageComponent = (props: any) => {
   const { src, Img, ...rest } = props

   const url = getResponsiveImageSourceProps(src).src
   return <Img draggable='false' src={url} {...rest} />
}

ImageComponent.defaultProps = {
   Img: ImageWrapper
}

export default ImageComponent
