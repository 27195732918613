import React from 'react'

import { useTranslation } from 'react-i18next'
import { MatchConfigType } from '../../constants/MatchConfigConstants'

import OversConfigIcon from '../../icons/MatchConfig/Overs'
import WicketsConfigIcon from '../../icons/MatchConfig/Wickets'
import WeatherConfigIcon from '../../icons/MatchConfig/Weather'

import { Typo18BlackBarlowBoldItalic } from '../../../common/styleGuide/Typos'

import ConfigItemBg from '../../backgrounds/ConfigItem'
import {
   BottomBarGradeint,
   BottomBarBg,
   ContentHolder,
   ItemContainer,
   ValueWrapper,
   BottomBarTitleWrapper,
   BgAndIconWrapper,
   IconWrapper
} from './styledComponents'

interface MatchConfigProps {
   value?: string
   configType: string
}

interface CommonBottomBarBgProps {
   children: any
   className?: string
   innerDivClassName?: string
}

export const CommonBottomBarBg = (props: CommonBottomBarBgProps) => (
   <BottomBarBg className={props.className}>
      <BottomBarGradeint className={props.innerDivClassName}>
         <BottomBarTitleWrapper>{props.children}</BottomBarTitleWrapper>
      </BottomBarGradeint>
   </BottomBarBg>
)

function getConfigIconsObj(t) {
   return {
      [MatchConfigType.OVERS]: {
         Icon: OversConfigIcon,
         name: t('matchConfig.overs')
      },
      [MatchConfigType.WICKETS]: {
         Icon: WicketsConfigIcon,
         name: t('matchConfig.wickets')
      },
      [MatchConfigType.WEATHER]: {
         Icon: WeatherConfigIcon,
         name: t('matchConfig.match')
      }
   }
}

const MatchConfigItem = (props: MatchConfigProps) => {
   const { t } = useTranslation()
   const { value, configType } = props
   const { name, Icon } = getConfigIconsObj(t)[configType]

   const displayValue = value === '-1' ? '-' : value

   return (
      <ItemContainer>
         <BgAndIconWrapper>
            <ConfigItemBg />
            <IconWrapper>
               <Icon />
            </IconWrapper>
         </BgAndIconWrapper>
         <ContentHolder>
            <ValueWrapper>{displayValue}</ValueWrapper>
            <CommonBottomBarBg className={''}>
               <Typo18BlackBarlowBoldItalic>{name}</Typo18BlackBarlowBoldItalic>
            </CommonBottomBarBg>
         </ContentHolder>
      </ItemContainer>
   )
}
export default MatchConfigItem
