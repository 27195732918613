import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'
import { Typo16BattleShipGreyBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'

export const TitleText = styled(Typo16BattleShipGreyBarlowSemiBoldCapital)``

export const ResourceText = styled(TitleText)`
   padding-left: 8px;
`

export const RankContainer = styled.div`
   display: inline-block;
   display: flex;
   justify-content: flex-end;
   min-width: 17.826%;
`

export const SuperRunsContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   min-width: 23.369%;
   margin-right: 5.217%;
`
export const NameContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   min-width: 20.434%;
`

export const ScoreContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   min-width: 33.152%;
`

export const LeaderboardHeaderContainer = styled.div`
   display: flex;
   flex-direction: row;
   max-width: 920px;
   height: 67px;
   align-items: center;
   background: ${Colors.darkTwo};
`
