import React from 'react'

interface TbdIconComponentProps {
   width: number
   height: number
}

const TbdIconComponent = (props: TbdIconComponentProps) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 57 33'
      {...props}
   >
      <defs>
         <filter
            id='prefix__tbd__a'
            width='111.7%'
            height='112%'
            x='-5.9%'
            y='-3%'
            filterUnits='objectBoundingBox'
         >
            <feOffset dy={1} in='SourceAlpha' result='shadowOffsetOuter1' />
            <feColorMatrix
               in='shadowOffsetOuter1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.441236413 0'
            />
         </filter>
         <path
            id='prefix__tbd__b'
            d='M23.57 19.813a15.548 15.548 0 01-.058-1.274c0-1.37.25-2.357.747-2.962a3.809 3.809 0 012.434-1.39c.352-.053.696-.08 1.033-.08.605 0 1.186.085 1.745.253.873.268 1.53.796 1.975 1.584.39.712.585 1.62.585 2.721v.38c-.053 1.277-.316 2.336-.786 3.174-.471.838-1.185 1.444-2.141 1.82l-.035 2.1-2.445.046-.035-3.823c1.24-.184 1.998-.727 2.274-1.63a6.33 6.33 0 00.287-1.837c0-.253-.016-.501-.046-.746-.123-.804-.62-1.206-1.493-1.206h-.057c-.766.023-1.148.72-1.148 2.09 0 .26.011.54.034.838l-2.87-.058zM27.944 30.8a1.61 1.61 0 01-1.182-.493 1.611 1.611 0 01-.494-1.183c0-.467.164-.865.494-1.194a1.612 1.612 0 011.182-.493c.467 0 .865.164 1.194.493.33.33.494.727.494 1.194 0 .46-.165.854-.494 1.183-.33.329-.727.493-1.194.493z'
         />
      </defs>
      <g fill='none' fillRule='evenodd'>
         <rect
            width={25.393}
            height={27.274}
            x={3.097}
            y={2.821}
            fill='#001F51'
            fillOpacity={0.2}
            rx={4.702}
            transform='rotate(-15 15.794 16.459)'
         />
         <rect
            width={25.393}
            height={27.274}
            x={28.49}
            y={2.821}
            fill='#001F51'
            fillOpacity={0.2}
            rx={4.702}
            transform='rotate(15 41.187 16.459)'
         />
         <use
            fill='#000'
            filter='url(#prefix__tbd__a)'
            xlinkHref='#prefix__tbd__b'
         />
         <use fill='#2D3744' xlinkHref='#prefix__tbd__b' />
      </g>
   </svg>
)

TbdIconComponent.defaultProps = {
   width: 57,
   height: 33
}

export default TbdIconComponent
