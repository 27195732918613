import { observable } from 'mobx'
import TournamentLeaderboardSuperItem from '../../../../leaderboard/stores/models/TournamentLeaderboardSuperItem'
import {
   MatchConfigConstants,
   TournamentMatchStatus,
   userRewardsResource
} from '../../../constants/tournamentConstants'
import { sortArrayByIndex } from '../../../utils/sortArrayByIndex'
import {
   GetTournamentsInfoV2Response,
   RoundDetail,
   KnockoutMatchType,
   MatchDetailsWithParticipants
} from '../../types'
import KnockoutMatch from '../KnockoutMatch'
import ParticipantModel from '../ParticipantModel'
import PopupLeaderBoardModel from '../PopupLeaderBoardModel'

class TournamentInfoModel {
   @observable leaderboardItems!: TournamentLeaderboardSuperItem[]
   @observable participantsDetailsDict!: Record<string, ParticipantModel>
   @observable tournamentMatchesDict!: Record<
      string,
      MatchDetailsWithParticipants
   >
   @observable knockoutMatchesDict!: Record<number, KnockoutMatchType[]>
   @observable roundDetailsDict!: Record<number, RoundDetail>
   @observable lbContributionCount!: number
   @observable popupLeaderBoardModels!: Array<PopupLeaderBoardModel>
   @observable roundDetailsList: RoundDetail[]

   constructor(tournamentInfo: GetTournamentsInfoV2Response) {
      this.participantsDetailsDict = {}
      this.tournamentMatchesDict = {}
      this.knockoutMatchesDict = {}
      this.roundDetailsDict = {}
      this.popupLeaderBoardModels = []
      this.leaderboardItems = []
      this.roundDetailsList = tournamentInfo.round_details

      this.lbContributionCount =
         tournamentInfo.lb_round_details.lb_contribution_count

      this.setTournamentMatchDetails(tournamentInfo)
      this.setLBRoundDetails(tournamentInfo.lb_round_details)
      this.setLeaderboardItems(tournamentInfo.lb_round_details)
      this.formatKnockoutMatchModelsFromTournamentsData(tournamentInfo)
      this.setRoundDetails(tournamentInfo.round_details)
   }

   setTournamentMatchDetails = (response): void => {
      response.participant_details.forEach((participant): void => {
         this.participantsDetailsDict[
            participant.participant_id
         ] = new ParticipantModel(participant)
      })

      const participantEmptyDict = {
         game_state_blob: '',
         participant_status: MatchConfigConstants.TBD,
         participant_id: ''
      }
      const noParticipantEmptyDict = {
         game_state_blob: '',
         participant_status: MatchConfigConstants.BLANK,
         participant_id: ''
      }

      response.match_details.forEach((match): void => {
         const match_participants = match.participants_details
         switch (match_participants.length) {
            case 1:
               if (match.match_status === TournamentMatchStatus.COMPLETED) {
                  match_participants.push(noParticipantEmptyDict)
               } else {
                  match_participants.push(participantEmptyDict)
               }
               break
            case 0:
               if (match.match_status === TournamentMatchStatus.COMPLETED) {
                  match_participants.push(noParticipantEmptyDict)
                  match_participants.push(noParticipantEmptyDict)
               } else {
                  match_participants.push(participantEmptyDict)
                  match_participants.push(participantEmptyDict)
               }
               break
         }
         match_participants.forEach((participant): void => {
            if (participant.participant_status !== MatchConfigConstants.TBD) {
               participant.participant_model = this.participantsDetailsDict[
                  participant.participant_id
               ]
            }
         })
         this.tournamentMatchesDict[match.match_id] = match
      })
   }

   setLBRoundDetails = (lbRoundDetails): void => {
      this.popupLeaderBoardModels = lbRoundDetails.reward_based_on_rank.map(
         lbItem =>
            new PopupLeaderBoardModel({
               min_rank: lbItem.min_rank,
               max_rank: lbItem.max_rank,
               total_lb_entries_count: lbRoundDetails.total_lb_entries_count,
               lb_contribution_count: lbRoundDetails.lb_contribution_count,
               resources:
                  lbItem.reward_mode === userRewardsResource.resource
                     ? lbItem.resources
                     : lbItem.treasure_config?.resource_details,
               reward_mode: lbItem.reward_mode
            })
      )
   }

   setLeaderboardItems = (lbRoundDetails): void => {
      this.leaderboardItems = lbRoundDetails.lb_details.map(
         lbItem =>
            new TournamentLeaderboardSuperItem({
               rank: lbItem.rank,
               countryPic: lbItem.country_flag_pic_url,
               resourceType: lbItem.primary_reward.name,
               score: lbItem.score,
               name: lbItem.name,
               profilePic: lbItem.pic,
               resourceRating: lbItem.primary_reward.value,
               userID: lbItem.id
            })
      )
   }

   formatKnockoutMatchModelsFromTournamentsData = (response): void => {
      const tournamentMatches = this.tournamentMatchesDict
      response.match_hierarchy.forEach((roundHierarchyData): void => {
         this.knockoutMatchesDict[
            roundHierarchyData.round_no
         ] = roundHierarchyData.matches_map.map(
            (matchDict): KnockoutMatch => {
               const parentMatch = tournamentMatches[matchDict.match_id]
               matchDict.dependent_match_ids.forEach(
                  (dependencyMatchId, index) => {
                     const dependencyMatchDetails = this.tournamentMatchesDict[
                        dependencyMatchId
                     ]
                     const dependencyMatchWinner =
                        dependencyMatchDetails.winner_id

                     const parentMatchParticipants =
                        parentMatch.participants_details

                     parentMatchParticipants.forEach((participant, key) => {
                        if (
                           participant.participant_id ===
                              dependencyMatchWinner &&
                           index !== key
                        ) {
                           if (index < key) {
                              sortArrayByIndex(
                                 parentMatchParticipants,
                                 index,
                                 key
                              )
                           } else {
                              sortArrayByIndex(
                                 parentMatchParticipants,
                                 key,
                                 index
                              )
                           }
                        }
                     })
                  }
               )
               return new KnockoutMatch(matchDict, tournamentMatches)
            }
         )
      })
   }

   setRoundDetails = (roundDetails): void => {
      roundDetails.forEach(round => {
         this.roundDetailsDict[round.round_no] = round
      })
   }
}

export default TournamentInfoModel
