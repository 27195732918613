import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import StadiumDetails from '../StadiumDetails'
import MatchConfigItem from '../ConfigItem'
import GameModeConfig from '../GameModeConfig'
import BowlingLevel from '../BowlingLevelWithBowlerPic'
import BaseMatch from '../../stores/models/BaseMatch'
import { Container } from './styledComponents'

interface MatchConfigProps {
   model: BaseMatch
}
const MatchConfig = (props: MatchConfigProps): ReactElement => {
   const { t } = useTranslation()
   const { model } = props
   const { basicMatchDetails } = model
   const bowlingLevel = basicMatchDetails.getBowlingLevel()
   const levelText = `${t('bowling')} - ${bowlingLevel.displayName}`
   const configItems = basicMatchDetails.getMatachConfiguration()
   const gameModeConfig = basicMatchDetails.getGameModeConfig()
   return (
      <Container>
         <StadiumDetails
            name={basicMatchDetails.stadiumDetails.displayName}
            url={basicMatchDetails.stadiumDetails.pic}
         />
         {configItems.map(item => (
            <MatchConfigItem
               key={item.configType}
               value={item.value}
               configType={item.configType}
            />
         ))}
         <GameModeConfig
            runs={gameModeConfig.runs}
            superRuns={gameModeConfig.superRuns}
            gameMode={gameModeConfig.gameMode}
            gameModeDisplayName={gameModeConfig.gameModeDisplayName}
            batShield={model.basicMatchDetails.batShield}
            pitchMarker={model.basicMatchDetails.pitchMarker}
         />
         <BowlingLevel levelText={levelText} level={bowlingLevel.level} />
      </Container>
   )
}
export default MatchConfig
