import styled from 'styled-components'
import { tablet, mobile } from '../utils/MixinUtils'

export const RouteContainer = styled.div`
   padding-left: 276px;
   width: 100%;
   ${tablet} {
      padding-left: 30px;
   }
   ${mobile} {
      padding-left: 0px;
   }
   min-height: 100vh;
`
