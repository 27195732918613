import { leaderBoardConstants } from '../constants/leaderBoardConstants'

const getResourcesInOrder = (props: Array<any>) => {
   const { gem, coins, ibcRatingPoints } = leaderBoardConstants
   const gemResources = props.find(reward => reward.name === gem)
   const coinResources = props.find(reward => reward.name === coins)
   const ibcRatingPointResources = props.find(
      reward => reward.name === ibcRatingPoints
   )

   if (ibcRatingPointResources) {
      return {
         gems: gemResources,
         coins: coinResources,
         ibcRatingPoints: ibcRatingPointResources
      }
   }
   return {
      gems: gemResources,
      coins: coinResources
   }
}

export default getResourcesInOrder
