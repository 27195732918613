import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line
import { withRouter } from 'react-router-dom'
import { History } from 'history'
import { observer } from 'mobx-react'

import { API_FETCHING, API_FAILED } from '@ib/api-constants'
import { getSearchParamsObject } from '../../utils/SearchQueriesUtils'
import SteamIcon from '../../icons/SteamIcon'
import AuthStore from '../../stores/AuthStore'
import { get_login_url } from '../../utils/AuthUtils'
import Loader from '../Loader'
import { goToUserProfilePage } from '../../utils/NavigationUtils'
import { Typo32LightBlueGreyItalic } from '../../styleGuide/Typos'
import { IBC_LOGO_PATH } from '../../constants/PublicImageConstants'

import {
   SteamLogoWrapper,
   SteamLoginButtonText,
   SteamSignContainer,
   Logo,
   SteamLoginButton,
   ContentDiv
} from './styledComponents'

interface SteamSignPageProps extends WithTranslation {
   authStore: AuthStore
   history: History
}

@observer
class SteamSignPage extends Component<SteamSignPageProps> {
   onSuccessSteamLogin = () => {
      const { history } = this.props
      goToUserProfilePage(history)
   }

   onClickSteamSignButton = () => {
      const {
         authStore: { setShouldLoginWithFixture },
         history
      } = this.props
      const searchParams: any = getSearchParamsObject(history.location.search)
      setShouldLoginWithFixture(searchParams.signWithFixture === 'true')
      this.navigateToSteamPage()
   }

   navigateToSteamPage() {
      window.location.href = get_login_url()
   }

   renderLoader = () => <Loader height={25} width={25} />

   renderSteamLogo = () => {
      const { t } = this.props
      return (
         <>
            <SteamIcon />
            <SteamLoginButtonText>
               {t('loginForm.loginWithSteam')}
            </SteamLoginButtonText>
         </>
      )
   }

   renderTryAgain = () => {
      const { t } = this.props
      return (
         <SteamLoginButtonText>
            {t('common.failureView.tryAgain')}
         </SteamLoginButtonText>
      )
   }

   renderServerErrorText = () => {
      const { authStore } = this.props
      const { steamLoginAPIError, steamLoginAPIStatus } = authStore
      const isStatus400 = steamLoginAPIStatus === API_FAILED
      return (
         <Typo32LightBlueGreyItalic>
            {isStatus400 && steamLoginAPIError}
         </Typo32LightBlueGreyItalic>
      )
   }

   displayButtonText = (apiStatus: any) => {
      switch (apiStatus) {
         case API_FAILED:
            return this.renderTryAgain()
         case API_FETCHING:
            return this.renderLoader()
         default:
            return this.renderSteamLogo()
      }
   }

   render() {
      const { authStore } = this.props
      const { steamLoginAPIStatus } = authStore
      const isStatusFailed = steamLoginAPIStatus === API_FAILED
      const shouldDisplayLoader = steamLoginAPIStatus === API_FETCHING
      return (
         <SteamSignContainer>
            <Logo src={IBC_LOGO_PATH} />
            <ContentDiv>
               {isStatusFailed && this.renderServerErrorText()}
            </ContentDiv>
            <SteamLoginButton
               onClick={this.onClickSteamSignButton}
               isLoaderDisplaying={shouldDisplayLoader}
            >
               <SteamLogoWrapper>
                  {this.displayButtonText(steamLoginAPIStatus)}
               </SteamLogoWrapper>
            </SteamLoginButton>
         </SteamSignContainer>
      )
   }
}

export default withRouter(withTranslation()(SteamSignPage))
