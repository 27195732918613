import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { TournamentTabsStatus } from '../../constants/tournamentConstants'
import {
   EmptyOngoingTournamentsContainer,
   NoOngoingTournamentsText,
   CheckPreviousTournamentsText
} from './styledComponent'

interface EmptyTournamentsProps {
   title: string
}

const EmptyTournamentsComponent = (
   props: EmptyTournamentsProps
): ReactElement => {
   const { t } = useTranslation()
   return props.title === TournamentTabsStatus.ONGOING ? (
      <EmptyOngoingTournamentsContainer>
         <NoOngoingTournamentsText>
            {t('tournaments:noOngoingTournamentsNow')}
         </NoOngoingTournamentsText>
         <CheckPreviousTournamentsText>
            {t('tournaments:checkPreviousTournaments')}
         </CheckPreviousTournamentsText>
      </EmptyOngoingTournamentsContainer>
   ) : (
      <EmptyOngoingTournamentsContainer>
         <NoOngoingTournamentsText>
            {t('tournaments:emptypPreviousTournamentsText')}
         </NoOngoingTournamentsText>
      </EmptyOngoingTournamentsContainer>
   )
}

export default EmptyTournamentsComponent
