import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line
import {
   Typo18MidGreenBarlowBoldItalicCapital,
   Typo18AdobeBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'
import { Won, Lost, RoundText, WinOrLostContainer } from './styledComponents'

interface WinLoseProps {
   isWon: boolean | null
   round?: string | null
}
const WinOrLost = (props: WinLoseProps): ReactElement => {
   const { t } = useTranslation()
   const round = props.round ? <RoundText>{props.round}</RoundText> : null
   return (
      <WinOrLostContainer>
         {props.isWon ? (
            <Won>
               <Typo18MidGreenBarlowBoldItalicCapital>
                  {t('matches.won')}
               </Typo18MidGreenBarlowBoldItalicCapital>
            </Won>
         ) : (
            <Lost>
               <Typo18AdobeBarlowBoldItalicCapital>
                  {t('matches.lost')}
               </Typo18AdobeBarlowBoldItalicCapital>
            </Lost>
         )}
         {round}
      </WinOrLostContainer>
   )
}
export default WinOrLost
