import React, { ReactElement } from 'react'

import { RoundType } from '../../../../constants/MatchConfigConstants'
import TournamentMatch from '../../../../stores/models/TournamentMatch'

import LeaderBoardMatchCard from '../LeaderBoard'
import KnockOutMatchMatchCard from '../KnockOutMatch'
import TournamentMatchCard from '../Completed'

interface TournamentMatchCardsProps {
   model: TournamentMatch
}
const TournamentMatchCards = (
   props: TournamentMatchCardsProps
): ReactElement => {
   const { model } = props
   switch (props.model.roundType as string) {
      case RoundType[RoundType.KNOCK_OUT]:
         return <KnockOutMatchMatchCard model={model} />
      case RoundType[RoundType.LEADERBOARD]:
         return <LeaderBoardMatchCard model={model} />
      default:
         return <TournamentMatchCard model={model} />
   }
}

export default TournamentMatchCards
