import RewardModel from '../Reward'
import { MileStoneResponseType } from '../../../types'
export enum TargetStatus {
   ACHIEVED,
   NOT_ACHIEVED,
   NONE
}

class MileStone {
   title: string
   value: number

   targetStatus: TargetStatus

   rewards: Array<RewardModel>
   id: number

   constructor(mileStoneItem: MileStoneResponseType) {
      this.title = mileStoneItem.title
      this.value = mileStoneItem.value
      this.id = mileStoneItem.id

      this.rewards = mileStoneItem.rewards.map(
         reward => new RewardModel(reward)
      )

      this.targetStatus = this.getStatus(mileStoneItem)
   }
   getStatus = (mileStoneItem: MileStoneResponseType): TargetStatus => {
      if (mileStoneItem.isTargetAchieved) {
         return TargetStatus.ACHIEVED
      } else if (mileStoneItem.isTargetAchieved === false) {
         return TargetStatus.NOT_ACHIEVED
      }
      return TargetStatus.NONE
   }
}

export default MileStone
