import { ParticipantDetail } from '../../types'

class ParticipantModel {
   pic_thumbnail: string
   gender: string | null
   pic: string
   name: string
   participant_id: string
   country_flag_pic_url: string

   constructor(participantObj: ParticipantDetail) {
      this.pic_thumbnail = participantObj.pic_thumbnail
      this.gender = participantObj.gender
      this.pic = participantObj.pic
      this.name = participantObj.name
      this.participant_id = participantObj.participant_id
      this.country_flag_pic_url = participantObj.country_flag_pic_url
   }
}

export default ParticipantModel
