import styled from 'styled-components'
import colors from '../../../common/themes/Colors'
import {
   Typo14PumpkinOrangeBarlowBoldItalic,
   Typo16WhiteBarlowBoldItalicCapital
} from '../../../common/styleGuide/Typos'

export const TournamentsDetailsHeaderContainer = styled.div`
   display: flex;
   justify-content: space-between;
   text-align: center;
   align-items: center;
   background: ${colors.blackRussian};
   overflow: hidden;
   flex: wrap;
   padding: 27px 32px 27px 24px;
`
export const Container = styled.div`
   display: flex;
   align-items: center;
   cursor: pointer;
`
export const InfoIconWrapper = styled.div`
   width: 20px;
   height: 20px;
   background-color: ${colors.pumpkinOrangeWithOpacity};
   margin-right: 10px;
   border-radius: 50%;
   margin-top: 2px;
`
export const InfoText = styled(Typo14PumpkinOrangeBarlowBoldItalic)`
   font-weight: normal;
   color: ${colors.pumpkinOrange};
   text-transform: uppercase;
`
export const RoundDetailsText = styled(Typo16WhiteBarlowBoldItalicCapital)`
   color: ${colors.pumpkinOrange};
`
