import styled from 'styled-components'
import Colors from '../../themes/Colors'
import { LOGO_BACKGROUND_IMAGE_PATH } from '../../constants/PublicImageConstants'
import { Logo } from '../../components/SteamSignPage/styledComponents'

export const MobileView = styled.div`
   height: 100vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   background-color: ${Colors.allScreenBg};
   line-height: 1.6;
   background-image: url(${LOGO_BACKGROUND_IMAGE_PATH});
   background-position: center;
   background-repeat: no-repeat;
`
export const MobileLogo = styled(Logo)`
   margin-right: 58px;
`
