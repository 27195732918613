import styled from 'styled-components'

import { Typo14PointOneWhiteBarlowBoldItalicCapital } from '../../../../common/styleGuide/Typos'

export const KnockoutMatchContainer = styled.div`
   display: flex;
   align-items: center;
`

export const DependencyMatchOneWrapper = styled.div`
   margin-bottom: 76px;
`

export const DependencyMatchTwoWrapper = styled.div`
   margin-top: -5px;
`

export const DependencyMatchesWrapper = styled.div``

export const ResultMatchWrapper = styled.div`
   margin-left: -7px;
`

export const ArrowIconWrapper = styled.div`
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 1px;
   position: relative;
`

export const ArrowText = styled(Typo14PointOneWhiteBarlowBoldItalicCapital)``

export const FirstArrowTextWrapper = styled.div`
   position: absolute;
   top: -8px;
   right: 75px;
`

export const SecondArrowTextWrapper = styled.div`
   position: absolute;
   bottom: 19px;
   right: 75px;
`
