import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'
import { Typo18SilverBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'
import ImageComponent from '../../../common/components/BaseImageComponent'

export const TitleText = styled(Typo18SilverBarlowSemiBoldCapital)`
   padding-top: 5px;
   padding-bottom: 5px;
   padding-left: 5px;
`

export const CountryImage = styled(ImageComponent)`
   width: 48px;
   height: 32px;
   margin-right: 16.12%;
`

export const LeaderboardResourceText = styled(
   Typo18SilverBarlowSemiBoldCapital
)`
   margin-right: 80px;
`
export const UserDetails = styled.div`
   display: inline-block;
   display: flex;
   flex-direction: row;
   align-items: center;
   min-width: 23.04%;
   margin-left: 3.913%;
`

export const LeaderboardResourceDetails = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin-left: auto;
`

export const LeaderboardItemCardDiv = styled.div<{ isSelf: boolean }>`
   display: flex;
   flex-direction: row;
   max-width: 920px;
   height: 80px;
   align-items: center;
   background: ${p => (p.isSelf ? Colors.marineBlueTwo : Colors.charcoalGrey)};
   border-bottom: 1px solid ${Colors.blackRussian};
`
