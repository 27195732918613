import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { RankNumber, RankText, UserRankContainer } from './styledComponents'

interface UserRankProps {
   rank: number
}

const CheckInvalidUserRank = (rank: number) => (rank === -1 ? '-' : rank)

const UserRank = (props: UserRankProps): ReactElement => {
   const { rank } = props
   const { t } = useTranslation()

   return (
      <UserRankContainer>
         <RankNumber>{CheckInvalidUserRank(rank)}</RankNumber>
         <RankText>{t('user.rank')}</RankText>
      </UserRankContainer>
   )
}
export default UserRank
