import { action } from 'mobx'
import { API_INITIAL } from '@ib/api-constants'

import UserControl from '../../../../common/stores/models/UserControls'

import MatchesService from '../../../services/MatchesService'
import { MatchDetailType } from '../../types'
import MatchOverview from '../Overview'
import MatchOverDetails from '../OverDetails'
import BasicMatchDetails from '../BasicMatchDetails'
import MatchUserDetails from '../MatchUserDetails'

type MatchUserDetailsType = {
   users: Array<MatchUserDetails>
   requestedUserId: string
}

const INNINGS_NO = -1

class BaseMatch extends MatchOverview {
   isWon: boolean
   matchId: string
   matchType: string
   title: string
   titleUrl: string
   basicMatchDetails: BasicMatchDetails
   dateTime: string
   matchesService: MatchesService
   overDetails: MatchOverDetails
   matchUserDetails: MatchUserDetailsType
   expired: boolean
   userControlDetails: Record<string, UserControl>
   constructor(match: MatchDetailType, matchesService) {
      super(matchesService)
      this.matchesService = matchesService
      this.matchId = match.match_id
      this.matchType = match.match_type
      this.title = match.match_name
      this.titleUrl = match.match_pic_url as string
      this.basicMatchDetails = new BasicMatchDetails()

      if (match.game_details_blob) {
         this.basicMatchDetails.setBasicMatchDetails(
            JSON.parse(match.game_details_blob).basic_match_details
         )
      }
      this.dateTime = match.end_date_time
      this.overDetails = new MatchOverDetails()
      this.expired = match.expired
      this.matchUserDetails = this.getMatchUserDetails(match.match_user_details)
      this.userControlDetails = {}
      const users = match.match_user_details.users
      this.isWon =
         match.winner_id === match.match_user_details.requested_user_id

      this.participantUserControls(users)
      this.initAPIStatusAndErrors()
   }

   @action.bound
   initAPIStatusAndErrors(): void {
      this.getMatchOverviewAPIStatus = API_INITIAL
      this.getMatchOverviewAPIError = {}
      this.overDetails.getBallByBallDetailsAPIStatus = API_INITIAL
      this.overDetails.getBallByBallDetailsAPIError = null
   }

   @action.bound
   getBallByBallDetails(): Promise<any> {
      return this.overDetails.getBallByBallDetails(
         this.matchId,
         INNINGS_NO,
         this.matchesService
      )
   }

   getMatchUserDetails = matcheUserDetails => ({
      users: matcheUserDetails.users.map(
         userDetails => new MatchUserDetails(userDetails)
      ),
      requestedUserId: matcheUserDetails.requested_user_id
   })

   getSelfUserMatchDetails = (): MatchUserDetails => {
      const { matchUserDetails } = this
      if (matchUserDetails.users.length === 1) {
         return matchUserDetails.users[0]
      }
      return matchUserDetails.users.find(
         user => user.userId === matchUserDetails.requestedUserId
      ) as MatchUserDetails
   }

   getOpponentUserMatchDetails = (): MatchUserDetails => {
      const { matchUserDetails } = this
      return matchUserDetails.users.find(
         user => user.userId !== matchUserDetails.requestedUserId
      ) as MatchUserDetails
   }

   getSelfUserControls = () => {
      const { matchUserDetails } = this
      return this.userControlDetails[matchUserDetails.requestedUserId]
   }

   @action.bound
   clearStore(): void {
      this.initAPIStatusAndErrors()
   }

   participantUserControls(users: any): void {
      users.forEach((user: any) => {
         this.userControlDetails[user.user_id] = new UserControl(
            JSON.parse(user.game_stats_blob).user_control_details || []
         )
      })
   }

   getUserSelfUserControls = () => {
      const { matchUserDetails, userControlDetails } = this
      return userControlDetails[matchUserDetails.requestedUserId]
         ? userControlDetails[matchUserDetails.requestedUserId]
         : null
   }
}
export default BaseMatch
