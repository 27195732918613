import styled from 'styled-components'
import { mobile, tablet } from '../../../common/utils/MixinUtils'

export const RoundTwoTabComponentContainer = styled.div`
   position: relative;
   margin-top: 36px;
   margin-left: 134px;
   display: flex;
   height: 100%;
   ${tablet} {
      margin-left: 50px;
   }
   ${mobile} {
      margin-left: 10px;
   }
`

export const KnockoutMatchesWrapper = styled.div`
   margin-top: 69px;
   margin-right: 15%;
`
export const ListOfKnockOutMatchesContainer = styled.div`
   @media (max-width: 1461px) {
      padding-top: 36px;
   }
`
