import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourceDetail } from '../../../stores/types'

import {
   CoinsIconDimension,
   GemsIconDimension,
   CoinsAndGemsResourceItemTitleBg,
   ATHBg,
   CommonResourceItemContainer,
   ResourceCountText,
   ResourceNameText
} from './styledComponents'

interface CommonResourceItemProps {
   resource: ResourceDetail
}

const CommonResourceItemCmp = (
   props: CommonResourceItemProps
): ReactElement => {
   const { t } = useTranslation()

   return (
      <CommonResourceItemContainer>
         <CoinsAndGemsResourceItemTitleBg>
            {props.resource.name === 'GEMS' ? (
               <GemsIconDimension
                  src={props.resource.pic}
                  name={t('resources.gems')}
               />
            ) : (
               <CoinsIconDimension
                  src={props.resource.pic}
                  name={t('resources.coins')}
               />
            )}
            <ResourceCountText>{props.resource.value}</ResourceCountText>
         </CoinsAndGemsResourceItemTitleBg>
         <ATHBg>
            <ResourceNameText>{props.resource.display_name}</ResourceNameText>
         </ATHBg>
      </CommonResourceItemContainer>
   )
}

export default CommonResourceItemCmp
