import { observable, action, computed } from 'mobx'

import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'
import { APIStatus, API_INITIAL } from '@ib/api-constants'

import {
   MatchStatus,
   MatchType,
   MatchTypeDropDownValue
} from '../../constants/MatchConfigConstants'
import MatchesService from '../../services/MatchesService'

import QuickPlayMatch from '../models/QuickPlayMatch'
import ScenarioMatch from '../models/ScenarioMatch'
import TournamentMatch from '../models/TournamentMatch'
import BaseMatch from '../models/BaseMatch'
import SpecialChallengeMatch from '../models/SpecialChallengeMatch'
import {
   GetMatchesAPIResponse,
   MatchModel,
   MatchDetailType,
   GetMatchDetailsRequestObj
} from '../types'

class MatchesStore {
   matchesService: MatchesService
   @observable getMatchesListAPIStatus!: APIStatus
   @observable getMatchesListAPIError!: Error | null

   @observable matches: Array<MatchModel>
   @observable totalMatchesLength: number
   @observable matcheIds: Array<string>
   @observable filterBy: string

   @observable getMatchDetailsAPIStatus!: APIStatus
   @observable getMatchDetailsAPIError!: Error | null

   PAGINATION_LENGTH = 10

   constructor(matchesService: MatchesService) {
      this.matchesService = matchesService
      this.matches = []
      this.totalMatchesLength = 0
      this.matcheIds = []
      this.filterBy = MatchTypeDropDownValue.ALL
      this.init()
   }

   @action.bound
   init(): void {
      this.getMatchesListAPIStatus = API_INITIAL
      this.getMatchesListAPIError = null
      this.getMatchDetailsAPIStatus = API_INITIAL
      this.getMatchDetailsAPIError = null
   }

   resetLocalVars(): void {
      this.matches = []
      this.matcheIds = []
      this.totalMatchesLength = 0
   }

   @action.bound
   setGetMatchesListAPIStatus(status: APIStatus): void {
      this.getMatchesListAPIStatus = status
   }

   @action.bound
   setGetMatchesListAPIError(error: Error): void {
      this.getMatchesListAPIError = error
   }

   @computed get hasMoreMatches(): boolean {
      const { totalMatchesLength, matcheIds } = this
      return totalMatchesLength > matcheIds.length || totalMatchesLength === 0
   }

   getMatchObj(match: MatchDetailType): BaseMatch {
      const { matchesService } = this

      switch (match.match_type) {
         case MatchType.QUICK_PLAY:
            return new QuickPlayMatch(match, matchesService)
         case MatchType.SCENARIO_CHALLENGE:
            return new ScenarioMatch(match, matchesService)
         case MatchType.TOURNAMENT:
            return new TournamentMatch(match, matchesService)
         case MatchType.SPECIAL_CHALLENGE:
            return new SpecialChallengeMatch(match, matchesService)
      }
      return new BaseMatch(match, matchesService)
   }

   @action.bound
   setMatchesListResponse(response: GetMatchesAPIResponse | null): void {
      if (response) {
         this.totalMatchesLength = response.total
         response.match_details.forEach(match => {
            this.matches[match.match_id] = this.getMatchObj(match)
            const matchIdIndex = this.matcheIds.findIndex(
               id => id === match.match_id
            )
            matchIdIndex === -1 && this.matcheIds.push(match.match_id)
         })
      }
   }

   @action.bound
   getMatchesList(gameId, offset): Promise<GetMatchesAPIResponse> {
      const request = {
         length: this.PAGINATION_LENGTH,
         offset: offset,
         match_status: MatchStatus.COMPLETED,
         match_ids: [],
         game_id: gameId,
         match_type: this.filterBy,
         tournament_id: -1
      }
      if (offset === 0) this.resetLocalVars()

      const getMatchesPromise = this.matchesService.getMatchesAPI(request)
      return bindPromiseWithOnSuccess(getMatchesPromise)
         .to(this.setGetMatchesListAPIStatus, this.setMatchesListResponse, true)
         .catch(this.setGetMatchesListAPIError) as Promise<
         GetMatchesAPIResponse
      >
   }

   //#region  MATCH DETAILS
   @action.bound
   setMatchDetailsAPIStatus(status: APIStatus): void {
      this.getMatchDetailsAPIStatus = status
   }

   @action.bound
   setMatchDetailsAPIError(error: Error): void {
      this.getMatchDetailsAPIError = error
   }

   @action.bound
   setMatchDetailsResponse(response): void {
      const matchesResponse = response as GetMatchesAPIResponse
      matchesResponse.match_details.forEach((details): void => {
         const hasMatchId = this.matcheIds.includes(details.match_id)
         if (!hasMatchId) this.matcheIds.push(details.match_id)
         this.matches[details.match_id] = this.getMatchObj(details)
      })
   }

   @action.bound
   getMatchDetails(
      requestObj: GetMatchDetailsRequestObj
   ): Promise<GetMatchesAPIResponse> {
      const request = {
         length: 1,
         offset: 0,
         match_status: MatchStatus.COMPLETED,
         match_ids: [requestObj.matchId],
         game_id: requestObj.gameId,
         tournament_id: -1
      }
      const getMatchDetailsPromise = this.matchesService.getMatchesAPI(request)
      return bindPromiseWithOnSuccess(getMatchDetailsPromise)
         .to(this.setMatchDetailsAPIStatus, this.setMatchDetailsResponse)
         .catch(this.setMatchDetailsAPIError) as Promise<GetMatchesAPIResponse>
   }
   //#endregion
}

export default MatchesStore
