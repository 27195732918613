import { ApisauceInstance, create } from 'apisauce'
import Config from '../../../common/constants/EnvironmentConstants'

import {
   GetMatcheOverviewRequest,
   GetMatchesAPIRequest,
   GetMatchesAPIResponse,
   GetMatcheOverviewResponse,
   OverDetailsResponseType,
   OverDetailsRequestType
} from '../../stores/types'

import { apiMethods } from '../../../common/constants/APIConstants'

import endpoints from '../../../common/services/endpoints'
import MatchesService from '.'

class MatchesApi implements MatchesService {
   api: ApisauceInstance
   networkCallWithApisauce: Function

   constructor(networkCallWithApisauce: Function) {
      this.api = create({
         baseURL: Config.BASE_URL
      })
      this.networkCallWithApisauce = networkCallWithApisauce
   }
   async getMatchesAPI(
      request: GetMatchesAPIRequest
   ): Promise<GetMatchesAPIResponse> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getMatches,
         request,
         apiMethods.post
      )
   }

   getOverDetailsAPI(
      request: OverDetailsRequestType
   ): Promise<OverDetailsResponseType> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.overDetails,
         request,
         apiMethods.post
      )
   }
   getMatchOverview(
      request: GetMatcheOverviewRequest
   ): Promise<GetMatcheOverviewResponse> {
      return this.networkCallWithApisauce(
         this.api,
         endpoints.getMatchOverView,
         request,
         apiMethods.post
      )
   }
}

export default MatchesApi
