import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'
import { Typo16BattleShipGreyBarlowBoldItalicCapital } from '../../../common/styleGuide/Typos'

export const ContentWrapper = styled.div`
   display: flex;
   flex-direction: column;
   background: ${Colors.dark};
   width: 100%;
`
export const LoaderWrapper = styled.div`
   display: 'flex';
   justify-content: 'center';
   align-content: center;
   width: 100%;
`

export const BackArrowText = styled(
   Typo16BattleShipGreyBarlowBoldItalicCapital
)`
   padding-bottom: 2px;
`
