import styled from 'styled-components'

import {
   Typo36SliverBarlowExtraBoldItalic,
   Typo24SilverBarlowSemiBoldItalic
} from '../../../common/styleGuide/Typos'
import colors from '../../../common/themes/Colors'
import { EMPTY_ONGOING_TOURNAMENTS_IMAGE_PATH } from '../../../common/constants/PublicImageConstants'

export const EmptyOngoingTournamentsContainer = styled.div`
   background-image: url(${EMPTY_ONGOING_TOURNAMENTS_IMAGE_PATH});
   background-position: center;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   background-color: ${colors.dark};
   min-height: 75vh;
`
export const NoOngoingTournamentsText = styled(
   Typo36SliverBarlowExtraBoldItalic
)`
   font-weight: 600 !important;
   margin-bottom: 24px;
   margin-top: 75px;
   @media (max-width: 1200px) {
      margin-top: 30px;
   }
`
export const CheckPreviousTournamentsText = styled(
   Typo24SilverBarlowSemiBoldItalic
)``
