import styled from 'styled-components'

export const Container = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   padding-left: 24px;
   padding-right: 78px;
   margin-top: 16px;
`
