import styled from 'styled-components'

import ReactInfiniteScrollComponent from 'react-infinite-scroll-component'
import { tablet, mobile } from '../../../common/utils/MixinUtils'

export const InfiniteScrollWrapper = styled(ReactInfiniteScrollComponent)`
   margin-top: 24px;
   display: flex;
   ${tablet} {
      justify-content: center;
   }
   ${mobile} {
      justify-content: center;
   }
   text-align: center;
   align-items: flex-start;
   flex-wrap: wrap;
   width: 100%;
`
export const LoaderWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin-bottom: 1%;
   margin-top: 1%;
`
export const Wrapper = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   width: 100%;
`
