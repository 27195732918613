import React, { ReactElement } from 'react'
import { Container, TextContainer } from './styledComponents'

interface RoundHeaderProps {
   RoundHeadingName: string
   maxWidth?: string
}

export const RoundOneLeaderBoardHeading = (
   props: RoundHeaderProps
): ReactElement => (
   <Container>
      <TextContainer>{props.RoundHeadingName}</TextContainer>
   </Container>
)

export default RoundOneLeaderBoardHeading
