export interface KbcResponsiveImageSources {
   srcSet: string
   src: string
}
export const getResponsiveImageSourceProps = (
   src: string
): KbcResponsiveImageSources => {
   src = src || ''
   const newSrc = src.replace('__', '@3x')
   const srcSet = `${newSrc.replace(
      '@2x',
      '@1x'
   )} 1x, ${newSrc} 2x, ${newSrc.replace('@2x', '@3x')} 3x`
   return { src: newSrc, srcSet: srcSet }
}
