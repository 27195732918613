import { LeaderboardEntryType } from '../../types'

class LeaderboardEntryModel {
   rank: number
   countryPic: string
   score: number
   name: string
   profilePic: string
   userID: string

   constructor(LeaderboardRankObj: LeaderboardEntryType) {
      this.name = LeaderboardRankObj.name
      this.profilePic = LeaderboardRankObj.pic_url
      this.rank = LeaderboardRankObj.rank
      this.countryPic = LeaderboardRankObj.country_flag_pic_url
      this.score = LeaderboardRankObj.score
      this.userID = LeaderboardRankObj.user_id
   }
}

export default LeaderboardEntryModel
