import React from 'react'

interface Props {
   width: number
   height: number
}
const PitchIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 43 113'
   >
      <g fill='none' fillRule='evenodd'>
         <path fill='#CEC682' d='M4.6.333h34.133v112H4.6z'></path>
         <path
            stroke='#F0E9AC'
            strokeWidth='0.974'
            d='M.333 14.333H43m-32.711-8.4h22.755m0 8.4v-14m-14.222 5.6v-2.8m5.689 2.8v-2.8m-2.845 2.8v-2.8m-11.377 11.2v-14M.333 98.333H43m-32.711 8.4h22.755m0-8.4v14m-14.222-5.6v2.8m5.689-2.8v2.8m-2.845-2.8v2.8m-11.377-11.2v14'
         ></path>
      </g>
   </svg>
)
PitchIcon.defaultProps = {
   width: 43,
   height: 113
}

export default PitchIcon
