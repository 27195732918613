import { observable, action } from 'mobx'
import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'
import { APIStatus, API_INITIAL } from '@ib/api-constants'

import MatchesService from '../../../services/MatchesService'
import { OverDetail, OverDetailsResponseType } from '../../types'

class MatchOverDetails {
   @observable oversList!: Array<OverDetail>
   @observable getBallByBallDetailsAPIStatus!: APIStatus
   @observable getBallByBallDetailsAPIError!: Error | null

   constructor() {
      this.init()
   }

   init = () => {
      this.oversList = []
      this.getBallByBallDetailsAPIStatus = API_INITIAL
      this.getBallByBallDetailsAPIError = null
   }
   @action.bound
   private setBallByBallDetailsAPIStatus(status: APIStatus): void {
      this.getBallByBallDetailsAPIStatus = status
   }

   @action.bound
   private setBallByBallDetailsAPIError(error: Error): void {
      this.getBallByBallDetailsAPIError = error
   }

   @action.bound
   private setBallByBallDetailsResponse(
      response: OverDetailsResponseType | null
   ): void {
      if (response) {
         const data = response
         this.oversList = data.over_details
      }
   }

   @action.bound
   getBallByBallDetails(
      matchId: string,
      inningsNo: number,
      matchesService: MatchesService
   ): Promise<OverDetailsResponseType | void> {
      const getBallDetailsPromise = matchesService.getOverDetailsAPI({
         match_id: matchId,
         innings_no: inningsNo
      })
      return bindPromiseWithOnSuccess(getBallDetailsPromise)
         .to(
            this.setBallByBallDetailsAPIStatus,
            this.setBallByBallDetailsResponse
         )
         .catch(this.setBallByBallDetailsAPIError)
   }
}
export default MatchOverDetails
