import React from 'react'

import { RoundHeadeContainer, RoundHeaderText } from './styledComponent'

interface RoundHeaderComponentProps {
   roundName: string
}

const RoundHeaderComponent = (props: RoundHeaderComponentProps) => {
   const { roundName } = props
   return (
      <RoundHeadeContainer>
         <RoundHeaderText>{roundName}</RoundHeaderText>
      </RoundHeadeContainer>
   )
}

export default RoundHeaderComponent
