import React from 'react'
import { useTranslation } from 'react-i18next'

import pathToFinalsFixtures from '../../../fixtures/pathToFinalsFixtues.json'
import RoundTwoDottedLineIcon from '../../../../common/icons/RoundTwoDottedLineIcon'
import WinnerArrowMarkIcon from '../../../../common/icons/WinnerArrowMarkIcon'
import SemiFinalDottedLineIcon from '../../../../common/icons/SemiFinalDottedIcon'

import KnockoutMatchWithDependencyMatchOne from '../KnockOutMatchWithFirstDependencyMatch'
import KnockoutMatchWithDependencyMatchTwo from '../KnockOutMatchWithSecondDependencyMatch'
import KnockoutMatchHierarchyAtSemiFinal from '../KnockOutMatchHierarchyAtSemiFinalRound'
import KnockoutMatchHierarchy from '../KnockOutMatchHierarchy'

import {
   Container,
   FirstKnockoutMatchWrapper,
   SecondKncokoutMatchWrapper,
   ThirdMatchHierarchyWrapper,
   DottedIconWrapper,
   SecondDottedLineWrapper,
   DottedLinesWrapper,
   LeftPartContainer,
   RightPartContainer,
   SemiFinalMatchWrapper,
   SemiFinalRoundOneArrwoIconWrapper,
   SemiFinalRoundTwoArrwoIconWrapper,
   WinnerTextOneWrapper,
   WinnerTextTwoWrapper,
   WinnerTextThreeWrapper,
   WinnerTextFourWrapper,
   ArrowText,
   HorizontalDottedLineWrapper
} from './styledComponent'

const MatchesHierarchyWrapper = () => {
   const { t } = useTranslation()
   return (
      <Container>
         <LeftPartContainer>
            <FirstKnockoutMatchWrapper>
               <KnockoutMatchHierarchy
                  knocoutMatchDetails={
                     pathToFinalsFixtures.matches_hierarchy[0]
                  }
               />
            </FirstKnockoutMatchWrapper>
            <SecondKncokoutMatchWrapper>
               <KnockoutMatchWithDependencyMatchOne
                  matchDetails={pathToFinalsFixtures.matches_hierarchy[1]}
               />
            </SecondKncokoutMatchWrapper>
            <DottedLinesWrapper>
               <DottedIconWrapper>
                  <RoundTwoDottedLineIcon width={4} height={201} />
               </DottedIconWrapper>
               <SecondDottedLineWrapper>
                  <SemiFinalDottedLineIcon width={3} height={146} />
               </SecondDottedLineWrapper>
            </DottedLinesWrapper>
            <ThirdMatchHierarchyWrapper>
               <KnockoutMatchWithDependencyMatchTwo
                  matchDetails={pathToFinalsFixtures.matches_hierarchy[2]}
               />
            </ThirdMatchHierarchyWrapper>
         </LeftPartContainer>
         <HorizontalDottedLineWrapper>
            <RoundTwoDottedLineIcon width={10} height={139} />
         </HorizontalDottedLineWrapper>
         <RightPartContainer>
            <WinnerTextOneWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </WinnerTextOneWrapper>
            <SemiFinalRoundOneArrwoIconWrapper>
               <WinnerArrowMarkIcon width={175} height={139} />
            </SemiFinalRoundOneArrwoIconWrapper>
            <WinnerTextTwoWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </WinnerTextTwoWrapper>
            <SemiFinalMatchWrapper>
               <KnockoutMatchHierarchyAtSemiFinal
                  matchDetails={pathToFinalsFixtures.matches_hierarchy[3]}
               />
            </SemiFinalMatchWrapper>
            <WinnerTextThreeWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </WinnerTextThreeWrapper>
            <SemiFinalRoundTwoArrwoIconWrapper>
               <WinnerArrowMarkIcon width={175} height={139} />
            </SemiFinalRoundTwoArrwoIconWrapper>
            <WinnerTextFourWrapper>
               <ArrowText>{t('tournaments:winnerText')}</ArrowText>
            </WinnerTextFourWrapper>
         </RightPartContainer>
      </Container>
   )
}

export default MatchesHierarchyWrapper
