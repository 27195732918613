import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourceDetail } from '../../../stores/types'
import OversLeftBg from '../../../icons/OversLeftBG'

import {
   OversLeftContainer,
   OversResourceItemContainer,
   ResourceItemTitleBg,
   ResourceIcon,
   OversLeftTextBg,
   OversText,
   OversCountText
} from './styledComponents'

interface OversLeftProps {
   resource: ResourceDetail
}
const OversLeft = (props: OversLeftProps): ReactElement => {
   const { t } = useTranslation()
   return (
      <OversLeftContainer>
         <OversLeftBg />
         <OversResourceItemContainer>
            <ResourceItemTitleBg>
               <ResourceIcon src={props.resource.pic} />
               <OversCountText>{props.resource.value}</OversCountText>
            </ResourceItemTitleBg>
            <OversLeftTextBg>
               <OversText>{t('resources.oversLeft')}</OversText>
            </OversLeftTextBg>
         </OversResourceItemContainer>
      </OversLeftContainer>
   )
}

export default OversLeft
