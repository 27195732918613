import React from 'react'
import { useTranslation } from 'react-i18next'
import TbdIconComponent from '../../../common/icons/TbdIcon'
import { MatchConfigConstants } from '../../constants/tournamentConstants'

import {
   TbdCardContainer,
   TbdTextComponent,
   TextComponentAndIconWrapper,
   IconWrapper
} from './styledComponent'

interface TbdCardComponentProps {
   isPlayerOne: boolean
   participantMatchStatus: string
}

const TbdCardComponent = (props: TbdCardComponentProps) => {
   const { t } = useTranslation()
   const { isPlayerOne, participantMatchStatus } = props

   const renderPlayerCardByStatus = (participantMatchStatus: string) => {
      switch (participantMatchStatus) {
         case MatchConfigConstants.TBD:
            return (
               <TextComponentAndIconWrapper>
                  <IconWrapper>
                     <TbdIconComponent />
                  </IconWrapper>
                  <TbdTextComponent>
                     {t('tournaments:tbdText')}
                  </TbdTextComponent>
               </TextComponentAndIconWrapper>
            )
         default:
            return (
               <TextComponentAndIconWrapper>
                  <TbdTextComponent>
                     {t('tournaments:blankText')}
                  </TbdTextComponent>
               </TextComponentAndIconWrapper>
            )
      }
   }

   return (
      <TbdCardContainer isPlayerOne={isPlayerOne}>
         {renderPlayerCardByStatus(participantMatchStatus)}
      </TbdCardContainer>
   )
}

export default TbdCardComponent
