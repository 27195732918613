import React, { ReactElement } from 'react'

import {
   SideBarItemDiv,
   IconDiv,
   InactiveText,
   ActiveText
} from './styledComponents'

interface SideNavBarItemProps {
   title: string
   icon: any
   onClick: any
   isActive: boolean
   index: number
   activeIcon?: any
}

const getActiveIcon = (Icon, isActive) => {
   if (Icon === null) return null
   return <Icon fillColor={isActive ? 'active' : 'inactive'} />
}

const SideNavBarItem = (props: SideNavBarItemProps): ReactElement => {
   const { isActive, onClick, activeIcon } = props
   return (
      <SideBarItemDiv
         className='child_tab'
         isActive={isActive}
         onClick={() => onClick(props.index)}
      >
         <IconDiv>
            {isActive && activeIcon
               ? getActiveIcon(activeIcon, isActive)
               : getActiveIcon(props.icon, isActive)}
         </IconDiv>
         {isActive ? (
            <ActiveText>{props.title}</ActiveText>
         ) : (
            <InactiveText>{props.title}</InactiveText>
         )}
      </SideBarItemDiv>
   )
}

export default SideNavBarItem
