import { action } from 'mobx'
import BaseMatch from '../BaseMatch'

import { ResourceEnums } from '../../../../common/constants/ResourceConstants'
import {
   Resource,
   GetMatcheOverviewResponse,
   MatchDetailType,
   RewardConfig
} from '../../types'
import MileStone from './MileStone'

class ScenarioMatch extends BaseMatch {
   mileStonesAcheived: number
   totalMileStones: number
   fanbaseAth: number
   fanBase: Resource
   milestones: Array<MileStone>

   constructor(scenarioMatchObj: MatchDetailType, matchesService) {
      super(scenarioMatchObj, matchesService)
      this.matchesService = matchesService
      this.initAPIStatusAndErrors()

      this.mileStonesAcheived = 0
      this.totalMileStones = 0
      this.fanbaseAth = 0
      this.fanBase = {
         pic: '',
         display_name: '',
         name: '',
         value: 0,
         thumbnail: '',
         description: ''
      }
      this.milestones = []
   }

   setFanbase(resourceDetails: RewardConfig): Resource {
      const defaultResource = {
         display_name: '',
         value: -1,
         pic: '',
         thumbnail: '',
         name: '',
         description: ''
      }
      let resource
      if (this.isWon) {
         resource = resourceDetails.win_rewards.resources.find(
            item => item.name === ResourceEnums.FAN_BASE
         )
      } else {
         resource = resourceDetails.guarantee_rewards.resources.find(
            item => item.name === ResourceEnums.FAN_BASE
         )
      }
      return resource === undefined ? defaultResource : resource
   }
   @action.bound
   private setGetScenarioMatchOverviewResponse(
      response: GetMatcheOverviewResponse
   ): void {
      this.milestones = response.scenario_match.milestones.map(
         item => new MileStone(item)
      )
   }

   public getMatchesOverview(
      matchId: string
   ): Promise<GetMatcheOverviewResponse> {
      return super.getMatchesOverview(
         matchId,
         this.setGetScenarioMatchOverviewResponse
      )
   }
}
export default ScenarioMatch
