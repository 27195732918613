import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { action } from 'mobx'

import { API_SUCCESS } from '@ib/api-constants'
import CricketConstantsStore from '../../../common/stores/CricketConstantsStore'
import LoadingWrapper from '../../../common/LoadingWrapper'

import {
   MatchType,
   SpecialChallengeType
} from '../../constants/MatchConfigConstants'
import QuickPlayMatchHeader from '../../components/Header/QuickPlay'
import TournamentMatchHeader from '../../components/Header/Tournament'
import SteakChallengeHeader from '../../components/Header/SteakChallengeHeader'
import SurvivalMatchHeader from '../../components/Header/SurvivalMatchHeader'
import MatchDetailsTabs from '../../components/MatchDetailsTab'
import { MatchModel } from '../../stores/types'
import QuickPlayMatch from '../../stores/models/QuickPlayMatch'
import TournamentMatch from '../../stores/models/TournamentMatch'
import SpecialChallengeMatch from '../../stores/models/SpecialChallengeMatch'
import MatchesStore from '../../stores/MatchesStore'

import { NavigationHeaderGoBack } from '../../../common/components/NavigationHeaderGoBackComponent'
import { ContentWrapper, LoaderWrapper } from './styledComponents'

interface MatcheDetailsRouteProps {
   match: any
   matchId: string
   matchesStore: MatchesStore
   cricketConstantsStore: CricketConstantsStore
}

@inject('matchesStore', 'cricketConstantsStore')
@observer
class MatcheDetailsRoute extends React.Component<MatcheDetailsRouteProps> {
   componentDidMount(): void {
      const { matches } = this.props.matchesStore
      const { getMatchId } = this
      if (matches[getMatchId()] === undefined) {
         this.getMatchDetails()
      } else {
         this.props.matchesStore.setMatchDetailsAPIStatus(API_SUCCESS)
      }
   }

   getMatchId = (): string => {
      const { matchId } = this.props.match.params
      return matchId
   }

   @action.bound
   getMatchDetails(): void {
      const {
         getMatchId,
         props: { cricketConstantsStore }
      } = this
      const requestObj = {
         matchId: getMatchId(),
         gameId: cricketConstantsStore.gameId
      }
      this.props.matchesStore.getMatchDetails(requestObj)
   }

   getMatchesHeader(matchModel: MatchModel): JSX.Element {
      const { getSpecialChallengeHeader } = this
      if (matchModel != null && matchModel !== undefined) {
         switch (matchModel.matchType) {
            case MatchType.QUICK_PLAY:
               return (
                  <QuickPlayMatchHeader model={matchModel as QuickPlayMatch} />
               )
            case MatchType.TOURNAMENT:
               return (
                  <TournamentMatchHeader
                     model={matchModel as TournamentMatch}
                  />
               )
            case MatchType.SPECIAL_CHALLENGE:
               return getSpecialChallengeHeader(
                  matchModel as SpecialChallengeMatch
               )
            default:
               return <div />
         }
      } else return <div />
   }

   getSpecialChallengeHeader(matchModel: SpecialChallengeMatch): JSX.Element {
      switch (matchModel.specialChallengeDetails.nameEnum) {
         case SpecialChallengeType.STEAK_CHALLENGE:
            return <SteakChallengeHeader model={matchModel} />
         case SpecialChallengeType.SURVIVAL_CHALLENGE:
            return <SurvivalMatchHeader model={matchModel} />
         case SpecialChallengeType.WAGON_WHEEL_CHALLENGE:
            return <SteakChallengeHeader model={matchModel} />
         default:
            return <div />
      }
   }

   render(): JSX.Element {
      const {
         matches,
         getMatchDetailsAPIError,
         getMatchDetailsAPIStatus
      } = this.props.matchesStore
      const { getMatchId } = this
      const matchModel: MatchModel = matches[getMatchId()]
      return (
         <LoaderWrapper>
            <LoadingWrapper
               apiStatus={getMatchDetailsAPIStatus}
               apiError={getMatchDetailsAPIError as Error}
               onRetry={this.getMatchDetails}
            >
               <ContentWrapper>
                  <NavigationHeaderGoBack />
                  {this.getMatchesHeader(matchModel)}
                  {matchModel && <MatchDetailsTabs matchModel={matchModel} />}
               </ContentWrapper>
            </LoadingWrapper>
         </LoaderWrapper>
      )
   }
}

export default withRouter(MatcheDetailsRoute)
