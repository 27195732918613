import styled from 'styled-components'
import ImageComponent from '../../../common/components/BaseImageComponent'

export const BowlerPic = styled(ImageComponent)`
   width: 116px;
   height: 279px;
   object-fit: contain;
   align-content: center;
   text-align: center;
`
export const BowlingLevelBgWrapper = styled.div`
   position: relative;
`

export const BowlingLevelTextWrapper = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   align-items: center;
   justify-content: center;
   display: flex;
`

export const BowlingLevelContainer = styled.div`
   display: flex;
   flex-direction: column;
   min-width: 256px;
   align-items: center;
`
