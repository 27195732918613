import React from 'react'
import { inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { goToLoginPage, goToUserProfilePage } from '../../utils/NavigationUtils'
import { getSearchParamsObject } from '../../utils/SearchQueriesUtils'

const SteamReturnPage = inject('authStore')(props => {
   const { authStore } = props
   const history = useHistory()
   const searchQuery = history.location.search
   const steamSignResponse = getSearchParamsObject(searchQuery)

   const onSuccessSteamLogin = () => {
      goToUserProfilePage(history)
   }
   authStore.loginWithSteamId(
      btoa(JSON.stringify(steamSignResponse)),
      onSuccessSteamLogin
   )
   goToLoginPage(history)
   return <div />
})

export default SteamReturnPage
