import React from 'react'

interface RightArrowIconProps {
   width: number
   height: number
}

const RightArrowIcon = (props: RightArrowIconProps) => {
   const { width, height } = props
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width={width}
         height={height}
         viewBox='0 0 27 16'
      >
         <path
            fill='#A3A7B3'
            fillRule='evenodd'
            d='M17.594 15.6c.516.526 1.36.534 1.886.018l6.788-6.667a1.332 1.332 0 00-.001-1.902L19.48.382a1.334 1.334 0 00-1.869 1.903l4.462 4.382H1.333a1.333 1.333 0 000 2.666h20.74l-4.462 4.382a1.334 1.334 0 00-.017 1.886z'
         ></path>
      </svg>
   )
}

RightArrowIcon.defaultProps = {
   width: 27,
   height: 16
}

export default RightArrowIcon
