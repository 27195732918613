import styled, { css } from 'styled-components'
import Colors from '../../../themes/Colors'
import {
   Typo24WhiteBarlowBoldItalic,
   Typo16YellowOrangeBarlowBoldItalic
} from '../../../styleGuide/Typos'
import Image from '../../Image'

export const SkewNegative20Angle = css`
   transform: skew(-20deg);
`

export const ReverseSkew = css`
   transform: skew(20deg);
`
export const ResourceItemContainer = styled.div`
   display: inline-block;
   ${SkewNegative20Angle}
`
export const ResourceItemTitleBg = styled.div`
   display: flex;
   position: relative;
   padding: 5px 0px 6px 28px;
`
export const ATHBg = styled.div`
   background: ${Colors.navyBlue};
   padding: 1px 6px 3px 10px;
   position: relative;
`

export const CommonResourceItemTitleBg = styled(ResourceItemTitleBg)`
   background: ${Colors.marineBlue};
`

export const CoinsAndGemsResourceItemTitleBg = styled(ResourceItemTitleBg)`
   background: ${Colors.peacockBlue};
`

export const CommonResourceItemContainer = styled(ResourceItemContainer)`
   width: 123px;
`

export const CommonResourceIcon = styled(Image)`
   position: absolute;
   object-fit: contain;
   z-index: 10;
   top: -6%;
   left: -17%;
   ${ReverseSkew}
`

export const CoinsIconDimension = styled(CommonResourceIcon)`
   width: 46px;
   height: 42px;
`
export const GemsIconDimension = styled(CommonResourceIcon)`
   width: 60px;
   height: 59px;
   top: 15%;
`

export const ResourceNameText = styled(Typo16YellowOrangeBarlowBoldItalic)`
   display: inline-block;
   ${ReverseSkew}
`

export const ResourceCountText = styled(Typo24WhiteBarlowBoldItalic)`
   display: inline-block;
   ${ReverseSkew}
`
