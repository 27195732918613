import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next' // eslint-disable-line

import DropDown from '../../../common/components/DropDown'
import { MatchTypeDropDownValue } from '../../constants/MatchConfigConstants'
import {
   MatchesPlayedHeaderContainer,
   FilterByContainer,
   FilterByText
} from './styledComponents'

interface MatchesPlayedHeaderProps extends WithTranslation {
   title: string
   filterBy: string
   onChange: any
}

export const options = [
   MatchTypeDropDownValue.ALL,
   MatchTypeDropDownValue.TOURNAMENT,
   MatchTypeDropDownValue.SPECIAL_CHALLENGE,
   MatchTypeDropDownValue.QUICK_PLAY
]

@observer
class MatchesPlayedHeader extends Component<MatchesPlayedHeaderProps> {
   render() {
      const { title, filterBy, t } = this.props
      let defaultValue = {}
      const optionWithDisplayValue = options.map(item => {
         const optionObject = {
            label: t(`matchTypes.${item}`),
            value: item
         }
         if (item === filterBy) {
            defaultValue = optionObject
         }
         return optionObject
      })

      return (
         <MatchesPlayedHeaderContainer title={title}>
            <FilterByContainer>
               <FilterByText>{t('titles.filterBy')}</FilterByText>
               <DropDown
                  options={optionWithDisplayValue}
                  onChange={this.props.onChange}
                  defaultValue={defaultValue}
               />
            </FilterByContainer>
         </MatchesPlayedHeaderContainer>
      )
   }
}

export default withTranslation()(MatchesPlayedHeader)
