import React, { ReactElement } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { MY_PROFILE } from '../../constants/NavigationConstants'
import BackArrow from '../../../matches/icons/BackArrow'
import {
   BackArrowText,
   NavigateBackWrapper,
   BackArrowWrapper
} from './styledComponents'

const pagePosition = 2

export const NavigationHeaderGoBack = (): ReactElement => {
   const history = useHistory()
   console.log(history)
   const onClickOfArrow = (): void => {
      if (history.length === pagePosition) {
         history.push(MY_PROFILE)
      } else {
         history.goBack()
      }
   }

   const GoBackHistoryText = (): ReactElement => {
      const { t } = useTranslation()
      return history.length === pagePosition ? t('myProfile') : t('back')
   }

   return (
      <NavigateBackWrapper onClick={onClickOfArrow}>
         <BackArrowWrapper>
            <BackArrow />
         </BackArrowWrapper>
         <BackArrowText>{GoBackHistoryText()}</BackArrowText>
      </NavigateBackWrapper>
   )
}
