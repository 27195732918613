import { userRewardsResource } from '../constants/tournamentConstants'

export const getResourceItem = (userRewards: any): any => {
   if (!userRewards) {
      return []
   }

   const rewardMode = userRewards.reward_config.reward_mode

   if (rewardMode === userRewardsResource.resource) {
      const resources: Array<object> = []
      const primaryReward = userRewards.reward_config.primary_reward
      if (primaryReward && Object.keys(primaryReward).length > 0) {
         resources.push(primaryReward)
      }
      return resources.concat(userRewards.reward_config.resources)
   } else if (rewardMode === userRewardsResource.treasure) {
      const treasure = userRewards.reward_config.treasure
      return treasure.resource_details
   }
}
