import React from 'react'
interface Props {
   width: number
   height: number
}

const WicketsConfigIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 62 64'
   >
      <defs>
         <path
            id='wickets_prefix__a'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__c'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__e'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__g'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33'
         ></path>
         <path
            id='wickets_prefix__i'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33'
         ></path>
         <path
            id='wickets_prefix__k'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33'
         ></path>
         <path
            id='wickets_prefix__m'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__o'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__q'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__s'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__u'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__w'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__y'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__A'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__C'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
         <path
            id='wickets_prefix__E'
            d='M30.33.474C13.58.474 0 14.054 0 30.806c0 16.752 13.58 30.33 30.33 30.33 16.752 0 30.332-13.578 30.332-30.33S47.082.474 30.331.474z'
         ></path>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__b' fill='#fff'>
               <use xlinkHref='#wickets_prefix__a'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M10.64 84.812l-3.491-.772 15.882-71.769 3.491.772z'
               mask='url(#wickets_prefix__b)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__d' fill='#fff'>
               <use xlinkHref='#wickets_prefix__c'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M21.515 87.219l-3.49-.773 15.881-71.768 3.491.771z'
               mask='url(#wickets_prefix__d)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__f' fill='#fff'>
               <use xlinkHref='#wickets_prefix__e'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M32.39 89.625l-3.491-.772 15.882-71.769 3.491.772z'
               mask='url(#wickets_prefix__f)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__h' fill='#fff'>
               <use xlinkHref='#wickets_prefix__g'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M26.393 13.622c-.288 1.302-1.302 2.184-2.266 1.97-.964-.213-1.512-1.441-1.224-2.742.288-1.301 1.303-2.183 2.267-1.97.963.214 1.511 1.441 1.223 2.742'
               mask='url(#wickets_prefix__h)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__j' fill='#fff'>
               <use xlinkHref='#wickets_prefix__i'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M37.268 16.029c-.288 1.302-1.303 2.184-2.266 1.97-.964-.213-1.512-1.442-1.224-2.743.288-1.3 1.303-2.183 2.267-1.97.964.214 1.511 1.442 1.223 2.743'
               mask='url(#wickets_prefix__j)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__l' fill='#fff'>
               <use xlinkHref='#wickets_prefix__k'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M48.144 18.436c-.288 1.301-1.303 2.183-2.267 1.97-.964-.214-1.512-1.442-1.224-2.743.288-1.301 1.302-2.184 2.267-1.97.964.213 1.512 1.441 1.224 2.743'
               mask='url(#wickets_prefix__l)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__n' fill='#fff'>
               <use xlinkHref='#wickets_prefix__m'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M35.503 13.167l-11.401-2.524.267-1.207 11.401 2.523z'
               mask='url(#wickets_prefix__n)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__p' fill='#fff'>
               <use xlinkHref='#wickets_prefix__o'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M47.832 15.895l-11.4-2.523.267-1.207L48.1 14.687z'
               mask='url(#wickets_prefix__p)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__r' fill='#fff'>
               <use xlinkHref='#wickets_prefix__q'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M28.483 13.051l-1.278-.282.845-3.816 1.276.283z'
               mask='url(#wickets_prefix__r)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__t' fill='#fff'>
               <use xlinkHref='#wickets_prefix__s'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M30.138 13.418l-1.278-.283.845-3.816 1.277.283z'
               mask='url(#wickets_prefix__t)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__v' fill='#fff'>
               <use xlinkHref='#wickets_prefix__u'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M31.793 13.783l-1.278-.281.845-3.816 1.277.282z'
               mask='url(#wickets_prefix__v)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__x' fill='#fff'>
               <use xlinkHref='#wickets_prefix__w'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M33.448 14.149l-1.278-.282.845-3.815 1.277.283z'
               mask='url(#wickets_prefix__x)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__z' fill='#fff'>
               <use xlinkHref='#wickets_prefix__y'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M39.335 15.452l-1.278-.282.845-3.815 1.278.283z'
               mask='url(#wickets_prefix__z)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__B' fill='#fff'>
               <use xlinkHref='#wickets_prefix__A'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M40.99 15.819l-1.277-.283.844-3.815 1.278.283z'
               mask='url(#wickets_prefix__B)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__D' fill='#fff'>
               <use xlinkHref='#wickets_prefix__C'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M42.645 16.185l-1.278-.282.845-3.816 1.277.282z'
               mask='url(#wickets_prefix__D)'
            ></path>
         </g>
         <g transform='translate(.022 1.072)'>
            <mask id='wickets_prefix__F' fill='#fff'>
               <use xlinkHref='#wickets_prefix__E'></use>
            </mask>
            <path
               fill='#F0A540'
               d='M44.3 16.551l-1.277-.282.844-3.816 1.277.283z'
               mask='url(#wickets_prefix__F)'
            ></path>
         </g>
      </g>
   </svg>
)

WicketsConfigIcon.defaultProps = {
   width: 62,
   height: 64
}

export default WicketsConfigIcon
