import React from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { observable } from 'mobx'

import { LoaderWrapper } from '../../../matches/components/OversList/styledComponents'
import LoadingWrapper from '../../../common/LoadingWrapper'

import TournamentRoundsTabs from '../../components/TournamentRoundsTabComponent'
import TournamentsStore from '../../stores/TournamentsStore'
import TournamentsRoundDetailsPopup from '../../components/TournamentsRoudnDetailsPopup'

import TournamentDetailsHeaderComponent from '../../components/TournamentDetailsHeaderComponent'
import { NavigationHeaderGoBack } from '../../../common/components/NavigationHeaderGoBackComponent'
import { Container } from './styledComponent'

interface TournamentsDetailsRouteProps {
   text?: string
}

type InjectedProps = TournamentsDetailsRouteProps & {
   tournamentId: string
   match: any
   tournamentsStore: TournamentsStore
}

@inject('tournamentsStore')
@observer
class TournamentsDetailsRoute extends React.Component<InjectedProps> {
   @observable shouldShowRoundDetails = false

   hideRoundDetails = (): void => {
      this.shouldShowRoundDetails = false
   }

   showRoundDetails = (): void => {
      this.shouldShowRoundDetails = true
   }

   componentDidMount(): void {
      this.doNetworkCallForTournamentDetails()
   }

   doNetworkCallForTournamentDetails(): void {
      const { tournamentId } = this.props.match.params
      this.props.tournamentsStore.getTournamentDetails(tournamentId, '')
   }

   getInjectedProps = (): InjectedProps => this.props as InjectedProps

   render() {
      const { tournamentsStore } = this.props
      const {
         showRoundDetails,
         shouldShowRoundDetails,
         hideRoundDetails
      } = this
      const {
         getTournamentDetailsAPIStatus,
         getTournamentDetailsAPIError,
         tournamentDetails,
         setCurrentRoundIndex,
         currentRoundIndex,
         tournamentInfo
      } = tournamentsStore

      return (
         <LoaderWrapper>
            <LoadingWrapper
               apiStatus={getTournamentDetailsAPIStatus}
               apiError={getTournamentDetailsAPIError}
               onRetry={this.doNetworkCallForTournamentDetails}
            >
               <Container>
                  <NavigationHeaderGoBack />
                  <TournamentDetailsHeaderComponent
                     tournamentDetails={tournamentDetails?.tournament_details}
                     showRoundDetails={showRoundDetails}
                  />
                  <TournamentRoundsTabs
                     tournamentInfo={tournamentInfo}
                     setCurrentRoundIndex={setCurrentRoundIndex}
                     currentRoundIndex={currentRoundIndex}
                  />
                  <TournamentsRoundDetailsPopup
                     shouldShowRoundDetails={shouldShowRoundDetails}
                     hideRoundDetails={hideRoundDetails}
                     popupLeaderBoardModels={
                        tournamentInfo?.popupLeaderBoardModels
                     }
                  />
               </Container>
            </LoadingWrapper>
         </LoaderWrapper>
      )
   }
}
export default withRouter(TournamentsDetailsRoute)
