import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'

import { Typo14PumpkinOrangeBarlowItalicCapital } from '../../../../../common/styleGuide/Typos'

export const NavLink = styled(Link)`
   text-decoration: auto;
`

export const GotoTouranmentContainer = styled.div`
   ${tw`flex flex-col`}
   cursor: pointer;
   text-align: end;
`
export const ArrowWrapper = styled(Typo14PumpkinOrangeBarlowItalicCapital)`
   font-size: 26px;
`
