import styled from 'styled-components'
import {
   Typo16BattleShipGreyBarlowBoldItalic,
   Typo16BattleShipGreyBarlowSemiBoldCapital,
   Typo24SilverBarlowSemiBoldItalic
} from '../../../common/styleGuide/Typos'

export const TitleText = styled(Typo16BattleShipGreyBarlowSemiBoldCapital)``

export const ResourceText = styled(TitleText)`
   padding-left: 8px;
`

export const LeaderboardCard = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   padding-left: 24px;
   padding-top: 19px;
`
export const LeaderboardEmptyCard = styled.div`
   background-image: url('/images/group-3.png');
   background-position: center;
   background-repeat: no-repeat;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: white;
   font-family: 'Barlow';
   font-size: 24px;
   width: 100%;
   height: 80vh;
`
export const LeaderboardEmptyText = styled(Typo24SilverBarlowSemiBoldItalic)`
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   padding-top: 100px;
   white-space: pre-wrap;
   text-align: center;
`
export const LeaderboardMainDiv = styled.div`
   display: flex;
   width: 100%;
`

export const LeaderboardRatingNote = styled(
   Typo16BattleShipGreyBarlowBoldItalic
)`
   padding-right: 24px;
   padding-top: 19px;
   padding-left: 24px;
`
