import styled from 'styled-components'

import colors from '../../../common/themes/Colors'

export const FinalMatchHierarchyContainer = styled.div`
   display: flex;
   margin-top: 50px;
`
export const ArrowIconWrapper = styled.div`
   margin-top: 78px;
   margin-left: 100px;
   color: ${colors.white};
   font-size: 100px;
`

export const WinnerPlayerComponentWrapper = styled.div`
   margin-top: 110px;
   margin-left: 16px;
`

export const TbdComponentWrapper = styled.div`
   margin-top: 104px;
   margin-left: 30px;
`

export const KnockOutMatchCardWrapper = styled.div`
   margin-top: 44px;
   margin-bottom: 70px;
`
