import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { LOGIN_SCREEN_PATH } from '../../constants/NavigationConstants'

import { getIdToken } from '../StorageUtils'

interface RouteParams {
   component: any
   [x: string]: any
}

export const ProtectedRoute = ({
   component: Component,
   ...other
}: RouteParams) => {
   const renderComponent = props => {
      const idToken = getIdToken()
      if (idToken) {
         return <Component key={props.location.key} {...props} {...other} /> //eslint-disable-line
      }
      return (
         <Redirect
            to={{
               pathname: LOGIN_SCREEN_PATH,
               state: { from: props.location } // eslint-disable-line
            }}
         />
      )
   }
   return <Route {...other} render={renderComponent} />
}
