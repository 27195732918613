import styled from 'styled-components'

import Colors from '../../../themes/Colors'
import {
   Typo22WhiteSemiBoldBarlowText,
   Typo12YellowOrangeBarlowBoldItalic
} from '../../../styleGuide/Typos'

import {
   ResourceItemContainer,
   ReverseSkew
} from '../CommonResource/styledComponents'
import Image from '../../Image'

export const OversLeftContainer = styled.div`
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
`
export const OversResourceItemContainer = styled(ResourceItemContainer)`
   position: absolute;
   width: 99px;
`

export const ResourceItemTitleBg = styled.div`
   position: relative;
   background: ${Colors.greyishBrown};
   padding: 0px 0px 4px 28px;
`

export const ResourceIcon = styled(Image)`
   position: absolute;
   width: 54px;
   height: 54px;
   object-fit: cover;
   z-index: 10;
   top: -40%;
   left: -25%;
   ${ReverseSkew}
`
export const OversLeftTextBg = styled.div`
   background: ${Colors.blackTwo};
   padding: 1px 6px 1px 7px;
   position: relative;
`
export const OversText = styled(Typo12YellowOrangeBarlowBoldItalic)`
   display: inline-block;
   ${ReverseSkew}
`

export const OversCountText = styled(Typo22WhiteSemiBoldBarlowText)`
   display: inline-block;
   ${ReverseSkew}
`
