import styled from '@emotion/styled'
import Colors from '../../../../common/themes/Colors'
import { Typo24BlackBarlowBoldNormalCapital } from '../../../../common/styleGuide/Typos'
import { leaderBoardConstants } from '../../../constants/leaderBoardConstants'
import ImageComponent from '../../../../common/components/BaseImageComponent'

export const MainContainer = styled.div`
   width: 803px;
   height: 54px;
   background-color: ${Colors.darkThree};
   transform: skew(${leaderBoardConstants.eighteenDegrees}deg);
   display: flex;
   justify-content: center;
`
export const Container = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 780px;
   height: 54px;
   background-image: ${Colors.linerGradients.resourceItemBGGradient};
   position: relative;
`
export const RankRange = styled(Typo24BlackBarlowBoldNormalCapital)`
   left: 60px;
   min-width: 174px;
   text-shadow: 0 1px 0 ${Colors.marineBlueThree};
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
   position: absolute;
`
export const NextRoundContainer = styled(Typo24BlackBarlowBoldNormalCapital)`
   min-width: 219px;
   height: 54px;
   position: absolute;
   left: 174px;
   background-image: ${Colors.linerGradients.leaderBoardNextRoundGradient};
`
export const NextRoundTextWrapper = styled.div`
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
   text-align: center;
   height: 54px;
   width: 100%;
   line-height: 54px;
`

export const TrophyContainer = styled.div`
   height: 54px;
   display: flex;
   align-items: center;
   width: 60px;
`
export const TrophyIconWrapper = styled.div`
   position: relative;
   left: 430px;
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
`

export const TrophyPointsWrapper = styled(Typo24BlackBarlowBoldNormalCapital)`
   position: relative;
   left: 437px;
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
`
export const DiamondIconWrapper = styled.div`
   position: relative;
   left: 470px;
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
   margin-top: 5px;
`

export const DiamondPointsWrapper = styled(Typo24BlackBarlowBoldNormalCapital)`
   position: relative;
   left: 477px;
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
`
export const CoinIconWrapper = styled.div`
   position: relative;
   left: 510px;
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
   margin-top: 5px;
`

export const CoinPointsWrapper = styled(Typo24BlackBarlowBoldNormalCapital)`
   position: relative;
   left: 517px;
   transform: skew(-${leaderBoardConstants.eighteenDegrees}deg);
`

export const GemImageComponent = styled(ImageComponent)``

export const CoinsImageComponent = styled(ImageComponent)``

export const IbcRatingImageComponent = styled(ImageComponent)``
