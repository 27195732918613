export type LeaderboardRankObj = {
   rank: number
   countryPic: string
   resourceType: string
   score: string
   name: string
   profilePic: string
   resourceRating: string
   userID: string
}
class TournamentLeaderboardSuperItem {
   resourceRating: string
   rank: number
   countryPic: string
   resourceType: string
   score: string
   name: string
   profilePic: string
   userID: string

   constructor(leaderRankObj: LeaderboardRankObj) {
      this.name = leaderRankObj.name
      this.profilePic = leaderRankObj.profilePic
      this.rank = leaderRankObj.rank
      this.countryPic = leaderRankObj.countryPic
      this.resourceType = leaderRankObj.resourceType
      this.score = leaderRankObj.score
      this.resourceRating = leaderRankObj.resourceRating
      this.userID = leaderRankObj.userID
   }
}

export default TournamentLeaderboardSuperItem
