import React from 'react'
import { observer } from 'mobx-react'

import { API_SUCCESS } from '@ib/api-constants'

import Loader from '../../../common/components/Loader'
import CricketConstantsStore from '../../../common/stores/CricketConstantsStore'

import EmptyTournamentsComponent from '../../components/EmptyTournamentsComponent'
import TournamentCard from '../../components/TournamentCard'
import TournamentsStore from '../../stores/TournamentsStore'

import {
   InfiniteScrollWrapper,
   LoaderWrapper,
   Wrapper
} from './styledComponents'

export type TournamentsProps = {
   tabEnum: string
   tournamentsStore: TournamentsStore
   cricketConstantsStore: CricketConstantsStore
}

@observer
class TournamentsTabContent extends React.Component<TournamentsProps> {
   gettournamentsStore = () => this.props.tournamentsStore

   getTournaments = () => {
      this.gettournamentsStore().getTournamentsMinimalDetails(
         this.props.tabEnum,
         this.props.cricketConstantsStore.getCricketGameId()
      )
   }

   fetchMoreData = () => {
      const {
         hasMoreTournaments
      } = this.gettournamentsStore().getTournamentsTabObjectsByStatus(
         this.props.tabEnum
      )
      hasMoreTournaments && this.getTournaments()
   }

   renderLoader = () => (
      <LoaderWrapper>
         <Loader height={60} width={60} />
      </LoaderWrapper>
   )

   render() {
      const { tabEnum } = this.props
      const {
         currentTournamentsCount,
         hasMoreTournaments,
         tournaments
      } = this.gettournamentsStore().getTournamentsTabObjectsByStatus(tabEnum)

      const { getTournamentsListAPIStatus } = this.gettournamentsStore()
      const shouldRenderTournaments =
         currentTournamentsCount || getTournamentsListAPIStatus !== API_SUCCESS

      return (
         <InfiniteScrollWrapper
            dataLength={currentTournamentsCount}
            next={this.fetchMoreData}
            hasMore={hasMoreTournaments}
            loader={this.renderLoader()}
            endMessage={null}
         >
            {shouldRenderTournaments ? (
               <Wrapper>
                  {tournaments.map((tournament, index) => (
                     <TournamentCard key={index} tournament={tournament} />
                  ))}
               </Wrapper>
            ) : (
               <EmptyTournamentsComponent title={tabEnum} />
            )}
         </InfiniteScrollWrapper>
      )
   }
}
export default TournamentsTabContent
