import styled from 'styled-components'
import ImageComponent from '../../../common/components/BaseImageComponent'
import { Typo18SilverBarlowSemiBold } from '../../../common/styleGuide/Typos'

export const UserDetailsContainer = styled.div`
   display: flex;
   align-items: center;
   min-width: 24%;
   flex-wrap: wrap;
`
export const ProfilePic = styled(ImageComponent)`
   border-radius: 50%;
   width: 48px;
   height: 48px;
   object-fit: cover;
`
export const NameText = styled(Typo18SilverBarlowSemiBold)`
   display: inline-block;
   justify-content: flex-start;
   padding-left: 16px;
`
