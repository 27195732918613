import React, { ReactElement } from 'react'

import { Typo54WhiteBarlowBoldItalic } from '../../../../common/styleGuide/Typos'
import { getDateTimeDisplay } from '../../../../common/utils/TimeUtils'

import BaseMatch from '../../../stores/models/BaseMatch'

import {
   HeaderWrapper,
   ScoreWrapper,
   ScoreTextWrapper,
   HeaderRightSideContentWrapper,
   HeaderLeftSideContentWrapper,
   MatchIcon,
   TitleWrapper,
   DisplayDateAndTime,
   MatchDetailsHeaderText
} from './styledComponents'

interface MatchesHeaderProps {
   model: BaseMatch
   children: any
}

const MatchesBaseHeader = (props: MatchesHeaderProps): ReactElement => {
   const { model } = props
   const { gameStats } = model.getSelfUserMatchDetails()
   const score = `${gameStats.score}-${gameStats.wickets}`
   return (
      <HeaderWrapper>
         <HeaderLeftSideContentWrapper>
            {model.titleUrl ? <MatchIcon src={model.titleUrl} /> : null}
            <TitleWrapper>
               <MatchDetailsHeaderText>{model.title}</MatchDetailsHeaderText>
            </TitleWrapper>
            <DisplayDateAndTime>
               {getDateTimeDisplay(model.dateTime)}
            </DisplayDateAndTime>
         </HeaderLeftSideContentWrapper>
         <HeaderRightSideContentWrapper>
            {props.children}
         </HeaderRightSideContentWrapper>
         <ScoreWrapper>
            <ScoreTextWrapper>
               <Typo54WhiteBarlowBoldItalic>
                  {score}
               </Typo54WhiteBarlowBoldItalic>
            </ScoreTextWrapper>
         </ScoreWrapper>
      </HeaderWrapper>
   )
}
export default MatchesBaseHeader
