import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import ResourceItemCmp from '../../../../../common/components/Resources/ResourceItem'
import ScenarioMatch from '../../../../stores/models/ScenarioMatch'

import { TitleAndValue } from '../../../MatchesCommon/MatchesCommon'
import WinOrLost from '../../../WinAndLose'

import { ContentWrapper } from '../../BaseMatchCard/styledComponents'
import BaseMatchCardView from '../../BaseMatchCard'

interface ScenarioMatchCardProps {
   model: ScenarioMatch
}

const ScenarioMatchCard = (props: ScenarioMatchCardProps): ReactElement => {
   const { model: scenarioModel } = props
   const { t } = useTranslation()
   const { gameStats } = scenarioModel.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <BaseMatchCardView
         matchModel={scenarioModel}
         renderHeaderChildren={() => <WinOrLost isWon={scenarioModel.isWon} />}
      >
         <ContentWrapper>
            <ContentWrapper>
               <ResourceItemCmp resource={scenarioModel.fanBase} />
               <TitleAndValue
                  title={t('matches.mileStone')}
                  value={`${scenarioModel.mileStonesAcheived}/${scenarioModel.totalMileStones}`}
               />
               <TitleAndValue
                  title={t('matches.over', {
                     count: overs
                  })}
                  value={overs.toString()}
               />
            </ContentWrapper>
         </ContentWrapper>
      </BaseMatchCardView>
   )
}

export default ScenarioMatchCard
