import React from 'react'

interface ArrowMarkIconProps {
   width: number
   height: number
}

const ArrowMarkIcon = (props: ArrowMarkIconProps) => {
   const { width, height } = props
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width={width}
         height={height}
         viewBox='0 0 53 12'
         {...props}
      >
         <path
            d='M41.572.168l11 5.669-11 5.67-.001-4.937H.687V5.103h40.884V.168z'
            fill='#FFF'
            fillRule='nonzero'
         />
      </svg>
   )
}

ArrowMarkIcon.defaultProps = {
   width: 53,
   height: 12
}

export default ArrowMarkIcon
