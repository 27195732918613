import styled from '@emotion/styled'

import StatsList from '../../../common/components/StatsList'
import {
   tablet,
   mobile,
   mediumDesktopMaxWidth,
   desktop
} from '../../../common/utils/MixinUtils'
import { Typo16BattleShipGreyBarlowBoldItalic } from '../../../common/styleGuide/Typos'

import WagonWheel from '../WagonWheel'

export const StyledWagonWheel = styled(WagonWheel)`
   margin-top: 32px;
`

export const WagonWheelNoteText = styled(Typo16BattleShipGreyBarlowBoldItalic)`
   position: absolute;
   top: 8%;
   max-width: 250px;
`

export const StyledStatsList = styled(StatsList)`
   width: auto;
   padding-right: 23.9%;
   margin-top: 16px;
   ${tablet} {
      padding-right: 10%;
   }
   ${desktop} {
      padding-right: 10%;
   }
`

export const WheelContainer = styled.div`
   position: relative;
   margin-right: 10px;
   width: 45%;
   ${mediumDesktopMaxWidth} {
      margin-bottom: 10px;
   }
`

export const WagonWheelWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
`

export const Container = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
   margin-top: 40px;
   margin-left: 25px;
   margin-right: 25px;
   flex-wrap: wrap;
   ${tablet} {
      justify-content: space-between;
      align-items: flex-start;
   }
   ${mobile} {
      align-items: center;
   }
`
