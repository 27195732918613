import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import OverItem from '../OverItem'

import BaseMatch from '../../stores/models/BaseMatch'
import { OverDetail } from '../../stores/types'
import LoadingWrapper from '../../../common/LoadingWrapper'
import { Container } from './styledComponents'

interface OversListProps {
   baseMatch: BaseMatch
}

@observer
class OversList extends React.Component<OversListProps> {
   getBaseMatch = (): BaseMatch => this.props.baseMatch

   @action.bound
   getOversList(): void {
      this.getBaseMatch().getBallByBallDetails()
   }

   componentDidMount(): void {
      this.getOversList()
   }

   renderCardUI = (detail: OverDetail, overNumber: number): JSX.Element => (
      <OverItem
         key={detail.current_state.overs}
         overDetails={detail}
         overNumber={overNumber}
      />
   )

   render(): JSX.Element {
      const { overDetails } = this.getBaseMatch()
      const {
         getBallByBallDetailsAPIStatus,
         getBallByBallDetailsAPIError
      } = overDetails
      return (
         <LoadingWrapper
            apiStatus={getBallByBallDetailsAPIStatus}
            apiError={getBallByBallDetailsAPIError as Error}
         >
            <Container>
               {overDetails.oversList.map((details, index) =>
                  this.renderCardUI(details, index + 1)
               )}
            </Container>
         </LoadingWrapper>
      )
   }
}

export default OversList
