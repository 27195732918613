import styled from 'styled-components'
import ImageComponent from '../../../../common/components/BaseImageComponent'
import {
   TwoLineEllipsis,
   Typo14CoolGreyBarlowBoldItalic,
   Typo18LightBlueGrayBarlowBoldItalic
} from '../../../../common/styleGuide/Typos'

import Colors from '../../../../common/themes/Colors'
import {
   customDevice,
   maxDeviceWidth
} from '../../../../common/utils/MixinUtils'

export const HeaderWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   text-align: center;
   align-items: center;
   background: ${Colors.darkBlueGrayTwo};
   overflow: hidden;
   flex: wrap;
`
export const ScoreWrapper = styled.div`
   background: ${Colors.linerGradients.headerScoreGradient};
   transform: skew(-20deg);
   margin-right: -2%;
   min-height: 114px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex: 1;
`
export const ScoreTextWrapper = styled.div`
   min-width: 265px;
   transform: skew(20deg);
   padding-right: 2%;
`

export const HeaderRightSideContentWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
   flex: 6;
   flex-wrap: wrap;
   align-items: flex-end;
   ${customDevice(1403, 1544)} {
      flex: 7;
   }
   ${customDevice(1365, 1402)} {
      flex: 8;
   }
   ${maxDeviceWidth(1300)} {
      flex: 8;
   }
`
export const HeaderLeftSideContentWrapper = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: flex-end;
   flex: 5;
   margin-left: 1%;
   flex-wrap: wrap;
   ${customDevice(1403, 1544)} {
      flex: 4;
   }
   ${customDevice(1365, 1402)} {
      flex: 3;
   }
   ${maxDeviceWidth(1300)} {
      flex: 3;
   }
`
export const TitleWrapper = styled.div`
   max-width: 165px;
   text-align: left;
   margin-right: 5%;
   align-self: center;
`
export const MatchIcon = styled(ImageComponent)`
   max-width: 60px;
   max-height: 60px;
   margin-right: 3%;
`
export const ItemWrapper = styled.div`
   margin-left: 28px;
   margin-right: 28px;
   display: flex;
   align-items: center;
`
export const DisplayDateAndTime = styled(Typo14CoolGreyBarlowBoldItalic)`
   margin-top: 10px;
`
export const MatchDetailsHeaderText = styled(
   Typo18LightBlueGrayBarlowBoldItalic
)`
   ${TwoLineEllipsis};
   word-wrap: break-word;
   white-space: pre-wrap;
`
