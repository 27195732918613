import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import ResourceItemCmp from '../../../../../common/components/Resources/ResourceItem'
import SpecialChallengeMatch from '../../../../stores/models/SpecialChallengeMatch'

import { TitleAndValue } from '../../../MatchesCommon/MatchesCommon'
import WinOrLost from '../../../WinAndLose'

import { ContentWrapper } from '../../BaseMatchCard/styledComponents'
import BaseMatchCardView from '../../BaseMatchCard'

interface SteakChallengeCardProps {
   model: SpecialChallengeMatch
}

const SteakChallengeCard = (props: SteakChallengeCardProps): ReactElement => {
   const { model: specialChallengeModel } = props
   const { t } = useTranslation()
   const { gameStats } = specialChallengeModel.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <BaseMatchCardView
         matchModel={specialChallengeModel}
         renderHeaderChildren={() => (
            <WinOrLost isWon={specialChallengeModel.isWon} />
         )}
      >
         <ContentWrapper>
            <ContentWrapper>
               <ResourceItemCmp resource={specialChallengeModel.fanBase} />
               <TitleAndValue
                  title={t('matches.mileStone')}
                  value={`${specialChallengeModel.achivedChallengeMileStonesCount}/${specialChallengeModel.challengeMileStonesCount}`}
               />
               <TitleAndValue
                  title={t('matches.over', {
                     count: overs
                  })}
                  value={overs.toString()}
               />
            </ContentWrapper>
         </ContentWrapper>
      </BaseMatchCardView>
   )
}

export default SteakChallengeCard
