import React, { ReactElement } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { Typo18BattleShipGreyBarlowSemiBoldCapital } from '../../../common/styleGuide/Typos'
import PopupLeaderboardModel from '../../stores/models/PopupLeaderBoardModel'

import PathToFinalsComponent from '../PathToFinals/PathToFinalsComponents'
import OverallLeaderBoard from '../RoundOneLeaderBoardComponents/OverallLeaderboard'

import { TabsListWrapper, TabsListsBarWrapper } from './styledComponent'

interface TabProps {
   title: string
}

const TabComponent = (props: TabProps): ReactElement => (
   <Typo18BattleShipGreyBarlowSemiBoldCapital>
      {props.title}
   </Typo18BattleShipGreyBarlowSemiBoldCapital>
)

const tournamentRoundDetailsTabs = ['LEADERBOARDS', 'KNOCKOUTS']

interface OngoingTournamentRoundsTabsProps {
   text?: string
   popupLeaderBoardModels: Array<PopupLeaderboardModel>
}

const OngoingTournamentRoundsTabs = (
   props: OngoingTournamentRoundsTabsProps
): ReactElement => (
   <Tabs>
      <TabsListsBarWrapper>
         <TabsListWrapper className={'react-tabs-tabs-list'}>
            <TabList className={'react-tabs-tabs-list'}>
               {tournamentRoundDetailsTabs.map((round, index) => (
                  <Tab key={index}>
                     <TabComponent title={round} />
                  </Tab>
               ))}
            </TabList>
         </TabsListWrapper>
      </TabsListsBarWrapper>
      {tournamentRoundDetailsTabs.map((round, index) => (
         <TabPanel key={index}>
            {index === 0 ? (
               <OverallLeaderBoard
                  popupLeaderBoardModels={props.popupLeaderBoardModels}
               />
            ) : (
               <PathToFinalsComponent />
            )}
         </TabPanel>
      ))}
   </Tabs>
)
export default OngoingTournamentRoundsTabs
