export const IBC_LOGO_PATH = '../../../images/i-bc-logo-10-x.png'
export const LOGIN_PAGE_BACKGROUND_IMAGE_PATH =
   '../../../images/steam-login-bg.png'
export const LOGO_BACKGROUND_IMAGE_PATH =
   '../../../images/Logo-background-image.png'
export const FAILURE_VIEW_IMAGE_PATH = '../../../images/error-4-x.png'
export const FAILURE_VIEW_BACKGROUND_IMAGE_PATH =
   '../../../images/group-3-failure-image.png'
export const EMPTY_MATCHES_IMAGE_PATH =
   '../../../images/empty-matches-image.png'
export const EMPTY_ONGOING_TOURNAMENTS_IMAGE_PATH =
   '../../../images/group-3.png'
export const IBC_GROUND_RENOVATION_IMAGE_PATH =
   '../../../images/i-bc-ground-renovation.jpg'
