import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import {
   Typo16BlackBarlowBold,
   Typo20SilverBarlowBoldCapital
} from '../../../common/styleGuide/Typos'

import { GetBallScoreDisplayTextAndBgAndTextColor } from '../../Utils/MatchUtils'
import { OverDetail } from '../../stores/types'
import {
   BallItemBg,
   OverItemWrapper,
   BallItemsWrapper
} from './styledComponents'

interface ScoreItemProps {
   score: number
   ball_summary: string
   status: string
}

const BallItem = (props: ScoreItemProps) => {
   const scoreData = GetBallScoreDisplayTextAndBgAndTextColor(
      props.status,
      props.ball_summary
   )
   return (
      <BallItemBg style={{ backgroundColor: scoreData.bgColor }}>
         <Typo16BlackBarlowBold style={{ color: scoreData.textColor }}>
            {scoreData.scoreText}
         </Typo16BlackBarlowBold>
      </BallItemBg>
   )
}

interface OverItemProps {
   overDetails: OverDetail
   overNumber: number
}

const OverItem = (props: OverItemProps): ReactElement => {
   const {
      overDetails: { ball_details },
      overNumber
   } = props
   const { t } = useTranslation()
   return (
      <OverItemWrapper>
         <Typo20SilverBarlowBoldCapital>
            {t('matches.over')} {overNumber}
         </Typo20SilverBarlowBoldCapital>
         <BallItemsWrapper>
            {ball_details?.map((item, index) => (
               <BallItem
                  key={`$item.ball_set_id}-${index}`}
                  score={item.ball_score}
                  status={item.ball_status}
                  ball_summary={item.ball_summary}
               />
            ))}
         </BallItemsWrapper>
      </OverItemWrapper>
   )
}
export default OverItem
