import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next' // eslint-disable-line

import ResourceItemCmp from '../../../../common/components/Resources/ResourceItem'
import SpecialChallengeMatch from '../../../stores/models/SpecialChallengeMatch'

import { TitleAndValue } from '../../MatchesCommon/MatchesCommon'
import WinOrLost from '../../WinAndLose'

import MatchesBaseHeader from '../BaseHeader'
import { ItemWrapper } from '../BaseHeader/styledComponents'

interface SurvivalMatchHeaderProps {
   model: SpecialChallengeMatch
}

const SurvivalMatchHeader = (props: SurvivalMatchHeaderProps): ReactElement => {
   const { t } = useTranslation()
   const { model } = props
   const { gameStats } = model.getSelfUserMatchDetails()
   const { overs } = gameStats
   return (
      <MatchesBaseHeader model={model}>
         <ItemWrapper>
            <TitleAndValue
               title={t('matches.over', {
                  count: overs
               })}
               value={overs.toString()}
            />
         </ItemWrapper>
         <ItemWrapper>
            <WinOrLost isWon={model.isWon} />
         </ItemWrapper>
         <ItemWrapper>
            <ResourceItemCmp resource={model.fanBase} />
         </ItemWrapper>
      </MatchesBaseHeader>
   )
}
export default SurvivalMatchHeader
