import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowRight } from 'react-icons/bs'

import { Typo14PumpkinOrangeBarlowItalicCapital } from '../../../../../common/styleGuide/Typos'
import { TOURNAMENTS } from '../../../../../common/constants/NavigationConstants'

import {
   GotoTouranmentContainer,
   ArrowWrapper,
   NavLink
} from './styledComponets'

type gotoTournamentProps = {
   tournamentId?: number | undefined
}

const GotoTournament = (props: gotoTournamentProps): ReactElement => {
   const { t } = useTranslation()
   const { tournamentId } = props
   return (
      <GotoTouranmentContainer onClick={e => e.stopPropagation()}>
         <NavLink to={`${TOURNAMENTS}/${tournamentId}`}>
            <ArrowWrapper>
               <BsArrowRight />
            </ArrowWrapper>
            <br />
            <Typo14PumpkinOrangeBarlowItalicCapital>
               {t('tournaments.goToTournaments')}
            </Typo14PumpkinOrangeBarlowItalicCapital>
         </NavLink>
      </GotoTouranmentContainer>
   )
}

export default GotoTournament
