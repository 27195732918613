import styled from 'styled-components'
import Colors from '../../../common/themes/Colors'

export const LeaderboardContainer = styled.div`
   background-color: ${Colors.dark};
`

export const LeaderboardTabsContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 19px;
`
