import { History } from 'history'

import {
   HOME_SCREEN_PATH,
   MY_PROFILE,
   LOGIN_SCREEN_PATH,
   TOURNAMENTS
} from '../constants/NavigationConstants'

export function goToLoginPage(history: History, shouldReplace = true) {
   if (shouldReplace) {
      history.replace(LOGIN_SCREEN_PATH)
   } else {
      history.push(LOGIN_SCREEN_PATH)
   }
}

export function goToHomePage(history: History) {
   history.replace(HOME_SCREEN_PATH)
}

export function goToUserProfilePage(history: History, username = 'name') {
   history.replace(MY_PROFILE)
}

export function navigateToPreviousPage(history: History) {
   history.goBack()
}

export function reloadPage() {
   window.location.reload()
}

export function goToTournamentsPage(history: History) {
   history.push(TOURNAMENTS)
}
