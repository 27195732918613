import React, { ReactElement } from 'react'

import { inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import AuthStore from '../../../common/stores/AuthStore'
import CountDownComponent from '../../../tournaments/components/CountdownComponent'

import { TournamentHeaderTitleProps } from '../../stores/types'

import DotsItem from '../DotsItem'
import TournamentLeaderboardHeader from '../TournamentLeaderboardHeader'
import TournamentLeaderboardItemCard from '../TournamentLeaderboardItemCard'

import {
   TableContainer,
   LeaderboardRoundContainer,
   LeaderboardText,
   Top100Text,
   TextWrapper
} from './styledComponents'

type TournamentLeaderboardProps = {
   leaderboards: TournamentHeaderTitleProps[]
   authStore?: AuthStore
   time: number
   lbContributionCount: number
}

const renderLeaderboardTable = (
   index: number,
   leaderboards: TournamentHeaderTitleProps[],
   requestUserId: string | undefined
): Array<ReactElement> => {
   const childs: Array<ReactElement> = []
   const data = leaderboards[index]
   if (
      index !== 0 &&
      leaderboards[index].rank - leaderboards[index - 1].rank > 1
   ) {
      childs.push(<DotsItem key={`dots-${index}`} />)
   }
   childs.push(
      <TournamentLeaderboardItemCard
         key={index}
         profilePic={data.profilePic}
         countryPic={data.countryPic}
         rank={data.rank}
         name={data.name}
         score={data.score}
         resourceRating={data.resourceRating ? data.resourceRating : '-'}
         isSelf={requestUserId === data.userID}
      />
   )
   return childs
}

const TournamentLeaderboardTable = (props: TournamentLeaderboardProps) => {
   const { leaderboards, time, lbContributionCount, authStore } = props
   const requestUserId = authStore?.getloggedInUserID()
   const { t } = useTranslation()
   return (
      <TableContainer>
         <LeaderboardRoundContainer>
            <TextWrapper>
               <LeaderboardText>
                  {t('tournaments:leaderBoardRound')}
               </LeaderboardText>
               <Top100Text>
                  {t('tournaments:top100will', { count: lbContributionCount })}
               </Top100Text>
            </TextWrapper>
            <CountDownComponent time={time} />
         </LeaderboardRoundContainer>
         <TournamentLeaderboardHeader />
         {leaderboards.map((data, index) =>
            renderLeaderboardTable(index, leaderboards, requestUserId)
         )}
      </TableContainer>
   )
}

export default inject('authStore')(TournamentLeaderboardTable)
