import React from 'react'
import { CommonBottomBarBg } from '../ConfigItem'

import { ItemContainer, StadiumIcon, StadiumTitle } from './styledComponents'

interface StadiumDetailsProps {
   name?: string
   url?: string
}

const StadiumDetails = (props: StadiumDetailsProps) => {
   const { name, url } = props
   return (
      <ItemContainer>
         <StadiumIcon src={url}></StadiumIcon>
         <CommonBottomBarBg className={''}>
            <StadiumTitle>{name}</StadiumTitle>
         </CommonBottomBarBg>
      </ItemContainer>
   )
}
export default StadiumDetails
