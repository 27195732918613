import styled from '@emotion/styled'
import { Typo16WhiteBarlowBoldCapital } from '../../styleGuide/Typos'
import colors from '../../themes/Colors'
import ImageComponent from '../BaseImageComponent'
import { StyledButton } from '../Button/styledComponents'
import { LOGIN_PAGE_BACKGROUND_IMAGE_PATH } from '../../../common/constants/PublicImageConstants'

export type SteamButtonProps = {
   isRenderLoader: boolean
}

export const BaseImage = styled(ImageComponent)`
   object-fit: contain;
`

export const Logo = styled(BaseImage)`
   top: 100px;
   left: 40%;
   width: 61%;
   height: 51%;
   margin-bottom: 7.3%;
   object-fit: contain;
   transition: 1s;
   margin-left: 58px;
`

export const SteamLogoWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   height: 60px;
   box-shadow: 0 6px 20px 0 rgba(244, 116, 0, 0.3);
   background-color: ${colors.pumpkinOrange};
   border-radius: 5px;
   min-width: 264px;
   text-align: center;
`

export const SteamLoginButtonText = styled(Typo16WhiteBarlowBoldCapital)`
   margin-left: 8px;
`

export const SteamSignContainer = styled.div`
   position: relative;
   background-image: url(${LOGIN_PAGE_BACKGROUND_IMAGE_PATH});
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-position: center;
   background-repeat: no-repeat;
   transition: 1s;
`

export const SteamLoginButton = styled(StyledButton)<SteamButtonProps>`
   opacity: ${props => (props.isRenderLoader ? 0.5 : 1)};
   text-decoration: none;
`

export const ContentDiv = styled.div`
   transition: 1s;
   margin-bottom: 20px;
`
