import styled from 'styled-components'

import Colors from '../../../common/themes/Colors'
import { Typo30WhiteBarlowExtraBoldItalic } from '../../../common/styleGuide/Typos'

export const ItemContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 101px;
`
export const BottomBarBg = styled.div`
   min-width: 131px;
   background-color: ${Colors.darkThree};
   transform: skew(-20deg);
`
export const BottomBarGradeint = styled.div`
   margin: 0px 6px;
   background-image: ${Colors.linerGradients.matchconfigItemGradient};
`
export const BottomBarTitleWrapper = styled.div`
   transform: skew(20deg);
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
`

export const ContentHolder = styled.div`
   display: flex;
   flex-direction: column;
   margin-left: -25px;
`

export const IconWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
`
export const ValueWrapper = styled(Typo30WhiteBarlowExtraBoldItalic)`
   text-align: center;
   padding-bottom: 5px;
`
export const BgAndIconWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`
