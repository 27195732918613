import styled from 'styled-components'
import {
   Typo36DarkTwoBoldItalic,
   Typo24DarkTwoBoldItalic
} from '../../../common/styleGuide/Typos'
import { IBC_GROUND_RENOVATION_IMAGE_PATH } from '../../constants/PublicImageConstants'

export const MaintenanceContainer = styled(`div`)`
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 516px;
   margin-top: 112px;
`
export const MainDiv = styled.div`
   display: flex;
   justify-content: center;
   width: 100%;
   height: 100vh;
   background-image: url(${IBC_GROUND_RENOVATION_IMAGE_PATH});
   background-size: cover;
   background-position: center;
   z-index: 10000;
`
export const MaintenanceTextHeader = styled(Typo36DarkTwoBoldItalic)``
export const MaintenanceTextDescription = styled(Typo24DarkTwoBoldItalic)`
   margin-top: 22px;
   text-align: center;
`
