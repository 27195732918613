import React, { ReactElement } from 'react'
import { inject } from 'mobx-react'

import SuccessTrophyIcon from '../../../common/icons/SuccessTrophyIcon'

import {
   ProfilePicAndCountryPicWrapper,
   PlayerProfilePic,
   PlayerCountryFlagPic
} from '../PlayerCardComponent/styledComponent'

import { getScoreFromGameStateBlob } from '../../utils/getPlayerPosition'
import { PaticipantBasicDetails } from '../../stores/types'
import PlayerDetailsComponent from '../PlayerDetailsComponent'

import { Container, TrophyIconWrapper } from './styledComponent'

interface WinnerPlayerCardProps {
   playerModel: PaticipantBasicDetails
   isPlayerOne: boolean
   authStore?: any
}

const WinnerPlayerCardComponent = (
   props: WinnerPlayerCardProps
): ReactElement => {
   const { playerModel, isPlayerOne, authStore } = props
   const {
      participant_model,
      participant_status,
      game_state_blob
   } = playerModel
   const { name, pic, participant_id, country_flag_pic_url } = participant_model
   const score = getScoreFromGameStateBlob(game_state_blob)
   const requestUserId = authStore?.getloggedInUserID()
   return (
      <Container isSamePerson={participant_id === requestUserId}>
         {!isPlayerOne && (
            <PlayerDetailsComponent
               playerName={name}
               matchStatus={participant_status}
               score={score}
               isPlayerOne={isPlayerOne}
            />
         )}
         <ProfilePicAndCountryPicWrapper>
            <PlayerProfilePic src={pic} isPlayerOne={isPlayerOne} />
            <PlayerCountryFlagPic
               src={country_flag_pic_url}
               isPlayerOne={isPlayerOne}
            />
         </ProfilePicAndCountryPicWrapper>
         {isPlayerOne && (
            <PlayerDetailsComponent
               playerName={name}
               matchStatus={participant_status}
               score={score}
               isPlayerOne={isPlayerOne}
            />
         )}
         <TrophyIconWrapper>
            <SuccessTrophyIcon width={65} height={65} />
         </TrophyIconWrapper>
      </Container>
   )
}
export default inject('authStore')(WinnerPlayerCardComponent)
