import React from 'react'

interface Props {
   width: number
   height: number
}

const OversConfigIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 55 55'
   >
      <defs>
         <filter
            id='prefix__b'
            width='143.4%'
            height='144.3%'
            x='-21.7%'
            y='-22.1%'
            filterUnits='objectBoundingBox'
         >
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur
               in='shadowOffsetOuter1'
               result='shadowBlurOuter1'
               stdDeviation='3'
            ></feGaussianBlur>
            <feColorMatrix
               in='shadowBlurOuter1'
               values='0 0 0 0 0.227042798 0 0 0 0 0.333925304 0 0 0 0 0.429528061 0 0 0 0.288241621 0'
            ></feColorMatrix>
         </filter>
         <filter
            id='prefix__d'
            width='143.4%'
            height='144.3%'
            x='-21.7%'
            y='-22.1%'
            filterUnits='objectBoundingBox'
         >
            <feGaussianBlur
               in='SourceAlpha'
               result='shadowBlurInner1'
               stdDeviation='3'
            ></feGaussianBlur>
            <feOffset
               in='shadowBlurInner1'
               result='shadowOffsetInner1'
            ></feOffset>
            <feComposite
               in='shadowOffsetInner1'
               in2='SourceAlpha'
               k2='-1'
               k3='1'
               operator='arithmetic'
               result='shadowInnerInner1'
            ></feComposite>
            <feColorMatrix
               in='shadowInnerInner1'
               values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
            ></feColorMatrix>
         </filter>
         <filter
            id='prefix__f'
            width='100%'
            height='100%'
            x='0%'
            y='0%'
            filterUnits='objectBoundingBox'
         >
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
            <feColorMatrix
               in='shadowOffsetOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.71337183 0'
            ></feColorMatrix>
         </filter>
         <filter
            id='prefix__i'
            width='100%'
            height='100%'
            x='0%'
            y='0%'
            filterUnits='objectBoundingBox'
         >
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
            <feColorMatrix
               in='shadowOffsetOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.71337183 0'
            ></feColorMatrix>
         </filter>
         <linearGradient
            id='prefix__c'
            x1='70.623%'
            x2='20.881%'
            y1='85.762%'
            y2='16.904%'
         >
            <stop offset='0%' stopColor='#B42A20'></stop>
            <stop offset='100%' stopColor='#C12A2A'></stop>
         </linearGradient>
         <linearGradient
            id='prefix__h'
            x1='100%'
            x2='16.129%'
            y1='36.87%'
            y2='75.137%'
         >
            <stop offset='0%' stopColor='#FFF'></stop>
            <stop offset='100%' stopColor='#FFF'></stop>
         </linearGradient>
         <linearGradient
            id='prefix__k'
            x1='100%'
            x2='16.129%'
            y1='40.081%'
            y2='68.989%'
         >
            <stop offset='0%' stopColor='#FFF'></stop>
            <stop offset='100%' stopColor='#FFF'></stop>
         </linearGradient>
         <path
            id='prefix__a'
            d='M40.694 14.89c3.362 10.73-2.789 22.098-13.737 25.392C16.01 43.576 4.41 37.548 1.05 26.82-2.311 16.089 3.84 4.72 14.787 1.427 25.734-1.867 37.334 4.161 40.694 14.89z'
         ></path>
         <path
            id='prefix__g'
            d='M39.715 5.393L38.31 4.076c-.78-.702-23.017 3.049-36.61 23.585-.478 3.898-2.293 1.69-.418 4.587 0 0 12.813-20.819 38.434-26.855z'
         ></path>
         <path
            id='prefix__j'
            d='M43.214 9.775s-22.584 3.013-39.1 23.462c0 0 1.762 1.707 2.733 1.496.972-.21 8.91-14.15 37.21-23.583l-.843-1.375z'
         ></path>
      </defs>
      <g fill='none' fillRule='evenodd' transform='translate(6.6 6.773)'>
         <mask id='prefix__e' fill='#fff'>
            <use xlinkHref='#prefix__a'></use>
         </mask>
         <g transform='matrix(-1 0 0 1 41.744 0)'>
            <use
               fill='#000'
               filter='url(#prefix__b)'
               xlinkHref='#prefix__a'
            ></use>
            <use fill='url(#prefix__c)' xlinkHref='#prefix__a'></use>
            <use
               fill='#000'
               filter='url(#prefix__d)'
               xlinkHref='#prefix__a'
            ></use>
         </g>
         <g mask='url(#prefix__e)' transform='scale(-1 1) rotate(81 0 -5.39)'>
            <use
               fill='#000'
               filter='url(#prefix__f)'
               xlinkHref='#prefix__g'
            ></use>
            <use fill='url(#prefix__h)' xlinkHref='#prefix__g'></use>
         </g>
         <g mask='url(#prefix__e)' transform='scale(-1 1) rotate(78 0 -7.48)'>
            <use
               fill='#000'
               filter='url(#prefix__i)'
               xlinkHref='#prefix__j'
            ></use>
            <use fill='url(#prefix__k)' xlinkHref='#prefix__j'></use>
         </g>
      </g>
   </svg>
)

OversConfigIcon.defaultProps = {
   width: 55,
   height: 55
}

export default OversConfigIcon
