import styled from 'styled-components'
import tw from 'twin.macro'

import { Typo14BattleShipGreyBarlowSemiBoldItalic } from '../../../common/styleGuide/Typos'
import Colors from '../../../common/themes/Colors'

export const WinLoseBase = styled.div`
   border-radius: 13px;
   display: flex;
   align-content: center;
   justify-content: center;
   padding: 1px 18px 3px;
   max-height: 22px;
`
export const Won = styled(WinLoseBase)`
   background: ${Colors.everGreen};
   max-width: 38px;
`
export const Lost = styled(WinLoseBase)`
   background: ${Colors.darkMaroon};
   max-width: 42px;
`
export const RoundText = styled(Typo14BattleShipGreyBarlowSemiBoldItalic)`
   text-transform: uppercase;
   margin-top: 5px;
`

export const WinOrLostContainer = styled.div`
   ${tw`flex flex-col items-center`}
`
