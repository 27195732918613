import { observable, action } from 'mobx'

import { API_INITIAL } from '@ib/api-constants'
import { bindPromiseWithOnSuccess } from '@ib/mobx-promise'

import { getStats } from '../../utils/ProfileUtils'
import PlayerProfileService from '../../services/PlayerProfileService'
import { OrderedResourceEnumsList } from '../../constants/ResourceConstants'
import ProfileDetails from '../models/ProfileDetails'
import UserControl from '../models/UserControls'
import {
   StatsItem,
   ResourceDetail,
   UserProfileBattingStats,
   PlayerDetails,
   PlayerProfileRequestType,
   PlayerProfileResponseType
} from '../types'

class PlayerProfileStore {
   myProfileService: PlayerProfileService
   @observable getUserProfileDetailsAPIStatus
   @observable getUserProfileDetailsAPIError
   @observable profileDetails: ProfileDetails
   @observable stats: StatsItem[] = []
   @observable resources: ResourceDetail[] = []
   userControls!: UserControl | null
   matchesCount: number | undefined
   ath: number
   scoreRanges: Array<number | string> = []

   constructor(myProfileService: any) {
      this.ath = -1
      this.myProfileService = myProfileService
      this.profileDetails = new ProfileDetails('', '')
      this.matchesCount = 0
      this.userControls = null
      this.init()
   }

   @action.bound
   init(): void {
      this.getUserProfileDetailsAPIStatus = API_INITIAL
      this.getUserProfileDetailsAPIError = null
   }

   @action.bound
   setGetMyProfileAPIStatus(status): void {
      this.getUserProfileDetailsAPIStatus = status
   }

   @action.bound
   setGetMyProfileAPIError(error): void {
      this.getUserProfileDetailsAPIError = error
   }

   @action.bound
   setProfileDetailsResponse(playerDetails: PlayerDetails): void {
      this.profileDetails.name = playerDetails.name
      this.profileDetails.profileUrl = playerDetails.pic
   }

   @action.bound
   setResourcesListResponse(resourceDetails: ResourceDetail[]): void {
      const resourceDetailsWithKey = {}
      resourceDetails.forEach(resource => {
         resourceDetailsWithKey[resource.name] = resource
      })
      this.resources = []
      OrderedResourceEnumsList.forEach(resourceEnum => {
         const resource = resourceDetailsWithKey[resourceEnum]
         if (resource !== undefined) {
            this.resources.push(resource)
         }
      })
   }

   setUserControls = userControls => {
      userControls !== undefined &&
         (this.userControls = new UserControl(userControls))
   }

   @action.bound
   setStatsListResponse(stats: UserProfileBattingStats): void {
      this.stats = getStats(stats)
      this.matchesCount = stats.matches_count
   }

   @action.bound
   setUserProfileResponse(response: PlayerProfileResponseType | null): void {
      if (response) {
         this.setProfileDetailsResponse(response.player_details)
         this.setStatsListResponse(response.batting_stats)
         this.setResourcesListResponse(response.resource_details)
         this.setUserControls(response.user_controls)
         this.ath = response.fanbase_ath
         this.scoreRanges = [] //response.scoreRanges
      }
   }

   @action.bound
   getPlayerProfileDetails(
      requestObj: PlayerProfileRequestType
   ): Promise<PlayerProfileResponseType | void> {
      const getResourcesPromise = this.myProfileService.getPlayerProfileDetailsAPI(
         requestObj
      )
      return bindPromiseWithOnSuccess(getResourcesPromise)
         .to(this.setGetMyProfileAPIStatus, this.setUserProfileResponse)
         .catch(this.setGetMyProfileAPIError)
   }
}

export default PlayerProfileStore
