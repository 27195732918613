import React from 'react'

interface Props {
   width: number
   height: number
}

const MatchesIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox='0 0 29 30'
   >
      <defs>
         <filter id='ls8j01v3na'>
            <feColorMatrix
               in='SourceGraphic'
               values='0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.701961 0 0 0 1.000000 0'
            ></feColorMatrix>
         </filter>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#ls8j01v3na)'
         transform='translate(0 -3)'
      >
         <g fillRule='nonzero'>
            <path
               fill='#FFF'
               d='M27.92 6.318l-5.135 5.134-1.791-1.791 5.134-5.175a.507.507 0 01.719 0l1.073 1.113a.507.507 0 010 .719z'
            ></path>
            <path
               fill='#FFF'
               d='M11.686 3v1.012h-.507c-.838 0-1.518.68-1.518 1.519H8.649c0-.839-.68-1.519-1.519-1.519h-.506v-.506c0-.28.227-.506.506-.506h4.556zm5.061.506v.506h-.506c-.839 0-1.519.68-1.519 1.519H13.71c0-.839-.68-1.519-1.518-1.519h-.506V3h4.555c.28 0 .506.227.506.506zm1.012 2.024v3.787l-2.024 2.023V5.53c0-.28.226-.506.506-.506h1.012c.28 0 .506.226.506.506zm0 14.522V31.85c0 .28-.226.506-.506.506h-1.011a.506.506 0 01-.506-.506v-9.774l2.024-2.024zM12.698 5.53v8.833l-2.026 2.024V5.531c0-.28.227-.506.506-.506h1.014c.28 0 .506.226.506.506zm0 19.583v6.737c0 .28-.227.506-.506.506h-1.013a.506.506 0 01-.506-.506v-4.712l2.025-2.025zM7.637 5.53v13.88l-2.025 2.023V5.531c0-.28.227-.506.506-.506H7.13c.28 0 .507.226.507.506zm0 24.644v1.676c0 .28-.227.506-.507.506H6.118a.507.507 0 01-.425-.238l1.944-1.944z'
               opacity='0.25'
            ></path>
            <path
               fill='#000'
               d='M22.527 13.857L4.18 32.204a.507.507 0 01-.719 0l-3.26-3.23a.506.506 0 010-.713L18.59 9.92a.505.505 0 01.587-.096l1.113.556 1.787 1.787.557 1.103a.508.508 0 01-.107.588z'
            ></path>
            <path
               fill='#FFF'
               d='M22.527 13.857L4.18 32.204a.507.507 0 01-.719 0l-3.26-3.23a.506.506 0 010-.713L18.59 9.92a.505.505 0 01.587-.096l1.113.556 1.787 1.787.557 1.103a.508.508 0 01-.107.588z'
            ></path>
         </g>
      </g>
   </svg>
)

MatchesIcon.defaultProps = {
   width: 29,
   height: 30
}
export default MatchesIcon
