import React, { ReactElement } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { Typo18PaleGreyBarlowSemiBoldItalic } from '../../../common/styleGuide/Typos'
import { TimeText } from './styledComponent'

const renderer = (props: any): ReactElement => {
   const { days, hours, minutes, seconds, completed } = props

   const TimerHook = (): ReactElement => {
      const { t } = useTranslation()

      if (completed) {
         return (
            <TimeText>
               <Typo18PaleGreyBarlowSemiBoldItalic>
                  {t('tournaments:roundCompleted')}
               </Typo18PaleGreyBarlowSemiBoldItalic>
            </TimeText>
         )
      }

      if (days > 0) {
         return (
            <TimeText>
               {days} {days >= 2 ? t('tournaments:days') : t('tournaments:day')}
            </TimeText>
         )
      } else if (hours > 0 && minutes !== 0) {
         return (
            <TimeText>
               {hours}
               {hours >= 2
                  ? t('tournaments:hoursShortcut')
                  : t('tournaments:hourShortcut')}{' '}
               {minutes}
               {minutes > 1
                  ? t('tournaments:minutesShortcut')
                  : t('tournaments:minuteShortcut')}
            </TimeText>
         )
      } else if (hours > 0 && minutes === 0) {
         return (
            <TimeText>
               {hours}
               {hours > 1
                  ? t('tournaments:hoursShortcut')
                  : t('tournaments:hourShortcut')}
            </TimeText>
         )
      } else if (minutes > 0 && seconds === 0) {
         return (
            <TimeText>
               {minutes}
               {minutes > 1
                  ? t('tournaments:minutesShortcut')
                  : t('tournaments:minuteShortcut')}
            </TimeText>
         )
      }
      return (
         <TimeText>
            {minutes}
            {minutes > 1
               ? t('tournaments:minutesShortcut')
               : t('tournaments:minuteShortcut')}{' '}
            {seconds}
            {seconds > 1
               ? t('tournaments:secondShortcut')
               : t('tournaments:secondsShortcut')}
         </TimeText>
      )
   }

   return <TimerHook />
}

interface CountdownProps {
   time: number
   makeTimeZero: () => void
}

const CountDownTimerComponent = (props: CountdownProps): ReactElement => {
   const timeInMilliseconds = props.time * 1000
   return (
      <Countdown
         date={Date.now() + timeInMilliseconds}
         daysInHours={true}
         renderer={renderer}
         onComplete={props.makeTimeZero}
      />
   )
}

export default CountDownTimerComponent
