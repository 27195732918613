import styled from 'styled-components'

import colors from '../../../../common/themes/Colors'
import { Typo18WhiteBarlowBoldItaclicCapital } from '../../../../common/styleGuide/Typos'
import { leaderBoardConstants } from '../../../constants/leaderBoardConstants'

export const RoundHeadeContainer = styled.div`
   height: 26px;
   width: 165px;
   background-color: ${colors.dodgerBlueOne};
   display: flex;
   justify-content: center;
   align-items: center;
   transform: skew(-${leaderBoardConstants.twentyDegreeskewPercentage}deg);
`

export const RoundHeaderText = styled(Typo18WhiteBarlowBoldItaclicCapital)`
   transform: skew(${leaderBoardConstants.twentyDegreeskewPercentage}deg);
`
