import { computed } from 'mobx'

import { ResourceEnums } from '../../../../common/constants/ResourceConstants'
import { getResourceItems } from '../../../Utils/MatchUtils'

import {
   MatchDetailType,
   Resource,
   SpecialChallengeDetailsType
} from '../../types'
import BaseMatch from '../BaseMatch'
import SpecialChallengeDetails from './SpecialChallengeDetails'
import ChallengeMileStone from './ChallengeMileStone'

class SpecialChallengeMatch extends BaseMatch {
   specialChallengeDetails: SpecialChallengeDetails
   challengeMilestones: Array<ChallengeMileStone>
   fanBase: Resource
   constructor(specialChallengeMatchObj: MatchDetailType, matchesService) {
      super(specialChallengeMatchObj, matchesService)
      const isAchievedMilestoneFn = this.getSelfUserMatchDetails()
         .getUserMileStoneObject
      const specialChallengeDetails = JSON.parse(
         specialChallengeMatchObj.game_details_blob
      ).special_challenge_details
      this.specialChallengeDetails = new SpecialChallengeDetails(
         specialChallengeDetails as SpecialChallengeDetailsType
      )
      this.challengeMilestones = specialChallengeDetails?.challenge_milestones.map(
         milestone => new ChallengeMileStone(milestone, isAchievedMilestoneFn)
      )
      this.fanBase = this.getFanbaseResource()
   }

   getMileStonesCount = () => {
      if (this.challengeMilestones) {
         return this.challengeMilestones?.length
      }
      return 0
   }

   getFanbaseResource(): Resource {
      const defaultResource = {
         display_name: '',
         value: -1,
         pic: '',
         thumbnail: '',
         name: '',
         description: ''
      }
      const matchUserDetails = this.getSelfUserMatchDetails()
      let resource
      if (matchUserDetails.gameStats.totalPrize) {
         const userRewardResource = getResourceItems(
            matchUserDetails.gameStats.totalPrize
         )
         resource = userRewardResource.find(
            item => item.name === ResourceEnums.FAN_BASE
         )
      }
      return resource === undefined ? defaultResource : resource
   }

   @computed get challengeMileStonesCount(): number {
      return this.challengeMilestones ? this.challengeMilestones.length : 0
   }

   @computed get achivedChallengeMileStonesCount(): number {
      const achivedMileStones = this.challengeMilestones?.filter(
         milestone => milestone.isTargetAchieved
      )
      return achivedMileStones ? achivedMileStones.length : 0
   }
}
export default SpecialChallengeMatch
