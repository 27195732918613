import React from 'react'
interface Props {
   width: number
   height: number
}

const GemIcon = (props: Props) => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
      viewBox='0 0 39 39'
   >
      <defs>
         <linearGradient
            id='prefix__d'
            x1='101.356%'
            x2='24.518%'
            y1='50%'
            y2='0%'
         >
            <stop offset='0%' stopColor='#EC31ED'></stop>
            <stop offset='100%' stopColor='#F874F8'></stop>
         </linearGradient>
         <linearGradient
            id='prefix__e'
            x1='87.777%'
            x2='31.804%'
            y1='10.649%'
            y2='30.615%'
         >
            <stop offset='0%' stopColor='#EC31ED'></stop>
            <stop offset='100%' stopColor='#F874F8'></stop>
         </linearGradient>
         <filter
            id='prefix__a'
            width='198.3%'
            height='198.3%'
            x='-47.3%'
            y='-47.3%'
            filterUnits='objectBoundingBox'
         >
            <feOffset
               dy='2'
               in='SourceAlpha'
               result='shadowOffsetOuter1'
            ></feOffset>
            <feGaussianBlur
               in='shadowOffsetOuter1'
               result='shadowBlurOuter1'
               stdDeviation='1.5'
            ></feGaussianBlur>
            <feColorMatrix
               in='shadowBlurOuter1'
               result='shadowMatrixOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
            ></feColorMatrix>
            <feMerge>
               <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
               <feMergeNode in='SourceGraphic'></feMergeNode>
            </feMerge>
         </filter>
         <path
            id='prefix__b'
            d='M4.883.667L11.099 0l6.181.667 4.861 6.439-11.06 12.787L.021 7.106z'
         ></path>
      </defs>
      <g
         fill='none'
         fillRule='evenodd'
         filter='url(#prefix__a)'
         transform='translate(8 8)'
      >
         <g transform='translate(0 1.991)'>
            <mask id='prefix__c' fill='#fff'>
               <use xlinkHref='#prefix__b'></use>
            </mask>
            <use fill='#FEEEB7' xlinkHref='#prefix__b'></use>
            <path
               fill='#D815D7'
               d='M17.637 19.89h4.504L11.081 7.104z'
               mask='url(#prefix__c)'
               transform='matrix(1 0 0 -1 0 26.994)'
            ></path>
            <path
               fill='#D815D7'
               d='M6.578 19.894h4.503L.022 7.104z'
               mask='url(#prefix__c)'
               transform='rotate(180 5.552 13.499)'
            ></path>
            <path
               fill='#FE4BFF'
               d='M4.526 19.892l13.11-.003-6.554-12.785z'
               mask='url(#prefix__c)'
               transform='matrix(1 0 0 -1 0 26.997)'
            ></path>
            <path
               fill='url(#prefix__d)'
               d='M17.28.666l.358 6.438L11.099.001z'
               mask='url(#prefix__c)'
            ></path>
            <path
               fill='#FF6BFE'
               d='M17.28.666l.358 6.438h4.502z'
               mask='url(#prefix__c)'
            ></path>
            <path
               fill='#FF6BFE'
               d='M.022.666L.38 7.104h4.503z'
               mask='url(#prefix__c)'
               transform='matrix(-1 0 0 1 4.905 0)'
            ></path>
            <path
               fill='url(#prefix__e)'
               d='M10.741.665l.358 6.438L4.526.001z'
               mask='url(#prefix__c)'
               transform='matrix(-1 0 0 1 15.625 0)'
            ></path>
            <path
               fill='#FF98FF'
               d='M4.526 7.104h13.111L11.099.001z'
               mask='url(#prefix__c)'
            ></path>
         </g>
         <path
            fill='#FFF'
            d='M19.937 2.724c-1.113-.287-1.99-1.278-2.244-2.536-.02-.095-.13-.095-.15 0-.253 1.258-1.129 2.249-2.243 2.536-.084.022-.084.147 0 .169 1.113.287 1.99 1.278 2.244 2.536.019.095.13.095.149 0 .254-1.258 1.13-2.25 2.244-2.536.084-.022.084-.147 0-.169m-3.291 2.253c-.587-.172-1.05-.695-1.202-1.358-.015-.067-.091-.067-.106 0-.153.663-.615 1.186-1.202 1.358-.06.017-.06.104 0 .12.587.173 1.05.695 1.202 1.359.015.067.091.067.106 0 .152-.664.615-1.186 1.202-1.358.06-.017.06-.104 0-.121'
         ></path>
      </g>
   </svg>
)

GemIcon.defaultProps = {
   width: 24,
   height: 24
}

export default GemIcon
