import React, { ReactElement } from 'react'

interface Props {
   className: string
   width: number
   height: number
}

const SuperRunWithNoOutlineIcon = (props: Props): ReactElement => (
   <svg
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width}
      height={props.height}
   >
      <defs>
         <linearGradient
            id='prefix__superrun_c'
            x1='51.985%'
            x2='38.12%'
            y1='54.246%'
            y2='43.874%'
         >
            <stop offset='0%' stopColor='#FFC100'></stop>
            <stop offset='100%' stopColor='#ED964E'></stop>
         </linearGradient>
         <linearGradient
            id='prefix__superrun_d'
            x1='50%'
            x2='73.411%'
            y1='0%'
            y2='50%'
         >
            <stop offset='0%' stopColor='#FFF' stopOpacity='0.7'></stop>
            <stop offset='20.951%' stopColor='#FFF' stopOpacity='0.634'></stop>
            <stop offset='100%' stopColor='#FFF' stopOpacity='0.489'></stop>
         </linearGradient>
         <filter
            id='prefix__superrun_a'
            width='142%'
            height='121.7%'
            x='-21%'
            y='-5.4%'
            filterUnits='objectBoundingBox'
         >
            <feOffset
               dy='1'
               in='SourceAlpha'
               result='shadowOffsetOuter1'
            ></feOffset>
            <feGaussianBlur
               in='shadowOffsetOuter1'
               result='shadowBlurOuter1'
               stdDeviation='0.5'
            ></feGaussianBlur>
            <feColorMatrix
               in='shadowBlurOuter1'
               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
            ></feColorMatrix>
         </filter>
         <path
            id='prefix__superrun_b'
            d='M3.137 1.514A478.193 478.193 0 00.82 9.43c-.297 1.066.259 1.357.576 1.335l.441-.03h1.518l-2.242 7 .058.837-.005.02a.478.478 0 00.462.608h.313c.201 0 .392-.09.518-.247l6.952-8.602a.665.665 0 00-.518-1.086H6.222l3.966-7.486A.666.666 0 009.598.8H4.095a.999.999 0 00-.958.714z'
         ></path>
      </defs>
      <g fill='none' fillRule='evenodd'>
         <g transform='rotate(4 -1.66 10.25)'>
            <use
               fill='#000'
               filter='url(#prefix__superrun_a)'
               xlinkHref='#prefix__superrun_b'
            ></use>
            <use fill='#935D00' xlinkHref='#prefix__superrun_b'></use>
         </g>
         <path
            fill='url(#prefix__superrun_c)'
            d='M3.14 1.519L.83 9.448a.666.666 0 00.64.853h1.886l-2.182 7.491a.473.473 0 00.455.607h.322a.667.667 0 00.509-.237l6.903-8.17a.666.666 0 00-.509-1.096H6.222l3.936-7.108a.667.667 0 00-.583-.99H4.1c-.444 0-.835.294-.96.72z'
            transform='rotate(4 -1.685 9.848)'
         ></path>
         <path
            fill='url(#prefix__superrun_d)'
            d='M3.432 1.428L1.104 9.32a.667.667 0 00.625.855l1.958.043L7.442 1.07l.16-.39-3.22.03a1 1 0 00-.95.717z'
            transform='rotate(4 -2.82 5.7)'
         ></path>
      </g>
   </svg>
)

SuperRunWithNoOutlineIcon.defaultProps = {
   width: 11,
   height: 21
}

export default SuperRunWithNoOutlineIcon
