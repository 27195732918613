import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import MaxIcon from '../../../icons/MaxIcon'
import { Typo14WhiteBarlowBoldItalic } from '../../../styleGuide/Typos'
import { ResourceItemWrapperProps } from '../ResourceItemWrapper'

import {
   FanBaseResourceItemContainer,
   ResourceNameContainer,
   ResourceCountBg,
   ResourceName,
   CurrentResourceCount,
   CurrentResourceCountText,
   CurrentResourceImage,
   InnerResourceCountBg
} from './styledComponents'

const FanBaseResource = (props: ResourceItemWrapperProps): ReactElement => {
   const { t } = useTranslation()
   return (
      <FanBaseResourceItemContainer>
         <CurrentResourceImage src={props.resource.pic}></CurrentResourceImage>
         <CurrentResourceCount>
            <CurrentResourceCountText>
               {props.resource.value}
            </CurrentResourceCountText>
         </CurrentResourceCount>
         <ResourceNameContainer>
            <ResourceName>{t('resources.fanBase')}</ResourceName>
            <ResourceCountBg>
               <InnerResourceCountBg>
                  <MaxIcon />
                  <Typo14WhiteBarlowBoldItalic>
                     {props.fanBaseATH}
                  </Typo14WhiteBarlowBoldItalic>
               </InnerResourceCountBg>
            </ResourceCountBg>
         </ResourceNameContainer>
      </FanBaseResourceItemContainer>
   )
}

export default FanBaseResource
