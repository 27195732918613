import React, { ReactElement } from 'react'

import {
   RoundThreeBackgroundBoxContainer,
   RoundThreeBackgroundBoxHeader,
   RoundThreeHeadingText
} from './styledComponents'

interface RoundThreeBackgroundBoxProps {
   title: string
}

const RoundThreeBackgroundBox = (
   props: RoundThreeBackgroundBoxProps
): ReactElement => {
   const { title } = props

   return (
      <RoundThreeBackgroundBoxContainer>
         <RoundThreeBackgroundBoxHeader>
            <RoundThreeHeadingText>{title}</RoundThreeHeadingText>
         </RoundThreeBackgroundBoxHeader>
      </RoundThreeBackgroundBoxContainer>
   )
}

export default RoundThreeBackgroundBox
